import React, { useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  Alert,
  Platform,
  ActivityIndicator,
  Text,
  useWindowDimensions,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useRoute } from '@react-navigation/native';
import axios from 'axios';
import { Audio } from 'expo-av';
import { API_URL } from '../../../config';
import { terminalEndpoints } from '../api/terminalApi';
import { KeypadComponent } from '../components/KeypadComponent';
import { PinDisplayComponent } from '../components/PinDisplayComponent';
import { ClockDisplay } from '../components/ClockDisplay';
import { useTerminalSession } from '../hooks/useTerminalSession';
import { useToast } from '../../../context/ToastContext';
import { EmployeeInfoModal } from '../components/EmployeeInfoModal';

type RootStackParamList = {
  TerminalActivation: undefined;
  Login: undefined;
};

type NavigationProp = NativeStackNavigationProp<RootStackParamList, 'TerminalActivation'>;

export const TerminalScreen = () => {
  const navigation = useNavigation<NavigationProp>();
  const route = useRoute();
  const { locationId: routeLocationId, locationName: routeLocationName } = route.params || {};
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const isTablet = width >= 600;
  
  const [pin, setPin] = useState('');
  const [lastAction, setLastAction] = useState<'in' | 'out' | null>(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [locationId, setLocationId] = useState<string | null>(routeLocationId || null);
  const [locationName, setLocationName] = useState<string | null>(routeLocationName || null);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const { currentSession, startSession, endSession, isLoading } = useTerminalSession();
  const toast = useToast();
  
  // State za info funkcionalnost
  const [infoMode, setInfoMode] = useState(false);
  const [infoModeTimeoutId, setInfoModeTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [showEmployeeInfo, setShowEmployeeInfo] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState<any>(null);
  const [infoSound, setInfoSound] = useState<Audio.Sound | null>(null);

  const showNotification = async (title: string, body: string, type: string = 'success') => {
    try {
      toast.showToast(body, title, type, 5000);
    } catch (error) {
      // Ako ne uspije toast, pokazat ćemo barem Alert
      Alert.alert(title, body);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Očisti timer i toast kada se PIN unese
  useEffect(() => {
    if (pin.length === 4 && infoMode) {
      if (infoModeTimeoutId) {
        clearTimeout(infoModeTimeoutId);
      }
      toast.hideToastImmediately();
    }
  }, [pin, infoMode, infoModeTimeoutId]);

  useEffect(() => {
    const checkTerminalStatus = async () => {
      try {
        if (routeLocationId) {
          setLocationId(routeLocationId);
          setLocationName(routeLocationName);
          return;
        }

        const [storedLocationId, storedLocationName, terminalActive] = await Promise.all([
          AsyncStorage.getItem('locationId'),
          AsyncStorage.getItem('locationName'),
          AsyncStorage.getItem('terminalActive')
        ]);

        if (storedLocationId && terminalActive === 'true') {
          try {
            const response = await terminalEndpoints.getLocationName(storedLocationId);
            setLocationName(response.data.name);
          } catch (error) {
            setLocationName(storedLocationName);
          }
          setLocationId(storedLocationId);
        } else {
          navigation.replace('Login');
        }
      } catch (error) {
        navigation.replace('Login');
      }
    };

    checkTerminalStatus();
  }, [routeLocationId, routeLocationName, navigation]);

  useEffect(() => {
    const getDeviceId = async () => {
      try {
        const storedDeviceId = await AsyncStorage.getItem('@device_id');
        setDeviceId(storedDeviceId);
      } catch (error) {
      }
    };

    getDeviceId();
  }, []);

  const handleKeyPress = (key: string) => {
    if (infoMode) {
      // U info modu, PIN se koristi za dohvaćanje informacija o djelatniku
      if (pin.length < 4) {
        const newPin = pin + key;
        setPin(newPin);
        
        // Resetiraj timer za info mod
        if (infoModeTimeoutId) {
          clearTimeout(infoModeTimeoutId);
        }
        
        // Ako je PIN već unesen, ne postavljamo novi timeout
        if (newPin.length < 4) {
          const timeoutId = setTimeout(() => {
            if (infoMode && !showEmployeeInfo) {
              setInfoMode(false);
              setPin('');
              toast.showToast('Info mod isključen', 'Isteklo je vrijeme za unos PIN-a', 'info', 3000);
            }
          }, 10000);
          setInfoModeTimeoutId(timeoutId);
        }
        
        if (newPin.length === 4) {
          handleInfoPinSubmit(newPin);
        }
      }
    } else {
      // Standardni mod za prijavu/odjavu
      if (pin.length < 4) {
        const newPin = pin + key;
        setPin(newPin);
        
        if (newPin.length === 4) {
          handlePinSubmit(newPin);
        }
      }
    }
  };

  const handleDelete = () => {
    setPin(prev => prev.slice(0, -1));
    
    // Ako smo u info modu, resetiraj timer
    if (infoMode && infoModeTimeoutId) {
      clearTimeout(infoModeTimeoutId);
      const timeoutId = setTimeout(() => {
        setInfoMode(false);
        setPin('');
        toast.showToast('Info mod isključen', 'Isteklo je vrijeme za unos PIN-a', 'info', 3000);
      }, 10000);
      setInfoModeTimeoutId(timeoutId);
    }
  };

  const handlePinSubmit = async (submittedPin: string) => {
    // Ako smo u info modu, ne izvršavaj standardnu prijavu/odjavu
    if (infoMode) {
      return;
    }
    
    if (!locationId) {
      await AsyncStorage.clear();
      navigation.replace('Login');
      return;
    }

    try {
      const employeeResponse = await axios.get(`${API_URL}/employees/verify-pin`, {
        params: {
          locationId,
          pin: submittedPin
        }
      });

      if (employeeResponse.data && employeeResponse.data.name) {
        try {
          await AsyncStorage.setItem('currentEmployeeId', employeeResponse.data.id);
          
          const sessionResponse = await terminalEndpoints.startSession(employeeResponse.data.id);
          
          await AsyncStorage.setItem('currentSessionId', sessionResponse._id);
          const employeeFullName = `${employeeResponse.data.name} ${employeeResponse.data.surname || ''}`;
          showNotification(`${employeeFullName}- uspješna prijava`, `Uspješno ste se prijavili u smjenu za poslovnicu ${locationName}`, 'checkin');
        } catch (sessionError: any) {
          if (sessionError.message === 'redirect_to_login') {
            navigation.replace('Login');
            return;
          }
          
          if (sessionError.response?.status === 400 && sessionError.response?.data?.existingSession) {
            const sessionId = sessionError.response.data.existingSession.id;
            await AsyncStorage.setItem('currentSessionId', sessionId);
            
            // Odmah resetiraj PIN i navigiraj na BreakList za brži odaziv
            setPin('');
            toast.hideToastImmediately(); // Sakrij sve aktivne toast poruke
            navigation.navigate('BreakList');
            
            // Paralelno dohvati podatke o sesiji
            terminalEndpoints.getActiveSession(employeeResponse.data.id, locationId)
              .then(session => {
                // Ako postoji aktivna pauza, preusmjeri na ActiveBreak
                const activeBlock = session.workBlocks.find((block: any) => !block.endTime);
                if (activeBlock && activeBlock.breaks && activeBlock.breaks.length > 0) {
                  const activeBreak = activeBlock.breaks.find((breakItem: any) => !breakItem.endTime);
                  if (activeBreak) {
                    toast.hideToastImmediately(); // Sakrij sve aktivne toast poruke
                    navigation.navigate('ActiveBreak', {
                      sessionId,
                      breakId: activeBreak.breakId,
                      breakName: activeBreak.breakName,
                      startTime: activeBreak.startTime,
                      allowedMinutes: activeBreak.allowedMinutes,
                      employeeName: `${employeeResponse.data.name}`
                    });
                  }
                }
              })
              .catch(error => {
                console.error('Greška pri dohvaćanju aktivne sesije:', error);
              });
            
            return;
          }
          
          // Prikaži crveni toast za grešku pri započinjanju smjene
          toast.showToast('Greška', 'Greška pri započinjanju smjene', 'error', 3000);
        }

        setPin('');
        return;
      }
    } catch (error: any) {
      if (error.response?.data?.message === 'Lokacija nije pronađena') {
        await AsyncStorage.clear();
        navigation.replace('Login');
        return;
      }
      
      // Prikaži crveni toast za neispravan PIN
      toast.showToast('Neispravan PIN', 'Molimo provjerite PIN i pokušajte ponovno', 'error', 3000);
      
      setPin('');
    }
  };

  // Funkcija za aktiviranje info moda
  const handleInfoPress = () => {
    // Reproduciraj zvuk za info
    playInfoSound();
    
    setInfoMode(true);
    setPin('');
    
    // Koristimo setTimeout da damo vremena našem zvuku da se reproducira prije toasta
    // koji bi inače pokrenuo zvuk greške
    setTimeout(() => {
      toast.showToast('Pregled djelatnika aktiviran', 'Unesite PIN djelatnika', 'info', 3000);
    }, 50);
    
    // Postavi timer za automatsko isključivanje info moda nakon 10 sekundi
    if (infoModeTimeoutId) {
      clearTimeout(infoModeTimeoutId);
    }
    const timeoutId = setTimeout(() => {
      // Samo ako još uvijek smo u info modu i modal nije prikazan
      if (infoMode && !showEmployeeInfo) {
        setInfoMode(false);
        setPin('');
        toast.showToast('Info mod isključen', 'Isteklo je vrijeme za unos PIN-a', 'info', 3000);
      }
    }, 10000);
    setInfoModeTimeoutId(timeoutId);
  };

  // Funkcija za reprodukciju zvuka info
  const playInfoSound = async () => {
    try {
      // Zaustavi prethodni zvuk ako postoji
      if (infoSound) {
        await infoSound.unloadAsync();
      }
      
      // Učitaj i reproduciraj zvuk za info
      const soundFile = require('../../../../assets/sounds/checkinfo.mp3');
      
      const { sound: newSound } = await Audio.Sound.createAsync(soundFile);
      setInfoSound(newSound);
      await newSound.playAsync();
    } catch (error) {
      console.error('Greška pri reprodukciji zvuka:', error);
    }
  };

  // Funkcija za obradu PIN-a za info
  const handleInfoPinSubmit = async (infoPin: string) => {
    try {
      // Očisti info mod
      setInfoMode(false);
      setPin('');
      
      // Očisti timeout za info mod i sakrij sve toast poruke
      if (infoModeTimeoutId) {
        clearTimeout(infoModeTimeoutId);
        setInfoModeTimeoutId(null);
      }
      toast.hideToastImmediately(); // Sakrij sve aktivne toast poruke
      
      if (!locationId) {
        return;
      }

      const employeeResponse = await axios.get(`${API_URL}/employees/verify-pin`, {
        params: {
          locationId,
          pin: infoPin
        }
      });

      if (employeeResponse.data && employeeResponse.data.id) {
        try {
          const employeeId = employeeResponse.data.id;
          const infoResponse = await terminalEndpoints.getEmployeeInfo(employeeId, locationId);
          
          setEmployeeInfo({
            ...infoResponse,
            name: `${employeeResponse.data.name} ${employeeResponse.data.surname || ''}`,
            employeeId: employeeId
          });
          
          setShowEmployeeInfo(true);
        } catch (error) {
          console.error('Greška pri dohvaćanju informacija o djelatniku:', error);
          toast.showToast('Greška', 'Nije moguće dohvatiti informacije o djelatniku', 'error', 3000);
        }
      } else {
        toast.showToast('Neispravan PIN', 'Molimo provjerite PIN i pokušajte ponovno', 'error', 3000);
      }
    } catch (error) {
      toast.showToast('Neispravan PIN', 'Molimo provjerite PIN i pokušajte ponovno', 'error', 3000);
    }
  };

  // Funkcija za zatvaranje info modala
  const handleEmployeeInfoDismiss = () => {
    setShowEmployeeInfo(false);
  };

  // Očisti timer za info mod kad se komponenta unmountira
  useEffect(() => {
    return () => {
      if (infoModeTimeoutId) {
        clearTimeout(infoModeTimeoutId);
      }
      
      // Očisti zvuk kada se komponenta unmountira
      if (infoSound) {
        infoSound.unloadAsync().catch(() => {});
      }
    };
  }, [infoModeTimeoutId, infoSound]);

  if (!locationId || !locationName) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={theme.colors.primary} />
      </View>
    );
  }

  return (
    <SafeAreaView style={[styles.container, { backgroundColor: '#fff' }]}>
      <View style={styles.content}>
        <ClockDisplay 
          currentTime={currentTime}
          isTablet={isTablet}
        />
        
        <View style={styles.mainContent}>
          <PinDisplayComponent 
            pinLength={pin.length} 
            maxLength={4}
            isTablet={isTablet}
            locationName={infoMode ? 'Unesite PIN djelatnika' : locationName}
          />
          
          <KeypadComponent 
            onKeyPress={handleKeyPress}
            onDelete={handleDelete}
            onInfoPress={handleInfoPress}
            isTablet={isTablet}
          />
        </View>
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerText}>Device ID: {deviceId}</Text>
        <Text style={styles.footerSubText}>clock.hr</Text>
      </View>

      {/* Employee Info Modal */}
      <EmployeeInfoModal
        visible={showEmployeeInfo}
        onDismiss={handleEmployeeInfoDismiss}
        employeeData={employeeInfo}
        employeeId={employeeInfo?.employeeId}
        locationId={locationId}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  mainContent: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    paddingVertical: 15,
    paddingBottom: Platform.OS === 'ios' ? 15 : 25,
  },
  footerText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#999',
    marginBottom: 5,
  },
  footerSubText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#666',
    fontWeight: '500',
  },
});
