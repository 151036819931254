import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Platform } from 'react-native';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';

interface KeypadComponentProps {
  onKeyPress: (key: string) => void;
  onDelete: () => void;
  onInfoPress?: () => void;
  isTablet?: boolean;
}

export const KeypadComponent: React.FC<KeypadComponentProps> = ({ 
  onKeyPress, 
  onDelete,
  onInfoPress,
  isTablet = false 
}) => {
  const dynamicStyles = {
    numberButton: {
      width: isTablet ? 76 : 72,
      height: isTablet ? 76 : 72,
      borderRadius: isTablet ? 38 : 36,
      backgroundColor: '#ffffff',
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.15,
      shadowRadius: 3,
      elevation: 3,
    },
    numberText: {
      fontSize: isTablet ? 32 : 32,
      color: '#000000',
      fontWeight: '600',
    },
  };

  const renderNumber = (number: string) => (
    <TouchableOpacity
      key={number}
      style={[styles.numberButton, dynamicStyles.numberButton]}
      onPress={() => onKeyPress(number)}
    >
      <Text style={[styles.numberText, dynamicStyles.numberText]}>{number}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={[styles.keypad, { maxWidth: isTablet ? 380 : 280 }]}>
      <View style={styles.keypadRow}>
        {['1', '2', '3'].map(renderNumber)}
      </View>
      <View style={styles.keypadRow}>
        {['4', '5', '6'].map(renderNumber)}
      </View>
      <View style={styles.keypadRow}>
        {['7', '8', '9'].map(renderNumber)}
      </View>
      <View style={styles.keypadRow}>
        {onInfoPress ? (
          <TouchableOpacity
            style={[styles.numberButton, dynamicStyles.numberButton, { backgroundColor: '#007AFF' }]}
            onPress={onInfoPress}
          >
            <MaterialIcons name="info" size={isTablet ? 24 : 28} color="#fff" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={[styles.numberButton, dynamicStyles.numberButton, { opacity: 0 }]}
            disabled={true}
          >
            <Text style={[styles.numberText, dynamicStyles.numberText]}>7</Text>
          </TouchableOpacity>
        )}
        {renderNumber('0')}
        <TouchableOpacity
          style={[styles.numberButton, dynamicStyles.numberButton, { backgroundColor: '#ff3b30' }]}
          onPress={onDelete}
        >
          <Ionicons name="backspace-outline" size={isTablet ? 24 : 28} color="#fff" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  keypad: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 20,
    width: '100%',
    maxWidth: 280,
    alignSelf: 'center',
  },
  keypadRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  numberButton: {
    width: 72,
    height: 72,
    borderRadius: 36,
    backgroundColor: 'rgb(255, 255, 255)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberText: {
    fontSize: 32,
    color: '#000',
    fontWeight: '400',
  },
});
