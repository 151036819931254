import React, { useState, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useLocations } from '../../../hooks/useLocations';
import { ErrorScreen } from '../../../components/ErrorScreen';
import { useAuthContext } from '../../../context/AuthContext';
import { useDashboardStats } from '../../../hooks/useDashboardStats';
import { useLocationStats } from '../../../hooks/useLocationStats';
import { WebDashboardContent } from './WebDashboardContent';
import { WebDashboardSkeleton } from './components/WebDashboardSkeleton';

export const WebDashboardScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { owner } = useAuthContext();
  const [refreshing, setRefreshing] = useState(false);
  const { data: locations, isLoading: locationsLoading, error: locationsError, refetch: refetchLocations } = useLocations();
  const { refetch: refetchStats } = useDashboardStats();
  const { refetch: refetchLocationStats } = useLocationStats();

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await Promise.all([
      refetchLocations(),
      refetchStats(),
      refetchLocationStats()
    ]);
    setRefreshing(false);
  }, [refetchLocations, refetchStats, refetchLocationStats]);

  if (locationsLoading) {
    return <WebDashboardSkeleton />;
  }

  if (locationsError) {
    return <ErrorScreen error={locationsError} />;
  }

  return (
    <View style={styles.container}>
      <WebDashboardContent 
        locations={locations || []}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    padding: 0,
    backgroundColor: '#FFFFFF',
  }
});
