import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';

interface NavItemProps {
  title: string;
  route: string;
  icon: string;
  isActive: boolean;
}

const NavItem = ({ title, route, icon, isActive }: NavItemProps) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={[styles.navItem, isActive && styles.navItemActive]}
      onPress={() => navigation.navigate(route as never)}
    >
      <Ionicons 
        name={icon as any} 
        size={16} 
        color={isActive ? '#4C6FFF' : '#64748B'} 
      />
      <Text style={[styles.navText, isActive && styles.navTextActive]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export const HelpSidebar = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const navItems = [
    {
      title: 'Pregled',
      route: 'Help',
      icon: 'home-outline'
    },
    {
      title: 'O nama',
      route: 'AboutUs',
      icon: 'information-circle-outline'
    },
    {
      title: 'Kontakt',
      route: 'Contact',
      icon: 'mail-outline'
    },
    {
      title: 'Česta pitanja',
      route: 'FAQ',
      icon: 'help-circle-outline'
    },
    {
      title: 'Pravni dokumenti',
      route: 'LegalDocuments',
      icon: 'document-text-outline',
      subItems: [
        {
          title: '1. Zaštita podataka',
          route: 'legal/privacy'
        },
        {
          title: '2. Uvjeti korištenja',
          route: 'legal/terms'
        },
        {
          title: '3. Zakonska usklađenost',
          route: 'legal/compliance'
        },
        {
          title: '4. Sigurnost',
          route: 'legal/security'
        }
      ]
    },
    {
      title: 'Početak rada',
      route: 'Guide',
      icon: 'play-outline',
      subItems: [
        {
          title: '1. Registracija i prijava',
          route: 'guide/registration'
        },
        {
          title: '2. Postavljanje lokacije',
          route: 'guide/location-setup'
        },
        {
          title: '3. Aktivacija terminala',
          route: 'guide/terminal-setup'
        },
        {
          title: '4. Upravljanje djelatnicima',
          route: 'guide/employee-setup'
        },
        {
          title: '5. Upravljanje pauzama',
          route: 'guide/break-setup'
        },
        {
          title: '6. Pristupni podaci',
          route: 'guide/access-management'
        },
        {
          title: '7. Testiranje sustava',
          route: 'guide/system-testing'
        },
        {
          title: '8. Savjeti za početak',
          route: 'guide/getting-started-tips'
        }
      ]
    }
  ];

  const renderNavItem = (item: any, index: number) => {
    const isActive = route.name === item.route;
    const [isExpanded, setIsExpanded] = useState(isActive);

    if (item.subItems) {
      return (
        <View key={index}>
          <TouchableOpacity
            style={[styles.navItem, isActive && styles.navItemActive]}
            onPress={() => {
              setIsExpanded(!isExpanded);
              if (!isExpanded) {
                navigation.navigate(item.route as never);
              }
            }}
          >
            <Ionicons 
              name={item.icon as any} 
              size={16} 
              color={isActive ? '#4C6FFF' : '#64748B'} 
            />
            <Text style={[styles.navText, isActive && styles.navTextActive]}>
              {item.title}
            </Text>
            <Ionicons 
              name={isExpanded ? 'chevron-down-outline' : 'chevron-forward-outline'} 
              size={16} 
              color='#64748B' 
              style={styles.expandIcon}
            />
          </TouchableOpacity>
          {isExpanded && (
            <View style={styles.subItems}>
              {item.subItems.map((subItem: any, subIndex: number) => (
                <TouchableOpacity
                  key={subIndex}
                  style={[styles.subItem, route.name === subItem.route && styles.subItemActive]}
                  onPress={() => navigation.navigate(subItem.route as never)}
                >
                  <Text style={[
                    styles.subItemText,
                    route.name === subItem.route && styles.subItemTextActive
                  ]}>
                    {subItem.title}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
      );
    }

    return (
      <NavItem
        key={index}
        {...item}
        isActive={route.name === item.route}
      />
    );
  };

  if (isMobile) {
    return (
      <View style={styles.mobileNav}>
        {navItems.map((item, index) => renderNavItem(item, index))}
      </View>
    );
  }

  return (
    <View style={styles.sidebar}>
      <View style={styles.logoContainer}>
        <View style={styles.logoRow}>
          <TouchableOpacity 
            style={styles.logoTextContainer}
            onPress={() => navigation.navigate('Landing' as never)}
          >
            <Text style={styles.logoPart}>Clock</Text>
            <Text style={styles.logoDot}>.</Text>
            <Text style={styles.logoHr}>hr</Text>
          </TouchableOpacity>
          <Text style={styles.chevron}>›</Text>
          <TouchableOpacity 
            style={styles.helpCenterButton}
            onPress={() => navigation.navigate('Help' as never)}
          >
            <Text style={styles.helpCenter}>Help center</Text>
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.sidebarContent}>
        <Text style={styles.sidebarTitle}>Vodič</Text>
        {navItems.map((item, index) => renderNavItem(item, index))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sidebar: {
    height: '100%',
    backgroundColor: '#ffffff',
    borderRightWidth: 1,
    borderRightColor: '#E2E8F0',
    width: 280,
    paddingTop: 24,
  },
  logoContainer: {
    padding: 20,
    paddingBottom: 0,
  },
  logoRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoPart: {
    fontSize: 18,
    fontWeight: '700',
    color: '#1E293B',
  },
  logoDot: {
    fontSize: 18,
    fontWeight: '700',
    color: '#4C6FFF',
  },
  logoHr: {
    fontSize: 18,
    fontWeight: '700',
    color: '#1E293B',
  },
  chevron: {
    fontSize: 18,
    color: '#64748B',
    marginLeft: 8,
    marginRight: 8,
    marginTop: -2,
  },
  helpCenter: {
    fontSize: 18,
    color: '#64748B',
    fontWeight: '500',
  },
  helpCenterButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sidebarContent: {
    padding: 20,
  },
  sidebarTitle: {
    fontSize: 13,
    fontWeight: '600',
    color: '#94A3B8',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    marginBottom: 16,
  },
  mobileNav: {
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    padding: 16,
  },
  navItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginBottom: 4,
    borderRadius: 8,
    cursor: 'pointer',
  },
  navItemActive: {
    backgroundColor: '#F0F3FF',
  },
  navText: {
    fontSize: 14,
    color: '#64748B',
    marginLeft: 12,
    flex: 1,
  },
  navTextActive: {
    color: '#4C6FFF',
    fontWeight: '500',
  },
  expandIcon: {
    marginLeft: 8,
  },
  subItems: {
    marginLeft: 32,
    marginTop: 4,
    marginBottom: 8,
  },
  subItem: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginBottom: 2,
    borderRadius: 6,
    cursor: 'pointer',
  },
  subItemActive: {
    backgroundColor: '#F0F3FF',
  },
  subItemText: {
    fontSize: 13,
    color: '#64748B',
  },
  subItemTextActive: {
    color: '#4C6FFF',
    fontWeight: '500',
  },
});
