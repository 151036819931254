import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';

const pricingTiers = [
  { min: 1, max: 50, pricePerEmployee: 10 },
  { min: 51, max: 100, pricePerEmployee: 8 },
];

export const PriceCalculator = () => {
  const [employeeCount, setEmployeeCount] = useState(5);
  const [selectedPeriod, setSelectedPeriod] = useState<'monthly' | 'yearly'>('monthly');

  const getCurrentTier = (count: number) => {
    return pricingTiers.find(tier => count >= tier.min && count <= tier.max);
  };

  const calculatePrice = (count: number): number => {
    const tier = getCurrentTier(count);
    if (!tier) return 0;
    const basePrice = count * tier.pricePerEmployee;
    return selectedPeriod === 'yearly' ? basePrice * 10 : basePrice; // 2 mjeseca gratis za godišnju pretplatu
  };

  const getInfoText = (count: number): string => {
    if (count > 100) {
      return 'Za više od 100 djelatnika, kontaktirajte nas za individualnu ponudu.';
    }
    const tier = getCurrentTier(count);
    if (!tier) return '';
    return `${tier.pricePerEmployee}€ po djelatniku`;
  };

  return (
    <View style={styles.container}>
      <View style={styles.periodToggle}>
        <Text
          style={[
            styles.periodOption,
            selectedPeriod === 'monthly' && styles.periodOptionActive,
          ]}
          onPress={() => setSelectedPeriod('monthly')}
        >
          Mjesečno
        </Text>
        <Text
          style={[
            styles.periodOption,
            selectedPeriod === 'yearly' && styles.periodOptionActive,
          ]}
          onPress={() => setSelectedPeriod('yearly')}
        >
          Godišnje (2 mjeseca gratis)
        </Text>
      </View>

      <View style={styles.sliderContainer}>
        <Text style={styles.label}>Broj djelatnika: {employeeCount}</Text>
        <input
          type="range"
          min="1"
          max="100"
          value={employeeCount}
          onChange={(e) => setEmployeeCount(Number(e.target.value))}
          style={{
            width: '100%',
            height: '2px',
            backgroundColor: '#E2E8F0',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            outline: 'none',
            opacity: 0.7,
            transition: 'opacity .2s',
            cursor: 'pointer',
            marginBottom: '20px',
          }}
        />
        <style>
          {`
            input[type=range]::-webkit-slider-thumb {
              -webkit-appearance: none;
              width: 20px;
              height: 20px;
              background-color: #4C6FFF;
              border-radius: 50%;
              cursor: pointer;
            }
            input[type=range]::-moz-range-thumb {
              width: 20px;
              height: 20px;
              background-color: #4C6FFF;
              border-radius: 50%;
              cursor: pointer;
              border: none;
            }
          `}
        </style>
        <Text style={styles.priceInfo}>{getInfoText(employeeCount)}</Text>
      </View>

      <View style={styles.priceContainer}>
        <Text style={styles.priceLabel}>Ukupna cijena:</Text>
        <View style={styles.priceWrapper}>
          <Text style={styles.price}>
            {employeeCount > 100 ? 'Po dogovoru' : `${calculatePrice(employeeCount)}€`}
          </Text>
          <Text style={styles.period}>/{selectedPeriod === 'yearly' ? 'godišnje' : 'mjesečno'}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    padding: 32,
    borderRadius: 16,
    width: '100%',
    maxWidth: 600,
    marginHorizontal: 'auto',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    elevation: 4,
  } as any,
  periodToggle: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 16,
    marginBottom: 32,
  },
  periodOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: '#F1F5F9',
    color: '#64748B',
    fontSize: 14,
    fontWeight: '500',
    cursor: 'pointer',
  } as any,
  periodOptionActive: {
    backgroundColor: '#4C6FFF',
    color: '#FFFFFF',
  },
  sliderContainer: {
    marginBottom: 32,
  },
  label: {
    fontSize: 16,
    color: '#1E293B',
    marginBottom: 16,
    fontWeight: '500',
  },
  priceInfo: {
    fontSize: 14,
    color: '#64748B',
    marginTop: 8,
    textAlign: 'center',
  },
  priceContainer: {
    alignItems: 'center',
  },
  priceLabel: {
    fontSize: 14,
    color: '#64748B',
    marginBottom: 8,
  },
  priceWrapper: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 4,
  },
  price: {
    fontSize: 36,
    fontWeight: '700',
    color: '#1E293B',
  },
  period: {
    fontSize: 16,
    color: '#64748B',
  },
});
