import React, { useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { WebFooter } from '../../screens/landing/components/WebFooter';
import { HelpHeader } from './HelpHeader';
import { HelpSidebar } from './HelpSidebar';

interface HelpLayoutProps {
  children: React.ReactNode;
}

export const HelpLayout: React.FC<HelpLayoutProps> = ({ children }) => {
  useEffect(() => {
    // Postavljamo viewport meta tag za force desktop view
    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) {
      viewport.setAttribute('content', 'width=1024');
    } else {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=1024';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    // Čistimo kad se komponenta unmounta
    return () => {
      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1');
      }
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.mainContainer}>
        <View style={styles.sidebarContainer}>
          <HelpSidebar />
        </View>
        <ScrollView style={[styles.contentContainer, styles.contentMargin]}>
          <View style={styles.innerContainer}>
            <HelpHeader />
            <View style={styles.contentWrapper}>
              <View style={styles.mainWrapper}>
                <View style={styles.contentInner}>
                  {children}
                </View>
              </View>
            </View>
            <WebFooter />
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    minHeight: '100vh',
    minWidth: 1024,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    maxWidth: 2000,
    width: '100%',
    marginHorizontal: 'auto',
  },
  sidebarContainer: {
    width: 280,
    borderRightWidth: 1,
    borderRightColor: '#E2E8F0',
    backgroundColor: '#ffffff',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    overflowY: 'auto',
  },
  contentContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  contentMargin: {
    marginLeft: 280,
  },
  innerContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  mainWrapper: {
    maxWidth: 1400,
    width: '100%',
    marginHorizontal: 'auto',
    paddingHorizontal: 40,
    backgroundColor: '#ffffff',
  },
  contentInner: {
    flex: 1,
    backgroundColor: '#ffffff',
    paddingHorizontal: 24,
    paddingTop: 16,
    paddingBottom: 48,
  },
});
