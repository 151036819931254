import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../../../config';
import { TerminalSession, TerminalBreak } from '../types/terminal.types';
import api from '../../../services/api';

// Kreiramo zasebnu instancu axiosa za terminal
const terminalApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor za dodavanje terminal ID-a
terminalApi.interceptors.request.use(async (config) => {
  const terminalId = await AsyncStorage.getItem('@device_id');
  if (terminalId) {
    config.headers['X-Terminal-ID'] = terminalId;
  }
  return config;
});

export const terminalEndpoints = {
  // Terminal aktivacija
  activate: async (activationCode: string) => {
    const deviceId = await AsyncStorage.getItem('@device_id');
    const response = await terminalApi.post('/locations/activate-terminal', { 
      deviceId,
      activationCode 
    });
    return response;
  },
  
  deactivate: async (deactivationPin: string) => {
    const response = await terminalApi.post('/locations/deactivate-terminal', { deactivationPin });
    return response;
  },

  // Validacija pinova
  validateEmployeePin: async (locationId: string, pin: string) => {
    const response = await terminalApi.get(`/employees?locationId=${locationId}&pin=${pin}`);
    return response;
  },
  
  validateDeactivationPin: async (locationId: string, pin: string) => {
    const response = await terminalApi.post(`/locations/${locationId}/validate-deactivation-pin`, { pin });
    return response;
  },

  // Sesije
  startSession: async (employeeId: string): Promise<TerminalSession> => {
    const locationId = await AsyncStorage.getItem('locationId');
    
    if (!locationId) {
      await AsyncStorage.clear();
      throw new Error('redirect_to_login');
    }
    
    const response = await terminalApi.post('/sessions/start', { employeeId, locationId });
    return response.data;
  },
  
  endSession: async (sessionId: string) => {
    const deviceId = await AsyncStorage.getItem('@device_id');
    const response = await terminalApi.post(`/sessions/${sessionId}/end`);
    return response;
  },

  // Pauze
  startBreak: async (sessionId: string, breakType: string): Promise<TerminalBreak> => {
    try {
      const response = await terminalApi.post(`/sessions/${sessionId}/breaks/start`, { type: breakType });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
  endBreak: async (sessionId: string, breakId: string) => {
    try {
      const response = await terminalApi.post(`/sessions/${sessionId}/breaks/${breakId}/end`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Status
  getTerminalStatus: async (locationId: string, deviceId: string) => {
    return terminalApi.get(`/locations/${locationId}/status?deviceId=${deviceId}`);
  },

  getLocationName: async (locationId: string) => {
    return terminalApi.get(`/locations/${locationId}/name`);
  },

  // Aktivna sesija
  getActiveSession: async (employeeId: string, locationId: string) => {
    const response = await terminalApi.get(`/sessions/active/${employeeId}/${locationId}`);
    return response.data;
  },

  // Dohvaćanje informacija o djelatniku za info modal
  getEmployeeInfo: async (employeeId: string, locationId: string, date?: string) => {
    try {
      const url = date 
        ? `${API_URL}/employees/${employeeId}/info?locationId=${locationId}&date=${date}`
        : `${API_URL}/employees/${employeeId}/info?locationId=${locationId}`;
        
      // Vraćam cijeli response objekt da bude kompatibilno s EmployeeInfoModal
      const response = await axios.get(url);
      return { data: response.data }; // Vraćamo objekt s data propertyjem
    } catch (error) {
      throw error;
    }
  },

  // Admin akcije za upravljanje evidencijom djelatnika
  adminStartSession: async (employeeId: string, locationId: string, timestamp: string) => {
    try {
      const response = await api.post('/admin/sessions/start', { employeeId, locationId, timestamp });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  adminEndSession: async (sessionId: string, timestamp: string) => {
    try {
      const response = await api.post(`/admin/sessions/${sessionId}/end`, { timestamp });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  adminStartBreak: async (sessionId: string, breakType: string, timestamp: string) => {
    try {
      const response = await api.post(`/admin/sessions/${sessionId}/breaks/start`, { type: breakType, timestamp });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  adminEndBreak: async (sessionId: string, breakId: string, timestamp: string) => {
    try {
      const response = await api.post(`/admin/sessions/${sessionId}/breaks/${breakId}/end`, { timestamp });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  adminEditSession: async (sessionId: string, startTime?: string, endTime?: string) => {
    try {
      const response = await api.patch(`/admin/sessions/${sessionId}`, { startTime, endTime });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
  adminEditBreak: async (sessionId: string, breakId: string, startTime?: string, endTime?: string) => {
    try {
      const response = await api.patch(`/admin/sessions/${sessionId}/breaks/${breakId}`, { startTime, endTime });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  adminDeleteSession: async (sessionId: string) => {
    try {
      const response = await api.delete(`/admin/sessions/${sessionId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Dohvaćanje dostupnih tipova pauza
  getBreakTypes: async () => {
    try {
      const response = await api.get('/admin/breaks/types');
      return response.data.breakTypes || [];
    } catch (error) {
      return [];
    }
  },

  getEmployeeSessions: async (employeeId: string, locationId: string, date: string) => {
    try {
      const response = await api.get(`/admin/employee/${employeeId}/sessions`, { 
        params: { locationId, date } 
      });
      return response.data;
    } catch (error) {
      return { sessions: [] };
    }
  }
};

export default terminalApi;