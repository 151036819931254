import React, { useState } from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  Text,
  StyleSheet,
  ActivityIndicator,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { useAuthContext } from '../../context/AuthContext';

type RootStackParamList = {
  Register: undefined;
  Login: undefined;
};

export const WebRegisterScreen = () => {
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 1100;
  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;

  const navigation = useNavigation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { register } = useAuthContext();

  const handleRegister = async () => {
    setHasError(true);
    setErrorMessage('Za registraciju se javite na info@rakicgroup.com');
    return;

    // Stari kod je komentiran
    /*
    if (isSmallScreen) {
      setHasError(true);
      setErrorMessage(
        'Molimo koristite desktop računalo ili preuzmite našu Android aplikaciju za prijavu.'
      );
      return;
    }

    // Validacija
    if (!name.trim()) {
      setHasError(true);
      setErrorMessage('Molimo unesite ime i prezime');
      return;
    }

    if (!email.trim()) {
      setHasError(true);
      setErrorMessage('Molimo unesite email adresu');
      return;
    }

    if (!password || password.length < 6) {
      setHasError(true);
      setErrorMessage('Lozinka mora imati najmanje 6 znakova');
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);
      const response = await register({ name, email, password });
      if (response.success) {
        // Nakon uspješne registracije, preusmjeri na dashboard
        navigation.reset({
          index: 0,
          routes: [{ name: 'Main' as never }],
        });
      } else {
        setHasError(true);
        setErrorMessage(response.error || 'Greška pri registraciji');
        setPassword('');
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage('Došlo je do greške pri registraciji');
      setPassword('');
    } finally {
      setIsLoading(false);
    }
    */
  };

  const handleBackToLogin = () => {
    navigation.navigate('Login' as never);
  };

  return (
    <View style={styles.container}>
      {!isMobile && (
        <View style={[styles.leftPanel, isTablet && styles.leftPanelTablet]}>
          <View style={styles.backgroundGradient} />
          <View style={styles.logoContainer}>
            <View style={styles.logoTextContainer}>
              <Text style={styles.logoPart}>clock</Text>
              <Text style={styles.logoDot}>.</Text>
              <Text style={styles.logoHr}>hr</Text>
            </View>
            <Text style={styles.subtitle}>Evidencija radnog vremena</Text>
          </View>
          <View style={styles.featureContainer}>
            {!isTablet && (
              <>
                <View style={styles.featureItem}>
                  <Ionicons name="rocket-outline" size={48} color="#2563eb" />
                  <Text style={styles.featureText}>Započnite besplatno 7 dana</Text>
                </View>
                <View style={styles.featureItem}>
                  <Ionicons name="cloud-done-outline" size={48} color="#2563eb" />
                  <Text style={styles.featureText}>Bez instalacije - odmah spremno</Text>
                </View>
                <View style={styles.featureItem}>
                  <Ionicons name="shield-checkmark-outline" size={48} color="#2563eb" />
                  <Text style={styles.featureText}>Sigurno i pouzdano rješenje</Text>
                </View>
              </>
            )}
          </View>
        </View>
      )}
      
      <View style={[
        styles.rightPanel, 
        isMobile && styles.rightPanelMobile,
        isTablet && styles.rightPanelTablet
      ]}>
        {isMobile && (
          <View style={styles.mobileLogoContainer}>
            <View style={styles.logoTextContainer}>
              <Text style={styles.logoPart}>clock</Text>
              <Text style={styles.logoDot}>.</Text>
              <Text style={styles.logoHr}>hr</Text>
            </View>
            <Text style={styles.subtitle}>Evidencija radnog vremena</Text>
          </View>
        )}
        <View style={[
          styles.registerBox, 
          isMobile && styles.registerBoxMobile,
          isTablet && styles.registerBoxTablet
        ]}>
          <Text style={[
            styles.registerTitle,
            isMobile && styles.titleMobile,
            isTablet && styles.titleTablet
          ]}>Registracija</Text>
          <Text style={[
            styles.registerSubtitle,
            isMobile && styles.subtitleMobile,
            isTablet && styles.subtitleTablet
          ]}>Kreirajte svoj račun za pristup sustavu</Text>
          
          <View style={styles.inputContainer}>
            <TextInput
              style={[
                styles.input,
                { borderColor: hasError && !name ? '#ff4444' : '#e6e6e6' }
              ]}
              placeholder="Ime i prezime"
              value={name}
              onChangeText={(text) => {
                setName(text);
                setHasError(false);
              }}
              placeholderTextColor="#666666"
            />
            <Ionicons 
              name="person-outline" 
              size={20} 
              color={hasError && !name ? '#ff4444' : '#666666'} 
              style={styles.inputIcon} 
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[
                styles.input,
                { borderColor: hasError && !email ? '#ff4444' : '#e6e6e6' }
              ]}
              placeholder="Email"
              value={email}
              onChangeText={(text) => {
                setEmail(text);
                setHasError(false);
              }}
              autoCapitalize="none"
              keyboardType="email-address"
              placeholderTextColor="#666666"
            />
            <Ionicons 
              name="mail-outline" 
              size={20} 
              color={hasError && !email ? '#ff4444' : '#666666'} 
              style={styles.inputIcon} 
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[
                styles.input,
                { borderColor: hasError && (!password || password.length < 6) ? '#ff4444' : '#e6e6e6' }
              ]}
              placeholder="Lozinka"
              value={password}
              onChangeText={(text) => {
                setPassword(text);
                setHasError(false);
              }}
              secureTextEntry={!showPassword}
              placeholderTextColor="#666666"
            />
            <Ionicons 
              name="lock-closed-outline" 
              size={20} 
              color={hasError && (!password || password.length < 6) ? '#ff4444' : '#666666'} 
              style={styles.inputIcon} 
            />
            <TouchableOpacity 
              onPress={() => setShowPassword(!showPassword)}
              style={styles.eyeIcon}
            >
              <Ionicons 
                name={showPassword ? "eye-off-outline" : "eye-outline"} 
                size={20} 
                color="#666666"
              />
            </TouchableOpacity>
          </View>

          {hasError && (
            <View style={styles.errorContainer}>
              <Ionicons name="alert-circle-outline" size={18} color="#ff4444" />
              <Text style={styles.errorText}>{errorMessage}</Text>
            </View>
          )}

          <TouchableOpacity
            style={[
              styles.button,
              isMobile && styles.buttonMobile,
              isTablet && styles.buttonTablet
            ]}
            onPress={handleRegister}
            disabled={isLoading}
          >
            {isLoading ? (
              <ActivityIndicator color="#fff" />
            ) : (
              <Text style={styles.buttonText}>Registracija</Text>
            )}
          </TouchableOpacity>

          <View style={[
            styles.footerContainer,
            isMobile && styles.footerContainerMobile,
            isTablet && styles.footerContainerTablet
          ]}>
            <TouchableOpacity
              style={styles.backButton}
              onPress={() => navigation.navigate('Landing' as never)}
            >
              <Ionicons name="arrow-back-outline" size={20} color="#666666" />
              <Text style={styles.backButtonText}>Natrag na početnu</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.loginButton}
              onPress={handleBackToLogin}
            >
              <Text style={styles.loginText}>
                Već imate račun? <Text style={styles.loginTextBold}>Prijavite se</Text>
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  leftPanel: {
    flex: 1,
    padding: 48,
    position: 'relative',
    backgroundColor: '#f8fafc',
    borderRightWidth: 1,
    borderRightColor: '#e2e8f0',
  },
  leftPanelTablet: {
    padding: 20,
  },
  backgroundGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#f8fafc',
  },
  logoContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 48,
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  logoPart: {
    fontSize: 28,
    fontWeight: '600',
    color: '#1E293B',
    letterSpacing: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoDot: {
    fontSize: 28,
    fontWeight: '800',
    color: '#4299E1',
    marginHorizontal: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoHr: {
    fontSize: 28,
    fontWeight: '800',
    color: '#1E293B',
    letterSpacing: 1,
    transition: 'color 0.3s ease',
  } as any,
  mobileLogoContainer: {
    alignItems: 'center',
    marginBottom: 32,
  },
  featureContainer: {
    marginTop: 48,
  },
  featureItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
    backgroundColor: '#fff',
    padding: 24,
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
    elevation: 2,
    borderWidth: 1,
    borderColor: '#e2e8f0',
  },
  featureText: {
    fontSize: 16,
    color: '#334155',
    marginLeft: 20,
    flex: 1,
    lineHeight: 24,
    letterSpacing: 0.1,
  },
  rightPanel: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
  rightPanelMobile: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  rightPanelTablet: {
    padding: 30,
  },
  registerBox: {
    width: '80%',
    maxWidth: 450,
    backgroundColor: '#ffffff',
    borderRadius: 12,
    padding: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  registerBoxMobile: {
    width: '100%',
    padding: 20,
    shadowOpacity: 0,
    elevation: 0,
  },
  registerBoxTablet: {
    padding: 30,
    width: '90%',
  },
  registerTitle: {
    fontSize: 32,
    fontWeight: '700',
    marginBottom: 10,
    color: '#333',
  },
  registerSubtitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 30,
  },
  inputContainer: {
    marginBottom: 20,
    position: 'relative',
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 45,
    fontSize: 16,
    backgroundColor: '#ffffff',
    color: '#333',
  },
  inputIcon: {
    position: 'absolute',
    left: 15,
    top: 15,
  },
  eyeIcon: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
  button: {
    height: 50,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    backgroundColor: '#0056b3',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#ffffff',
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 68, 68, 0.1)',
    padding: 10,
    borderRadius: 8,
    marginBottom: 15,
  },
  errorText: {
    color: '#ff4444',
    marginLeft: 8,
    fontSize: 14,
  },
  footerContainer: {
    marginTop: 25,
    alignItems: 'center',
  },
  loginButton: {
    marginBottom: 10,
  },
  loginText: {
    fontSize: 15,
    color: '#666666',
  },
  loginTextBold: {
    fontWeight: '700',
    color: '#0056b3',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
  },
  backButtonText: {
    color: '#666666',
    marginLeft: 5,
    fontSize: 14,
  },
  // Mobile specific styles
  titleMobile: {
    fontSize: 24,
    textAlign: 'center',
  },
  subtitleMobile: {
    fontSize: 14,
    textAlign: 'center',
  },
  buttonMobile: {
    marginTop: 20,
  },
  footerContainerMobile: {
    marginTop: 20,
  },
  // Tablet specific styles
  titleTablet: {
    fontSize: 28,
  },
  subtitleTablet: {
    fontSize: 15,
  },
  buttonTablet: {
    marginTop: 15,
  },
  footerContainerTablet: {
    marginTop: 22,
  },
  subtitle: {
    fontSize: 16,
    color: '#64748B',
    marginTop: 16,
    textAlign: 'center',
  },
});
