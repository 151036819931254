import React, { useState, useEffect } from 'react';
import { View, StyleSheet, FlatList, RefreshControl, ActivityIndicator } from 'react-native';
import { Text, List, Divider, useTheme, Button } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { breaksAPI } from '../../services/api';

interface Break {
  id: string;
  name: string;
  duration: number;
  isPaid: boolean;
}

export const BreaksScreen = ({ navigation }: any) => {
  const theme = useTheme();
  const [breaks, setBreaks] = useState<Break[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchData = async () => {
    try {
      const data = await breaksAPI.getBreaks();
      setBreaks(data);
    } catch (err) {
      console.error('Greška pri dohvaćanju pauza:', err);
      setError('Došlo je do greške pri dohvaćanju pauza');
    } finally {
      setLoading(false);
    }
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchData().finally(() => setRefreshing(false));
  }, []);

  useEffect(() => {
    // Učitaj podatke samo prvi put i kad se vratimo s kreiranja pauze
    if (loading) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData();
    });

    return unsubscribe;
  }, [navigation]);

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={theme.colors.primary} />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.errorContainer}>
        <Text style={[styles.errorText, { color: theme.colors.error }]}>{error}</Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={[styles.safeArea, { backgroundColor: '#fff' }]} edges={['left', 'right']}>
      {breaks.length === 0 ? (
        <View style={styles.emptyStateContainer}>
          <View style={styles.emptyStateContent}>
            <Text style={styles.emptyStateText}>
              Nemate još dodanih pauza
            </Text>
            <Text style={styles.emptyStateSubtext}>
              Dodajte svoju prvu pauzu kako biste mogli pratiti vrijeme odmora djelatnika
            </Text>
            <Button 
              mode="contained" 
              onPress={() => navigation.navigate('CreateBreak')}
              style={styles.createButton}
              contentStyle={styles.createButtonContent}
            >
              Dodaj pauzu
            </Button>
          </View>
        </View>
      ) : (
        <View style={styles.container}>
          <FlatList
            data={breaks}
            keyExtractor={(item) => item.id}
            contentContainerStyle={styles.listContent}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh}
                colors={[theme.colors.primary]}
              />
            }
            renderItem={({ item }) => (
              <List.Item
                style={[styles.breakItem, { backgroundColor: '#f8f9fa' }]}
                title={item.name}
                description={`${item.duration} min${item.isPaid ? ' • Plaćena' : ' • Neplaćena'}`}
                left={props => (
                  <View style={[styles.iconContainer, { backgroundColor: `${theme.colors.primary}10` }]}>
                    <MaterialCommunityIcons
                      name="coffee"
                      size={24}
                      color={theme.colors.primary}
                    />
                  </View>
                )}
                right={props => (
                  <View style={styles.chevronContainer}>
                    <MaterialCommunityIcons
                      name="chevron-right"
                      size={24}
                      color="#9E9E9E"
                    />
                  </View>
                )}
                onPress={() => navigation.navigate('BreakDetails', { breakId: item.id })}
              />
            )}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 0,
    paddingTop: 12,
    paddingBottom: 16,
  },
  emptyStateContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStateContent: {
    width: '100%',
    paddingHorizontal: 24,
    alignItems: 'center',
    marginTop: -30,
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#37474F',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: '#78909C',
    textAlign: 'center',
    marginBottom: 32,
    paddingHorizontal: 20,
    fontWeight: 'normal',
  },
  createButton: {
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  createButtonContent: {
    minWidth: 180,
  },
  iconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  chevronContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  breakItem: {
    borderRadius: 12,
    marginVertical: 4,
    marginHorizontal: 16,
    elevation: 1,
    paddingRight: 0,
    paddingVertical: 4,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  errorText: {
    fontSize: 16,
    textAlign: 'center',
  },
});
