import { keywords, KeywordGroup } from '../config/keywords';
import { siteMetadata } from '../config/siteMetadata';

/**
 * Generira naslov stranice prema predlošku
 */
export const generatePageTitle = (pageTitle?: string): string => {
  if (!pageTitle) return siteMetadata.defaultTitle;
  return siteMetadata.titleTemplate.replace('%s', pageTitle);
};

/**
 * Generira kanonski URL za stranicu
 */
export const generateCanonicalUrl = (path: string): string => {
  const baseUrl = siteMetadata.baseUrl.replace(/\/$/, '');
  const cleanPath = path.replace(/^\//, '');
  return `${baseUrl}/${cleanPath}`;
};

/**
 * Generira strukturirane podatke za organizaciju
 */
export const generateOrganizationSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: siteMetadata.company.name,
    legalName: siteMetadata.company.legalName,
    url: siteMetadata.baseUrl,
    logo: {
      '@type': 'ImageObject',
      url: `${siteMetadata.baseUrl}${siteMetadata.logo.url}`,
      width: siteMetadata.logo.width,
      height: siteMetadata.logo.height
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: siteMetadata.company.address,
      addressLocality: siteMetadata.company.city,
      addressCountry: siteMetadata.company.country
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: siteMetadata.company.phone,
      email: siteMetadata.company.email,
      contactType: 'customer service'
    },
    sameAs: siteMetadata.social.map(social => social.url)
  };
};

/**
 * Generira strukturirane podatke za softversku aplikaciju
 */
export const generateSoftwareApplicationSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: siteMetadata.company.name,
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'Web browser',
    offers: {
      '@type': 'Offer',
      price: '0',
      priceCurrency: 'HRK',
      description: 'Besplatno probno razdoblje od 7 dana'
    }
  };
};

/**
 * Generira Open Graph podatke za društvene mreže
 */
export const generateOpenGraphData = (
  title: string,
  description: string,
  image?: string,
  type: 'website' | 'article' = 'website'
) => {
  return {
    'og:title': title,
    'og:description': description,
    'og:type': type,
    'og:url': siteMetadata.baseUrl,
    'og:image': image || `${siteMetadata.baseUrl}${siteMetadata.logo.url}`,
    'og:site_name': siteMetadata.company.name
  };
};

/**
 * Generira Twitter Card podatke
 */
export const generateTwitterCardData = (
  title: string,
  description: string,
  image?: string
) => {
  return {
    'twitter:card': 'summary_large_image',
    'twitter:title': title,
    'twitter:description': description,
    'twitter:image': image || `${siteMetadata.baseUrl}${siteMetadata.logo.url}`
  };
};