import React, { useState, useRef, useEffect } from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { TextInput, Text, Switch, useTheme, Divider, List, Appbar } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { breaksAPI } from '../../services/api';
import { Dimensions } from 'react-native';

export const CreateBreakScreen = ({ navigation }: any) => {
  const theme = useTheme();
  const scrollViewRef = useRef<ScrollView>(null);
  const numbersRef = useRef<View>(null);
  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [isPaid, setIsPaid] = useState(true);
  const [unlimited, setUnlimited] = useState(true);
  const [allowReuseAfterExpiry, setAllowReuseAfterExpiry] = useState(false);
  const [restrictTimeEnabled, setRestrictTimeEnabled] = useState(false);
  const [minWorkMinutes, setMinWorkMinutes] = useState('0');
  const [restrictedHours, setRestrictedHours] = useState<number[]>([]);
  const [isTimeRestrictionEnabled, setIsTimeRestrictionEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [windowHeight, setWindowHeight] = useState(Dimensions.get('window').height);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWindowHeight(window.height);
    });
    return () => subscription?.remove();
  }, []);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await breaksAPI.createBreak({
        name,
        duration: parseInt(duration),
        isPaid,
        isUnlimited: unlimited,
        allowReuseAfterExpiry,
        restrictTimeEnabled,
        minWorkMinutes: restrictTimeEnabled ? parseInt(minWorkMinutes) : undefined,
        isTimeRestrictionEnabled,
        restrictedHours: isTimeRestrictionEnabled ? restrictedHours : []
      });

      navigation.navigate('Breaks', { refresh: true });
    } catch (error) {
      Alert.alert(
        'Greška',
        error.response?.data?.message || 'Došlo je do greške pri kreiranju pauze'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggleHour = (hour: number) => {
    setRestrictedHours(prev => 
      prev.includes(hour) 
        ? prev.filter(h => h !== hour)
        : [...prev, hour].sort((a, b) => a - b)
    );
  };

  const handleTimeRestrictionToggle = (value: boolean) => {
    setIsTimeRestrictionEnabled(value);
  };

  return (
    <SafeAreaView style={styles.safeArea} edges={['left', 'right']}>
      <Appbar.Header style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Appbar.Content title="Nova pauza" titleStyle={styles.headerTitle} />
        <TouchableOpacity 
          style={styles.saveButton}
          onPress={handleSubmit}
          disabled={isLoading}
        >
          <Text style={styles.saveButtonText}>Kreiraj</Text>
        </TouchableOpacity>
      </Appbar.Header>
      
      <ScrollView 
        ref={scrollViewRef}
        style={styles.content} 
        contentContainerStyle={styles.contentContainer}
        keyboardShouldPersistTaps="handled"
      >
        <View style={styles.inputGroup}>
          <Text style={styles.sectionTitle}>Osnovne informacije</Text>
          <TextInput
            mode="outlined"
            label="Naziv pauze"
            value={name}
            onChangeText={setName}
            placeholder="npr. Pauza za ručak"
            style={styles.input}
            outlineStyle={styles.inputOutline}
            contentStyle={styles.inputContent}
            theme={{
              colors: {
                primary: theme.colors.primary,
                onSurfaceVariant: '#666',
              }
            }}
            dense
          />

          <TextInput
            mode="outlined"
            label="Trajanje"
            value={duration}
            onChangeText={setDuration}
            keyboardType="numeric"
            style={styles.input}
            outlineStyle={styles.inputOutline}
            contentStyle={styles.inputContent}
            theme={{
              colors: {
                primary: theme.colors.primary,
                onSurfaceVariant: '#666',
              }
            }}
            right={<TextInput.Affix text="min" />}
            dense
          />
        </View>

        <List.Section style={styles.section}>
          <List.Subheader style={styles.sectionHeader}>Postavke plaćanja</List.Subheader>
          <List.Item
            title="Plaćena pauza"
            description="Pauza će biti uračunata u radno vrijeme"
            left={props => <List.Icon {...props} icon="cash" color={theme.colors.primary} />}
            right={props => (
              <Switch
                value={isPaid}
                onValueChange={setIsPaid}
                color={theme.colors.primary}
              />
            )}
            style={styles.listItem}
          />
        </List.Section>

        <Divider />

        <List.Section style={styles.section}>
          <List.Subheader style={styles.sectionHeader}>Ograničenja korištenja</List.Subheader>
          
          <TouchableOpacity 
            onPress={() => {
              setUnlimited(false);
              setAllowReuseAfterExpiry(false);
            }}
            style={styles.radioItem}
          >
            <List.Item
              title="Jednokratno korištenje"
              description="Bez obzira da li se pauza iskoristila do kraja, kad se jednom započne, smatra se iskorištenom."
              left={props => (
                <View style={styles.radioContainer}>
                  <View style={[
                    styles.radio,
                    !unlimited && styles.radioSelected
                  ]}>
                    {!unlimited && <View style={styles.radioInner} />}
                  </View>
                </View>
              )}
              style={[
                styles.listItem,
                !unlimited && styles.listItemSelected,
                { 
                  minHeight: 80,
                  paddingVertical: 16
                }
              ]}
              descriptionNumberOfLines={3}
              descriptionStyle={{ 
                fontSize: 14, 
                lineHeight: 20,
                flexWrap: 'wrap'
              }}
            />
          </TouchableOpacity>

          <Divider />

          <TouchableOpacity 
            onPress={() => setUnlimited(true)}
            style={styles.radioItem}
          >
            <List.Item
              title="Neograničeno korištenje"
              description="Pauza se može koristiti više puta u smjeni"
              left={props => (
                <View style={styles.radioContainer}>
                  <View style={[
                    styles.radio,
                    unlimited && styles.radioSelected
                  ]}>
                    {unlimited && <View style={styles.radioInner} />}
                  </View>
                </View>
              )}
              style={[
                styles.listItem,
                unlimited && styles.listItemSelected
              ]}
            />
          </TouchableOpacity>

          {unlimited && (
            <>
              <View style={styles.nestedOption}>
                <List.Item
                  title="Korištenje nakon isteka"
                  description="Dozvoljava prekoračenje definiranog vremena pauze. Preporučeno za kratke pauze. Sustav bilježi prekoračenja za obračun plaće."
                  left={props => <List.Icon {...props} icon="timer-outline" color={theme.colors.primary} />}
                  right={props => (
                    <Switch
                      value={allowReuseAfterExpiry}
                      onValueChange={(value) => {
                        setAllowReuseAfterExpiry(value);
                      }}
                      color={theme.colors.primary}
                    />
                  )}
                  style={[
                    styles.listItem, 
                    { 
                      minHeight: 120,
                      paddingVertical: 16
                    }
                  ]}
                  descriptionNumberOfLines={10}
                  descriptionStyle={{ 
                    fontSize: 14, 
                    lineHeight: 20,
                    flexWrap: 'wrap',
                    flex: 1
                  }}
                />
              </View>
            </>
          )}
        </List.Section>

        <Divider />

        <List.Section style={styles.section}>
          <List.Subheader style={styles.sectionHeader}>Vremensko ograničenje</List.Subheader>
          <List.Item
            title="Zabrani pauzu u određenim satima"
            description={restrictTimeEnabled ? "Pauza nije dozvoljena odmah nakon prijave" : "Pauza je dozvoljena bilo kad"}
            left={props => <List.Icon {...props} icon="clock-outline" color={theme.colors.primary} />}
            right={props => (
              <Switch
                value={restrictTimeEnabled}
                onValueChange={(value) => {
                  setRestrictTimeEnabled(value);
                }}
                color={theme.colors.primary}
              />
            )}
            style={styles.listItem}
          />

          {restrictTimeEnabled && (
            <View style={styles.timeRestrictionContainer}>
              <TextInput
                mode="outlined"
                label="Minimalno vrijeme rada prije pauze"
                value={minWorkMinutes}
                onChangeText={setMinWorkMinutes}
                keyboardType="numeric"
                style={styles.input}
                outlineStyle={styles.inputOutline}
                contentStyle={styles.inputContent}
                theme={{
                  colors: {
                    primary: theme.colors.primary,
                    onSurfaceVariant: '#666',
                  }
                }}
                right={<TextInput.Affix text="min" />}
                dense
              />
            </View>
          )}
        </List.Section>

        <List.Section>
          <List.Item
            title="Zabrana korištenja pauze"
            description="Odaberite sate u kojima je zabranjeno korištenje pauze"
            left={props => <List.Icon {...props} icon="clock-alert" />}
            right={() => (
              <Switch
                value={isTimeRestrictionEnabled}
                onValueChange={handleTimeRestrictionToggle}
              />
            )}
          />
          
          {isTimeRestrictionEnabled && (
            <View ref={numbersRef} style={styles.hoursGrid}>
              {Array.from({ length: 24 }, (_, i) => (
                <TouchableOpacity
                  key={i}
                  onPress={() => toggleHour(i)}
                  style={[
                    styles.hourButton,
                    { borderColor: theme.colors.primary },
                    restrictedHours.includes(i) && { backgroundColor: theme.colors.primary }
                  ]}
                >
                  <Text style={[
                    styles.hourText,
                    { color: theme.colors.primary },
                    restrictedHours.includes(i) && { color: theme.colors.surface }
                  ]}>
                    {i.toString().padStart(2, '0')}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </List.Section>

        <View style={styles.previewSection}>
          <Text style={styles.previewHeader}>
            Pregled postavki pauze
          </Text>
          
          <View style={styles.previewCard}>
            <View style={styles.previewRow}>
              <View style={styles.previewIconContainer}>
                <List.Icon icon="information" color={theme.colors.primary} />
              </View>
              <View style={styles.previewContent}>
                <Text style={styles.sectionTitle}>Osnovno</Text>
                <View style={styles.infoGroup}>
                  <Text style={styles.infoLabel}>Naziv pauze</Text>
                  <Text style={styles.infoValue}>{name || '(nije uneseno)'}</Text>
                </View>
                <View style={styles.infoGroup}>
                  <Text style={styles.infoLabel}>Trajanje</Text>
                  <Text style={styles.infoValue}>{duration} minuta</Text>
                </View>
                <View style={styles.infoGroup}>
                  <Text style={styles.infoLabel}>Status plaćanja</Text>
                  <Text style={styles.infoValue}>{isPaid ? 'Plaćena pauza' : 'Neplaćena pauza'}</Text>
                </View>
              </View>
            </View>

            <Divider style={styles.previewDivider} />

            <View style={styles.previewRow}>
              <View style={styles.previewIconContainer}>
                <List.Icon icon="clock-outline" color={theme.colors.primary} />
              </View>
              <View style={styles.previewContent}>
                <Text style={styles.sectionTitle}>Pravila korištenja</Text>
                <View style={styles.infoGroup}>
                  <Text style={styles.infoLabel}>Učestalost korištenja</Text>
                  <Text style={styles.infoValue}>
                    {unlimited ? 'Neograničeno korištenje' : 'Jednokratno korištenje'}
                  </Text>
                  <Text style={styles.infoDescription}>
                    {unlimited 
                      ? 'Pauza se može koristiti više puta tijekom smjene'
                      : 'Pauza se može iskoristiti samo jednom tijekom smjene'}
                  </Text>
                </View>

                {unlimited && (
                  <View style={styles.infoGroup}>
                    <Text style={styles.infoLabel}>Nakon isteka pauze</Text>
                    <Text style={styles.infoValue}>
                      {allowReuseAfterExpiry ? 'Dozvoljeno novo korištenje' : 'Čekanje nove smjene'}
                    </Text>
                    <Text style={styles.infoDescription}>
                      {allowReuseAfterExpiry 
                        ? 'Nakon isteka vremena pauze, moguće je uzeti novu pauzu'
                        : 'Nova pauza je dostupna tek u sljedećoj smjeni'}
                    </Text>
                  </View>
                )}

                {restrictTimeEnabled && (
                  <View style={styles.infoGroup}>
                    <Text style={styles.infoLabel}>Minimalno vrijeme rada</Text>
                    <Text style={styles.infoValue}>{minWorkMinutes} minuta</Text>
                    <Text style={styles.infoDescription}>
                      Potrebno je odraditi navedeno vrijeme prije korištenja pauze
                    </Text>
                  </View>
                )}
              </View>
            </View>

            {isTimeRestrictionEnabled && restrictedHours.length > 0 && (
              <>
                <Divider style={styles.previewDivider} />
                <View style={styles.previewRow}>
                  <View style={styles.previewIconContainer}>
                    <List.Icon icon="block-helper" color={theme.colors.primary} />
                  </View>
                  <View style={styles.previewContent}>
                    <Text style={styles.sectionTitle}>Zabrana korištenja</Text>
                    <View style={styles.infoGroup}>
                      <Text style={styles.infoLabel}>Zabranjeni termini</Text>
                      <Text style={styles.infoValue}>
                        {restrictedHours
                          .sort((a, b) => a - b)
                          .reduce((acc, hour, index, array) => {
                            if (index === 0 || hour !== array[index - 1] + 1) {
                              if (index > 0) acc.push('\n');
                              acc.push(`${hour.toString().padStart(2, '0')}:00-${hour.toString().padStart(2, '0')}:59`);
                            } else if (index === array.length - 1 || array[index + 1] !== hour + 1) {
                              acc[acc.length - 1] = acc[acc.length - 1].replace(
                                /\d{2}:59$/,
                                `${hour.toString().padStart(2, '0')}:59`
                              );
                            }
                            return acc;
                          }, [] as string[])
                          .join('')}
                      </Text>
                      <Text style={styles.infoDescription}>
                        U navedenim terminima nije moguće započeti pauzu
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            )}

            <Divider style={styles.previewDivider} />

            <View style={styles.previewRow}>
              <View style={styles.previewIconContainer}>
                <List.Icon icon="alert" color={theme.colors.primary} />
              </View>
              <View style={styles.previewContent}>
                <Text style={styles.sectionTitle}>Važna napomena</Text>
                <View style={styles.infoGroup}>
                  <Text style={styles.infoLabel}>Prekoračenje pauze</Text>
                  <Text style={styles.infoValue}>
                    Sustav bilježi svako prekoračenje vremena pauze
                  </Text>
                  <Text style={styles.infoDescription}>
                    Prekoračenja se automatski obračunavaju kroz sustav plaća
                  </Text>
                </View>
              </View>
            </View>
          </View>

        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  headerTitle: {
    fontSize: 20,
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    padding: 8,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  saveButton: {
    marginRight: 16,
  },
  saveButtonText: {
    color: '#007AFF',
    fontSize: 17,
    fontWeight: '600',
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingBottom: 24,
  },
  inputGroup: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 17,
    fontWeight: '600',
    color: '#1C1C1E',
    marginBottom: 12
  },
  input: {
    marginBottom: 12,
    backgroundColor: '#fff',
  },
  inputOutline: {
    borderRadius: 8,
  },
  inputContent: {
    paddingHorizontal: 12,
  },
  section: {
    backgroundColor: '#fff',
  },
  sectionHeader: {
    fontSize: 13,
    letterSpacing: 0.1,
    color: '#666',
  },
  listItem: {
    paddingVertical: 8,
  },
  radioItem: {
    backgroundColor: '#fff',
  },
  radioContainer: {
    width: 56,
    justifyContent: 'center',
    alignItems: 'center',
  },
  radio: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#666',
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioSelected: {
    borderColor: '#007AFF',
  },
  radioInner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#007AFF',
  },
  listItemSelected: {
    backgroundColor: '#f0f9ff',
  },
  nestedOption: {
    backgroundColor: '#f5f5f5',
    marginTop: 1,
  },
  timeRestrictionContainer: {
    paddingHorizontal: 16,
    paddingBottom: 8,
  },
  hoursGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    paddingHorizontal: 16,
    paddingVertical: 12
  },
  hourButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent'
  },
  hourText: {
    fontSize: 14,
    fontWeight: '500'
  },
  previewSection: {
    marginTop: 24,
    marginBottom: 24,
    paddingHorizontal: 16
  },
  previewCard: {
    backgroundColor: '#fff',
    borderRadius: 12,
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    padding: 16
  },
  previewRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 4
  },
  previewIconContainer: {
    marginRight: 8,
    marginTop: -4
  },
  previewContent: {
    flex: 1
  },
  infoGroup: {
    marginBottom: 16
  },
  infoLabel: {
    fontSize: 15,
    fontWeight: '500',
    color: '#48484A',
    marginBottom: 4
  },
  infoValue: {
    fontSize: 16,
    fontWeight: '400',
    color: '#1C1C1E',
    marginBottom: 4
  },
  infoDescription: {
    fontSize: 14,
    color: '#8E8E93',
    opacity: 1
  },
  previewDivider: {
    marginVertical: 16,
    backgroundColor: '#E5E5EA'
  },
  previewHeader: {
    fontSize: 20,
    fontWeight: '600',
    color: '#007AFF',
    marginBottom: 16
  }
});
