import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const GettingStartedTips = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Savjeti za početak</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Prije prvog radnog dana</Text>
            <Text style={styles.description}>
              Kako biste osigurali uspješan početak rada sa sustavom:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Provjerite jesu li svi djelatnici dobili svoje PIN-ove</Text>
              <Text style={styles.bulletItem}>• Osigurajte da su terminali postavljeni na lako dostupnim mjestima</Text>
              <Text style={styles.bulletItem}>• Testirajte internet vezu na svim lokacijama</Text>
              <Text style={styles.bulletItem}>• Pripremite kratke upute za djelatnike</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Edukacija djelatnika</Text>
            <Text style={styles.description}>
              Organizirajte kratki sastanak s djelatnicima i objasnite im:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Kako se prijaviti i odjaviti s posla</Text>
              <Text style={styles.bulletItem}>• Postupak za odlazak na pauzu i povratak</Text>
              <Text style={styles.bulletItem}>• Što učiniti ako zaborave PIN</Text>
              <Text style={styles.bulletItem}>• Kome se obratiti u slučaju problema</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Praćenje prvih dana</Text>
            <Text style={styles.description}>
              U prvim danima korištenja sustava:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Budite dostupni za pitanja djelatnika</Text>
              <Text style={styles.bulletItem}>• Redovito provjeravajte izvještaje</Text>
              <Text style={styles.bulletItem}>• Pratite eventualne probleme s prijavama</Text>
              <Text style={styles.bulletItem}>• Bilježite povratne informacije za poboljšanja</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Korisni savjeti</Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Postavite obavijest na terminal s brojem za podršku</Text>
              <Text style={styles.bulletItem}>• Imenujte odgovornu osobu za svaku lokaciju</Text>
              <Text style={styles.bulletItem}>• Napravite rezervni plan u slučaju problema s internetom</Text>
              <Text style={styles.bulletItem}>• Redovito radite sigurnosne kopije podataka</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Važne napomene</Text>
            <View style={styles.noteBox}>
              <Text style={styles.noteText}>
                • Budite strpljivi - prilagodba na novi sustav može potrajati nekoliko dana
              </Text>
              <Text style={styles.noteText}>
                • Prikupljajte povratne informacije od djelatnika
              </Text>
              <Text style={styles.noteText}>
                • Redovito ažurirajte podatke o djelatnicima
              </Text>
              <Text style={styles.noteText}>
                • Za sva pitanja kontaktirajte našu podršku na support@clock.hr
              </Text>
            </View>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: '#444',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  bulletList: {
    marginLeft: 20,
    marginTop: 8,
    marginBottom: 16,
  },
  bulletItem: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  noteBox: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderLeftColor: '#007bff',
  },
  noteText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
  },
});
