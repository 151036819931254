import React, { useState, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { Text, Button } from 'react-native-paper';
import { format, subDays } from 'date-fns';
import { hr } from 'date-fns/locale';
import { MaterialIcons } from '@expo/vector-icons';
import { employeesAPI } from '../../../../services/api';
import { useQuery } from '@tanstack/react-query';
import { DateFilters } from './admin-controls';

interface Break {
  breakId: string;
  startTime: string;
  endTime?: string;
  durationMinutes: number;
  allowedMinutes: number;
  overMinutes: number;
  paid: boolean;
  breakName: string;
}

interface WorkBlock {
  id: string;
  startTime: string;
  endTime?: string;
  deviceId: string;
  crossesMidnight: boolean;
  breaks: Break[];
  hourlyRate: number;
  stats: {
    workMinutes: number;
    breakMinutes: number;
    overBreakMinutes: number;
    effectiveMinutes: number;
    paidMinutes: number;
    totalPaidBreakMinutes: number;
    totalUnpaidBreakMinutes: number;
    overPaidBreakMinutes: number;
    overUnpaidBreakMinutes: number;
    hourlyRate: number;
    earnings: number;
    deductions: number;
  };
}

interface DailyStats {
  totalWorkMinutes: number;
  effectiveWorkMinutes: number;
  totalBreakMinutes: number;
  overBreakMinutes: number;
  paidMinutes: number;
  totalPaidBreakMinutes: number;
  totalUnpaidBreakMinutes: number;
  overPaidBreakMinutes: number;
  overUnpaidBreakMinutes: number;
  hourlyRate: number;
  earnings: number;
  deductions: number;
}

interface Props {
  employeeId: string;
}

const formatTime = (date: string) => {
  return format(new Date(date), 'HH:mm', { locale: hr });
};

const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes - Math.floor(minutes)) * 60);
  
  if (hours === 0) {
    if (secs === 0) return `${mins}min`;
    return `${mins}min ${secs}sek`;
  }
  
  if (secs === 0) return `${hours}h ${mins}min`;
  return `${hours}h ${mins}min ${secs}sek`;
};

const formatMoney = (amount: number | undefined) => {
  if (amount === undefined) return '0.00 €';
  return `${amount.toFixed(2)} €`;
};

const BreakItem: React.FC<{ breakData: Break }> = ({ breakData }) => (
  <View style={styles.breakRow}>
    <View style={styles.breakHeader}>
      <Text style={styles.breakTitle}>
        {formatTime(breakData.startTime)} - {breakData.endTime ? formatTime(breakData.endTime) : 'U tijeku'}
      </Text>
    </View>

    <View style={styles.breakDetail}>
      <Text style={styles.breakDetailLabel}>Trajanje:</Text>
      <Text style={styles.breakDetailValue}>{formatDuration(breakData.durationMinutes || 0)}</Text>
    </View>
    <View style={styles.breakDetail}>
      <Text style={styles.breakDetailLabel}>Dozvoljeno:</Text>
      <Text style={styles.breakDetailValue}>{formatDuration(breakData.allowedMinutes || 0)}</Text>
    </View>
    {(breakData.overMinutes || 0) > 0 && (
      <View style={styles.breakDetail}>
        <Text style={styles.breakDetailLabel}>Prekoračenje:</Text>
        <Text style={[styles.breakDetailValue, styles.warning]}>{formatDuration(breakData.overMinutes || 0)}</Text>
      </View>
    )}
    <View style={styles.breakDetail}>
      <Text style={styles.breakDetailLabel}>Naziv pauze:</Text>
      <Text style={styles.breakDetailValue}>{breakData.breakName}</Text>
    </View>
  </View>
);

export const WebEmployeeStats: React.FC<Props> = ({ employeeId }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState(new Date());
  const [displayDate, setDisplayDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const { data: stats, isLoading } = useQuery({
    queryKey: ['employeeStats', employeeId, format(displayDate, 'yyyy-MM-dd')],
    queryFn: () => employeesAPI.getEmployeeStats(employeeId, format(displayDate, 'yyyy-MM-dd')),
  });

  const quickFilters = [
    { label: 'Danas', date: new Date() },
    { label: 'Jučer', date: subDays(new Date(), 1) },
    { label: 'Prekjučer', date: subDays(new Date(), 2) },
  ];

  const handleQuickFilter = (filter: string, date: Date) => {
    setSelectedFilter(filter);
    setDisplayDate(date);
    setTempDate(date);
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Učitavanje...</Text>
      </View>
    );
  }

  if (!stats) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Nema podataka za odabrani datum</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.dateFiltersContainer}>
        <DateFilters
          displayDate={displayDate}
          onDateChange={setDisplayDate}
          onTempDateChange={setTempDate}
          tempDate={tempDate}
          showDatePicker={showDatePicker}
          onToggleDatePicker={setShowDatePicker}
          quickFilters={quickFilters}
          selectedFilter={selectedFilter}
          onQuickFilterSelect={handleQuickFilter}
        />
      </View>

      <View style={styles.statsGrid}>
        <View style={styles.kpiRow}>
          <View style={styles.kpiCard}>
            <View style={styles.kpiIconContainer}>
              <MaterialIcons name="timer" size={24} color="#6366F1" />
            </View>
            <View style={styles.kpiContent}>
              <Text style={styles.kpiLabel}>Ukupno vrijeme</Text>
              <Text style={styles.kpiValue}>{formatDuration(stats.dailyStats?.totalWorkMinutes || 0)}</Text>
              <Text style={styles.kpiSubtext}>Efektivno: {formatDuration(stats.dailyStats?.effectiveWorkMinutes || 0)}</Text>
            </View>
          </View>

          <View style={styles.kpiCard}>
            <View style={styles.kpiIconContainer}>
              <MaterialIcons name="coffee" size={24} color="#10B981" />
            </View>
            <View style={styles.kpiContent}>
              <Text style={styles.kpiLabel}>Ukupno pauza</Text>
              <Text style={styles.kpiValue}>{formatDuration(stats.dailyStats?.totalBreakMinutes || 0)}</Text>
              <Text style={styles.kpiSubtext}>Prekoračenje: {formatDuration(stats.dailyStats?.overBreakMinutes || 0)}</Text>
            </View>
          </View>

          <View style={styles.kpiCard}>
            <View style={styles.kpiIconContainer}>
              <MaterialIcons name="euro" size={24} color="#F59E0B" />
            </View>
            <View style={styles.kpiContent}>
              <Text style={styles.kpiLabel}>Zarada</Text>
              <Text style={styles.kpiValue}>{formatMoney(stats.dailyStats?.earnings)}</Text>
              <Text style={styles.kpiSubtext}>Satnica: {formatMoney(stats.dailyStats?.hourlyRate)}/h</Text>
            </View>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={styles.card}>
            <View style={styles.detailsHeader}>
              <Text style={styles.detailsTitle}>Detaljna statistika vremena</Text>
            </View>
            <View style={styles.detailsContent}>
              <View style={styles.detailRow}>
                <Text style={styles.detailLabel}>Plaćeno vrijeme</Text>
                <Text style={styles.detailValue}>{formatDuration(stats.dailyStats?.paidMinutes || 0)}</Text>
              </View>
              <View style={styles.detailRow}>
                <Text style={styles.detailLabel}>Plaćene pauze</Text>
                <Text style={styles.detailValue}>{formatDuration(stats.dailyStats?.totalPaidBreakMinutes || 0)}</Text>
              </View>
              <View style={styles.detailRow}>
                <Text style={styles.detailLabel}>Neplaćene pauze</Text>
                <Text style={styles.detailValue}>{formatDuration(stats.dailyStats?.totalUnpaidBreakMinutes || 0)}</Text>
              </View>
              <View style={styles.detailRow}>
                <Text style={styles.detailLabel}>Prekoračenje plaćenih pauza</Text>
                <Text style={[styles.detailValue, styles.warning]}>{formatDuration(stats.dailyStats?.overPaidBreakMinutes || 0)}</Text>
              </View>
              <View style={styles.detailRow}>
                <Text style={styles.detailLabel}>Prekoračenje neplaćenih pauza</Text>
                <Text style={[styles.detailValue, styles.warning]}>{formatDuration(stats.dailyStats?.overUnpaidBreakMinutes || 0)}</Text>
              </View>
            </View>
          </View>

          {stats.workBlocks && stats.workBlocks.length > 0 && (
            <View style={styles.workBlocksSection}>
              <Text style={styles.sectionTitle}>Radni blokovi</Text>
              <View style={styles.workBlocksList}>
                {stats.workBlocks.map((block: WorkBlock) => (
                  <View key={block.id} style={styles.workBlock}>
                    <View style={styles.workBlockHeader}>
                      <View style={styles.workBlockTimeContainer}>
                        <MaterialIcons name="schedule" size={20} color="#6366F1" style={styles.workBlockIcon} />
                        <Text style={styles.workBlockTime}>
                          {formatTime(block.startTime)} - {block.endTime ? formatTime(block.endTime) : 'U tijeku'}
                        </Text>
                      </View>
                    </View>
                    
                    <View style={styles.workBlockStatsContainer}>
                      <View style={styles.workBlockStatItem}>
                        <Text style={styles.workBlockLabel}>Ukupno:</Text>
                        <Text style={styles.workBlockValue}>
                          {formatDuration(block.stats.workMinutes)}
                        </Text>
                      </View>
                      
                      <View style={styles.workBlockStatItem}>
                        <Text style={styles.workBlockLabel}>Efektivno:</Text>
                        <Text style={styles.workBlockValue}>
                          {formatDuration(block.stats.effectiveMinutes)}
                        </Text>
                      </View>
                      
                      <View style={styles.workBlockStatItem}>
                        <Text style={styles.workBlockLabel}>Pauze:</Text>
                        <Text style={styles.workBlockValue}>
                          {formatDuration(block.stats.breakMinutes)}
                        </Text>
                      </View>
                    </View>

                    {block.breaks.length > 0 && (
                      <View style={styles.breaksList}>
                        <Text style={styles.breaksListTitle}>Pauze:</Text>
                        {block.breaks.filter(breakItem => breakItem.startTime).map((breakItem) => (
                          <View key={breakItem.breakId} style={styles.breakItem}>
                            <View style={styles.breakHeader}>
                              <View style={styles.breakTimeContainer}>
                                <MaterialIcons name="coffee" size={16} color="#10B981" style={styles.breakIcon} />
                                <Text style={styles.breakTime}>
                                  {formatTime(breakItem.startTime)} - {breakItem.endTime ? formatTime(breakItem.endTime) : 'U tijeku'}
                                </Text>
                              </View>
                              <Text style={styles.breakName}>{breakItem.breakName}</Text>
                            </View>
                            <View style={styles.breakStats}>
                              <View style={styles.breakStat}>
                                <Text style={styles.breakStatLabel}>Trajanje</Text>
                                <Text style={styles.breakStatValue}>
                                  {breakItem.endTime ? formatDuration(breakItem.durationMinutes) : 'U tijeku'}
                                </Text>
                              </View>
                              <View style={styles.breakStat}>
                                <Text style={styles.breakStatLabel}>Dozvoljeno</Text>
                                <Text style={styles.breakStatValue}>
                                  {formatDuration(breakItem.allowedMinutes)}
                                </Text>
                              </View>
                              {breakItem.overMinutes > 0 && (
                                <View style={styles.breakStat}>
                                  <Text style={styles.breakStatLabel}>Prekoračenje</Text>
                                  <Text style={[styles.breakStatValue, styles.warning]}>
                                    {formatDuration(breakItem.overMinutes)}
                                  </Text>
                                </View>
                              )}
                              <View style={styles.breakStat}>
                                <Text style={styles.breakStatLabel}>Status</Text>
                                <Text style={[
                                  styles.breakStatValue, 
                                  breakItem.paid ? styles.paidStatus : styles.unpaidStatus
                                ]}>
                                  {breakItem.paid ? 'Plaćena' : 'Neplaćena'}
                                </Text>
                              </View>
                            </View>
                          </View>
                        ))}
                      </View>
                    )}
                  </View>
                ))}
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 24,
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    overflow: 'hidden',
    marginBottom: 16,
  },
  kpiCard: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    padding: 24,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  statsGrid: {
    flex: 1,
    gap: 24,
  },
  dateFiltersContainer: {
    marginBottom: 24,
  },
  kpiRow: {
    flexDirection: 'row',
    gap: 24,
  },
  kpiIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  kpiContent: {
    flex: 1,
  },
  kpiLabel: {
    fontSize: 14,
    color: '#64748B',
    marginBottom: 4,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  kpiValue: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 4,
  },
  kpiSubtext: {
    fontSize: 14,
    color: '#64748B',
  },
  detailsContainer: {
    flex: 1,
    gap: 24,
  },
  detailsHeader: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    backgroundColor: '#FFFFFF',
  },
  detailsTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#1E293B',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  detailsContent: {
    padding: 16,
  },
  detailRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  detailLabel: {
    fontSize: 14,
    color: '#64748B',
    flex: 1,
    marginRight: 8,
  },
  detailValue: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1E293B',
  },
  warning: {
    color: '#DC2626',
  },
  workBlocksSection: {
    marginTop: 24,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
    letterSpacing: 0.5,
  },
  workBlocksList: {
    gap: 16,
  },
  workBlock: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    overflow: 'hidden',
  },
  workBlockHeader: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  workBlockTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  workBlockIcon: {
    marginRight: 4,
  },
  workBlockTime: {
    fontSize: 15,
    fontWeight: '600',
    color: '#1E293B',
  },
  workBlockStatsContainer: {
    padding: 16,
  },
  workBlockStatItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    marginBottom: 12,
  },
  workBlockLabel: {
    fontSize: 14,
    color: '#64748B',
  },
  workBlockValue: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1E293B',
  },
  breaksList: {
    padding: 16,
    gap: 12,
  },
  breaksListTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 8,
  },
  breakItem: {
    backgroundColor: '#F8FAFC',
    borderRadius: 8,
    padding: 12,
  },
  breakHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  breakTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  breakIcon: {
    marginRight: 4,
  },
  breakTime: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1E293B',
  },
  breakName: {
    fontSize: 14,
    color: '#64748B',
  },
  breakStats: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
  },
  breakStat: {
    minWidth: 100,
  },
  breakStatLabel: {
    fontSize: 12,
    color: '#64748B',
    marginBottom: 4,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  breakStatValue: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1E293B',
  },
  paidStatus: {
    color: '#10B981',
  },
  unpaidStatus: {
    color: '#DC2626',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
