import React from 'react';
import { ScrollView, StyleSheet, View, RefreshControl } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { WebTotalStatsCard } from './components/WebTotalStatsCard';
import { WebLocationStatsGrid } from './components/WebLocationStatsGrid';
import { WebEmptyState } from './WebEmptyState';
import { Location } from '../../../types/location';

interface Props {
  locations: Location[];
  refreshing: boolean;
  onRefresh: () => void;
}

export const WebDashboardContent: React.FC<Props> = ({ locations, refreshing, onRefresh }) => {
  const theme = useTheme();
  const hasLocations = locations && locations.length > 0;

  if (!hasLocations) {
    return (
      <WebEmptyState 
        title="Nemate još dodanih poslovnica"
        description="Dodajte svoju prvu poslovnicu kako biste mogli pratiti rad djelatnika"
        actionText="Dodaj poslovnicu"
        iconName="store-off-outline"
        navigationRoute="CreateLocation"
      />
    );
  }

  return (
    <ScrollView 
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      }
    >
      <View style={styles.header}>
        <View style={styles.welcomeContainer}>
          <MaterialCommunityIcons 
            name="view-dashboard" 
            size={32} 
            color={theme.colors.primary} 
          />
          <View style={styles.welcomeText}>
            <Text style={styles.greeting}>Dobrodošli natrag!</Text>
            <Text style={styles.subtitle}>Pregled statistike vašeg poslovanja</Text>
          </View>
        </View>
      </View>

      <View style={styles.statsSection}>
        <WebTotalStatsCard />
        <View style={styles.locationSection}>
          <View style={styles.sectionHeader}>
            <MaterialCommunityIcons 
              name="store" 
              size={24} 
              color="#37474F" 
            />
            <Text style={styles.sectionTitle}>Pregled po poslovnicama</Text>
          </View>
          <WebLocationStatsGrid locations={locations} />
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    paddingBottom: 32,
  },
  header: {
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 16,
  },
  welcomeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  welcomeText: {
    flex: 1,
  },
  greeting: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A202C',
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 16,
    color: '#4A5568',
  },
  statsSection: {
    paddingTop: 24,
  },
  locationSection: {
    marginTop: 24,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    paddingHorizontal: 24,
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#37474F',
  },
});
