import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

interface PinDisplayComponentProps {
  pinLength: number;
  maxLength: number;
  isTablet?: boolean;
  locationName?: string | null;
}

export const PinDisplayComponent: React.FC<PinDisplayComponentProps> = ({ 
  pinLength, 
  maxLength,
  isTablet = false,
  locationName
}) => {
  const dynamicStyles = {
    locationName: {
      fontSize: isTablet ? 38 : 24,
      marginBottom: isTablet ? 40 : 30,
    },
    pinLabel: {
      fontSize: isTablet ? 24 : 18,
      marginBottom: isTablet ? 30 : 20,
    },
    pinDot: {
      width: isTablet ? 24 : 20,
      height: isTablet ? 24 : 20,
      borderRadius: isTablet ? 12 : 10,
      margin: isTablet ? 6 : 5,
    }
  };

  // Provjeri je li u info modu
  const isInfoMode = locationName === 'Unesite PIN djelatnika';

  return (
    <View style={styles.locationContainer}>
      {locationName && (
        <Text style={[styles.locationName, dynamicStyles.locationName]} numberOfLines={1} ellipsizeMode="tail">
          {locationName}
        </Text>
      )}
      <Text style={[styles.pinLabel, dynamicStyles.pinLabel]}>
        {isInfoMode ? 'PIN djelatnika' : 'Unesite PIN'}
      </Text>
      <View style={styles.pinDots}>
        {[...Array(maxLength)].map((_, index) => (
          <View
            key={index}
            style={[
              styles.pinDot,
              dynamicStyles.pinDot,
              { backgroundColor: index < pinLength ? '#007AFF' : '#E5E5E5' }
            ]}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  locationContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingBottom: 40,
  },
  locationName: {
    fontSize: 24,
    fontWeight: '500',
    color: '#333',
    marginBottom: 40,
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  pinLabel: {
    fontSize: 18,
    marginBottom: 25,
    color: '#666',
    fontWeight: '400',
  },
  pinDots: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pinDot: {
    width: 20,
    height: 20,
    borderRadius: 10,
    margin: 5,
  },
});
