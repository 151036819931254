import React from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { Text } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

export const WebSettingsScreen = () => {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <ScrollView style={styles.scrollView}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Račun</Text>
            <TouchableOpacity
              style={[styles.settingsItem, styles.settingsItemWithBorder]}
              onPress={() => navigation.navigate('AccountSettings')}
            >
              <View style={styles.settingsItemLeft}>
                <View style={styles.iconContainer}>
                  <Ionicons name="person-circle-outline" size={24} color="#64748B" />
                </View>
                <View style={styles.settingsItemContent}>
                  <Text style={styles.settingsItemTitle}>Račun</Text>
                  <Text style={styles.settingsItemSubtitle}>Ime, email i osobni podaci</Text>
                </View>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#94A3B8" />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.settingsItem}
              onPress={() => navigation.navigate('CompanySettings')}
            >
              <View style={styles.settingsItemLeft}>
                <View style={styles.iconContainer}>
                  <Ionicons name="business-outline" size={24} color="#64748B" />
                </View>
                <View style={styles.settingsItemContent}>
                  <Text style={styles.settingsItemTitle}>Tvrtka</Text>
                  <Text style={styles.settingsItemSubtitle}>Podaci o tvrtki</Text>
                </View>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#94A3B8" />
            </TouchableOpacity>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Ostalo</Text>
            <TouchableOpacity
              style={[styles.settingsItem, styles.settingsItemWithBorder]}
              onPress={() => navigation.navigate('Logs')}
            >
              <View style={styles.settingsItemLeft}>
                <View style={styles.iconContainer}>
                  <Ionicons name="list-outline" size={24} color="#64748B" />
                </View>
                <View style={styles.settingsItemContent}>
                  <Text style={styles.settingsItemTitle}>Logovi djelatnika</Text>
                  <Text style={styles.settingsItemSubtitle}>Povijest aktivnosti djelatnika</Text>
                </View>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#94A3B8" />
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.settingsItem, styles.settingsItemWithBorder]}
              onPress={() => navigation.navigate('About')}
            >
              <View style={styles.settingsItemLeft}>
                <View style={styles.iconContainer}>
                  <Ionicons name="information-circle-outline" size={24} color="#64748B" />
                </View>
                <View style={styles.settingsItemContent}>
                  <Text style={styles.settingsItemTitle}>O aplikaciji</Text>
                  <Text style={styles.settingsItemSubtitle}>Verzija i novosti</Text>
                </View>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#94A3B8" />
            </TouchableOpacity>

            <TouchableOpacity
              style={[styles.settingsItem, styles.settingsItemWithBorder]}
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.open('/help', '_blank');
                } else {
                  navigation.navigate('Help' as never);
                }
              }}
            >
              <View style={styles.settingsItemLeft}>
                <View style={styles.iconContainer}>
                  <Ionicons name="help-circle-outline" size={24} color="#64748B" />
                </View>
                <View style={styles.settingsItemContent}>
                  <Text style={styles.settingsItemTitle}>Pomoć</Text>
                  <Text style={styles.settingsItemSubtitle}>Upute i česta pitanja</Text>
                </View>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#94A3B8" />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.settingsItem}
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.open('/legal', '_blank');
                } else {
                  navigation.navigate('LegalDocuments' as never);
                }
              }}
            >
              <View style={styles.settingsItemLeft}>
                <View style={styles.iconContainer}>
                  <Ionicons name="document-text-outline" size={24} color="#64748B" />
                </View>
                <View style={styles.settingsItemContent}>
                  <Text style={styles.settingsItemTitle}>Pravni dokumenti</Text>
                  <Text style={styles.settingsItemSubtitle}>Uvjeti korištenja i privatnost</Text>
                </View>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#94A3B8" />
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
    marginTop: 24,
    paddingHorizontal: 16,
  },
  scrollView: {
    flex: 1,
  },
  section: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: '#FFFFFF',
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 16,
    color: '#475569',
    marginBottom: 8,
  },
  settingsItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    paddingVertical: 12,
    paddingHorizontal: 15,
    cursor: 'pointer',
  },
  settingsItemWithBorder: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#F1F5F9',
  },
  settingsItemLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
  },
  settingsItemContent: {
    flex: 1,
  },
  settingsItemTitle: {
    fontSize: 16,
    color: '#334155',
    marginBottom: 2,
  },
  settingsItemSubtitle: {
    fontSize: 14,
    color: '#64748B',
  },
  disabledItem: {
    opacity: 0.5,
  },
  disabledText: {
    color: '#A0AEC0',
  },
  comingSoonBadge: {
    backgroundColor: '#F1F5F9',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  comingSoonText: {
    fontSize: 12,
    color: '#94A3B8',
  },
});
