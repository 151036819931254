import React, { useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Text, TextInput, Button, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { employeesAPI } from '../../../../services/api';
import { useAuth } from '../../../../hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';

interface LocationData {
  name: string;
  address: string;
  country: string;
  city: string;
  companyName: string;
  companyOIB: string;
  weeklyHours: number;
  ownerId: string;
}

export const WebCreateLocationScreen = () => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { owner } = useAuth();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [locationData, setLocationData] = useState<LocationData>({
    name: '',
    address: '',
    country: 'Hrvatska',
    city: '',
    companyName: '',
    companyOIB: '',
    weeklyHours: 40,
    ownerId: owner?.id || '',
  });

  const handleSave = async () => {
    if (!owner) {
      setError('Niste prijavljeni');
      return;
    }

    // Validacija
    if (!locationData.name.trim()) {
      setError('Molimo unesite ime poslovnice');
      return;
    }
    if (!locationData.address.trim()) {
      setError('Molimo unesite adresu poslovnice');
      return;
    }
    if (!locationData.city.trim()) {
      setError('Molimo unesite grad');
      return;
    }
    if (!locationData.companyName.trim()) {
      setError('Molimo unesite ime tvrtke');
      return;
    }
    if (!locationData.companyOIB.trim() || locationData.companyOIB.length !== 11) {
      setError('Molimo unesite ispravan OIB (11 znamenki)');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await employeesAPI.createLocation({
        ...locationData,
        ownerId: owner.id
      });
      
      await Promise.all([
        queryClient.invalidateQueries(['locations']),
        queryClient.invalidateQueries(['dashboard']),
        queryClient.invalidateQueries(['locationStats']),
        queryClient.invalidateQueries(['totalStats']),
        queryClient.invalidateQueries(['settings']),
        queryClient.invalidateQueries(['locationLogs']),
        queryClient.invalidateQueries(['breaks'])
      ]);
      
      navigation.navigate('Locations');
    } catch (error) {
      setError(
        'Došlo je do greške prilikom kreiranja poslovnice. ' +
        (error.response?.data?.message || 'Molimo pokušajte ponovno.')
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.formContainer}>
          <View style={styles.formHeader}>
            <Text variant="headlineMedium" style={styles.title}>Nova poslovnica</Text>
            <Text style={styles.subtitle}>Ispunite podatke o poslovnici koju želite dodati u sustav.</Text>
          </View>

          {error && (
            <View style={styles.errorContainer}>
              <MaterialCommunityIcons name="alert-circle" size={20} color="#DC2626" />
              <Text style={styles.errorText}>{error}</Text>
            </View>
          )}

          <View style={styles.formContent}>
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Osnovni podaci</Text>
              <TextInput
                mode="outlined"
                label="Ime poslovnice"
                value={locationData.name}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, name: text }))}
                placeholder="npr. Šibenik - Podi"
                style={styles.input}
                outlineStyle={styles.inputOutline}
                contentStyle={styles.inputContent}
                error={error?.includes('ime poslovnice')}
                disabled={loading}
              />

              <TextInput
                mode="outlined"
                label="Adresa"
                value={locationData.address}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, address: text }))}
                placeholder="npr. Ul. Velimira Škorpika 24"
                style={styles.input}
                outlineStyle={styles.inputOutline}
                contentStyle={styles.inputContent}
                error={error?.includes('adresu')}
                disabled={loading}
              />

              <View style={styles.row}>
                <TextInput
                  mode="outlined"
                  label="Grad"
                  value={locationData.city}
                  onChangeText={(text) => setLocationData(prev => ({ ...prev, city: text }))}
                  placeholder="npr. Šibenik"
                  style={[styles.input, styles.flex1]}
                  outlineStyle={styles.inputOutline}
                  contentStyle={styles.inputContent}
                  error={error?.includes('grad')}
                  disabled={loading}
                />

                <TextInput
                  mode="outlined"
                  label="Država"
                  value={locationData.country}
                  onChangeText={(text) => setLocationData(prev => ({ ...prev, country: text }))}
                  placeholder="npr. Hrvatska"
                  style={[styles.input, styles.flex1]}
                  outlineStyle={styles.inputOutline}
                  contentStyle={styles.inputContent}
                  disabled={loading}
                />
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Podaci o tvrtki</Text>
              <TextInput
                mode="outlined"
                label="Ime tvrtke"
                value={locationData.companyName}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, companyName: text }))}
                placeholder="npr. Clock d.o.o."
                style={styles.input}
                outlineStyle={styles.inputOutline}
                contentStyle={styles.inputContent}
                error={error?.includes('ime tvrtke')}
                disabled={loading}
              />

              <View style={styles.row}>
                <TextInput
                  mode="outlined"
                  label="OIB tvrtke"
                  value={locationData.companyOIB}
                  onChangeText={(text) => setLocationData(prev => ({ ...prev, companyOIB: text }))}
                  placeholder="11 znamenki"
                  style={[styles.input, styles.flex1]}
                  outlineStyle={styles.inputOutline}
                  contentStyle={styles.inputContent}
                  error={error?.includes('OIB')}
                  disabled={loading}
                  keyboardType="numeric"
                  maxLength={11}
                />

                <TextInput
                  mode="outlined"
                  label="Tjedno radno vrijeme (h)"
                  value={locationData.weeklyHours.toString()}
                  onChangeText={(text) => {
                    const hours = parseInt(text) || 0;
                    setLocationData(prev => ({ ...prev, weeklyHours: hours }));
                  }}
                  placeholder="npr. 40"
                  style={[styles.input, styles.flex1]}
                  outlineStyle={styles.inputOutline}
                  contentStyle={styles.inputContent}
                  disabled={loading}
                  keyboardType="numeric"
                />
              </View>
            </View>
          </View>

          <View style={styles.footer}>
            <Button 
              mode="outlined"
              onPress={() => navigation.goBack()}
              style={styles.cancelButton}
              contentStyle={styles.buttonContent}
              labelStyle={styles.cancelButtonText}
            >
              Odustani
            </Button>
            <Button 
              mode="contained"
              onPress={handleSave}
              loading={loading}
              disabled={loading}
              style={styles.saveButton}
              contentStyle={styles.buttonContent}
              buttonColor="#1F2937"
            >
              {loading ? 'Kreiranje...' : 'Kreiraj poslovnicu'}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
    maxHeight: '100vh',
  },
  content: {
    flex: 1,
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
    padding: 16,
    display: 'flex',
  },
  formContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E5E7EB',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 4,
    elevation: 2,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
  },
  title: {
    color: '#111827',
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 4,
  },
  subtitle: {
    color: '#6B7280',
    fontSize: 14,
    lineHeight: 20,
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FEF2F2',
    padding: 12,
    margin: 16,
    borderRadius: 8,
    gap: 8,
  },
  errorText: {
    color: '#DC2626',
    fontSize: 14,
    flex: 1,
    lineHeight: 20,
  },
  formContent: {
    padding: 16,
    flex: 1,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 13,
    fontWeight: '600',
    color: '#374151',
    marginBottom: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  input: {
    backgroundColor: 'white',
    fontSize: 14,
    marginBottom: 12,
  },
  inputOutline: {
    borderRadius: 8,
    borderColor: '#E5E7EB',
  },
  inputContent: {
    height: 40,
    paddingHorizontal: 12,
  },
  row: {
    flexDirection: Platform.OS === 'web' && window.innerWidth <= 640 ? 'column' : 'row',
    gap: 16,
    marginBottom: 12,
  },
  flex1: {
    flex: 1,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: '#E5E7EB',
    gap: 12,
  },
  cancelButton: {
    borderColor: '#E5E7EB',
    height: 36,
  },
  cancelButtonText: {
    color: '#374151',
    fontSize: 14,
  },
  saveButton: {
    minWidth: 120,
    height: 36,
  },
  buttonContent: {
    height: 36,
  },
});
