import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, KeyboardAvoidingView, Platform, ScrollView, Alert, Keyboard } from 'react-native';
import { Appbar, TextInput, useTheme, HelperText } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { employeesAPI } from '../../../services/api';
import { Location } from '../../../types/location';
import { LocationPickerModal } from './LocationPickerModal';

export const CreateEmployeeScreen = ({ navigation }: any) => {
  const theme = useTheme();
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [oib, setOib] = useState('');
  const [position, setPosition] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [pin, setPin] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setIsLoading(true);
      setError('');
      const locations = await employeesAPI.getLocations();
      setLocations(locations);
    } catch (error) {
      setError('Nije moguće dohvatiti poslovnice. Provjerite internetsku vezu i pokušajte ponovno.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocationPress = () => {
    Keyboard.dismiss();
    if (locations.length === 0) {
      Alert.alert(
        "Nema dostupnih poslovnica",
        "Prvo morate kreirati barem jednu poslovnicu prije nego što možete dodati djelatnika.",
        [
          { 
            text: "U redu", 
            onPress: () => navigation.navigate('CreateLocation') 
          }
        ]
      );
      return;
    }
    setLocationModalVisible(true);
  };

  const handleLocationSelect = async (location: Location) => {
    setSelectedLocation(location);
    setLocationModalVisible(false);

    try {
      let newPin;
      let isAvailable = false;
      let attempts = 0;
      const maxAttempts = 20;

      do {
        newPin = Math.floor(1000 + Math.random() * 9000).toString();
        isAvailable = await employeesAPI.checkPin(location._id, newPin);
        attempts++;
        if (attempts >= maxAttempts) {
          throw new Error('Nije moguće generirati jedinstveni PIN nakon 20 pokušaja');
        }
      } while (!isAvailable);

      setPin(newPin);
    } catch (error) {
      Alert.alert(
        "Greška",
        "Došlo je do greške pri generiranju PIN-a. Molimo pokušajte ponovno."
      );
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!fullName) {
      isValid = false;
    }

    if (!dateOfBirth) {
      isValid = false;
    } else {
      const [day, month, year] = dateOfBirth.split('.');
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      if (isNaN(date.getTime()) || 
          date.getFullYear() > 2024 || 
          date.getFullYear() < 1900 || 
          parseInt(month) > 12 || 
          parseInt(day) > 31) {
        Alert.alert("Greška", "Neispravan format datuma. Koristite format DD.MM.YYYY");
        return false;
      }
    }

    if (!selectedLocation) {
      isValid = false;
    }

    if (!pin) {
      isValid = false;
    }

    if (!hourlyRate) {
      isValid = false;
    }

    if (!phone || phone.replace(/[^0-9]/g, '').length < 8) {
      isValid = false;
    }

    if (!oib) {
      isValid = false;
    } else if (oib.length !== 11) {
      Alert.alert("Greška", "OIB mora imati točno 11 znamenki");
      return false;
    }

    if (pin && pin.length !== 4) {
      Alert.alert("Greška", "PIN mora imati točno 4 znamenke");
      return false;
    }

    setShowErrors(!isValid);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const isPinAvailable = await employeesAPI.checkPin(selectedLocation._id, pin);
      
      if (!isPinAvailable) {
        Alert.alert(
          "Greška",
          "PIN je već u upotrebi na ovoj lokaciji. Molimo odaberite drugi PIN."
        );
        return;
      }

      if (!fullName || !fullName.trim()) {
        Alert.alert("Greška", "Ime i prezime su obavezni");
        return;
      }

      const [firstName, ...surnameArray] = fullName.trim().split(' ');
      const lastName = surnameArray.join(' ');

      if (!firstName || !lastName) {
        Alert.alert("Greška", "Unesite ime i prezime odvojene razmakom");
        return;
      }

      const [day, month, year] = dateOfBirth.split('.');
      const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

      const employee = {
        name: firstName,
        surname: lastName,
        dateOfBirth: isoDate,
        email,
        phone,
        oib,
        position,
        hourlyRate: parseFloat(hourlyRate),
        locationId: selectedLocation._id,
        pin
      };

      await employeesAPI.createEmployee(employee);
      
      navigation.navigate('Employees', { refresh: true });
    } catch (error) {
      Alert.alert(
        "Greška",
        "Došlo je do greške pri spremanju djelatnika. Molimo pokušajte ponovno."
      );
    }
  };

  return (
    <SafeAreaView style={styles.container} edges={['left', 'right']}>
      <Appbar.Header style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Appbar.Content title="Novi djelatnik" titleStyle={styles.headerTitle} />
        <TouchableOpacity 
          style={styles.saveButton}
          onPress={handleSave}
        >
          <Text style={[styles.saveButtonText, { color: theme.colors.primary }]}>Kreiraj</Text>
        </TouchableOpacity>
      </Appbar.Header>

      <KeyboardAvoidingView 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.content}
      >
        <ScrollView 
          style={styles.scrollView}
          contentContainerStyle={styles.scrollContent}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Osnovne informacije</Text>
            
            <TextInput
              mode="outlined"
              label="Ime i prezime *"
              value={fullName}
              onChangeText={(text) => {
                if (text.length === 1) {
                  setFullName(text.toUpperCase());
                  return;
                }
                if (text.length > fullName.length && text[text.length - 2] === ' ') {
                  const parts = text.split(' ');
                  const lastPart = parts[parts.length - 1];
                  parts[parts.length - 1] = lastPart.charAt(0).toUpperCase() + lastPart.slice(1);
                  setFullName(parts.join(' '));
                  return;
                }
                if (text.includes(' ')) {
                  const parts = text.split(' ');
                  const formattedParts = parts.map(part => 
                    part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
                  );
                  setFullName(formattedParts.join(' '));
                  return;
                }
                setFullName(text);
              }}
              style={styles.input}
              error={showErrors && !fullName}
            />

            <TextInput
              mode="outlined"
              label="Datum rođenja (DD.MM.YYYY) *"
              value={dateOfBirth}
              onChangeText={(text) => {
                // Dozvoli brisanje
                if (text.length < dateOfBirth.length) {
                  setDateOfBirth(text);
                  return;
                }

                // Dozvoli samo brojeve i točku
                let cleaned = text.replace(/[^0-9.]/g, '');
                
                // Validacija dana (prvi broj ne smije biti 4 ili veći, drugi broj ne smije dati više od 31)
                if (cleaned.length === 1) {
                  const firstDigit = parseInt(cleaned);
                  if (firstDigit > 3) return;
                } else if (cleaned.length === 2) {
                  const day = parseInt(cleaned);
                  if (day > 31) return;
                  cleaned += '.';
                }
                
                // Validacija mjeseca (prvi broj ne smije biti 2 ili veći, drugi broj ne smije dati više od 12)
                if (cleaned.length === 4) {
                  const firstDigit = parseInt(cleaned[3]);
                  if (firstDigit > 1) return;
                } else if (cleaned.length === 5) {
                  const month = parseInt(cleaned.split('.')[1]);
                  if (month > 12) return;
                  cleaned += '.';
                }
                
                // Validacija godine (1900-danas)
                if (cleaned.length > 6) {
                  const parts = cleaned.split('.');
                  if (parts.length === 3) {
                    const yearSoFar = parts[2];
                    if (yearSoFar.length === 4) {
                      const year = parseInt(yearSoFar);
                      const currentYear = new Date().getFullYear();
                      if (year < 1900 || year > currentYear) return;
                    }
                  }
                }

                setDateOfBirth(cleaned.slice(0, 10));
              }}
              keyboardType="numeric"
              style={styles.input}
              error={showErrors && !dateOfBirth}
            />

            <TextInput
              mode="outlined"
              label="Email"
              value={email}
              onChangeText={(text) => {
                const atIndex = text.lastIndexOf('@');
                if (atIndex !== -1 && (!email.includes('@') || email.endsWith('@'))) {
                  const letter = text.substring(atIndex + 1);
                  if (letter === 'g') {
                    setEmail(text + 'mail.com');
                  } else if (letter === 'h') {
                    setEmail(text + 'otmail.com');
                  } else {
                    setEmail(text);
                  }
                } else {
                  setEmail(text);
                }
              }}
              keyboardType="email-address"
              autoCapitalize="none"
              style={styles.input}
            />

            <TextInput
              mode="outlined"
              label={showErrors && (!phone || phone.replace(/[^0-9]/g, '').length < 8) ? "Broj mobitela - minimalno 8 brojeva *" : "Broj mobitela *"}
              value={phone}
              onChangeText={setPhone}
              placeholder="+385"
              keyboardType="phone-pad"
              style={styles.input}
              error={showErrors && (!phone || phone.replace(/[^0-9]/g, '').length < 8)}
            />

            <TextInput
              mode="outlined"
              label={showErrors && !oib ? "OIB - 11 brojeva je obavezno *" : "OIB *"}
              value={oib}
              onChangeText={(text) => {
                const numbersOnly = text.replace(/[^0-9]/g, '');
                setOib(numbersOnly);
              }}
              error={showErrors && !oib}
              keyboardType="numeric"
              style={styles.input}
            />
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Poslovno</Text>

            <TouchableOpacity 
              onPress={handleLocationPress}
              activeOpacity={0.8}
              style={styles.locationInput}
            >
              <TextInput
                mode="outlined"
                label="Poslovnica *"
                value={selectedLocation?.name || ''}
                editable={false}
                pointerEvents="none"
                style={styles.input}
                error={showErrors && !selectedLocation}
              />
            </TouchableOpacity>
            {isLoading && (
              <HelperText type="info" visible={true}>
                Učitavanje poslovnica...
              </HelperText>
            )}
            {error && (
              <HelperText type="error" visible={true}>
                {error}
              </HelperText>
            )}

            <TextInput
              mode="outlined"
              label="Pozicija *"
              value={position}
              onChangeText={setPosition}
              style={styles.input}
              error={showErrors && !position}
            />

            <TextInput
              mode="outlined"
              label="Satnica *"
              value={hourlyRate}
              onChangeText={(text) => {
                // Dozvoli samo brojeve i jednu decimalnu točku
                const cleaned = text.replace(/[^0-9.,]/g, '').replace(',', '.');
                if (cleaned.includes('.')) {
                  const [whole, decimal] = cleaned.split('.');
                  setHourlyRate(whole + '.' + (decimal || '').slice(0, 2));
                } else {
                  setHourlyRate(cleaned);
                }
              }}
              keyboardType="decimal-pad"
              right={<TextInput.Affix text="€/h" />}
              style={styles.input}
              error={showErrors && !hourlyRate}
            />

            <TextInput
              label={pin && pin.length !== 4 ? "PIN mora imati 4 znamenke" : "PIN - automatski dodijeljen *"}
              value={pin}
              onChangeText={setPin}
              mode="outlined"
              keyboardType="numeric"
              maxLength={4}
              error={!!(pin && pin.length !== 4)}
              style={styles.input}
              editable={false}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>

      <LocationPickerModal
        visible={locationModalVisible}
        locations={locations}
        onDismiss={() => setLocationModalVisible(false)}
        onSelect={handleLocationSelect}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  headerTitle: {
    fontSize: 20,
    textAlign: 'center',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  saveButton: {
    marginRight: 16,
  },
  saveButtonText: {
    fontSize: 17,
    fontWeight: '600',
  },
  content: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  scrollContent: {
    padding: 16,
    paddingBottom: 32,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 16,
    color: '#000',
  },
  input: {
    marginBottom: 8,
    backgroundColor: '#fff',
  },
  locationInput: {
    width: '100%'
  },
});
