import api from '../api';

// Tip za log unos
export type LogEntry = {
  id: string;
  employeeName: string;
  adminName?: string;  // Ime admin korisnika koji je napravio promjenu
  action: 'login' | 'logout' | 'break_start' | 'break_end' | 'admin_checkin' | 'admin_checkout' | 'admin_break_start' | 'admin_break_end' | 'admin_edit_session' | 'admin_edit_break';
  timestamp: string;
  breakName?: string;
  originalTime?: string;  // Originalno vrijeme prije admin promjene
  actionBy?: string;  // Tko je napravio radnju - "Djelatnik" ili "Administrator: [ime]"
  startedBy?: string;  // Tko je započeo pauzu - koristi se kad je razlicita osoba (djelatnik) započela, a druga (admin) završila
  endedBy?: string;    // Tko je završio sesiju ili pauzu
  deviceId?: string;   // ID uređaja s kojeg je napravljena akcija
};

export const logsAPI = {
  // Dohvaća logove za određenu lokaciju i datum
  getLocationLogs: async (locationId: string, date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    const response = await api.get(`/logs/${locationId}?date=${formattedDate}`);
    return response.data;
  },
  
  // Dohvaća logove za određenog djelatnika i datum
  getEmployeeLogs: async (employeeId: string, date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    const response = await api.get(`/logs/employee/${employeeId}?date=${formattedDate}`);
    return response.data;
  },
  
  // Dohvaća administratorske logove za određenu lokaciju i datum
  getAdminLogs: async (locationId: string, date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    const response = await api.get(`/logs/admin/${locationId}?date=${formattedDate}`);
    return response.data;
  }
};
