import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CustomToast } from '../components/CustomToast';

type ToastType = 'success' | 'error' | 'info';

interface ToastContextProps {
  showToast: (message: string, title?: string, type?: ToastType, duration?: number) => void;
  hideToast: () => void;
  hideToastImmediately: () => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [type, setType] = useState<ToastType>('success');
  const [duration, setDuration] = useState(3000);
  const [toastId, setToastId] = useState(0);

  const showToast = (
    message: string,
    title?: string,
    type: ToastType = 'success',
    duration: number = 3000
  ) => {
    if (visible) {
      setVisible(false);
      
      setTimeout(() => {
        setMessage(message);
        setTitle(title);
        setType(type);
        setDuration(duration);
        setToastId(prevId => prevId + 1);
        setVisible(true);
      }, 100);
    } else {
      setMessage(message);
      setTitle(title);
      setType(type);
      setDuration(duration);
      setToastId(prevId => prevId + 1);
      setVisible(true);
    }
  };

  const hideToast = () => {
    setVisible(false);
  };

  const hideToastImmediately = () => {
    setVisible(false);
  };

  return (
    <ToastContext.Provider value={{ showToast, hideToast, hideToastImmediately }}>
      {children}
      <CustomToast
        visible={visible}
        message={message}
        title={title}
        type={type}
        duration={duration}
        onHide={hideToast}
        onHideImmediately={hideToastImmediately}
        toastId={toastId}
      />
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast mora biti korišten unutar ToastProvider-a');
  }
  return context;
};
