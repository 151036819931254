import { registerRootComponent } from 'expo';
import { Platform } from 'react-native';

import App from './App';
import WebApp from './WebApp';

// Kod korištenja Web okruženja, trebamo dodati web-specific stvari
if (Platform.OS === 'web') {
  // Koristimo react-dom za web
  require('./src/utils/setupWeb');
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(Platform.OS === 'web' ? WebApp : App);
