import React, { useState, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Text, Divider, useTheme, Button } from 'react-native-paper';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { employeesAPI } from '../../../../services/api';
import { DateFilters, WebAdminEmployeeTimesheetManager } from './admin-controls';
import { WebEmployeeStats } from './WebEmployeeStats';
import { WebEmployeeDetailedStats } from './WebEmployeeDetailedStats';
import { format, subDays } from 'date-fns';
import { hr } from 'date-fns/locale';

interface WebEmployeeDetailsProps {
  route?: {
    params: {
      employeeId: string;
    };
  };
}

interface Employee {
  _id: string;
  name: string;
  surname: string;
  pin: string;
  dateOfBirth?: string;
  location?: {
    _id: string;
    name: string;
  };
  locationId?: {
    _id: string;
    name: string;
  };
  position?: string;
  active: boolean;
  isActive?: boolean;
  phone?: string;
  oib?: string;
  email?: string;
  hourlyRate?: number;
}

export const WebEmployeeDetailsScreen: React.FC<WebEmployeeDetailsProps> = ({ route }) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [selectedTab, setSelectedTab] = useState<'info' | 'stats' | 'timesheet' | 'detaljna'>('info');
  const [loading, setLoading] = useState(true);
  const [displayDate, setDisplayDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const fetchEmployee = async () => {
    try {
      setLoading(true);
      const response = await employeesAPI.getEmployeeDetails(route?.params?.employeeId);
      setEmployee(response);
    } catch (error) {
      console.error('Error fetching employee:', error);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchEmployee();
    }, [route?.params?.employeeId])
  );

  const formatDate = (isoDate: string) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  };

  // DateFilters props
  const quickFilters = [
    { label: 'Danas', date: new Date() },
    { label: 'Jučer', date: subDays(new Date(), 1) },
    { label: 'Prekjučer', date: subDays(new Date(), 2) },
  ];

  const handleQuickFilter = (filter: string, date: Date) => {
    setSelectedFilter(filter);
    setDisplayDate(date);
  };

  const renderEmployeeInfo = () => (
    <View style={styles.contentContainer}>
      <View style={styles.card}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>Osnovne informacije</Text>
          <TouchableOpacity 
            onPress={() => navigation.navigate('EditEmployee', { employeeId: employee._id })} 
            style={styles.editButton}
          >
            <Ionicons name="create-outline" size={20} color="#007AFF" />
          </TouchableOpacity>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Ime i prezime</Text>
              <Text style={styles.tableCellValue}>{`${employee?.name} ${employee?.surname}`}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Poslovnica</Text>
              <Text style={styles.tableCellValue}>{employee?.locationId?.name || employee?.location?.name || 'Nije dodijeljeno'}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Datum rođenja</Text>
              <Text style={styles.tableCellValue}>{employee?.dateOfBirth ? formatDate(employee.dateOfBirth) : '-'}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Email</Text>
              <Text style={styles.tableCellValue}>{employee?.email || '-'}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>OIB</Text>
              <Text style={styles.tableCellValue}>{employee?.oib || '-'}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Pozicija</Text>
              <Text style={styles.tableCellValue}>{employee?.position || '-'}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Broj mobitela</Text>
              <Text style={styles.tableCellValue}>{employee?.phone || '-'}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Satnica</Text>
              <Text style={styles.tableCellValue}>{employee?.hourlyRate !== undefined ? `${employee.hourlyRate.toFixed(2)} €` : '-'}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.card}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>Pristupni podaci</Text>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>PIN</Text>
              <Text style={styles.tableCellValue}>{employee?.pin || '-'}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.tableCellLabel}>Status</Text>
              <View style={[styles.statusBadge, employee?.isActive ? styles.statusActive : styles.statusInactive]}>
                <Text style={[styles.statusText, employee?.isActive ? styles.statusTextActive : styles.statusTextInactive]}>
                  {employee?.isActive ? 'Aktivan' : 'Neaktivan'}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  if (loading || !employee) {
    return (
      <View style={styles.container}>
        <View style={styles.loadingContainer}>
          <Text>Učitavanje...</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.mainContainer}>
        <View style={styles.tabContainer}>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'info' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('info')}
          >
            <Text style={[
              styles.tabText,
              selectedTab === 'info' && styles.activeTabText
            ]}>
              Informacije
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'stats' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('stats')}
          >
            <Text style={[
              styles.tabText,
              selectedTab === 'stats' && styles.activeTabText
            ]}>
              Statistika
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'detaljna' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('detaljna')}
          >
            <View style={{ position: 'relative' }}>
              <View style={styles.badgeContainer}>
                <Text style={styles.badgeText}>NOVO imenjače</Text>
              </View>
              <Text style={[
                styles.tabText,
                selectedTab === 'detaljna' && styles.activeTabText
              ]}>
                Detaljna statistika
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'timesheet' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('timesheet')}
          >
            <Text style={[
              styles.tabText,
              selectedTab === 'timesheet' && styles.activeTabText
            ]}>
              Evidencija
            </Text>
          </TouchableOpacity>
        </View>

        {selectedTab === 'info' && renderEmployeeInfo()}
        {selectedTab === 'stats' && (
          <WebEmployeeStats employeeId={employee._id} />
        )}
        {selectedTab === 'detaljna' && (
          <WebEmployeeDetailedStats employeeId={employee._id} />
        )}
        {selectedTab === 'timesheet' && (
          <WebAdminEmployeeTimesheetManager
            employeeId={employee._id}
            locationId={employee.location?._id || employee.locationId?._id}
            employee={{
              name: employee.name,
              surname: employee.surname
            }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 24,
  },
  mainContainer: {
    backgroundColor: '#FFFFFF',
  },
  contentContainer: {
    gap: 24,
    paddingBottom: 24,
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    overflow: 'hidden',
    marginBottom: 16,
  },
  section: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    marginBottom: 16,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    backgroundColor: '#F8FAFC',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#1E293B',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
  editButton: {
    padding: 8,
  },
  tableContainer: {
    padding: 0,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  tableCell: {
    flex: 1,
    padding: 16,
    borderRightWidth: 1,
    borderRightColor: '#E2E8F0',
    backgroundColor: '#FFFFFF',
  },
  tableCellLabel: {
    fontSize: 12,
    color: '#64748B',
    marginBottom: 6,
    letterSpacing: 0.2,
    textTransform: 'uppercase',
  },
  tableCellValue: {
    fontSize: 14,
    color: '#1E293B',
    fontWeight: '500',
    letterSpacing: 0.1,
  },
  statusBadge: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    alignSelf: 'flex-start',
    marginTop: 4,
  },
  statusActive: {
    backgroundColor: '#ECFDF5',
    borderWidth: 1,
    borderColor: '#A7F3D0',
  },
  statusInactive: {
    backgroundColor: '#FEF2F2',
    borderWidth: 1,
    borderColor: '#FECACA',
  },
  statusText: {
    fontSize: 12,
    fontWeight: '600',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
  statusTextActive: {
    color: '#059669',
  },
  statusTextInactive: {
    color: '#DC2626',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabContainer: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  tab: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginRight: 8,
    backgroundColor: '#FFFFFF',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#2563EB',
  },
  tabText: {
    fontSize: 15,
    color: '#64748B',
  },
  activeTabText: {
    color: '#2563EB',
    fontWeight: '500',
  },
  badgeContainer: {
    backgroundColor: '#10B981',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 2,
    position: 'absolute',
    top: -18,
    right: -20,
    zIndex: 1,
  },
  badgeText: {
    fontSize: 12,
    color: '#FFFFFF',
    fontWeight: '600',
  },
});
