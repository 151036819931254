import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, KeyboardAvoidingView, Platform, ScrollView, Alert, Keyboard } from 'react-native';
import { useTheme, HelperText, TextInput } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { employeesAPI } from '../../../services/api';
import { Location } from '../../../types/location';
import { LocationPickerModal } from './LocationPickerModal';
import { useRoute } from '@react-navigation/native';

export const EditEmployeeScreen = ({ navigation }: any) => {
  const theme = useTheme();
  const route = useRoute();
  const employeeId = route.params?.employeeId;

  const [fullName, setFullName] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [oib, setOib] = useState('');
  const [oibError, setOibError] = useState('');
  const [position, setPosition] = useState('');
  const [positionError, setPositionError] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [hourlyRateError, setHourlyRateError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [locationError, setLocationError] = useState('');
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [pin, setPin] = useState('');
  const [pinError, setPinError] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [originalPin, setOriginalPin] = useState('');
  const [originalOib, setOriginalOib] = useState('');
  const [isPinValid, setIsPinValid] = useState(true);
  const [isOibValid, setIsOibValid] = useState(true);

  useEffect(() => {
    fetchLocations();
    if (employeeId) {
      fetchEmployeeDetails();
    }
  }, [employeeId]);

  const fetchEmployeeDetails = async () => {
    try {
      setIsLoading(true);
      const employee = await employeesAPI.getEmployeeDetails(employeeId);
      setFullName(`${employee.name} ${employee.surname}`);
      const date = new Date(employee.dateOfBirth);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      setDateOfBirth(formattedDate);
      setEmail(employee.email || '');
      setPhone(employee.phone || '');
      setOib(employee.oib || '');
      setPosition(employee.position || '');
      setHourlyRate(employee.hourlyRate?.toString() || '');
      setSelectedLocation(employee.locationId || employee.location || null);
      setPin(employee.pin);
      setOriginalPin(employee.pin);
      setOriginalOib(employee.oib || '');
    } catch (error) {
      Alert.alert('Greška', 'Nije moguće učitati podatke djelatnika');
      navigation.goBack();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLocations = async () => {
    try {
      setIsLoading(true);
      setError('');
      const locations = await employeesAPI.getLocations();
      setLocations(locations);
    } catch (error) {
      setError('Nije moguće dohvatiti poslovnice. Provjerite internetsku vezu i pokušajte ponovno.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocationPress = () => {
    Keyboard.dismiss();
    if (locations.length === 0) {
      Alert.alert(
        "Nema dostupnih poslovnica",
        "Prvo morate kreirati barem jednu poslovnicu.",
        [{ 
          text: "U redu",
          onPress: () => navigation.navigate('CreateLocation')
        }]
      );
      return;
    }
    setLocationModalVisible(true);
  };

  const generateAvailablePin = async (locationId: string): Promise<string> => {
    const generateRandomPin = () => {
      return Math.floor(1000 + Math.random() * 9000).toString();
    };

    let newPin = generateRandomPin();
    let attempts = 0;
    const maxAttempts = 100;

    while (attempts < maxAttempts) {
      try {
        const isAvailable = await employeesAPI.checkPin(locationId, newPin);
        if (isAvailable) {
          return newPin;
        }
        newPin = generateRandomPin();
        attempts++;
      } catch (error) {
        throw new Error('Nije moguće generirati novi PIN');
      }
    }

    throw new Error('Nije moguće pronaći slobodan PIN nakon više pokušaja');
  };

  const handleLocationSelect = async (location: Location) => {
    setSelectedLocation(location);
    setLocationModalVisible(false);

    if (pin) {
      try {
        const isPinAvailable = await employeesAPI.checkPin(location._id, pin, employeeId);
        if (!isPinAvailable) {
          const newPin = await generateAvailablePin(location._id);
          setPin(newPin);
          Alert.alert(
            "Automatska promjena PIN-a",
            `PIN ${pin} je već u upotrebi na odabranoj lokaciji. Generiran je novi PIN: ${newPin}`
          );
        }
      } catch (error) {
        Alert.alert(
          "Greška",
          "Došlo je do greške pri provjeri PIN-a"
        );
      }
    }
  };

  useEffect(() => {
    const checkPinAvailability = async () => {
      if (!selectedLocation || !pin || pin === originalPin || pin.length !== 4) return;

      try {
        const isPinAvailable = await employeesAPI.checkPin(selectedLocation._id, pin, employeeId);
        setIsPinValid(isPinAvailable);
        setPinError(isPinAvailable ? '' : 'PIN je već u upotrebi na ovoj lokaciji');
      } catch (error) {
        setPinError('Greška pri provjeri PIN-a');
      }
    };

    checkPinAvailability();
  }, [pin, selectedLocation, originalPin, employeeId]);

  useEffect(() => {
    const checkOibAvailability = async () => {
      if (!selectedLocation || !oib || oib === originalOib || oib.length !== 11) return;

      try {
        const response = await employeesAPI.checkOib(selectedLocation._id, oib);
        setIsOibValid(response.available);
        setOibError(response.available ? '' : 'OIB je već u upotrebi na ovoj lokaciji');
      } catch (error) {
        setOibError('Greška pri provjeri OIB-a');
      }
    };

    checkOibAvailability();
  }, [oib, selectedLocation, originalOib]);

  const validateForm = () => {
    let isValid = true;

    if (!fullName || !fullName.trim()) {
      setFullNameError('Ime i prezime su obavezni');
      isValid = false;
    } else {
      const [firstName, ...surnameArray] = fullName.trim().split(' ');
      const lastName = surnameArray.join(' ');
      if (!firstName || !lastName) {
        setFullNameError('Unesite ime i prezime odvojene razmakom');
        isValid = false;
      }
    }

    if (!dateOfBirth) {
      setDateOfBirthError('Datum rođenja je obavezan');
      isValid = false;
    } else {
      const [day, month, year] = dateOfBirth.split('.');
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      if (isNaN(date.getTime()) || 
          date.getFullYear() > 2024 || 
          date.getFullYear() < 1900 || 
          parseInt(month) > 12 || 
          parseInt(day) > 31) {
        setDateOfBirthError('Neispravan format datuma. Koristite format DD.MM.YYYY');
        isValid = false;
      }
    }

    if (!selectedLocation) {
      setLocationError('Lokacija je obavezna');
      isValid = false;
    }

    if (!pin || pin.length !== 4) {
      setPinError('PIN mora imati točno 4 znamenke');
      isValid = false;
    }

    if (!hourlyRate) {
      setHourlyRateError('Satnica je obavezna');
      isValid = false;
    }

    if (!phone || phone.trim() === '') {
      setPhoneError('Broj mobitela je obavezan');
      isValid = false;
    }

    if (!position || position.trim() === '') {
      setPositionError('Radno mjesto je obavezno');
      isValid = false;
    }

    if (oib && oib.length !== 11) {
      setOibError('OIB mora imati točno 11 znamenki');
      isValid = false;
    }

    if (!isValid) {
      Alert.alert("Greška", "Molimo popunite sva obavezna polja označena sa *");
    }

    return isValid;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const [firstName, ...surnameArray] = fullName.trim().split(' ');
      const lastName = surnameArray.join(' ');

      if (!firstName || !lastName) {
        Alert.alert("Greška", "Unesite ime i prezime odvojene razmakom");
        return;
      }

      const [day, month, year] = dateOfBirth.split('.');
      const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

      await employeesAPI.updateEmployee(employeeId, {
        name: firstName,
        surname: lastName,
        dateOfBirth: isoDate,
        email,
        phone,
        oib,
        position,
        hourlyRate: parseFloat(hourlyRate),
        locationId: selectedLocation._id,
        pin
      });

      const refreshedEmployee = await employeesAPI.getEmployeeDetails(employeeId);

      Alert.alert('Uspjeh', 'Djelatnik je uspješno ažuriran');
      navigation.navigate('EmployeeDetails', { 
        employeeId,
        employee: refreshedEmployee,
        refresh: true
      });
    } catch (error) {
      Alert.alert(
        "Greška",
        "Došlo je do greške pri ažuriranju djelatnika. Molimo pokušajte ponovno."
      );
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1 }}
      >
        <View style={styles.header}>
          <TouchableOpacity 
            style={styles.backButton} 
            onPress={() => navigation.goBack()}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <Ionicons name="arrow-back" size={24} color="#000" />
            <Text style={styles.backText}>Natrag</Text>
          </TouchableOpacity>
          
          <Text style={styles.title}>Uredi djelatnika</Text>
          
          <TouchableOpacity 
            style={styles.saveButton} 
            onPress={handleSave}
          >
            <Text style={[styles.saveText, { color: theme.colors.primary }]}>Spremi</Text>
          </TouchableOpacity>
        </View>

        <ScrollView style={styles.scrollView} keyboardShouldPersistTaps="handled">
          <View style={styles.content}>
            <TextInput
              label={fullNameError || "Ime i prezime *"}
              value={fullName}
              onChangeText={setFullName}
              mode="outlined"
              error={!!fullNameError}
              style={styles.input}
            />

            <TextInput
              label={dateOfBirthError || "Datum rođenja *"}
              placeholder="DD.MM.YYYY"
              value={dateOfBirth}
              onChangeText={setDateOfBirth}
              mode="outlined"
              error={!!dateOfBirthError}
              style={styles.input}
              keyboardType="numeric"
            />

            <TextInput
              label="Email"
              value={email}
              onChangeText={setEmail}
              mode="outlined"
              keyboardType="email-address"
              style={styles.input}
            />

            <TextInput
              label={phoneError || "Broj mobitela *"}
              placeholder="+385"
              value={phone}
              onChangeText={setPhone}
              mode="outlined"
              keyboardType="phone-pad"
              error={!!phoneError}
              style={styles.input}
            />

            <TextInput
              label={oibError || "OIB"}
              value={oib}
              onChangeText={setOib}
              mode="outlined"
              keyboardType="numeric"
              maxLength={11}
              error={!!oibError}
              style={styles.input}
            />

            <TextInput
              label={positionError || "Radno mjesto *"}
              value={position}
              onChangeText={setPosition}
              mode="outlined"
              error={!!positionError}
              style={styles.input}
            />

            <TextInput
              label={hourlyRateError || "Satnica *"}
              value={hourlyRate}
              onChangeText={setHourlyRate}
              mode="outlined"
              keyboardType="numeric"
              error={!!hourlyRateError}
              style={styles.input}
            />

            <TouchableOpacity onPress={handleLocationPress} style={styles.locationButton}>
              <View style={[styles.locationContainer, !!locationError && styles.errorBorder]}>
                <Text style={styles.locationLabel}>{locationError || "Poslovnica *"}</Text>
                <Text style={[
                  styles.locationValue,
                  !!locationError && styles.errorText
                ]}>
                  {selectedLocation ? selectedLocation.name : 'Odaberi poslovnicu'}
                </Text>
                <Ionicons 
                  name="chevron-forward" 
                  size={24} 
                  color={locationError ? theme.colors.error : '#666'} 
                />
              </View>
            </TouchableOpacity>

            <TextInput
              label={pinError || "PIN *"}
              value={pin}
              onChangeText={setPin}
              mode="outlined"
              keyboardType="numeric"
              maxLength={4}
              error={!!pinError}
              style={styles.input}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>

      <LocationPickerModal
        visible={locationModalVisible}
        locations={locations}
        onDismiss={() => setLocationModalVisible(false)}
        onSelect={handleLocationSelect}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 80,
    paddingVertical: 8,
    zIndex: 1,
  },
  backText: {
    marginLeft: 4,
    fontSize: 16,
    color: '#000',
  },
  title: {
    fontSize: 20,
    color: '#000',
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  saveButton: {
    padding: 8,
  },
  saveText: {
    fontSize: 16,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    padding: 16,
  },
  input: {
    marginBottom: 8,
  },
  locationButton: {
    marginVertical: 8,
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 16,
  },
  locationLabel: {
    position: 'absolute',
    top: -8,
    left: 8,
    backgroundColor: '#fff',
    paddingHorizontal: 4,
    fontSize: 12,
    color: '#666',
  },
  locationValue: {
    flex: 1,
    fontSize: 16,
  },
  errorText: {
    color: '#B00020',
  },
  errorBorder: {
    borderColor: '#B00020',
  },
});
