import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { Text, Button, useTheme, ActivityIndicator, DataTable } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { employeesAPI } from '../../../services/api';
import { WebEmptyState } from '../dashboard/WebEmptyState';

interface Location {
  _id: string;
  name: string;
  address?: string;
  companyOIB?: string;
  companyName?: string;
  weeklyHours?: number;
  city?: string;
}

export const WebLocationsScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [locations, setLocations] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchLocations = async () => {
    try {
      const response = await employeesAPI.getLocations();
      // Dohvati detalje za svaku lokaciju
      const locationsWithDetails = await Promise.all(
        response.map(async (location: Location) => {
          const details = await employeesAPI.getLocationDetails(location._id);
          console.log('Location details:', details); // Dodajemo log
          return { ...location, ...details };
        })
      );
      console.log('All locations with details:', locationsWithDetails); // Dodajemo log
      setLocations(locationsWithDetails);
    } catch (error) {
      console.error('Greška pri dohvaćanju poslovnica:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchLocations();
    setRefreshing(false);
  }, []);

  const handleLocationPress = (locationId: string) => {
    navigation.navigate('LocationDetails', { locationId });
  };

  const filteredLocations = locations.filter(location =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (location.companyOIB && location.companyOIB.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (location.companyName && location.companyName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (location.address && location.address.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (location.city && location.city.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (location.weeklyHours && location.weeklyHours.toString().includes(searchQuery))
  );

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        ) : locations.length === 0 ? (
          <WebEmptyState 
            title="Nemate još dodanih poslovnica"
            description="Dodajte svoju prvu poslovnicu kako biste mogli pratiti rad djelatnika"
            actionText="Dodaj poslovnicu"
            iconName="store-off-outline"
            navigationRoute="CreateLocation"
          />
        ) : (
          <>
            <View style={styles.searchContainer}>
              <View style={styles.searchBarContainer}>
                <View style={styles.searchBar}>
                  <MaterialCommunityIcons name="magnify" size={20} color="#6B7280" style={styles.searchIcon} />
                  <TextInput
                    placeholder="Pretraži poslovnice"
                    onChangeText={(query) => setSearchQuery(query)}
                    value={searchQuery}
                    style={[styles.searchInput, { outlineStyle: 'none' }]}
                    placeholderTextColor="#6B7280"
                    autoComplete="off"
                  />
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <Button 
                  mode="contained"
                  onPress={() => navigation.navigate('CreateLocation')}
                  style={[styles.addButton]}
                  contentStyle={styles.buttonContent}
                  labelStyle={{ color: '#FFFFFF' }}
                  buttonColor="#1F2937"
                  icon={({ size, color }) => (
                    <MaterialCommunityIcons name="domain-plus" size={20} color={color} />
                  )}
                >
                  Dodaj poslovnicu
                </Button>
              </View>
            </View>

            <View style={styles.tableContainer}>
              <DataTable style={styles.table}>
                <DataTable.Header style={styles.tableHeader}>
                  <DataTable.Title style={[styles.column, styles.nameColumn]}>Ime poslovnice</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.companyColumn]}>Tvrtka</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.oibColumn]}>OIB</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.addressColumn]}>Adresa</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.cityColumn]}>Grad</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.hoursColumn]}>Tjedni fond sati</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.actionsColumn]}>Akcije</DataTable.Title>
                </DataTable.Header>

                {filteredLocations.map((location) => (
                  <DataTable.Row 
                    key={location._id} 
                    style={styles.tableRow}
                    onPress={() => handleLocationPress(location._id)}
                  >
                    <DataTable.Cell style={[styles.column, styles.nameColumn]}><Text style={styles.locationText}>{location.name}</Text></DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.companyColumn]}><Text style={styles.locationText}>{location.companyName || '-'}</Text></DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.oibColumn]}><Text style={styles.locationText}>{location.companyOIB || '-'}</Text></DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.addressColumn]}><Text style={styles.locationText}>{location.address || '-'}</Text></DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.cityColumn]}><Text style={styles.locationText}>{location.city || '-'}</Text></DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.hoursColumn]}><Text style={styles.locationText}>{location.weeklyHours ? `${location.weeklyHours}h` : '-'}</Text></DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.actionsColumn]}>
                      <MaterialCommunityIcons
                        name="chevron-right"
                        size={24}
                        color="#666"
                      />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
              </DataTable>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 24,
  },
  content: {
    flex: 1,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
    gap: 24,
  },
  searchBarContainer: {
    flex: 1,
  },
  searchBar: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    paddingHorizontal: 12,
    height: 40,
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 14,
    color: '#1F2937',
    height: '100%',
    padding: 0,
    outline: 'none',
    border: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
  },
  buttonContainer: {
    width: 200,
  },
  addButton: {
    height: 40,
    borderRadius: 4,
  },
  buttonContent: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  },
  table: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  tableHeader: {
    backgroundColor: '#F1F5F9',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  tableRow: {
    minHeight: 48,
  },
  column: {
    justifyContent: 'flex-start',
  },
  nameColumn: {
    flex: 2,
  },
  companyColumn: {
    flex: 2,
  },
  oibColumn: {
    flex: 1.5,
  },
  addressColumn: {
    flex: 2,
  },
  cityColumn: {
    flex: 1.5,
  },
  hoursColumn: {
    flex: 1,
  },
  actionsColumn: {
    flex: 0.5,
  },
  locationText: {
    color: '#1F2937',
    fontSize: 14,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
