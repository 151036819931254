import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform, Alert, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Button, useTheme, Text, Dialog, Portal } from 'react-native-paper';
import { format, subDays, parseISO } from 'date-fns';
import { hr } from 'date-fns/locale';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { terminalEndpoints } from '../../../../../modules/terminal/api/terminalApi';
import { useToast } from '../../../../../context/ToastContext';
import { DateFilters } from './DateFilters';
import { WebTimePickerModal } from './WebTimePickerModal';
import { WebBreakTypeModal } from './WebBreakTypeModal';

interface Props {
  employeeId: string;
  locationId?: string;
  employee?: {
    name: string;
    surname: string;
  };
}

interface Session {
  id: string;
  employeeId: string;
  startTime: Date;
  endTime?: Date;
  status: 'ACTIVE' | 'ON_BREAK' | 'COMPLETED';
  breaks?: Break[];
  currentBreak?: Break;
  workBlocks: Array<{
    startTime: Date;
    endTime?: Date;
  }>;
  actionBy?: string;  // Tko je započeo sesiju
  endedBy?: string;   // Tko je završio sesiju
}

interface Break {
  id: string;
  sessionId: string;
  type: string;
  startTime: string;
  endTime?: string;
  maxDuration?: number;
  actionBy?: string;
}

export const WebAdminEmployeeTimesheetManager: React.FC<Props> = ({ employeeId, locationId, employee }) => {
  const theme = useTheme();
  const { showToast } = useToast();
  const [displayDate, setDisplayDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState(new Date());
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState<'checkIn' | 'checkOut' | 'endBreak' | 'editSessionStart' | 'editSessionEnd' | 'editBreakStart' | 'editBreakEnd' | null>(null);
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [selectedBreak, setSelectedBreak] = useState<Break | null>(null);
  const [timeModalDate, setTimeModalDate] = useState(new Date());
  const [breakType, setBreakType] = useState('Pauza');
  const [breakTypes, setBreakTypes] = useState<string[]>([
    'Pauza za ručak',
    'Kraća pauza',
    'Privatni izlazak'
  ]);
  const [isBreakTypeModalVisible, setBreakTypeModalVisible] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState<Session | null>(null);

  // Opcije za brzo filtriranje datuma
  const quickFilters = [
    { label: 'Danas', date: new Date() },
    { label: 'Jučer', date: subDays(new Date(), 1) },
    { label: 'Prekjučer', date: subDays(new Date(), 2) },
  ];

  // Dohvat podataka o sesijama za određeni datum
  const fetchSessions = async () => {
    try {
      setLoading(true);
      
      // Formatiraj datum za API zahtjev
      const formattedDate = format(displayDate, 'yyyy-MM-dd');
      
      // Poziv API-ja za dohvat sesija - koristimo getEmployeeSessions jer getEmployeeSessionsByDate ne postoji
      const response = await terminalEndpoints.getEmployeeSessions(
        employeeId, 
        locationId || '',
        formattedDate
      );
      
      if (response && response.sessions) {
        setSessions(response.sessions);
      } else {
        setSessions([]);
      }
    } catch (error) {
      console.error('Greška kod dohvata sesija:', error);
      showToast('Došlo je do greške prilikom dohvata podataka', undefined, 'error');
      setSessions([]);
    } finally {
      setLoading(false);
    }
  };

  // Učitaj podatke kad se promijeni datum
  useEffect(() => {
    fetchSessions();
  }, [displayDate, employeeId]);

  // Brzo filtriranje datuma
  const handleQuickFilter = (date: Date) => {
    setDisplayDate(date);
  };

  // Upravljanje datumom
  const handleDateChange = (selectedDate?: Date) => {
    if (selectedDate) {
      setTempDate(selectedDate);
    }
  };

  const handleDateConfirm = () => {
    // Postavi odabrani datum
    setDisplayDate(tempDate);
    setShowDatePicker(false);
  };

  const handleDateCancel = () => {
    setTempDate(displayDate);
    setShowDatePicker(false);
  };

  const handleAdminAction = (
    action: 'checkIn' | 'checkOut' | 'endBreak' | 'editSessionStart' | 'editSessionEnd' | 'editBreakStart' | 'editBreakEnd',
    session?: Session,
    breakItem?: Break
  ) => {
    // Provjeri ima li aktivnu smjenu za checkIn
    if (action === 'checkIn') {
      const activeSession = sessions.find(s => 
        s.status === 'ACTIVE' || s.status === 'ON_BREAK'
      );
      
      if (activeSession) {
        showToast('Zaposlenik već ima aktivnu smjenu. Prvo završite postojeću smjenu.', undefined, 'error');
        return;
      }
    }

    setSelectedAction(action);
    setSelectedSession(session || null);
    setSelectedBreak(breakItem || null);
    
    // Postavi inicijalno vrijeme ovisno o akciji
    let initialTime = new Date();
    
    // Ako je displayDate u budućnosti, koristi trenutno vrijeme
    if (displayDate > initialTime) {
      initialTime = new Date();
    } else {
      initialTime = new Date(displayDate);
    }

    // Postavi specifično vrijeme ovisno o akciji
    if (action === 'checkOut' && session?.endTime) {
      initialTime = new Date(session.endTime);
    } else if (action === 'endBreak' && breakItem) {
      if (breakItem.endTime) {
        initialTime = new Date(breakItem.endTime);
      } else {
        const startTime = new Date(breakItem.startTime);
        initialTime = new Date(Math.min(startTime.getTime() + 30 * 60000, Date.now())); // 30 minuta nakon početka ili trenutno vrijeme
      }
    } else if (action === 'editSessionStart' && session) {
      initialTime = new Date(session.startTime);
    } else if (action === 'editSessionEnd' && session?.endTime) {
      initialTime = new Date(session.endTime);
    } else if (action === 'editBreakStart' && breakItem) {
      initialTime = new Date(breakItem.startTime);
    } else if (action === 'editBreakEnd' && breakItem?.endTime) {
      initialTime = new Date(breakItem.endTime);
    }

    // Provjeri je li datum valjan
    if (isNaN(initialTime.getTime())) {
      console.error('Neispravan inicijalni datum:', initialTime);
      initialTime = new Date();
    }
    
    setTimeModalDate(initialTime);
    
    // Ako je akcija početak pauze, prvo prikaži modal za odabir tipa pauze
    if (action === 'startBreak') {
      setBreakTypeModalVisible(true);
    } else {
      setShowTimeModal(true);
    }
  };

  // Funkcija za potvrdu odabranog vremena
  const handleTimeConfirm = async () => {
    if (!selectedAction) return;

    // Provjera da vrijeme nije u budućnosti
    const now = new Date();
    if (timeModalDate > now) {
      showToast('Ne možete odabrati vrijeme u budućnosti.', undefined, 'error');
      return;
    }

    try {
      setLoadingAction(true);

      // Koristimo direktno toISOString() kao u mobilnoj aplikaciji
      const time = timeModalDate.toISOString();
      
      // Odaberi odgovarajuću API funkciju ovisno o akciji
      if (selectedAction === 'checkIn') {
        await terminalEndpoints.adminStartSession(employeeId, locationId || '', time);
      } 
      else if (selectedAction === 'checkOut' && selectedSession) {
        await terminalEndpoints.adminEndSession(selectedSession.id, time);
      } 
      else if (selectedAction === 'endBreak' && selectedSession && selectedBreak) {
        await terminalEndpoints.adminEndBreak(selectedSession.id, selectedBreak.id, time);
      } 
      else if (selectedAction === 'editSessionStart' && selectedSession) {
        await terminalEndpoints.adminEditSession(selectedSession.id, time);
      } 
      else if (selectedAction === 'editSessionEnd' && selectedSession) {
        await terminalEndpoints.adminEditSession(selectedSession.id, undefined, time);
      } 
      else if (selectedAction === 'editBreakStart' && selectedSession && selectedBreak) {
        await terminalEndpoints.adminEditBreak(selectedSession.id, selectedBreak.id, time);
      } 
      else if (selectedAction === 'editBreakEnd' && selectedSession && selectedBreak) {
        await terminalEndpoints.adminEditBreak(selectedSession.id, selectedBreak.id, undefined, time);
      }

      // Osvježi prikaz nakon uspješne akcije
      await fetchSessions();
      
      // Zatvori modal
      setShowTimeModal(false);
      setSelectedAction(null);
      setSelectedSession(null);
      setSelectedBreak(null);
      
      showToast('Uspješno izvršena akcija', undefined, 'success');
    } catch (error: any) {
      console.error('Greška kod izvršavanja admin akcije:', error);
      showToast('Došlo je do greške prilikom izvršavanja akcije. ' + 
        (error.response?.data?.message || error.message || ''), undefined, 'error');
    } finally {
      setLoadingAction(false);
    }
  };

  // Funkcija za brisanje sesije
  const handleDeleteSession = (session: Session) => {
    setSessionToDelete(session);
    setShowDeleteConfirmDialog(true);
  };

  // Funkcija za potvrdu brisanja
  const confirmDeleteSession = async () => {
    if (!sessionToDelete) return;
    
    try {
      setLoadingAction(true);
      await terminalEndpoints.adminDeleteSession(sessionToDelete.id);
      
      await fetchSessions();
      
      showToast('Work block je uspješno obrisan', undefined, 'success');
    } catch (error: any) {
      console.error('Greška kod brisanja sesije:', error);
      showToast('Došlo je do greške prilikom brisanja work blocka', undefined, 'error');
    } finally {
      setLoadingAction(false);
      setShowDeleteConfirmDialog(false);
      setSessionToDelete(null);
    }
  };

  // Funkcija za odustajanje od brisanja
  const cancelDeleteSession = () => {
    setShowDeleteConfirmDialog(false);
    setSessionToDelete(null);
  };

  return (
    <View style={styles.container}>
      {/* Naslov s imenom djelatnika */}
      {employee && (
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.employeeName}>
              {employee.name} {employee.surname}
            </Text>
          </View>
          <View style={styles.headerRight}>
            <Button
              mode="contained"
              icon={({ size, color }) => (
                <MaterialCommunityIcons name="login" size={20} color={color} />
              )}
              onPress={() => handleAdminAction('checkIn')}
              style={styles.checkInButton}
              contentStyle={styles.checkInButtonContent}
              labelStyle={styles.checkInButtonLabel}
              loading={loadingAction}
              disabled={loadingAction}
            >
              Ručna prijava
            </Button>
          </View>
        </View>
      )}

      {/* Komponenta za filtriranje datuma */}
      <DateFilters
        displayDate={displayDate}
        onDateChange={setDisplayDate}
        quickFilters={quickFilters}
        selectedFilter={selectedFilter}
        onQuickFilterSelect={(filter, date) => {
          setSelectedFilter(filter);
          handleQuickFilter(date);
        }}
        showDatePicker={showDatePicker}
        onToggleDatePicker={(show) => {
          if (show) {
            setTempDate(new Date(displayDate));
          }
          setShowDatePicker(show);
        }}
        tempDate={tempDate}
        onTempDateChange={setTempDate}
      />

      {/* Prikaz sesija */}
      {loading ? (
        <View style={styles.loadingContainer}>
          <Text>Učitavanje...</Text>
        </View>
      ) : sessions.length === 0 ? (
        <View style={styles.noSessionsContainer}>
          <Text style={styles.noSessionsText}>Nema radnih blokova za odabrani datum</Text>
        </View>
      ) : (
        <ScrollView style={styles.sessionsContainer}>
          <Text style={styles.sectionTitle}>Radni blokovi</Text>
          {Array.isArray(sessions) ? sessions.map((session) => (
            <View 
              key={session.id} 
              style={[
                styles.sessionCard,
                session.status === 'ON_BREAK' && styles.sessionCardOnBreak
              ]}
            >
              <View style={styles.sessionContent}>
                <View style={styles.sessionHeader}>
                  <View>
                    <Text style={styles.sessionTime}>
                      {format(new Date(session.startTime), 'HH:mm', { locale: hr })} - 
                      {session.endTime 
                        ? format(new Date(session.endTime), 'HH:mm', { locale: hr }) 
                        : 'U tijeku'}
                    </Text>
                    <View style={styles.sessionStatus}>
                      <View style={[styles.statusIndicator, session.status === 'ACTIVE' ? styles.statusActive : session.status === 'ON_BREAK' ? styles.statusOnBreak : styles.statusCompleted]} />
                      <Text style={{ fontSize: 14, color: '#6B7280', marginTop: 4, marginLeft: 8 }}>
                        Status: {session.status === 'ACTIVE' ? 'Aktivan' : 
                                session.status === 'ON_BREAK' ? 'Na pauzi' : 
                                session.status === 'COMPLETED' ? 'Završen' : session.status}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.sessionActions}>
                    {session.status === 'ACTIVE' && (
                      <TouchableOpacity
                        style={styles.actionButton}
                        onPress={() => handleAdminAction('checkOut', session)}
                      >
                        <MaterialIcons name="logout" size={18} style={styles.actionIconRed} />
                        <Text style={styles.actionButtonTextRed}>Odjavi</Text>
                      </TouchableOpacity>
                    )}
                    
                    <TouchableOpacity
                      style={styles.actionButton}
                      onPress={() => handleAdminAction('editSessionStart', session)}
                    >
                      <MaterialIcons name="edit" size={18} style={styles.actionIconBlue} />
                      <Text style={styles.actionButtonText}>Početak</Text>
                    </TouchableOpacity>
                    
                    {session.endTime && (
                      <TouchableOpacity
                        style={styles.actionButton}
                        onPress={() => handleAdminAction('editSessionEnd', session)}
                      >
                        <MaterialIcons name="edit" size={18} style={styles.actionIconBlue} />
                        <Text style={styles.actionButtonText}>Kraj</Text>
                      </TouchableOpacity>
                    )}

                    <TouchableOpacity
                      style={styles.actionButton}
                      onPress={() => handleDeleteSession(session)}
                    >
                      <MaterialIcons name="delete" size={18} style={styles.actionIconRed} />
                      <Text style={styles.actionButtonTextRed}>Obriši</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                
                {session.breaks && session.breaks.length > 0 && (
                  <View style={styles.breaksContainer}>
                    <View style={styles.divider} />
                    <Text style={styles.breaksTitle}>Pauze</Text>
                    {session.breaks.map((breakItem) => (
                      <View key={breakItem.id} style={styles.breakItem}>
                        <View>
                          <Text style={styles.breakType}>{breakItem.type}</Text>
                          <Text style={styles.breakTime}>
                            {format(new Date(breakItem.startTime), 'HH:mm', { locale: hr })} - 
                            {breakItem.endTime 
                              ? format(new Date(breakItem.endTime), 'HH:mm', { locale: hr }) 
                              : 'U tijeku'}
                          </Text>
                        </View>
                        
                        <View style={styles.breakActions}>
                          {!breakItem.endTime && (
                            <TouchableOpacity
                              style={styles.actionButton}
                              onPress={() => handleAdminAction('endBreak', session, breakItem)}
                            >
                              <MaterialIcons name="stop-circle" size={18} style={styles.actionIconRed} />
                              <Text style={styles.actionButtonTextRed}>Završi</Text>
                            </TouchableOpacity>
                          )}
                          
                          <TouchableOpacity
                            style={styles.actionButton}
                            onPress={() => handleAdminAction('editBreakStart', session, breakItem)}
                          >
                            <MaterialIcons name="edit" size={18} style={styles.actionIconBlue} />
                            <Text style={styles.actionButtonText}>Početak</Text>
                          </TouchableOpacity>
                          
                          {breakItem.endTime && (
                            <TouchableOpacity
                              style={styles.actionButton}
                              onPress={() => handleAdminAction('editBreakEnd', session, breakItem)}
                            >
                              <MaterialIcons name="edit" size={18} style={styles.actionIconBlue} />
                              <Text style={styles.actionButtonText}>Kraj</Text>
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    ))}
                  </View>
                )}
              </View>
            </View>
          )) : (
            <Text>Nema dostupnih podataka o radnim blokovima</Text>
          )}
        </ScrollView>
      )}
      
      {/* Modal za odabir vremena */}
      <WebTimePickerModal
        visible={showTimeModal}
        date={timeModalDate}
        onDateChange={(date) => setTimeModalDate(date)}
        onConfirm={handleTimeConfirm}
        onCancel={() => {
          setShowTimeModal(false);
          setSelectedAction(null);
          setSelectedSession(null);
          setSelectedBreak(null);
        }}
        loading={loadingAction}
        title={
          selectedAction === 'checkIn' ? 'Odaberi vrijeme prijave' :
          selectedAction === 'checkOut' ? 'Odaberi vrijeme odjave' :
          selectedAction === 'endBreak' ? 'Odaberi vrijeme završetka pauze' :
          selectedAction === 'editSessionStart' ? 'Uredi vrijeme početka smjene' :
          selectedAction === 'editSessionEnd' ? 'Uredi vrijeme završetka smjene' :
          selectedAction === 'editBreakStart' ? 'Uredi vrijeme početka pauze' :
          selectedAction === 'editBreakEnd' ? 'Uredi vrijeme završetka pauze' :
          'Odaberi vrijeme'
        }
      />
      
      {/* Modal za odabir tipa pauze */}
      <WebBreakTypeModal
        visible={isBreakTypeModalVisible}
        breakTypes={breakTypes}
        onSelect={(type) => {
          setBreakType(type);
          setBreakTypeModalVisible(false);
          // Ovdje bi trebalo dodati logiku za početak pauze
        }}
        onCancel={() => {
          setBreakTypeModalVisible(false);
        }}
        loading={loadingAction}
      />
      
      {/* Dialog za potvrdu brisanja */}
      <Portal>
        <Dialog
          visible={showDeleteConfirmDialog}
          onDismiss={cancelDeleteSession}
          style={{ maxWidth: 400, width: '100%', alignSelf: 'center' }}
        >
          <Dialog.Title>Brisanje work blocka</Dialog.Title>
          <Dialog.Content>
            <Text>Jeste li sigurni da želite obrisati ovaj work block? Ova akcija je nepovratna.</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={cancelDeleteSession}>Odustani</Button>
            <Button onPress={confirmDeleteSession} mode="contained" style={{ backgroundColor: '#ef4444' }}>Obriši</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#FFF'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  headerLeft: {
    flex: 1,
  },
  headerRight: {
    marginLeft: 16,
  },
  employeeName: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1F2937',
  },
  checkInButton: {
    marginVertical: 0,
    backgroundColor: '#10B981',
    borderRadius: 4,
  },
  checkInButtonContent: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkInButtonLabel: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: '500',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  noSessionsContainer: {
    marginTop: 20,
    padding: 16,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    alignItems: 'flex-start'
  },
  noSessionsText: {
    fontSize: 16,
    color: '#666',
  },
  sessionsContainer: {
    marginTop: 16,
    flex: 1
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 12
  },
  sessionCard: {
    marginBottom: 16,
    padding: 16,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E5E7EB',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  },
  sessionCardOnBreak: {
    borderColor: '#F59E0B',
    borderWidth: 2,
  },
  sessionContent: {
    padding: 16,
  },
  sessionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sessionTime: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1F2937',
  },
  sessionStatus: {
    fontSize: 14,
    color: '#6B7280',
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 8,
  },
  statusActive: {
    backgroundColor: '#10B981',  // zelena
  },
  statusOnBreak: {
    backgroundColor: '#F59E0B',  // narančasta
  },
  statusCompleted: {
    backgroundColor: '#6B7280',  // siva
  },
  sessionActions: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
    marginBottom: 4,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 4,
    backgroundColor: '#F3F4F6',
    pointerEvents: 'auto',
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  actionButtonText: {
    fontSize: 13,
    color: '#1F2937',
    marginLeft: 6,
    fontWeight: '500',
  },
  actionButtonTextRed: {
    fontSize: 13,
    color: '#DC2626',
    marginLeft: 6,
    fontWeight: '500',
  },
  actionIcon: {
    width: 18,
    height: 18,
  },
  actionIconBlue: {
    color: '#2563EB',
  },
  actionIconRed: {
    color: '#DC2626',
  },
  breaksContainer: {
    marginTop: 12
  },
  breaksTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: '#4B5563',
    marginBottom: 8,
  },
  breakItem: {
    marginVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  breakType: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  breakTime: {
    fontSize: 12,
    color: '#666',
    marginTop: 2
  },
  breakActions: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  divider: {
    height: 1,
    backgroundColor: '#E5E7EB',
    marginVertical: 12,
  },
});
