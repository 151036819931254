import React, { useEffect, useState, useCallback, useRef } from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity, ScrollView, Pressable } from 'react-native';
import { Text, useTheme, Button } from 'react-native-paper';
import Modal from 'react-native-modal';
import { format, subDays, isToday, isYesterday } from 'date-fns';
import { hr } from 'date-fns/locale';
import { MaterialIcons } from '@expo/vector-icons';
import { terminalEndpoints } from '../api/terminalApi';

interface EmployeeInfoModalProps {
  visible: boolean;
  onDismiss: () => void;
  employeeData: {
    name: string;
    isActive: boolean;
    checkInTime?: string;
    checkOutTime?: string;
    workBlocks?: {
      startTime: string;
      endTime?: string;
    }[];
    breaks: {
      type: string;
      startTime: string;
      endTime?: string;
    }[];
    isOnBreak: boolean;
    currentBreak?: {
      type: string;
      startTime: string;
    };
    date?: Date;
  } | null;
  employeeId?: string;
  locationId?: string;
  autoCloseTimeout?: number;
}

const { height } = Dimensions.get('window');

export const EmployeeInfoModal: React.FC<EmployeeInfoModalProps> = ({
  visible,
  onDismiss,
  employeeData: initialEmployeeData,
  employeeId,
  locationId,
  autoCloseTimeout = 10000 // 10 sekundi
}) => {
  const theme = useTheme();
  const [timeLeft, setTimeLeft] = useState(10);
  const [employeeData, setEmployeeData] = useState(initialEmployeeData);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<'today' | 'yesterday'>('today');
  const [error, setError] = useState<string | null>(null);
  
  // Referenca za zadnji dodir ekrana
  const lastTouchRef = useRef<number>(Date.now());
  // Referenca za vrijeme kada je modal otvoren
  const openTimeRef = useRef<number>(Date.now());
  // Referenca za praćenje je li komponenta još aktivna
  const isMountedRef = useRef<boolean>(true);
  // Referenca za praćenje je li početno učitavanje već izvršeno
  const initialLoadRef = useRef<boolean>(false);
  
  // Postavimo isMountedRef na false kada se komponenta unmountira
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
      initialLoadRef.current = false;
    };
  }, []);
  
  // Funkcija za resetiranje timera na dodir
  const handleTouch = () => {
    lastTouchRef.current = Date.now();
    setTimeLeft(10); // Eksplicitno resetiranje timera
  };
  
  // Efekt za inicijalizaciju kada se modal otvori
  useEffect(() => {
    if (visible) {
      openTimeRef.current = Date.now();
      lastTouchRef.current = Date.now();
      setTimeLeft(10);
      setError(null);
      initialLoadRef.current = false; // Resetiramo za sljedeće otvaranje modala
    }
  }, [visible]);

  // Efekt za odbrojavanje
  useEffect(() => {
    if (!visible) return;
    
    const timer = setInterval(() => {
      const now = Date.now();
      const timeSinceLastTouch = (now - lastTouchRef.current) / 1000;
      
      // Ako je prošlo manje od 1 sekunde od zadnjeg dodira, resetiraj timer
      if (timeSinceLastTouch < 1) {
        setTimeLeft(10);
        return;
      }
      
      // Izračunaj preostalo vrijeme
      const newTimeLeft = Math.max(0, 10 - Math.floor(timeSinceLastTouch));
      
      // Ažuriraj prikaz samo ako se vrijeme promijenilo
      if (newTimeLeft !== timeLeft) {
        setTimeLeft(newTimeLeft);
        
        // Ako je vrijeme isteklo, zatvori modal
        if (newTimeLeft === 0) {
          onDismiss();
        }
      }
    }, 200); // Provjera svakih 200ms za glatko ažuriranje
    
    return () => {
      clearInterval(timer);
    };
  }, [visible, timeLeft, onDismiss]);

  const loadEmployeeData = async (date: 'today' | 'yesterday') => {
    if (!employeeId || !locationId) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const dateParam = date === 'yesterday' ? format(subDays(new Date(), 1), 'yyyy-MM-dd') : undefined;
      
      const result = await terminalEndpoints.getEmployeeInfo(employeeId, locationId, dateParam);
      
      if (isMountedRef.current) {
        setEmployeeData(result.data);
      }
    } catch (error) {
      setError("Došlo je do greške prilikom dohvaćanja podataka.");
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  // Promjena datuma
  const handleDateChange = (date: 'today' | 'yesterday') => {
    setSelectedDate(date);
    setTimeLeft(10);
    lastTouchRef.current = Date.now();
    loadEmployeeData(date);
  };

  // Inicijalno učitaj podatke
  useEffect(() => {
    if (visible && employeeId && locationId) {
      // Postavi početno stanje i odmah učitaj podatke kad se modal otvori
      setEmployeeData(initialEmployeeData?.data || initialEmployeeData);
      setSelectedDate('today');
      
      // Učitaj podatke samo ako još nisu učitani
      if (!initialLoadRef.current) {
        initialLoadRef.current = true;
        loadEmployeeData('today');
      }
    }
  }, [visible, initialEmployeeData, employeeId, locationId]);

  // Pomoćna funkcija za formatiranje vremena
  const formatTime = (timeString: string) => {
    if (!timeString) return '-';
    const date = new Date(timeString);
    return format(date, 'HH:mm');
  };

  // Pomoćna funkcija za formatiranje datuma
  const formatDate = (date?: Date) => {
    if (!date) return '';
    
    if (isToday(date)) {
      return 'Danas';
    } else if (isYesterday(date)) {
      return 'Jučer';
    } else {
      return format(date, 'dd.MM.yyyy');
    }
  };

  // Pomoćna funkcija za izračun trajanja pauze
  function calculateDuration(startTime: string, endTime: string) {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffMs = end.getTime() - start.getTime();
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const seconds = diffSeconds % 60;
    
    if (diffMinutes < 60) {
      return `${diffMinutes} min ${seconds} sec`;
    } else {
      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;
      return `${hours}h ${minutes}min ${seconds}sec`;
    }
  }

  if (!employeeData) {
    return null;
  }

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={onDismiss}
      onBackButtonPress={onDismiss}
      swipeDirection={null}
      style={styles.modal}
      backdropOpacity={0.5}
      useNativeDriverForBackdrop
      animationIn="slideInUp"
      animationOut="slideOutDown"
      animationInTiming={300}
      animationOutTiming={300}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      hideModalContentWhileAnimating={true}
      propagateSwipe={true}
      onTouchStart={handleTouch}
    >
      <View style={styles.modalContainer}>
        {/* Header dio */}
        <View style={styles.headerContainer}>
          <View style={styles.handle} />
          
          <View style={styles.header}>
            <Text variant="titleLarge" style={styles.title}>
              {initialEmployeeData?.name || "Djelatnik"}
            </Text>
            <View style={styles.headerRight}>
              <Text style={styles.countdown}>
                {timeLeft}s
              </Text>
              <TouchableOpacity onPress={onDismiss} style={styles.closeButton}>
                <MaterialIcons name="close" size={24} color="#757575" />
              </TouchableOpacity>
            </View>
          </View>
          
          {/* Status ispod imena */}
          <View style={styles.headerStatus}>
            <View style={[
              styles.statusIndicator, 
              { backgroundColor: employeeData.isActive ? '#4CAF50' : '#F44336' }
            ]} />
            <Text style={[
              styles.headerStatusText,
              { color: employeeData.isActive ? '#4CAF50' : '#F44336' }
            ]}>
              {employeeData.isActive 
                ? (employeeData.isOnBreak 
                  ? `Trenutno ste na pauzi (${employeeData.currentBreak?.type})` 
                  : 'Prijavljeni ste na poslu') 
                : 'Odjavljeni ste, niste na poslu'}
            </Text>
          </View>
        </View>

        {/* Gumbi za odabir datuma */}
        <View style={styles.dateButtonsContainer}>
          <Pressable 
            style={[
              styles.dateButtonSmall, 
              selectedDate === 'today' && styles.dateButtonSmallActive
            ]}
            onPress={() => handleDateChange('today')}
            disabled={isLoading}
            onTouchStart={handleTouch}
          >
            <Text style={[
              styles.dateButtonText, 
              selectedDate === 'today' && styles.dateButtonTextActive
            ]}>
              Danas
            </Text>
          </Pressable>
          <Pressable 
            style={[
              styles.dateButtonSmall, 
              selectedDate === 'yesterday' && styles.dateButtonSmallActive
            ]}
            onPress={() => handleDateChange('yesterday')}
            disabled={isLoading}
            onTouchStart={handleTouch}
          >
            <Text style={[
              styles.dateButtonText, 
              selectedDate === 'yesterday' && styles.dateButtonTextActive
            ]}>
              Jučer
            </Text>
          </Pressable>
        </View>

        {/* Sadržaj koji reagira na touch */}
        <View 
          style={styles.touchableContent}
          onTouchStart={handleTouch}
          onResponderGrant={handleTouch}
        >
          <ScrollView 
            style={styles.scrollView} 
            contentContainerStyle={styles.scrollContent}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
            nestedScrollEnabled={true}
            onTouchStart={handleTouch}
            onScrollBeginDrag={handleTouch}
            onMomentumScrollBegin={handleTouch}
          >
            {isLoading ? (
              <View style={styles.loadingContainer}>
                <Text>Učitavanje...</Text>
              </View>
            ) : error ? (
              <View style={styles.errorContainer}>
                <Text style={styles.errorText}>{error}</Text>
                <Button 
                  mode="contained" 
                  onPress={() => loadEmployeeData(selectedDate)}
                  style={styles.retryButton}
                >
                  Pokušaj ponovno
                </Button>
              </View>
            ) : (
              <View style={styles.employeeInfo}>
                {/* Blokovi prijava/odjava */}
                {employeeData.workBlocks && employeeData.workBlocks.length > 0 ? (
                  <View style={styles.section}>
                    <Text variant="titleMedium" style={styles.sectionTitle}>
                      Radni blokovi - {formatDate(employeeData.date)}
                    </Text>
                    {employeeData.workBlocks.map((block, index) => (
                      <View key={`block-${index}`} style={[styles.blockItem, { width: '100%' }]}>
                        <View style={styles.blockRow}>
                          <MaterialIcons name="login" size={20} color={theme.colors.primary} />
                          <Text style={styles.blockLabel}>Prijava:</Text>
                          <Text style={styles.blockValue}>
                            {formatTime(block.startTime)}
                          </Text>
                        </View>

                        <View style={styles.blockRow}>
                          <MaterialIcons name="logout" size={20} color="#F44336" />
                          <Text style={styles.blockLabel}>Odjava:</Text>
                          <Text style={styles.blockValue}>
                            {block.endTime ? formatTime(block.endTime) : '-'}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                ) : (
                  <View style={styles.noDataContainer}>
                    <Text style={styles.noDataText}>Nema prijava za odabrani datum</Text>
                  </View>
                )}

                {/* Pauze */}
                {employeeData.breaks && employeeData.breaks.length > 0 ? (
                  <View style={styles.section}>
                    <Text variant="titleMedium" style={styles.sectionTitle}>
                      Pauze
                    </Text>
                    {employeeData.breaks.map((breakItem, index) => (
                      <View key={`break-${index}`} style={[styles.breakItem, { width: '100%' }]}>
                        <View style={styles.breakHeader}>
                          <Text style={styles.breakType}>{breakItem.type}</Text>
                          <Text style={styles.breakDuration}>
                            {breakItem.endTime 
                              ? calculateDuration(breakItem.startTime, breakItem.endTime)
                              : 'U tijeku'}
                          </Text>
                        </View>
                        <View style={styles.breakRow}>
                          <MaterialIcons name="schedule" size={16} color="#757575" />
                          <Text style={styles.breakTimeText}>
                            {formatTime(breakItem.startTime)} - {breakItem.endTime ? formatTime(breakItem.endTime) : 'U tijeku'}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                ) : null}
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end'
  },
  modalContainer: {
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    maxHeight: height * 0.9,
    flex: 1,
  },
  handle: {
    width: 40,
    height: 5,
    backgroundColor: '#e0e0e0',
    borderRadius: 3,
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  headerContainer: {
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#f0f0f0',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontWeight: 'bold',
    flex: 1,
  },
  countdown: {
    fontSize: 12,
    color: '#757575',
    backgroundColor: '#f5f5f5',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
  },
  closeButton: {
    padding: 8,
  },
  dateButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
    gap: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f0f0f0',
  },
  dateButtonSmall: {
    minWidth: 80,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 8,
    borderColor: '#ddd',
    borderWidth: 1,
  },
  dateButtonSmallActive: {
    backgroundColor: '#333',
    borderColor: '#333',
  },
  dateButtonText: {
    fontSize: 14,
    color: '#333',
    textAlign: 'center',
  },
  dateButtonTextActive: {
    color: '#fff',
  },
  selectedDateContainer: {
    alignItems: 'center',
    paddingBottom: 10,
    display: 'none', /* Sakrivanje jer je redundantno s gumbima */
  },
  selectedDateText: {
    fontSize: 14,
    color: '#555',
  },
  scrollView: {
    flex: 1,
  },
  scrollContent: {
    paddingHorizontal: 0,
    paddingBottom: 20,
  },
  content: {
    padding: 20,
  },
  employeeInfo: {
    padding: 10,
    paddingHorizontal: 15,
  },
  headerStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  headerStatusText: {
    fontWeight: '500',
    marginLeft: 8,
  },
  statusIndicator: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 8,
  },
  section: {
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: '#f0f0f0',
    paddingTop: 15,
    paddingHorizontal: 0,
  },
  sectionTitle: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 10,
    paddingHorizontal: 15,
  },
  sectionDate: {
    fontSize: 14,
    color: '#757575',
  },
  infoSection: {
    marginTop: 15,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  infoLabel: {
    marginLeft: 8,
    fontWeight: '500',
    width: 80,
  },
  infoValue: {
    flex: 1,
  },
  blockItem: {
    backgroundColor: '#f9f9f9',
    padding: 15,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginBottom: 15,
  },
  blockRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  blockLabel: {
    marginLeft: 8,
    fontWeight: '500',
    width: 60,
  },
  blockValue: {
    flex: 1,
  },
  breakItem: {
    backgroundColor: '#f9f9f9',
    padding: 15,
    paddingHorizontal: 15,
    borderRadius: 8,
    marginBottom: 15,
  },
  breakHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  breakType: {
    fontWeight: '600',
  },
  breakDuration: {
    color: '#757575',
  },
  breakRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  breakTimeText: {
    fontSize: 14,
    color: '#757575',
    marginLeft: 8,
    fontWeight: '500',
  },
  loadingContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDataContainer: {
    padding: 15,
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    marginTop: 20,
  },
  noDataText: {
    color: '#757575',
  },
  touchableContent: {
    flex: 1,
  },
  errorContainer: {
    padding: 20,
    alignItems: 'center',
    backgroundColor: '#fff0f0',
    borderRadius: 8,
    marginTop: 20,
  },
  errorText: {
    color: '#F44336',
    marginBottom: 15,
  },
  retryButton: {
    marginTop: 10,
  }
});