import React from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity, Platform, Modal, TouchableWithoutFeedback } from 'react-native';
import { Text, Button } from 'react-native-paper';
import { format } from 'date-fns';
import { hr } from 'date-fns/locale';
import DateTimePicker from '@react-native-community/datetimepicker';
import { MaterialIcons } from '@expo/vector-icons';

interface QuickFilter {
  label: string;
  date: Date;
}

interface DateFiltersProps {
  displayDate: Date;
  onDateChange: (date: Date) => void;
  quickFilters: QuickFilter[];
  selectedFilter: string | null;
  onFilterSelect: (filter: string, date: Date) => void;
  showDatePicker: boolean;
  onDatePickerShow: () => void;
  onDatePickerHide: () => void;
  tempDate: Date;
  onTempDateChange: (date: Date) => void;
  onDateConfirm: () => void;
  onDateCancel: () => void;
}

export const DateFilters: React.FC<DateFiltersProps> = ({
  displayDate,
  onDateChange,
  quickFilters,
  selectedFilter,
  onFilterSelect,
  showDatePicker,
  onDatePickerShow,
  onDatePickerHide,
  tempDate,
  onTempDateChange,
  onDateConfirm,
  onDateCancel
}) => {
  // Pomoćna funkcija za formatiranje datuma
  const formatDateObj = (date: Date) => {
    try {
      if (isNaN(date.getTime())) {
        return '--.--.----.';
      }
      return format(date, 'dd.MM.yyyy.', { locale: hr });
    } catch (error) {
      console.error('Greška kod formatiranja datuma:', error);
      return '--.--.----.';
    }
  };

  // Handler za promjenu datuma
  const handleDateChange = (event: any, selectedDate?: Date) => {
    if (Platform.OS === 'android') {
      onDatePickerHide();
    }
    
    if (selectedDate) {
      onTempDateChange(selectedDate);
      
      if (Platform.OS === 'android') {
        onDateChange(selectedDate);
        onDateConfirm();
      }
    }
  };

  return (
    <>
      {/* Header s prikazom odabranog datuma */}
      <TouchableOpacity
        style={styles.header}
        onPress={() => {
          onDatePickerShow();
        }}
        activeOpacity={0.7}
        testID="datePicker"
      >
        <View style={styles.headerContent}>
          <MaterialIcons name="calendar-today" size={18} color="#64748b" />
          <Text style={styles.headerTitle}>{formatDateObj(displayDate)}</Text>
        </View>
        <MaterialIcons name="expand-more" size={20} color="#64748b" />
      </TouchableOpacity>

      {/* Filteri za brzi odabir datuma */}
      <ScrollView 
        horizontal 
        showsHorizontalScrollIndicator={false}
        style={styles.filtersContainer}
        contentContainerStyle={styles.filtersContent}
      >
        {quickFilters.map((filter) => (
          <TouchableOpacity
            key={filter.label}
            style={[
              styles.filterChip,
              selectedFilter === filter.label && styles.activeFilterChip
            ]}
            onPress={() => onFilterSelect(filter.label, filter.date)}
          >
            <Text style={styles.filterButtonText}>{filter.label}</Text>
          </TouchableOpacity>
        ))}
      </ScrollView>

      {/* Modal za odabir datuma */}
      <Modal
        visible={showDatePicker}
        transparent={true}
        animationType="fade"
        onRequestClose={onDatePickerHide}
      >
        <TouchableWithoutFeedback onPress={onDateCancel}>
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Odaberi datum</Text>
              
              {Platform.OS === 'ios' ? (
                <DateTimePicker
                  testID="dateTimePicker"
                  value={tempDate}
                  mode="date"
                  display="spinner"
                  onChange={handleDateChange}
                  style={{ width: 320, height: 180 }}
                  locale="hr"
                />
              ) : (
                showDatePicker && (
                  <DateTimePicker
                    testID="dateTimePicker"
                    value={tempDate}
                    mode="date"
                    display="default"
                    onChange={handleDateChange}
                    locale="hr"
                  />
                )
              )}
              
              <View style={styles.buttonContainer}>
                <Button 
                  mode="outlined" 
                  onPress={onDateCancel}
                  style={{ marginRight: 10 }}
                >
                  Odustani
                </Button>
                
                {Platform.OS === 'ios' && (
                  <Button 
                    mode="contained" 
                    onPress={onDateConfirm}
                  >
                    Potvrdi
                  </Button>
                )}
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#f8fafc',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: '500',
    marginLeft: 8,
  },
  filtersContainer: {
    marginBottom: 16,
  },
  filtersContent: {
    paddingRight: 8,
  },
  filterChip: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    backgroundColor: '#f1f5f9',
    borderRadius: 16,
    marginRight: 8,
  },
  activeFilterChip: {
    backgroundColor: '#dbeafe',
  },
  filterButtonText: {
    fontSize: 13,
    color: '#334155',
    fontWeight: '500',
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  datePicker: {
    width: '100%',
  },
  iosButtons: {
    marginTop: 16,
    marginBottom: 8,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
});
