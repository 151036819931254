interface SocialMedia {
  platform: string;
  url: string;
}

interface CompanyInfo {
  name: string;
  legalName: string;
  address: string;
  city: string;
  country: string;
  email: string;
  phone: string;
}

interface SiteMetadata {
  title: string;
  titleTemplate: string;
  defaultTitle: string;
  baseUrl: string;
  company: CompanyInfo;
  social: SocialMedia[];
  logo: {
    url: string;
    width: number;
    height: number;
    alt: string;
  };
}

export const siteMetadata: SiteMetadata = {
  title: 'Clock.hr - Evidencija Radnog Vremena',
  titleTemplate: '%s | Clock.hr',
  defaultTitle: 'Clock.hr - Evidencija Radnog Vremena',
  baseUrl: 'https://clock.hr',
  company: {
    name: 'Clock.hr',
    legalName: 'Clock d.o.o.',
    address: 'Vaša Adresa 123',
    city: 'Zagreb',
    country: 'Hrvatska',
    email: 'info@clock.hr',
    phone: '+385 1 234 5678'
  },
  social: [
    {
      platform: 'Facebook',
      url: 'https://facebook.com/clock.hr'
    },
    {
      platform: 'LinkedIn',
      url: 'https://linkedin.com/company/clock-hr'
    },
    {
      platform: 'Instagram',
      url: 'https://instagram.com/clock.hr'
    }
  ],
  logo: {
    url: '/assets/images/logo.png',
    width: 200,
    height: 50,
    alt: 'Clock.hr Logo'
  }
};