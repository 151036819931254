import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Text, Divider, useTheme, ActivityIndicator, Button, IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { breaksAPI } from '../../../../services/api';
import { WebHeader } from '../../dashboard/WebHeader';

interface Break {
  id: string;
  name: string;
  duration: number;
  isPaid: boolean;
  isUnlimited: boolean;
  allowReuseAfterExpiry: boolean;
  restrictTimeEnabled: boolean;
  minWorkMinutes?: number;
  isTimeRestrictionEnabled: boolean;
  restrictedHours: number[];
  createdAt: string;
  updatedAt: string;
}

export const WebBreakDetailsScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const route = useRoute();
  const breakId = (route.params as any)?.breakId || '';
  
  const [breakDetails, setBreakDetails] = useState<Break | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadBreakDetails = async () => {
      try {
        const response = await breaksAPI.getBreakDetails(breakId);
        console.log('Break details response:', response.data);
        if (response.data && typeof response.data === 'object') {
          // Provjera strukture podataka i prilagodba ako je potrebno
          const breakData = {
            id: response.data.id || response.data._id,
            name: response.data.name,
            duration: response.data.duration,
            isPaid: response.data.isPaid,
            isUnlimited: response.data.isUnlimited,
            allowReuseAfterExpiry: response.data.allowReuseAfterExpiry,
            restrictTimeEnabled: response.data.restrictTimeEnabled,
            minWorkMinutes: response.data.minWorkMinutes,
            isTimeRestrictionEnabled: response.data.isTimeRestrictionEnabled,
            restrictedHours: response.data.restrictedHours || [],
            createdAt: response.data.createdAt,
            updatedAt: response.data.updatedAt
          };
          setBreakDetails(breakData);
        } else {
          setError('Neispravan format podataka');
        }
      } catch (err) {
        console.error('Greška pri dohvaćanju detalja pauze:', err);
        setError('Došlo je do greške pri dohvaćanju detalja pauze');
      } finally {
        setIsLoading(false);
      }
    };

    loadBreakDetails();
  }, [breakId]);
  
  const InfoRow = ({ icon, label, value }: { icon: string; label: string; value: string }) => (
    <View style={styles.infoRow}>
      <MaterialCommunityIcons name={icon} size={24} color={theme.colors.primary} style={styles.icon} />
      <View style={styles.infoContent}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  );

  if (isLoading) {
    return (
      <View style={styles.container}>
        <WebHeader />
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={theme.colors.primary} />
        </View>
      </View>
    );
  }

  if (error || !breakDetails) {
    return (
      <View style={styles.container}>
        <WebHeader />
        <View style={styles.errorContainer}>
          <Text style={{ color: theme.colors.error, fontSize: 16 }}>{error || 'Pauza nije pronađena'}</Text>
          <Button 
            mode="contained" 
            onPress={() => navigation.goBack()}
            style={{ marginTop: 16 }}
          >
            Natrag na pauze
          </Button>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <WebHeader />
      <View style={styles.content}>
        <View style={styles.headerContainer}>
          <View style={styles.titleContainer}>
          </View>
        </View>

        <View style={styles.detailsContent}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Osnovne informacije</Text>
            <View style={styles.detailsContainer}>
              <InfoRow 
                icon="coffee" 
                label="Naziv pauze"
                value={breakDetails.name || 'Nepoznato'}
              />
              <Divider style={styles.divider} />
              
              <InfoRow 
                icon="clock-outline" 
                label="Trajanje"
                value={`${breakDetails.duration || 0} minuta`}
              />
              <Divider style={styles.divider} />

              <InfoRow 
                icon="cash" 
                label="Plaćena"
                value={breakDetails.isPaid ? 'Da' : 'Ne'}
              />
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Ograničenja</Text>
            <View style={styles.detailsContainer}>
              <InfoRow 
                icon="infinity" 
                label="Korištenje"
                value={breakDetails.isUnlimited ? 'Neograničeno' : 'Jednokratno'}
              />
              <Divider style={styles.divider} />

              <InfoRow 
                icon="refresh" 
                label="Korištenje nakon isteka"
                value={breakDetails.allowReuseAfterExpiry ? 'Dozvoljeno' : 'Nije dozvoljeno'}
              />
              <Divider style={styles.divider} />

              <InfoRow 
                icon="clock-check" 
                label="Potrebno raditi prije korištenja pauze"
                value={breakDetails.restrictTimeEnabled ? 'Uključeno' : 'Isključeno'}
              />
              <Divider style={styles.divider} />

              {breakDetails.restrictTimeEnabled ? (
                <>
                  <InfoRow 
                    icon="clock-check-outline" 
                    label="Minimalno vrijeme rada"
                    value={`${breakDetails.minWorkMinutes} minuta`}
                  />
                  <Divider style={styles.divider} />
                </>
              ) : (
                <>
                  <InfoRow 
                    icon="clock-check-outline" 
                    label="Minimalno vrijeme rada"
                    value="Nije postavljeno"
                  />
                  <Divider style={styles.divider} />
                </>
              )}

              <InfoRow 
                icon="clock-alert" 
                label="Zabrana korištenja u određenim satima"
                value={breakDetails.isTimeRestrictionEnabled ? 'Uključeno' : 'Isključeno'}
              />
              <Divider style={styles.divider} />

              {breakDetails.isTimeRestrictionEnabled ? (
                <InfoRow 
                  icon="clock-alert-outline" 
                  label="Pauza se ne može koristiti u satima"
                  value={breakDetails.restrictedHours && breakDetails.restrictedHours.length > 0 
                    ? breakDetails.restrictedHours.sort((a, b) => a - b).join(', ')
                    : 'Nije odabrano'}
                />
              ) : (
                <InfoRow 
                  icon="clock-alert-outline" 
                  label="Pauza se ne može koristiti u satima"
                  value="Nije postavljeno"
                />
              )}
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Ostale informacije</Text>
            <View style={styles.detailsContainer}>
              <InfoRow 
                icon="calendar" 
                label="Kreirano"
                value={new Date(breakDetails.createdAt).toLocaleString('hr')}
              />
              <Divider style={styles.divider} />

              <InfoRow 
                icon="calendar-clock" 
                label="Zadnja izmjena"
                value={new Date(breakDetails.updatedAt).toLocaleString('hr')}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
    padding: 24,
    paddingBottom: 48,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 0,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  editButton: {
    padding: 8,
  },
  detailsContent: {
    paddingBottom: 24,
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#4A5568',
    marginBottom: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  detailsContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  icon: {
    marginRight: 16,
  },
  infoContent: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    color: '#718096',
    marginBottom: 4,
  },
  value: {
    fontSize: 16,
    color: '#2D3748',
    fontWeight: '500',
  },
  divider: {
    backgroundColor: '#E2E8F0',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
});
