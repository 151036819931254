export const formatAvailableHours = (hours: number[] = []): string => {
  if (!hours.length) return 'Dostupno 0-24h';
  if (hours.length === 24) return 'Dostupno 0-24h';
  if (hours.length === 0) return 'Pauza trenutno nije dostupna';

  // Group consecutive hours into ranges
  const ranges: { start: number; end: number; }[] = [];
  let currentRange: { start: number; end: number; } | null = null;

  // Sort hours before grouping
  const sortedHours = [...hours].sort((a, b) => a - b);

  sortedHours.forEach((hour) => {
    if (!currentRange) {
      currentRange = { start: hour, end: hour };
    } else if (hour === currentRange.end + 1) {
      currentRange.end = hour;
    } else {
      ranges.push(currentRange);
      currentRange = { start: hour, end: hour };
    }
  });

  if (currentRange) {
    ranges.push(currentRange);
  }

  const formatHour = (hour: number): string => 
    `${hour.toString().padStart(2, '0')}:00`;

  const rangeText = ranges
    .map(range => range.start === range.end 
      ? formatHour(range.start)
      : `${formatHour(range.start)} - ${formatHour(range.end + 1)}`
    )
    .join(', ');

  return `Dostupno: ${rangeText}`;
};

export const formatMinimumTime = (minutes?: number): string => {
  if (!minutes) return '';
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours > 0) {
    return `Minimalno ${hours}h${remainingMinutes > 0 ? ` ${remainingMinutes}min` : ''} rada`;
  }
  return `Minimalno ${remainingMinutes}min rada`;
};

export const formatRemainingTime = (minutes: number): string => {
  if (!minutes) return 'Preostalo: 0 min';
  
  // Pretvaramo minute u minute i sekunde za precizniji prikaz
  const wholeMinutes = Math.floor(minutes);
  const seconds = Math.round((minutes - wholeMinutes) * 60);
  
  if (seconds > 0) {
    return `Preostalo: ${wholeMinutes} min ${seconds} sek`;
  }
  return `Preostalo: ${wholeMinutes} min`;
};

export const formatRestrictedTime = (restrictedHours: number[] = []): string => {
  if (!restrictedHours.length) return 'Nema zabrana';
  
  // Sort hours before grouping
  const sortedHours = [...restrictedHours].sort((a, b) => a - b);
  
  // Group consecutive hours into ranges
  const ranges: { start: number; end: number; }[] = [];
  let currentRange: { start: number; end: number; } | null = null;

  sortedHours.forEach((hour) => {
    if (!currentRange) {
      currentRange = { start: hour, end: hour };
    } else if (hour === currentRange.end + 1) {
      currentRange.end = hour;
    } else {
      ranges.push(currentRange);
      currentRange = { start: hour, end: hour };
    }
  });

  if (currentRange) {
    ranges.push(currentRange);
  }

  const formatHour = (hour: number): string => 
    `${hour.toString().padStart(2, '0')}:00`;

  const rangeText = ranges
    .map(range => range.start === range.end 
      ? formatHour(range.start)
      : `${formatHour(range.start)} - ${formatHour(range.end + 1)}`
    )
    .join(', ');

  return `Zabranjeno: ${rangeText}`;
};
