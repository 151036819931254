import React, { useEffect, useState, useRef } from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { Text, useTheme, Appbar, Button, Divider } from 'react-native-paper';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation, useRoute } from '@react-navigation/native';
import { employeesAPI } from '../../../services/api';
import {
  hasAnyActiveDevices,
  shouldShowActivationBar,
  shouldShowNewDeviceInfo,
  shouldShowAddDeviceButton,
  shouldShowGenerateCodeButton
} from '../utils/locationConditions';
import {
  InfoRow,
  DeviceRow,
  ActivationBar,
  NewDeviceInfo
} from './locationComponents';

interface LocationDetails {
  _id: string;
  name: string;
  address: string;
  city: string;
  companyName: string;
  companyOIB: string;
  weeklyHours: number;
  active: boolean;
  activationCode: string;
  activationCodeExpiresAt: string;
  activationCodeUsed: boolean;
  deactivationPin: string | null;
  deactivationPinExpiresAt: string | null;
  deactivationPinUsed: boolean;
  activatedDevices: Array<{
    deviceId: string;
    activatedAt: string;
    lastUsed: string;
    active: boolean;
  }>;
}

interface LocationDetailsProps {
  route?: {
    params: {
      locationId: string;
      showActivationInfo?: boolean;
    };
  };
}

export const LocationDetailsScreen: React.FC<LocationDetailsProps> = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const route = useRoute();
  const { locationId, showActivationInfo, location: updatedLocation } = route.params || {};
  const scrollViewRef = useRef<ScrollView>(null);
  
  const [location, setLocation] = useState<LocationDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  const [deactivationPin, setDeactivationPin] = useState<string | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [showActivationInfoState, setShowActivationInfoState] = useState(false);

  const calculateTimeLeft = (expiryDate: string) => {
    const now = new Date().getTime();
    const expiry = new Date(expiryDate).getTime();
    const difference = expiry - now;
    
    if (difference <= 0) {
      setIsCodeExpired(true);
      return '00:00';
    }
    
    setIsCodeExpired(false);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const fetchLocationDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const locationData = await employeesAPI.getLocationDetails(locationId);
      setLocation(locationData);
    } catch (error) {
      console.error('Greška pri dohvaćanju detalja lokacije:', error);
      setError('Došlo je do greške pri dohvaćanju detalja lokacije');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNewCode = async () => {
    try {
      const response = await employeesAPI.generateActivationCode(locationId);
      setLocation(prev => prev ? {
        ...prev,
        activationCode: response.activationCode,
        activationCodeExpiresAt: response.activationCodeExpiresAt,
        activationCodeUsed: false
      } : null);
      setShowActivationInfoState(true);
      setError(null);
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
    } catch (error) {
      console.error('Greška pri generiranju koda:', error);
      setError('Greška pri generiranju aktivacijskog koda');
    }
  };

  const handleGenerateDeactivationPin = async (deviceId: string) => {
    try {
      const response = await employeesAPI.generateDeactivationPin(locationId, deviceId);
      setDeactivationPin(response.data.deactivationPin);
      setSelectedDeviceId(deviceId);
    } catch (error) {
      setError('Greška pri generiranju deaktivacijskog PIN-a');
    }
  };

  useEffect(() => {
    if (updatedLocation) {
      setLocation(updatedLocation);
    } else {
      fetchLocationDetails();
    }
  }, [locationId, updatedLocation]);

  useEffect(() => {
    if (showActivationInfo) {
      setShowActivationInfoState(true);
    }
  }, [showActivationInfo]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (location?.activationCode && !location.activationCodeUsed) {
      const updateTimer = () => {
        const timeLeft = calculateTimeLeft(location.activationCodeExpiresAt);
        setTimeLeft(timeLeft);
        
        if (timeLeft === '00:00') {
          setIsCodeExpired(true);
        }
      };
      
      updateTimer();
      timer = setInterval(updateTimer, 1000);
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [location?.activationCode, location?.activationCodeExpiresAt]);

  if (loading) {
    return (
      <SafeAreaView style={styles.safeArea}>
        <Appbar.Header style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="chevron-back" size={24} color="#007AFF" />
          </TouchableOpacity>
          <Appbar.Content title="Detalji poslovnice" titleStyle={styles.headerTitle} />
        </Appbar.Header>
        <View style={styles.loadingContainer}>
          <Text>Učitavanje...</Text>
        </View>
      </SafeAreaView>
    );
  }

  if (error || !location) {
    return (
      <SafeAreaView style={styles.safeArea}>
        <Appbar.Header style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="chevron-back" size={24} color="#007AFF" />
          </TouchableOpacity>
          <Appbar.Content title="Detalji poslovnice" titleStyle={styles.headerTitle} />
        </Appbar.Header>
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error || 'Poslovnica nije pronađena'}</Text>
          <Button mode="contained" onPress={fetchLocationDetails}>
            Pokušaj ponovno
          </Button>
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea} edges={['left', 'right']}>
      <Appbar.Header>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
        </TouchableOpacity>
        <Appbar.Content title={location?.name || 'Detalji poslovnice'} />
        <TouchableOpacity onPress={() => navigation.navigate('EditLocation', { locationId })} style={styles.editButton}>
          <Ionicons name="create-outline" size={24} color="#007AFF" />
        </TouchableOpacity>
      </Appbar.Header>

      {shouldShowActivationBar(location) && (
        <ActivationBar 
          location={location}
          timeLeft={timeLeft}
          isCodeExpired={isCodeExpired}
          onGenerateCode={handleGenerateNewCode}
          loading={loading}
        />
      )}

      {shouldShowNewDeviceInfo(location, showActivationInfoState, isCodeExpired) && location && (
        <NewDeviceInfo 
          location={location}
          timeLeft={timeLeft}
          isCodeExpired={isCodeExpired}
          onClose={() => setShowActivationInfoState(false)}
        />
      )}

      <ScrollView 
        ref={scrollViewRef}
        style={styles.container}
      >
        {error && (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{error}</Text>
          </View>
        )}

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Informacije o poslovnici</Text>
          <View style={styles.detailsContainer}>
            <InfoRow 
              icon="office-building" 
              label="Naziv poslovnice" 
              value={location.name} 
            />
            <Divider style={styles.divider} />
            <InfoRow 
              icon="map-marker" 
              label="Adresa" 
              value={location.address} 
            />
            <Divider style={styles.divider} />
            <InfoRow 
              icon="city" 
              label="Grad" 
              value={location.city} 
            />
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Informacije o tvrtki</Text>
          <View style={styles.detailsContainer}>
            <InfoRow 
              icon="domain" 
              label="Naziv tvrtke" 
              value={location.companyName} 
            />
            <Divider style={styles.divider} />
            <InfoRow 
              icon="identifier" 
              label="OIB" 
              value={location.companyOIB} 
            />
            <Divider style={styles.divider} />
            <InfoRow 
              icon="clock-outline" 
              label="Tjedno radno vrijeme" 
              value={`${location.weeklyHours}h`} 
            />
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Aktivirani uređaji</Text>
          <View style={styles.detailsContainer}>
            {shouldShowAddDeviceButton(location) && (
              <>
                <TouchableOpacity 
                  style={styles.addDeviceButton}
                  onPress={handleGenerateNewCode}
                >
                  <MaterialCommunityIcons 
                    name="plus-circle" 
                    size={24} 
                    color={theme.colors.primary} 
                  />
                  <Text style={styles.addDeviceText}>Dodaj novi uređaj</Text>
                </TouchableOpacity>
                <Divider style={styles.divider} />
              </>
            )}
            {location.activatedDevices.length === 0 ? (
              <View style={styles.noDevicesContainer}>
                <Text style={styles.noDevicesText}>Nema aktiviranih uređaja</Text>
              </View>
            ) : (
              <>
                {location.activatedDevices.map((device, index) => (
                  <React.Fragment key={`device-${device.deviceId}-${index}`}>
                    {index > 0 && <Divider style={styles.divider} />}
                    <DeviceRow 
                      device={device}
                      onDeactivate={handleGenerateDeactivationPin}
                      deactivationPin={deactivationPin}
                      selectedDeviceId={selectedDeviceId}
                    />
                  </React.Fragment>
                ))}
              </>
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    elevation: 0,
    backgroundColor: '#fff',
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: '600',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  section: {
    marginVertical: 12,
    marginRight: 16,
    marginLeft: 8,
  },
  sectionTitle: {
    fontSize: 13,
    color: '#666',
    marginBottom: 8,
    letterSpacing: 0.1,
    marginLeft: 4,
  },
  detailsContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 16,
    paddingLeft: 8,
    elevation: 1,
  },
  divider: {
    marginVertical: 4,
  },
  noDevicesContainer: {
    paddingVertical: 16,
    alignItems: 'center',
  },
  noDevicesText: {
    fontSize: 14,
    color: '#666',
  },
  backButton: {
    marginLeft: 8,
    padding: 8,
  },
  editButton: {
    marginRight: 8,
    padding: 8,
  },
  addDeviceButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  addDeviceText: {
    marginLeft: 12,
    fontSize: 16,
    color: '#000',
    fontFamily: 'System',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  errorText: {
    color: '#d32f2f',
    textAlign: 'center',
    marginBottom: 16,
    fontSize: 16,
  },
});
