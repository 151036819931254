import React from 'react';
import { View, StyleSheet, FlatList, Dimensions } from 'react-native';
import { Text, List, useTheme } from 'react-native-paper';
import Modal from 'react-native-modal';
import { Location } from '../../../types/location';

interface LocationPickerModalProps {
  visible: boolean;
  locations: Location[];
  onDismiss: () => void;
  onSelect: (location: Location) => void;
}

export const LocationPickerModal = ({ visible, locations, onDismiss, onSelect }: LocationPickerModalProps) => {
  const theme = useTheme();

  return (
    <Modal
      isVisible={visible}
      onSwipeComplete={onDismiss}
      onBackdropPress={onDismiss}
      onBackButtonPress={onDismiss}
      swipeDirection={['down']}
      style={styles.modal}
      backdropOpacity={0.5}
      useNativeDriverForBackdrop
      animationIn="slideInUp"
      animationOut="slideOutDown"
      animationInTiming={300}
      animationOutTiming={300}
      backdropTransitionInTiming={1}
      backdropTransitionOutTiming={1}
      hideModalContentWhileAnimating={true}
    >
      <View style={styles.modalContainer}>
        <View style={styles.handle} />
        
        <View style={styles.content}>
          <View style={styles.header}>
            <Text variant="titleLarge" style={styles.title}>Odaberi poslovnicu</Text>
          </View>

          <FlatList
            data={locations}
            keyExtractor={(item) => item._id}
            renderItem={({ item }) => (
              <List.Item
                title={item.name}
                description={item.address}
                onPress={() => {
                  onSelect(item);
                  onDismiss();
                }}
                left={props => <List.Icon {...props} icon="office-building" />}
                style={styles.listItem}
              />
            )}
            style={styles.list}
            keyboardShouldPersistTaps="never"
            contentContainerStyle={styles.listContent}
          />
        </View>
      </View>
    </Modal>
  );
};

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end'
  },
  modalContainer: {
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: height * 0.8,
    paddingTop: 8
  },
  handle: {
    width: 40,
    height: 4,
    backgroundColor: '#DDDDDD',
    borderRadius: 2,
    alignSelf: 'center',
    marginBottom: 8
  },
  content: {
    flex: 1
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  title: {
    textAlign: 'center'
  },
  list: {
    flex: 1
  },
  listContent: {
    paddingTop: 8
  },
  listItem: {
    paddingVertical: 8
  }
});
