import { useQuery } from '@tanstack/react-query';
import { dashboardAPI } from '../services/api';
import { DashboardTotalStats } from '../types/dashboard';

export const useDashboardStats = () => {
  return useQuery<DashboardTotalStats>({
    queryKey: ['dashboardStats'],
    queryFn: () => dashboardAPI.getTotalStats(),
  });
};
