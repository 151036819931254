import React, { useState, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { Text, Divider, useTheme, Appbar } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { employeesAPI } from '../../../services/api';
import { EmployeeStats } from './EmployeeStats';
import { AdminEmployeeTimesheetManager } from './AdminEmployeeTimesheetManager';

interface EmployeeDetailsProps {
  route?: {
    params: {
      employeeId: string;
    };
  };
}

interface Employee {
  _id: string;
  name: string;
  surname: string;
  pin: string;
  dateOfBirth?: string;
  location?: {
    _id: string;
    name: string;
  };
  locationId?: {
    _id: string;
    name: string;
  };
  position?: string;
  active: boolean;
  isActive?: boolean;
  phone?: string;
  oib?: string;
  email?: string;
  hourlyRate?: number;
}

export const EmployeeDetailsScreen: React.FC<EmployeeDetailsProps> = ({ route }) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [selectedTab, setSelectedTab] = useState<'info' | 'stats' | 'timesheet'>('info');
  const [loading, setLoading] = useState(true);

  const fetchEmployee = async () => {
    try {
      setLoading(true);
      const response = await employeesAPI.getEmployeeDetails(route?.params?.employeeId);
      setEmployee(response);
    } catch (error) {
      console.error('Error fetching employee:', error);
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchEmployee();
    }, [route?.params?.employeeId])
  );

  if (loading || !employee) {
    return (
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.loadingContainer}>
          <Text>Učitavanje...</Text>
        </View>
      </SafeAreaView>
    );
  }

  const formatDate = (isoDate: string) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  };

  const InfoRow = ({ icon, label, value }: { icon: string; label: string; value: string }) => (
    <View style={styles.infoRow}>
      <View style={styles.iconContainer}>
        <MaterialCommunityIcons name={icon} size={16} color="#2563eb" />
      </View>
      <View style={styles.infoContent}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  );

  const renderEmployeeInfo = () => (
    <ScrollView style={styles.contentContainer}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Osnovne informacije</Text>
        <InfoRow 
          icon="account" 
          label="Ime i prezime" 
          value={`${employee.name} ${employee.surname}`} 
        />
        <InfoRow 
          icon="office-building" 
          label="Poslovnica" 
          value={employee.locationId?.name || employee.location?.name || 'Nije dodijeljeno'} 
        />
        {employee.dateOfBirth && (
          <InfoRow 
            icon="calendar" 
            label="Datum rođenja" 
            value={formatDate(employee.dateOfBirth)} 
          />
        )}
        {employee.email && (
          <InfoRow 
            icon="email" 
            label="Email" 
            value={employee.email} 
          />
        )}
        {employee.oib && (
          <InfoRow 
            icon="card-account-details" 
            label="OIB" 
            value={employee.oib} 
          />
        )}
        {employee.position && (
          <InfoRow 
            icon="badge-account" 
            label="Pozicija" 
            value={employee.position} 
          />
        )}
        {employee.phone && (
          <InfoRow 
            icon="phone" 
            label="Broj mobitela" 
            value={employee.phone} 
          />
        )}
        {employee.hourlyRate !== undefined && (
          <InfoRow 
            icon="cash" 
            label="Satnica" 
            value={`${employee.hourlyRate.toFixed(2)} €`} 
          />
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Pristupni podaci</Text>
        <InfoRow 
          icon="key-variant" 
          label="PIN" 
          value={employee.pin} 
        />
        <InfoRow 
          icon="account-check" 
          label="Status" 
          value={employee.isActive ? 'Aktivan' : 'Neaktivan'} 
        />
      </View>
    </ScrollView>
  );

  return (
    <SafeAreaView style={styles.safeArea} edges={['left', 'right']}>
      <Appbar.Header style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
        </TouchableOpacity>
        <Appbar.Content title="Detalji djelatnika" titleStyle={styles.headerTitle} />
        <TouchableOpacity onPress={() => navigation.navigate('EditEmployee', { employeeId: employee._id })} style={styles.editButton}>
          <Ionicons name="create-outline" size={24} color="#007AFF" />
        </TouchableOpacity>
      </Appbar.Header>

      <View style={styles.mainContainer}>
        <View style={styles.tabContainer}>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'info' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('info')}
          >
            <Text style={[
              styles.tabText,
              selectedTab === 'info' && styles.activeTabText
            ]}>
              Informacije
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'stats' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('stats')}
          >
            <Text style={[
              styles.tabText,
              selectedTab === 'stats' && styles.activeTabText
            ]}>
              Statistika
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.tab,
              selectedTab === 'timesheet' && styles.activeTab
            ]}
            onPress={() => setSelectedTab('timesheet')}
          >
            <Text style={[
              styles.tabText,
              selectedTab === 'timesheet' && styles.activeTabText
            ]}>
              Administracija
            </Text>
          </TouchableOpacity>
        </View>

        <View style={styles.contentContainer}>
          {selectedTab === 'info' ? renderEmployeeInfo() : 
           selectedTab === 'stats' ? <EmployeeStats employeeId={route?.params?.employeeId} /> :
           <AdminEmployeeTimesheetManager 
             employeeId={route?.params?.employeeId} 
             locationId={employee?.locationId?._id || employee?.location?._id}
           />
          }
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    elevation: 0,
    backgroundColor: '#fff',
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: '600',
  },
  mainContainer: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    paddingTop: 8,
  },
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    paddingTop: 16,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#2563eb',
    marginBottom: 8,
    paddingLeft: 16,
  },
  detailsContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 16,
    paddingLeft: 8,
    elevation: 1,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 8,
    backgroundColor: '#ffffff',
  },
  iconContainer: {
    width: 32,
    height: 32,
    borderRadius: 6,
    backgroundColor: '#f1f5f9',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  infoContent: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    color: '#64748b',
    marginBottom: 2,
  },
  value: {
    fontSize: 15,
    color: '#334155',
    fontWeight: '500',
  },
  divider: {
    marginVertical: 4,
  },
  backButton: {
    marginLeft: 8,
    padding: 8,
  },
  editButton: {
    marginRight: 8,
    padding: 8,
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    paddingLeft: 16,
    paddingRight: 8,
    paddingTop: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0',
  },
  tab: {
    paddingLeft: 4,
    paddingRight: 32,
    paddingVertical: 12,
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#2563eb',
  },
  tabText: {
    fontSize: 15,
    color: '#64748b',
  },
  activeTabText: {
    color: '#2563eb',
    fontWeight: '600',
  },
  activeIndicator: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 3,
    backgroundColor: '#2563eb',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  },
});
