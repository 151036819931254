import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Text, Card, Divider, ActivityIndicator } from 'react-native-paper';
import { format } from 'date-fns';
import { MaterialIcons } from '@expo/vector-icons';

interface Break {
  id: string;
  sessionId: string;
  type: string;
  startTime: string;
  endTime?: string;
  maxDuration?: number;
  actionBy?: string;
  overMinutes?: number;
}

interface Session {
  id: string;
  employeeId: string;
  startTime: string;
  endTime?: string;
  status: 'ACTIVE' | 'ON_BREAK' | 'COMPLETED';
  breaks?: Break[];
  currentBreak?: Break;
  actionBy?: string;  // Tko je započeo sesiju
  endedBy?: string;   // Tko je završio sesiju
}

interface SessionsListProps {
  sessions: Session[];
  loading: boolean;
  onCheckOut: (session: Session) => void;
  onEndBreak: (session: Session, breakItem: Break) => void;
  onEditSessionStart: (session: Session) => void;
  onEditSessionEnd: (session: Session) => void;
  onEditBreakStart: (session: Session, breakItem: Break) => void;
  onEditBreakEnd: (session: Session, breakItem: Break) => void;
  onDeleteSession: (session: Session) => void;
}

export const SessionsList: React.FC<SessionsListProps> = ({
  sessions,
  loading,
  onCheckOut,
  onEndBreak,
  onEditSessionStart,
  onEditSessionEnd,
  onEditBreakStart,
  onEditBreakEnd,
  onDeleteSession
}) => {
  // Pomoćna funkcija za sigurno formatiranje datuma
  const formatTime = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return '--:--';
      }
      return format(date, 'HH:mm');
    } catch (error) {
      console.error('Greška kod formatiranja datuma:', error);
      return '--:--';
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#2563eb" />
        <Text style={styles.loadingText}>Učitavanje...</Text>
      </View>
    );
  }

  if (sessions.length === 0) {
    return (
      <View style={styles.emptyContainer}>
        <MaterialIcons name="event-busy" size={48} color="#94a3b8" />
        <Text style={styles.emptyText}>Nema evidencije za odabrani datum</Text>
      </View>
    );
  }

  return (
    <>
      {sessions.map((session) => (
        <Card key={session.id} style={styles.sessionCard}>
          <Card.Content>
            <View style={styles.sessionHeader}>
              <View>
                <Text style={styles.sessionTime}>
                  {formatTime(session.startTime)} - {session.endTime ? formatTime(session.endTime) : 'Aktivan'}
                </Text>
                <Text style={styles.sessionStatus}>
                  {session.status === 'ACTIVE' && 'Prijavljen'}
                  {session.status === 'ON_BREAK' && 'Na pauzi'}
                  {session.status === 'COMPLETED' && 'Završeno'}
                </Text>
              </View>
              
              <View style={styles.sessionActions}>
                {session.status === 'ACTIVE' && !session.endTime && (
                  <>
                    <TouchableOpacity
                      style={styles.actionButton}
                      onPress={() => onCheckOut(session)}
                    >
                      <MaterialIcons name="logout" size={20} color="#ef4444" />
                      <Text style={styles.actionButtonTextRed}>Odjavi</Text>
                    </TouchableOpacity>
                  </>
                )}
                
                <TouchableOpacity
                  style={styles.actionButton}
                  onPress={() => onEditSessionStart(session)}
                >
                  <MaterialIcons name="edit" size={20} color="#2563eb" />
                  <Text style={styles.actionButtonText}>Početak</Text>
                </TouchableOpacity>
                
                {session.endTime && (
                  <TouchableOpacity
                    style={styles.actionButton}
                    onPress={() => onEditSessionEnd(session)}
                  >
                    <MaterialIcons name="edit" size={20} color="#2563eb" />
                    <Text style={styles.actionButtonText}>Kraj</Text>
                  </TouchableOpacity>
                )}

                <TouchableOpacity
                  style={styles.actionButton}
                  onPress={() => onDeleteSession(session)}
                >
                  <MaterialIcons name="delete" size={20} color="#ef4444" />
                  <Text style={styles.actionButtonTextRed}>Obriši</Text>
                </TouchableOpacity>
              </View>
            </View>
            
            {session.breaks && session.breaks.length > 0 && (
              <>
                <Divider style={styles.divider} />
                <Text style={styles.breaksTitle}>Pauze:</Text>
                
                {session.breaks.map((breakItem) => (
                  <View key={breakItem.id} style={styles.breakItem}>
                    <View>
                      <Text style={styles.breakType}>{breakItem.type}</Text>
                      <Text style={styles.breakTime}>
                        {formatTime(breakItem.startTime)} - {breakItem.endTime ? formatTime(breakItem.endTime) : 'Aktivna'}
                      </Text>
                    </View>
                    
                    <View style={styles.breakActions}>
                      {!breakItem.endTime && (
                        <TouchableOpacity
                          style={styles.actionButton}
                          onPress={() => onEndBreak(session, breakItem)}
                        >
                          <MaterialIcons name="stop-circle" size={20} color="#ef4444" />
                          <Text style={styles.actionButtonTextRed}>Završi</Text>
                        </TouchableOpacity>
                      )}
                      
                      <TouchableOpacity
                        style={styles.actionButton}
                        onPress={() => onEditBreakStart(session, breakItem)}
                      >
                        <MaterialIcons name="edit" size={20} color="#2563eb" />
                        <Text style={styles.actionButtonText}>Početak</Text>
                      </TouchableOpacity>
                      
                      {breakItem.endTime && (
                        <TouchableOpacity
                          style={styles.actionButton}
                          onPress={() => onEditBreakEnd(session, breakItem)}
                        >
                          <MaterialIcons name="edit" size={20} color="#2563eb" />
                          <Text style={styles.actionButtonText}>Kraj</Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                ))}
              </>
            )}
          </Card.Content>
        </Card>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    marginTop: 32,
    alignItems: 'center',
  },
  loadingText: {
    marginTop: 8,
    color: '#64748b',
  },
  emptyContainer: {
    marginTop: 32,
    alignItems: 'center',
  },
  emptyText: {
    marginTop: 8,
    color: '#64748b',
    fontSize: 16,
  },
  sessionCard: {
    marginBottom: 16,
    borderRadius: 8,
    elevation: 2,
  },
  sessionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  sessionTime: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1e293b',
  },
  sessionStatus: {
    fontSize: 14,
    color: '#64748b',
    marginTop: 4,
  },
  sessionActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    maxWidth: '60%',
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    marginBottom: 8,
  },
  actionButtonText: {
    color: '#2563eb',
    marginLeft: 4,
    fontSize: 12,
  },
  actionButtonTextRed: {
    color: '#ef4444',
    marginLeft: 4,
    fontSize: 12,
  },
  divider: {
    marginVertical: 12,
  },
  breaksTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1e293b',
    marginBottom: 8,
  },
  breakItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 8,
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor: '#f8fafc',
    borderRadius: 4,
  },
  breakType: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1e293b',
  },
  breakTime: {
    fontSize: 13,
    color: '#64748b',
    marginTop: 2,
  },
  breakActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    maxWidth: '60%',
  },
});
