import React from 'react';
import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export const WebFooter = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const currentYear = new Date().getFullYear();
  const navigation = useNavigation();

  const handleNavigation = (route: string) => {
    navigation.navigate(route as never);
  };

  return (
    <View style={styles.footer}>
      <View style={[styles.footerContent, isMobile && styles.footerContentMobile]}>
        <Text style={styles.copyright}>{currentYear} Clock.hr. Sva prava pridržana.</Text>
        <View style={[styles.links, isMobile && styles.linksMobile]}>
          <TouchableOpacity onPress={() => handleNavigation('Guide')}>
            <Text style={styles.link}>Početak rada</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>·</Text>
          <TouchableOpacity onPress={() => handleNavigation('LegalDocuments')}>
            <Text style={styles.link}>Pravni dokumenti</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>·</Text>
          <TouchableOpacity onPress={() => handleNavigation('Contact')}>
            <Text style={styles.link}>Kontakt</Text>
          </TouchableOpacity>
          <Text style={styles.separator}>·</Text>
          <TouchableOpacity onPress={() => handleNavigation('FAQ')}>
            <Text style={styles.link}>Česta pitanja</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: '#E2E8F0',
    paddingVertical: 24,
  },
  footerContent: {
    maxWidth: 1200,
    marginHorizontal: 'auto',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  footerContentMobile: {
    flexDirection: 'column',
    gap: 16,
    paddingHorizontal: 16,
  },
  copyright: {
    color: '#64748B',
    fontSize: 14,
  },
  links: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  linksMobile: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  link: {
    color: '#64748B',
    fontSize: 14,
  },
  separator: {
    color: '#CBD5E1',
    fontSize: 14,
  },
});
