import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Modal, TouchableWithoutFeedback } from 'react-native';
import { Text, Button, ActivityIndicator, useTheme } from 'react-native-paper';
import { CustomTimePicker } from './CustomTimePicker';

interface TimePickerModalProps {
  visible: boolean;
  date: Date;
  onDateChange: (event: any, date?: Date) => void;
  onConfirm: (date: Date) => void;
  onCancel: () => void;
  loading: boolean;
  title: string;
  minTime?: Date;
  maxTime?: Date;
}

export const TimePickerModal: React.FC<TimePickerModalProps> = ({
  visible,
  date,
  onDateChange,
  onConfirm,
  onCancel,
  loading,
  title,
  minTime,
  maxTime
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(date);

  // Reset selectedDate kada se modal otvori
  useEffect(() => {
    if (visible) {
      setSelectedDate(date);
    }
  }, [visible, date]);

  const handleTimeChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const handleConfirm = () => {
    if (!loading) {
      onConfirm(selectedDate);
    }
  };

  return (
    <Modal 
      visible={visible} 
      transparent 
      animationType="none"
      onRequestClose={onCancel}
    >
      <TouchableWithoutFeedback onPress={onCancel}>
        <View style={[styles.modalOverlay, { backgroundColor: 'rgba(0, 0, 0, 0.6)' }]}>
          <TouchableWithoutFeedback>
            <View style={[styles.modalContent, { backgroundColor: theme.colors.surface }]}>
              <Text style={[styles.modalTitle, { color: theme.colors.primary }]}>{title}</Text>
              
              <CustomTimePicker
                value={selectedDate}
                onChange={handleTimeChange}
                minTime={minTime}
                maxTime={maxTime}
              />
              
              <View style={styles.buttonContainer}>
                <Button
                  mode="outlined"
                  onPress={onCancel}
                  disabled={loading}
                  style={[styles.button, { borderColor: theme.colors.primary }]}
                >
                  Odustani
                </Button>

                {loading ? (
                  <ActivityIndicator size="small" color={theme.colors.primary} style={styles.loader} />
                ) : (
                  <Button
                    mode="contained"
                    onPress={handleConfirm}
                    style={[styles.button, { backgroundColor: theme.colors.primary }]}
                    labelStyle={{ color: 'white' }}
                  >
                    Potvrdi
                  </Button>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    borderRadius: 16,
    padding: 20,
    width: '90%',
    maxWidth: 360,
    alignItems: 'center',
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20
  },
  button: {
    minWidth: 100,
    borderRadius: 8
  },
  loader: {
    marginHorizontal: 16
  }
});
