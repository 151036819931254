import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Text, Button, Chip, useTheme } from 'react-native-paper';
import { Break } from '../BreakListScreen';
import { formatAvailableHours, formatMinimumTime, formatRestrictedTime, formatRemainingTime } from '../../utils/formatters';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

interface BreakCardProps {
  breakItem: Break;
  onStartBreak: (breakItem: Break) => void;
}

export const BreakCard: React.FC<BreakCardProps> = ({ breakItem, onStartBreak }) => {
  const theme = useTheme();

  // Provjera je li pauza iskorištena (ne može se više koristiti)
  const isBreakDepleted = 
    // Za jednokratne pauze (isUnlimited=false), gledamo je li već korištena u bazi preko usedCount
    // i samo ako je stvarno iskorištena (usedCount > 0)
    (!breakItem.isUnlimited && breakItem.usedCount !== undefined && breakItem.usedCount > 0) ||
    // Za višekratne pauze (isUnlimited=true), gledamo preostalo vrijeme i allowReuseAfterExpiry
    (breakItem.isUnlimited && !breakItem.allowReuseAfterExpiry && 
     breakItem.remainingTime !== undefined && breakItem.remainingTime === 0);
  
  // Provjera je li pauza iskorištena ali se može ponovno koristiti
  const isBreakUsedButReusable = 
    // Samo za višekratne pauze koje dozvoljavaju ponovno korištenje
    breakItem.isUnlimited && 
    breakItem.allowReuseAfterExpiry &&
    breakItem.remainingTime !== undefined && 
    breakItem.remainingTime === 0;  // Upozorenje samo kad je točno 0
  
  // Debug log je uklonjen

  const isButtonDisabled = (isBreakDepleted && 
    !breakItem.allowReuseAfterExpiry);

  return (
    <Card style={styles.card} mode="outlined">
      <Card.Content style={styles.cardContent}>
        <View style={styles.header}>
          <Text variant="titleLarge" style={styles.title}>{breakItem.name}</Text>
          <Chip 
            icon={({ size, color }) => (
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="clock-outline"
                  size={size}
                  color={color}
                  style={styles.icon}
                />
              </View>
            )}
            style={[
              styles.durationChip,
              { backgroundColor: theme.colors.primaryContainer }
            ]}
          >
            {formatRemainingTime(breakItem.remainingTime !== undefined ? breakItem.remainingTime : breakItem.allowedMinutes)}
          </Chip>
        </View>

        <View style={styles.infoContainer}>
          <View style={styles.infoRow}>
            <View style={styles.iconContainer}>
              <MaterialCommunityIcons 
                name="refresh" 
                size={20} 
                color={theme.colors.primary}
                style={styles.icon}
              />
            </View>
            <Text variant="bodyLarge" style={styles.infoText}>
              {breakItem.isUnlimited ? 'Višekratno korištenje' : 'Jednokratno korištenje'}
            </Text>
          </View>

          <View style={styles.infoRow}>
            <View style={styles.iconContainer}>
              <MaterialCommunityIcons 
                name="cash" 
                size={20} 
                color={theme.colors.primary}
                style={styles.icon}
              />
            </View>
            <Text variant="bodyLarge" style={styles.infoText}>
              {breakItem.isPaid ? 'Plaćena pauza' : 'Neplaćena pauza'}
            </Text>
          </View>

          <View style={styles.infoRow}>
            <View style={styles.iconContainer}>
              <MaterialCommunityIcons 
                name="refresh-circle" 
                size={20} 
                color={theme.colors.primary}
                style={styles.icon}
              />
            </View>
            <Text variant="bodyLarge" style={styles.infoText}>
              {breakItem.allowReuseAfterExpiry ? 'Može se ponovno koristiti' : 'Ne može se ponovno koristiti'}
            </Text>
          </View>

          {breakItem.restrictTimeEnabled && breakItem.minWorkMinutes && (
            <View style={styles.infoRow}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons 
                  name="clock-time-eight" 
                  size={20} 
                  color={theme.colors.primary}
                  style={styles.icon}
                />
              </View>
              <Text variant="bodyLarge" style={styles.infoText}>
                Min. rad: {breakItem.minWorkMinutes} min
              </Text>
            </View>
          )}

          {breakItem.isTimeRestrictionEnabled && breakItem.restrictedHours && breakItem.restrictedHours.length > 0 && (
            <View style={styles.infoRow}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons 
                  name="clock-alert-outline" 
                  size={20} 
                  color={theme.colors.error}
                  style={styles.icon}
                />
              </View>
              <Text variant="bodyLarge" style={styles.infoText}>
                Nedostupna u: {breakItem.restrictedHours.sort((a, b) => a - b).map(h => 
                  `${h.toString().padStart(2, '0')}h`
                ).join(', ')}
              </Text>
            </View>
          )}

          {!breakItem.isTimeRestrictionEnabled && (
            <View style={styles.infoRow}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons 
                  name="clock-check-outline" 
                  size={20} 
                  color={theme.colors.primary}
                  style={styles.icon}
                />
              </View>
              <Text variant="bodyLarge" style={styles.infoText}>
                Nema vremenskog ograničenja
              </Text>
            </View>
          )}

          {breakItem.usedCount !== undefined && breakItem.usedCount > 0 && (
            <View style={styles.infoRow}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons 
                  name="counter" 
                  size={20} 
                  color={theme.colors.error}
                  style={styles.icon}
                />
              </View>
              <Text variant="bodyLarge" style={styles.infoText}>
                Korišteno u trenutnom bloku: {breakItem.usedCount}
              </Text>
            </View>
          )}
        </View>

        <Button 
          icon={({ size, color }) => (
            <View style={styles.iconContainer}>
              <MaterialCommunityIcons
                name="play"
                size={24}
                color={color}
                style={styles.icon}
              />
            </View>
          )}
          mode="contained" 
          onPress={() => onStartBreak(breakItem)}
          style={[
            styles.startButton,
            isBreakDepleted && styles.disabledButton,
            isBreakUsedButReusable && styles.warningButton
          ]}
          disabled={isButtonDisabled}
          contentStyle={styles.buttonContent}
          labelStyle={styles.buttonLabel}
        >
          {isBreakDepleted ? 'Iskorišteno' : 
           isBreakUsedButReusable ? 'Zabilježi prekoračenje' : 
           'Započni pauzu'}
        </Button>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 16,
    marginVertical: 8,
    borderRadius: 16,
    elevation: 2,
  },
  cardContent: {
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
    flex: 1,
    marginRight: 12,
  },
  durationChip: {
    borderRadius: 8,
    minWidth: 80,
    justifyContent: 'center',
  },
  infoContainer: {
    gap: 8,
    marginBottom: 16,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  infoText: {
    fontSize: 16,
    flex: 1,
  },
  iconContainer: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
  icon: {
    WebkitFontSmoothing: 'antialiased',
  },
  startButton: {
    borderRadius: 12,
    marginTop: 8,
  },
  buttonContent: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '600',
  },
  disabledButton: {
    backgroundColor: '#999999', // Siva boja za onemogućeni gumb
  },
  warningButton: {
    backgroundColor: '#FFC107', // Žuta boja za upozorenje
  },
});
