import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { List, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface LocationItemProps {
  location: {
    name: string;
    address: string;
    activatedDevices: Array<{
      deviceId: string;
      activatedAt: string;
      lastUsed: string;
      active: boolean;
    }>;
  };
  employeeCount: number;
  onPress: () => void;
  style?: any;
}

export const LocationItem: React.FC<LocationItemProps> = ({
  location,
  employeeCount,
  onPress,
  style
}) => {
  const theme = useTheme();
  const activeDevices = location.activatedDevices.filter(d => d.active).length;
  
  return (
    <List.Item
      title={location.name}
      description={location.address}
      onPress={onPress}
      style={[styles.container, { backgroundColor: '#f8f9fa' }, style]}
      left={props => (
        <View style={[styles.iconContainer, { backgroundColor: `${theme.colors.primary}10` }]}>
          <MaterialCommunityIcons 
            name="office-building" 
            size={24} 
            color={theme.colors.primary}
          />
        </View>
      )}
      right={props => (
        <View style={styles.employeesContainer}>
          <View style={styles.employeesTextContainer}>
            <Text style={styles.employeesText}>
              {employeeCount}
            </Text>
          </View>
          <View style={styles.chevronContainer}>
            <MaterialCommunityIcons
              name="chevron-right"
              size={24}
              color="#666"
            />
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    marginVertical: 4,
    marginHorizontal: 16,
    elevation: 1,
    backgroundColor: '#f8f9fa',
    paddingRight: 0,
    paddingVertical: 4,
  },
  iconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  employeesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 140,
  },
  employeesTextContainer: {
    flex: 1,
    marginRight: 8,
  },
  employeesText: {
    fontSize: 13,
    color: '#666',
    textAlign: 'right',
  },
  chevronContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
});
