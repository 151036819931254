import React from 'react';
import { Helmet } from 'react-helmet';
import { generatePageTitle, generateCanonicalUrl, generateOpenGraphData, generateTwitterCardData } from '../utils/seoHelpers';
import { keywords } from '../config/keywords';
import { siteMetadata } from '../config/siteMetadata';

interface MetaTagsProps {
  title?: string;
  description?: string;
  path: string;
  image?: string;
  type?: 'website' | 'article';
}

export const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  description,
  path,
  image,
  type = 'website'
}) => {
  const pageTitle = generatePageTitle(title);
  const canonicalUrl = generateCanonicalUrl(path);
  const metaDescription = description || siteMetadata.defaultTitle;
  const openGraphData = generateOpenGraphData(pageTitle, metaDescription, image, type);
  const twitterCardData = generateTwitterCardData(pageTitle, metaDescription, image);

  return (
    <Helmet>
      {/* Osnovni meta tagovi */}
      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={[...keywords.primary, ...keywords.secondary].join(', ')} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph tagovi */}
      {Object.entries(openGraphData).map(([property, content]) => (
        <meta key={property} property={property} content={content} />
      ))}

      {/* Twitter Card tagovi */}
      {Object.entries(twitterCardData).map(([name, content]) => (
        <meta key={name} name={name} content={content} />
      ))}

      {/* Dodatni meta tagovi */}
      <meta name="author" content={siteMetadata.company.name} />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="hr" />
      <meta name="geo.region" content="HR" />
      <meta name="geo.placename" content={siteMetadata.company.city} />

      {/* Favicon i Apple touch icon */}
      <link rel="icon" type="image/png" href="/favicon.png" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    </Helmet>
  );
};