import React from 'react';
import { ScrollView, StyleSheet, View, RefreshControl } from 'react-native';
import { useTheme, Button, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useLocations } from '../../hooks/useLocations';
import { TotalStatsCard } from './components/TotalStatsCard';
import { LocationStatsGrid } from './components/LocationStatsGrid';
import { LoadingScreen } from '../../components/LoadingScreen';
import { ErrorScreen } from '../../components/ErrorScreen';
import { getGreeting } from '../../utils/greetings';
import { useAuth } from '../../hooks/useAuth';
import { useNavigation } from '@react-navigation/native';
import { useDashboardStats } from '../../hooks/useDashboardStats';
import { useLocationStats } from '../../hooks/useLocationStats';

export const DashboardScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { owner } = useAuth();
  const [refreshing, setRefreshing] = React.useState(false);
  const { data: locations, isLoading: locationsLoading, error: locationsError, refetch: refetchLocations } = useLocations();
  const { refetch: refetchStats } = useDashboardStats();
  const { refetch: refetchLocationStats } = useLocationStats();
  const greeting = getGreeting();

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await Promise.all([
      refetchLocations(),
      refetchStats(),
      refetchLocationStats()
    ]);
    setRefreshing(false);
  }, [refetchLocations, refetchStats, refetchLocationStats]);

  if (locationsLoading) {
    return <LoadingScreen />;
  }

  if (locationsError) {
    return <ErrorScreen error={locationsError} />;
  }

  const hasLocations = locations && locations.length > 0;

  return (
    <SafeAreaView style={[styles.container, { backgroundColor: '#fff' }]} edges={[]}>
      {hasLocations ? (
        <ScrollView 
          contentContainerStyle={styles.content}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
        >
          <TotalStatsCard />
          <LocationStatsGrid locations={locations} />
        </ScrollView>
      ) : (
        <View style={styles.emptyStateContainer}>
          <View style={styles.emptyStateContent}>
            <Text style={styles.emptyStateText}>
              Nemate još dodanih poslovnica
            </Text>
            <Text style={styles.emptyStateSubtext}>
              Dodajte svoju prvu poslovnicu kako biste mogli pratiti rad djelatnika
            </Text>
            <Button 
              mode="contained" 
              onPress={() => navigation.navigate('CreateLocation' as never)}
              style={styles.createButton}
              contentStyle={styles.createButtonContent}
            >
              Dodaj poslovnicu
            </Button>
          </View>
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingTop: 4,
  },
  subtitle: {
    fontSize: 14,
    color: '#37474F',
    paddingHorizontal: 16,
  },
  emptyStateContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStateContent: {
    width: '100%',
    paddingHorizontal: 24,
    alignItems: 'center',
    marginTop: -30,
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#37474F',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: '#78909C',
    textAlign: 'center',
    marginBottom: 32,
    paddingHorizontal: 20,
    fontWeight: 'normal',
  },
  createButton: {
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  createButtonContent: {
    minWidth: 180,
  },
});
