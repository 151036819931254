import { TerminalState, TerminalSession, TerminalBreak } from '../types/terminal.types';

export const initialState: TerminalState = {
  isActive: false,
  terminalId: null,
  currentSession: null,
  currentBreak: null,
  error: null,
};

type TerminalAction =
  | { type: 'SET_ACTIVE'; payload: { terminalId: string } }
  | { type: 'SET_INACTIVE' }
  | { type: 'START_SESSION'; payload: TerminalSession }
  | { type: 'END_SESSION' }
  | { type: 'START_BREAK'; payload: TerminalBreak }
  | { type: 'END_BREAK' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' };

export function terminalReducer(state: TerminalState, action: TerminalAction): TerminalState {
  switch (action.type) {
    case 'SET_ACTIVE':
      return {
        ...state,
        isActive: true,
        terminalId: action.payload.terminalId,
        error: null,
      };

    case 'SET_INACTIVE':
      return {
        ...state,
        isActive: false,
        terminalId: null,
        currentSession: null,
        currentBreak: null,
        error: null,
      };

    case 'START_SESSION':
      return {
        ...state,
        currentSession: action.payload,
        error: null,
      };

    case 'END_SESSION':
      return {
        ...state,
        currentSession: state.currentSession ? {
          ...state.currentSession,
          endTime: new Date(),
          status: 'COMPLETED',
        } : null,
        currentBreak: null,
      };

    case 'START_BREAK':
      return {
        ...state,
        currentBreak: action.payload,
        currentSession: state.currentSession ? {
          ...state.currentSession,
          status: 'ON_BREAK',
        } : null,
      };

    case 'END_BREAK':
      return {
        ...state,
        currentBreak: null,
        currentSession: state.currentSession ? {
          ...state.currentSession,
          status: 'ACTIVE',
        } : null,
      };

    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };

    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
