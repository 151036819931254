import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet, FlatList, TouchableOpacity, RefreshControl } from 'react-native';
import { Searchbar, List, Menu, IconButton, useTheme, Text, ActivityIndicator, Button } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { employeesAPI } from '../../services/api';

export const EmployeesScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const route = useRoute();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('Sve poslovnice');
  const [menuVisible, setMenuVisible] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const fetchLocations = async () => {
    try {
      const response = await employeesAPI.getLocations();
      setLocations(response);
    } catch (error) {
      console.error('Greška pri dohvaćanju poslovnica:', error);
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchEmployees();
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setSearchQuery('');
      setSelectedLocation('Sve poslovnice');
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (route.params?.refresh) {
      fetchEmployees();
      navigation.setParams({ refresh: undefined });
    }
  }, [route.params?.refresh]);

  const fetchEmployees = async () => {
    try {
      const response = await employeesAPI.getEmployees();
      setEmployees(response);
    } catch (error) {
      console.error('Greška pri dohvaćanju djelatnika:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchEmployees().finally(() => setRefreshing(false));
  }, []);

  const filteredEmployees = employees.filter(employee => {
    const fullName = `${employee.name} ${employee.surname}`.toLowerCase();
    const matchesSearch = fullName.includes(searchQuery.toLowerCase());
    
    // Pronađi odabranu lokaciju po imenu
    const selectedLocationObj = locations.find(loc => loc.name === selectedLocation);
    
    const matchesLocation = selectedLocation === 'Sve poslovnice' || 
      (employee.locationId && selectedLocationObj && employee.locationId._id === selectedLocationObj._id);
    
    return matchesSearch && matchesLocation;
  });

  const handleEmployeePress = (employeeId: string) => {
    // @ts-ignore
    navigation.navigate('EmployeeDetails', { employeeId });
  };

  const renderEmployee = React.useCallback(({ item }) => {
    return (
      <TouchableOpacity onPress={() => handleEmployeePress(item._id)}>
        <List.Item
          title={`${item.name} ${item.surname}`}
          titleNumberOfLines={1}
          titleStyle={styles.titleStyle}
          description={
            <View style={styles.descriptionContainer}>
              <Text style={styles.descriptionText}>OIB: {item.oib || 'Nije unesen'}</Text>
              <Text style={styles.descriptionText}>Status: {item.status?.name || 'Nije definiran'}</Text>
            </View>
          }
          style={styles.employeeItem}
          descriptionStyle={styles.descriptionStyle}
          left={props => (
            <View style={[styles.avatarContainer, { backgroundColor: `${theme.colors.primary}10` }]}>
              <MaterialCommunityIcons 
                name="account" 
                size={24} 
                color={theme.colors.primary}
              />
            </View>
          )}
          right={props => (
            <View style={styles.chevronContainer}>
              <MaterialCommunityIcons
                name="chevron-right"
                size={24}
                color="#666"
              />
            </View>
          )}
        />
      </TouchableOpacity>
    );
  }, [theme.colors.primary]);

  return (
    <SafeAreaView style={[styles.safeArea, { backgroundColor: '#fff' }]} edges={['left', 'right']}>
      {employees.length === 0 && !isLoading ? (
        <View style={styles.emptyStateContainer}>
          <View style={styles.emptyStateContent}>
            <Text style={styles.emptyStateText}>
              Nemate još dodanih djelatnika
            </Text>
            <Text style={styles.emptyStateSubtext}>
              Dodajte svog prvog djelatnika kako biste mogli pratiti njihov rad
            </Text>
            <Button 
              mode="contained" 
              onPress={() => navigation.navigate('CreateEmployee')}
              style={styles.createButton}
              contentStyle={styles.createButtonContent}
            >
              Dodaj djelatnika
            </Button>
          </View>
        </View>
      ) : (
        <>
          <View style={styles.searchContainer}>
            <Searchbar
              placeholder="Pretraži djelatnike"
              onChangeText={setSearchQuery}
              value={searchQuery}
              style={styles.searchBar}
              inputStyle={styles.searchInput}
              right={(props) => (
                <Menu
                  visible={menuVisible}
                  onDismiss={() => setMenuVisible(false)}
                  anchor={
                    <IconButton
                      icon="filter-variant"
                      size={24}
                      onPress={() => setMenuVisible(true)}
                      style={styles.filterButton}
                    />
                  }
                >
                  <Menu.Item
                    onPress={() => {
                      setSelectedLocation('Sve poslovnice');
                      setMenuVisible(false);
                    }}
                    title="Sve poslovnice"
                  />
                  {locations.map((location) => (
                    <Menu.Item
                      key={location._id}
                      onPress={() => {
                        setSelectedLocation(location.name);
                        setMenuVisible(false);
                      }}
                      title={location.name}
                    />
                  ))}
                </Menu>
              )}
            />
          </View>
          {selectedLocation !== 'Sve poslovnice' && (
            <View style={styles.selectedLocationContainer}>
              <View style={styles.locationTag}>
                <Text style={styles.locationTagText}>{selectedLocation}</Text>
                <TouchableOpacity onPress={() => setSelectedLocation('Sve poslovnice')}>
                  <MaterialCommunityIcons name="close" size={18} color="#666" />
                </TouchableOpacity>
              </View>
            </View>
          )}
          {isLoading ? (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color={theme.colors.primary} />
            </View>
          ) : (
            <FlatList
              data={filteredEmployees}
              renderItem={renderEmployee}
              keyExtractor={(item) => item._id}
              contentContainerStyle={styles.listContent}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  colors={[theme.colors.primary]}
                />
              }
              ListEmptyComponent={
                <View style={styles.emptyContainer}>
                  <Text style={styles.emptyText}>
                    Nema pronađenih djelatnika
                  </Text>
                </View>
              }
            />
          )}
        </>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  searchContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: '#fff',
  },
  searchBar: {
    elevation: 0,
    backgroundColor: '#f5f5f5',
    borderRadius: 12,
    height: 40,
  },
  searchInput: {
    fontSize: 14,
    minHeight: 0,
    paddingBottom: 0,
  },
  filterButton: {
    margin: 0,
  },
  listContent: {
    paddingHorizontal: 0,
    paddingTop: 4,
    paddingBottom: 16,
  },
  employeeItem: {
    borderRadius: 12,
    marginVertical: 4,
    marginHorizontal: 16,
    elevation: 1,
    backgroundColor: '#f8f9fa',
    paddingRight: 0,
  },
  avatarContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
  chevronContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionContainer: {
    flexDirection: 'column',
    marginTop: 4,
  },
  descriptionText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 2,
  },
  descriptionStyle: {
    marginTop: 4,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStateContent: {
    width: '100%',
    paddingHorizontal: 24,
    alignItems: 'center',
    marginTop: -30,
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#37474F',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: '#78909C',
    textAlign: 'center',
    marginBottom: 32,
    paddingHorizontal: 20,
    fontWeight: 'normal',
  },
  createButton: {
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  createButtonContent: {
    minWidth: 180,
  },
  selectedLocationContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
  },
  locationTag: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 16,
    marginRight: 8,
  },
  locationTagText: {
    marginRight: 8,
    fontSize: 14,
    color: '#333',
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    fontSize: 16,
    color: '#666',
  },
  titleStyle: {
    fontSize: 16,
  },
});
