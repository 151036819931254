import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';

const SkeletonBox = ({ style }: { style?: any }) => {
  return (
    <View
      style={[
        styles.skeletonBox,
        style,
      ]}
      className="skeleton-pulse"
    />
  );
};

export const WebDashboardSkeleton = () => {
  const theme = useTheme();

  return (
    <View style={styles.container}>
      {/* Header skeleton */}
      <View style={styles.header}>
        <View style={styles.welcomeContainer}>
          <SkeletonBox style={styles.iconSkeleton} />
          <View style={styles.welcomeText}>
            <SkeletonBox style={styles.titleSkeleton} />
            <SkeletonBox style={styles.subtitleSkeleton} />
          </View>
        </View>
      </View>

      {/* Total stats card skeleton */}
      <View style={styles.statsCard}>
        <View style={styles.statsRow}>
          <View style={styles.statItem}>
            <SkeletonBox style={styles.statValue} />
            <SkeletonBox style={styles.statLabel} />
          </View>
          <View style={styles.statItem}>
            <SkeletonBox style={styles.statValue} />
            <SkeletonBox style={styles.statLabel} />
          </View>
          <View style={styles.statItem}>
            <SkeletonBox style={styles.statValue} />
            <SkeletonBox style={styles.statLabel} />
          </View>
        </View>
      </View>

      {/* Location grid skeleton */}
      <View style={styles.grid}>
        {[1, 2, 3, 4].map((item) => (
          <View key={item} style={styles.gridItem}>
            <SkeletonBox style={styles.locationTitle} />
            <View style={styles.locationStats}>
              <SkeletonBox style={styles.statValue} />
              <SkeletonBox style={styles.statLabel} />
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: '#FFFFFF',
  },
  header: {
    marginBottom: 24,
  },
  welcomeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  welcomeText: {
    gap: 8,
  },
  iconSkeleton: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: '#E2E8F0',
  },
  titleSkeleton: {
    width: 200,
    height: 24,
    borderRadius: 4,
    backgroundColor: '#E2E8F0',
  },
  subtitleSkeleton: {
    width: 150,
    height: 16,
    borderRadius: 4,
    backgroundColor: '#E2E8F0',
  },
  statsCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 24,
    marginBottom: 24,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  statsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 24,
  },
  statItem: {
    flex: 1,
    gap: 8,
  },
  statValue: {
    height: 24,
    borderRadius: 4,
    backgroundColor: '#E2E8F0',
  },
  statLabel: {
    height: 16,
    width: '60%',
    borderRadius: 4,
    backgroundColor: '#E2E8F0',
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 24,
  },
  gridItem: {
    flex: 1,
    minWidth: 250,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 16,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    gap: 16,
  },
  locationTitle: {
    height: 20,
    width: '80%',
    borderRadius: 4,
    backgroundColor: '#E2E8F0',
  },
  locationStats: {
    gap: 8,
  },
  skeletonBox: {
    backgroundColor: '#E2E8F0',
  },
});
