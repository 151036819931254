import { useState, useCallback } from 'react';
import { useTerminalContext } from '../context/TerminalContext';
import { terminalEndpoints } from '../api/terminalApi';
import { TerminalSession } from '../types/terminal.types';

export function useTerminalSession() {
  const { state, dispatch } = useTerminalContext();
  const [loading, setLoading] = useState(false);

  const startSession = useCallback(async (employeeId: string) => {
    try {
      setLoading(true);
      const session = await terminalEndpoints.startSession(employeeId);
      dispatch({ type: 'START_SESSION', payload: session });
      return session;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Greška pri pokretanju sesije' });
      throw error;
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const endSession = useCallback(async () => {
    if (!state.currentSession?.id) return;

    try {
      setLoading(true);
      await terminalEndpoints.endSession(state.currentSession.id);
      dispatch({ type: 'END_SESSION' });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: 'Greška pri završetku sesije' });
      throw error;
    } finally {
      setLoading(false);
    }
  }, [state.currentSession, dispatch]);

  return {
    currentSession: state.currentSession,
    isLoading: loading,
    startSession,
    endSession,
  };
}
