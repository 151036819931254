import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  FlatList,
  Animated,
  useColorScheme,
  SafeAreaView,
  StatusBar,
} from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

const { width, height } = Dimensions.get('window');

const slides = [
  {
    id: '1',
    title: 'Dobrodošli u Clock.hr',
    description: 'Vaš pametni sustav za evidenciju radnog vremena. Jednostavan, brz i pouzdan način praćenja rada.',
    icon: 'time',
  },
  {
    id: '2',
    title: 'Upravljanje Pauzama',
    description: 'Fleksibilno upravljanje pauzama tijekom radnog dana. Automatsko računanje vremena pauze.',
    icon: 'cafe-outline',
  },
  {
    id: '3',
    title: 'Prekovremeni Rad',
    description: 'Automatsko praćenje prekovremenih sati. Detaljan pregled i evidencija dodatnog rada.',
    icon: 'alarm-outline',
  },
  {
    id: '4',
    title: 'Statistika i Izvještaji',
    description: 'Detaljni izvještaji o radnom vremenu. Grafički prikazi i analitika za bolju produktivnost.',
    icon: 'stats-chart-outline',
  },
  {
    id: '5',
    title: 'Performance Djelatnika',
    description: 'Praćenje učinkovitosti i produktivnosti. Uvid u radne navike i optimizacija radnog vremena.',
    icon: 'trending-up-outline',
  },
  {
    id: '6',
    title: 'Započnite Odmah',
    description: 'Spremni ste za korištenje clock.hr aplikacije. Prijavite se i započnite s praćenjem radnog vremena.',
    icon: 'checkmark-circle-outline',
  },
];

export const OnboardingScreen = () => {
  const navigation = useNavigation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollX = useRef(new Animated.Value(0)).current;
  const slidesRef = useRef<FlatList>(null);
  const systemColorScheme = useColorScheme();
  const [isDarkMode, setIsDarkMode] = useState(systemColorScheme === 'dark');

  const resetPosition = () => {
    if (slidesRef.current) {
      slidesRef.current.scrollToIndex({ index: 0, animated: false });
      setCurrentIndex(0);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      resetPosition();
    }, [])
  );

  const theme = {
    background: isDarkMode ? '#000000' : '#ffffff',
    text: isDarkMode ? '#ffffff' : '#1a1a1a',
    secondaryText: isDarkMode ? '#999999' : '#666666',
    iconBackground: isDarkMode ? '#1a1a1a' : '#f0f0f0',
    buttonBackground: isDarkMode ? '#ffffff' : '#1a1a1a',
    buttonText: isDarkMode ? '#000000' : '#ffffff',
    toggleBackground: isDarkMode ? '#1a1a1a' : '#e6e6e6',
    dot: isDarkMode ? '#333333' : '#e6e6e6',
    activeDot: isDarkMode ? '#ffffff' : '#1a1a1a',
  };

  const viewableItemsChanged = useRef(({ viewableItems }: any) => {
    if (viewableItems[0]) {
      setCurrentIndex(viewableItems[0].index);
    }
  }).current;

  const viewConfig = useRef({ viewAreaCoveragePercentThreshold: 50 }).current;

  const scrollTo = async () => {
    if (currentIndex < slides.length - 1) {
      slidesRef.current?.scrollToIndex({
        index: currentIndex + 1,
        animated: true,
      });
    } else {
      try {
        await AsyncStorage.setItem('hasSeenOnboarding', 'true');
        navigation.navigate('Login' as never);
      } catch (error) {
        console.error('Greška pri spremanju onboarding statusa:', error);
      }
    }
  };

  const skipOnboarding = async () => {
    try {
      await AsyncStorage.setItem('hasSeenOnboarding', 'true');
      navigation.navigate('Login' as never);
    } catch (error) {
      console.error('Greška pri spremanju onboarding statusa:', error);
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const Slide = ({ item }: any) => {
    return (
      <View style={[styles.slide, { backgroundColor: theme.background }]}>
        <View style={styles.iconContainer}>
          <View style={[styles.iconBackground, { backgroundColor: theme.iconBackground }]}>
            <Ionicons name={item.icon as any} size={60} color={theme.text} />
          </View>
        </View>
        <View style={styles.textContainer}>
          <Text style={[styles.title, { color: theme.text }]}>{item.title}</Text>
          <Text style={[styles.description, { color: theme.secondaryText }]}>
            {item.description}
          </Text>
        </View>
      </View>
    );
  };

  const Pagination = () => {
    return (
      <View style={styles.paginationContainer}>
        {slides.map((_, index) => {
          const inputRange = [(index - 1) * width, index * width, (index + 1) * width];
          const dotWidth = scrollX.interpolate({
            inputRange,
            outputRange: [10, 20, 10],
            extrapolate: 'clamp',
          });
          const opacity = scrollX.interpolate({
            inputRange,
            outputRange: [0.3, 1, 0.3],
            extrapolate: 'clamp',
          });
          return (
            <Animated.View
              key={index.toString()}
              style={[
                styles.dot,
                { 
                  width: dotWidth,
                  opacity,
                  backgroundColor: currentIndex === index ? theme.activeDot : theme.dot,
                },
              ]}
            />
          );
        })}
      </View>
    );
  };

  return (
    <SafeAreaView style={[styles.container, isDarkMode ? styles.darkContainer : styles.lightContainer]}>
      <StatusBar
        barStyle={isDarkMode ? 'light-content' : 'dark-content'}
        backgroundColor={isDarkMode ? '#000000' : '#ffffff'}
      />
      <TouchableOpacity
        style={[styles.themeToggle, { backgroundColor: theme.toggleBackground }]}
        onPress={toggleTheme}
      >
        <Ionicons
          name={isDarkMode ? 'sunny-outline' : 'moon-outline'}
          size={24}
          color={theme.text}
        />
      </TouchableOpacity>

      <View style={styles.content}>
        <View style={styles.logoContainer}>
          <View style={styles.logoTextContainer}>
            <Text style={[styles.logoPart, { color: theme.text }]}>clock</Text>
            <Text style={[styles.logoDot, { color: theme.text }]}>.</Text>
            <Text style={[styles.logoHr, { color: theme.text }]}>hr</Text>
          </View>
        </View>

        <FlatList
          data={slides}
          renderItem={({ item }) => <Slide item={item} />}
          horizontal
          showsHorizontalScrollIndicator={false}
          pagingEnabled
          bounces={false}
          keyExtractor={(item) => item.id}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
            useNativeDriver: false,
          })}
          onViewableItemsChanged={viewableItemsChanged}
          viewabilityConfig={viewConfig}
          ref={slidesRef}
        />

        <Pagination />

        <View style={styles.buttonsContainer}>
          {currentIndex < slides.length - 1 && (
            <TouchableOpacity
              style={styles.skipButton}
              onPress={skipOnboarding}
            >
              <Text style={[styles.skipText, { color: theme.secondaryText }]}>
                Preskoči
              </Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity
            style={[styles.button, { backgroundColor: theme.buttonBackground }]}
            onPress={scrollTo}
          >
            <Text style={[styles.buttonText, { color: theme.buttonText }]}>
              {currentIndex === slides.length - 1 ? 'Započni' : 'Dalje'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  darkContainer: {
    backgroundColor: '#000000',
  },
  lightContainer: {
    backgroundColor: '#ffffff',
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 50,
  },
  slide: {
    width,
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  iconContainer: {
    alignItems: 'center',
    marginVertical: 40,
  },
  iconBackground: {
    width: 120,
    height: 120,
    borderRadius: 60,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
  },
  textContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 26,
  },
  logoContainer: {
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 20,
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoPart: {
    fontSize: 42,
    fontWeight: '600',
    letterSpacing: 1.2,
  },
  logoDot: {
    fontSize: 42,
    fontWeight: '800',
    marginHorizontal: 1,
  },
  logoHr: {
    fontSize: 42,
    fontWeight: '800',
    letterSpacing: 1.2,
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  dot: {
    height: 10,
    marginHorizontal: 3,
    borderRadius: 5,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    paddingHorizontal: 20,
    marginTop: 30,
  },
  button: {
    padding: 15,
    borderRadius: 12,
    alignItems: 'center',
    minWidth: 180,
    paddingHorizontal: 30,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '600',
  },
  skipButton: {
    padding: 15,
  },
  skipText: {
    fontSize: 16,
    fontWeight: '500',
  },
  themeToggle: {
    position: 'absolute',
    top: 50,
    right: 20,
    padding: 10,
    borderRadius: 20,
    zIndex: 1,
  },
});
