import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Platform, Alert } from 'react-native';
import { Button, useTheme, IconButton, Text } from 'react-native-paper';
import { format, subDays } from 'date-fns';
import { terminalEndpoints } from '../../../modules/terminal/api/terminalApi';
import { useToast } from '../../../context/ToastContext';
import { 
  TimePickerModal, 
  BreakTypeModal, 
  DateFilters, 
  SessionsList 
} from './admin-controls';

interface Props {
  employeeId: string;
  locationId?: string;
}

interface Session {
  id: string;
  employeeId: string;
  startTime: Date;
  endTime?: Date;
  status: 'ACTIVE' | 'ON_BREAK' | 'COMPLETED';
  breaks?: Break[];
  currentBreak?: Break;
  workBlocks: Array<{
    startTime: Date;
    endTime?: Date;
  }>;
  actionBy?: string;  // Tko je započeo sesiju
  endedBy?: string;   // Tko je završio sesiju
}

interface Break {
  id: string;
  sessionId: string;
  type: string;
  startTime: string;
  endTime?: string;
  maxDuration?: number;
  actionBy?: string;
}

export const AdminEmployeeTimesheetManager: React.FC<Props> = ({ employeeId, locationId }) => {
  const theme = useTheme();
  const colors = theme.colors;
  const [displayDate, setDisplayDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState(new Date());
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState<'checkIn' | 'checkOut' | 'endBreak' | 'editSessionStart' | 'editSessionEnd' | 'editBreakStart' | 'editBreakEnd' | null>(null);
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [selectedBreak, setSelectedBreak] = useState<Break | null>(null);
  const [timeModalDate, setTimeModalDate] = useState(new Date());
  const [breakType, setBreakType] = useState('Pauza');
  const [breakTypes, setBreakTypes] = useState<string[]>([
    'Pauza za ručak',
    'Kraća pauza',
    'Privatni izlazak',
    'Poslovni izlazak'
  ]);
  const [showBreakTypeModal, setShowBreakTypeModal] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [showWarningAlert, setShowWarningAlert] = useState(true);

  const toast = useToast();

  // Prikaži upozorenje pri učitavanju
  useEffect(() => {
    if (showWarningAlert) {
      Alert.alert(
        "Važno upozorenje",
        "Sustav omogućuje fleksibilnost pri izmjeni vremena djelatnika, ali zahtijeva oprez.\n\n• Početak mora biti prije završetka\n• Pauze moraju biti unutar radnog vremena\n• Izbjegavajte vremenska preklapanja\n• Administrator ne može započeti novu pauzu, dok djelatnici to mogu normalno preko terminala\n\nNepravilne izmjene mogu narušiti točnost statistike i izvještaja.",
        [
          { text: "Razumijem", onPress: () => setShowWarningAlert(false), style: "default" }
        ],
        { cancelable: false }
      );
    }
  }, [showWarningAlert]);

  // Dohvati podatke o sesijama za odabrani datum
  useEffect(() => {
    fetchSessions();
    fetchBreakTypes();
  }, []);

  useEffect(() => {
    setSelectedFilter('custom');
    setTempDate(displayDate); // Osiguraj da tempDate i displayDate budu sinkronizirani
  }, []);

  useEffect(() => {
    fetchSessions();
  }, [displayDate]);

  const fetchSessions = async () => {
    try {
      setLoading(true);
      
      // Provjeri je li locationId definiran
      if (!locationId) {
        // Koristimo currentLocation ako je definiran
        if (currentLocation) {
          useLocationId = currentLocation.id;
        }
      }
      
      // Formatiraj datum za API
      const formattedDate = format(displayDate, 'yyyy-MM-dd');
      
      try {
        const response = await terminalEndpoints.getEmployeeSessions(employeeId, locationId || '', formattedDate);
        
        if (response && response.sessions) {
          setSessions(response.sessions);
        } else {
          setLoading(false);
          toast.showToast('Nema podataka o evidenciji rada', 'Greška', 'error');
          setSessions([]);
        }
      } catch (apiError) {
        console.error('Greška kod dohvaćanja sesija:', 
          apiError instanceof Error ? apiError.message : 
          (apiError as any)?.response?.data || 'Nepoznata greška',
          '\nStatus:', (apiError as any)?.response?.status,
          '\nURL:', (apiError as any)?.config?.url
        );
        // Prikaži korisniku obavijest (toast) o grešci
        toast.showToast(
          `Greška ${(apiError as any)?.response?.status || ''}: ${(apiError as any)?.response?.data?.message || 'Molimo pokušajte ponovno.'}`, 
          'Greška kod dohvaćanja sesija', 
          'error'
        );
        setSessions([]);
        // Stvorimo mock podatke ako je u razvojnom okruženju
        if (__DEV__) {
          useMockData();
        }
      }
    } catch (error) {
      console.error('Neočekivana greška kod dohvaćanja sesija:', error);
      toast.showToast('Molimo pokušajte ponovno.', 'Neočekivana greška', 'error');
      setSessions([]);
      // Stvorimo mock podatke ako je u razvojnom okruženju
      if (__DEV__) {
        useMockData();
      }
    } finally {
      setLoading(false);
    }
  };

  const useMockData = () => {
    // Privremeni mock podaci za testiranje
    const mockSessions: Session[] = [
      {
        id: '1',
        employeeId,
        startTime: new Date(new Date(displayDate).setHours(8, 0, 0, 0)),
        endTime: new Date(new Date(displayDate).setHours(16, 0, 0, 0)),
        status: 'COMPLETED',
        breaks: [
          {
            id: 'b1',
            sessionId: '1',
            type: 'Pauza za ručak',
            startTime: new Date(new Date(displayDate).setHours(12, 0, 0, 0)).toISOString(),
            endTime: new Date(new Date(displayDate).setHours(12, 30, 0, 0)).toISOString(),
          }
        ]
      }
    ];

    setSessions(mockSessions);
    setLoading(false);
  };

  const quickFilters = [
    { label: 'Danas', date: new Date() },
    { label: 'Jučer', date: subDays(new Date(), 1) },
    { label: 'Prekjučer', date: subDays(new Date(), 2) },
  ];

  const handleQuickFilter = (newDate: Date) => {
    // Provjeri je li datum valjan
    if (isNaN(newDate.getTime())) {
      console.error('Neispravan datum za filter:', newDate);
      return;
    }
    setDisplayDate(newDate);
    setTempDate(newDate);
    setSelectedFilter('custom'); // Resetiraj filter nakon primjene
  };

  const handleDateChange = (event: any, selectedDate?: Date) => {
    if (selectedDate) {
      setTempDate(selectedDate);
    }
  };

  const handleDateConfirm = () => {
    // Postavi odabrani datum
    setDisplayDate(tempDate);
    setShowDatePicker(false);
  };

  const handleDateCancel = () => {
    setTempDate(displayDate);
    setShowDatePicker(false);
  };

  const handleAdminAction = (
    action: 'checkIn' | 'checkOut' | 'endBreak' | 'editSessionStart' | 'editSessionEnd' | 'editBreakStart' | 'editBreakEnd',
    session?: Session,
    breakItem?: Break
  ) => {
    // Provjeri ima li aktivnu smjenu za checkIn
    if (action === 'checkIn') {
      const activeSession = sessions.find(s => 
        s.status === 'ACTIVE' || s.status === 'ON_BREAK'
      );
      
      if (activeSession) {
        toast.showToast(
          'Zaposlenik već ima aktivnu smjenu. Prvo završite postojeću smjenu.',
          'Nije moguće',
          'error'
        );
        return;
      }
    }

    setSelectedAction(action);
    setSelectedSession(session || null);
    setSelectedBreak(breakItem || null);
    
    // Postavi inicijalno vrijeme ovisno o akciji
    let initialTime = new Date();
    
    // Ako je displayDate u budućnosti, koristi trenutno vrijeme
    if (displayDate > initialTime) {
      initialTime = new Date();
    } else {
      initialTime = new Date(displayDate);
    }

    // Postavi specifično vrijeme ovisno o akciji
    if (action === 'checkOut' && session?.endTime) {
      initialTime = new Date(session.endTime);
    } else if (action === 'endBreak' && breakItem) {
      if (breakItem.endTime) {
        initialTime = new Date(breakItem.endTime);
      } else {
        const startTime = new Date(breakItem.startTime);
        initialTime = new Date(Math.min(startTime.getTime() + 30 * 60000, Date.now())); // 30 minuta nakon početka ili trenutno vrijeme
      }
    } else if (action === 'editSessionStart' && session) {
      initialTime = new Date(session.startTime);
    } else if (action === 'editSessionEnd' && session?.endTime) {
      initialTime = new Date(session.endTime);
    } else if (action === 'editBreakStart' && breakItem) {
      initialTime = new Date(breakItem.startTime);
    } else if (action === 'editBreakEnd' && breakItem?.endTime) {
      initialTime = new Date(breakItem.endTime);
    }

    // Provjeri je li datum valjan
    if (isNaN(initialTime.getTime())) {
      console.error('Neispravan inicijalni datum:', initialTime);
      initialTime = new Date();
    }
    
    setTimeModalDate(initialTime);
    
    setShowTimeModal(true);
  };

  // Funkcija za promjenu vremena
  const onTimeChange = (event: any, selectedDate?: Date) => {
    if (!selectedDate) return;
    
    const now = new Date();
    if (selectedDate > now) {
      toast.showToast(
        'Ne možete odabrati vrijeme u budućnosti.',
        'Nije moguće',
        'error'
      );
      return;
    }

    // Dodatna validacija da završetak ne može biti prije početka
    if (selectedAction === 'editSessionEnd' && selectedSession) {
      const startTime = new Date(selectedSession.startTime);
      if (selectedDate < startTime) {
        toast.showToast(
          'Vrijeme završetka ne može biti prije vremena početka.',
          'Nije moguće',
          'error'
        );
        return;
      }
    }

    // Validacija da početak sesije ne može biti nakon početka neke pauze
    if (selectedAction === 'editSessionStart' && selectedSession && selectedSession.breaks && selectedSession.breaks.length > 0) {
      // Provjeri sve pauze u sesiji
      for (const breakItem of selectedSession.breaks) {
        const breakStartTime = new Date(breakItem.startTime);
        
        // Ako je odabrano vrijeme početka smjene nakon početka pauze
        if (selectedDate > breakStartTime) {
          toast.showToast(
            'Početak smjene ne može biti nakon početka neke pauze. Prvo uredite vrijeme pauze.',
            'Nije moguće',
            'error'
          );
          return;
        }
      }
    }

    // Dodatna validacija za pauze
    if (selectedAction === 'editBreakEnd' && selectedSession && selectedBreak) {
      const breakStartTime = new Date(selectedBreak.startTime);
      if (selectedDate < breakStartTime) {
        toast.showToast(
          'Vrijeme završetka pauze ne može biti prije vremena početka pauze.',
          'Nije moguće',
          'error'
        );
        return;
      }
    }
    
    if (selectedAction === 'editBreakStart' && selectedSession && selectedBreak) {
      const sessionStartTime = new Date(selectedSession.startTime);
      if (selectedDate < sessionStartTime) {
        toast.showToast(
          'Početak pauze ne može biti prije početka smjene.',
          'Nije moguće',
          'error'
        );
        return;
      }
      
      // Provjeri da pauza ne može početi nakon kraja sesije ako je sesija završena
      if (selectedSession.endTime) {
        const sessionEndTime = new Date(selectedSession.endTime);
        if (selectedDate > sessionEndTime) {
          toast.showToast(
            'Početak pauze ne može biti nakon završetka smjene.',
            'Nije moguće',
            'error'
          );
          return;
        }
      }
    }
    
    const normalizedDate = new Date(selectedDate);
    if (isNaN(normalizedDate.getTime())) {
      console.error('Neispravan datum:', selectedDate);
      return;
    }
    
    setTimeModalDate(normalizedDate);
    
    if (Platform.OS === 'android' && event.type === 'set') {
      handleTimeConfirm(normalizedDate);
      setSelectedAction(null);
    }
  };

  const handleTimeConfirm = async (selectedTime?: Date) => {
    if (!selectedTime || !selectedAction) {
      return;
    }

    // Resetiramo stanje i zatvaramo modal prije API poziva
    setSelectedAction(null);
    setSelectedSession(null);
    setSelectedBreak(null);
    setShowTimeModal(false);

    try {
      setLoadingAction(true);

      if (selectedAction === 'checkIn') {
        await terminalEndpoints.adminStartSession(employeeId, locationId, selectedTime.toISOString());
      } else if (selectedAction === 'checkOut' && selectedSession) {
        await terminalEndpoints.adminEndSession(selectedSession.id, selectedTime.toISOString());
      } else if (selectedAction === 'editSessionStart' && selectedSession) {
        await terminalEndpoints.adminEditSession(selectedSession.id, selectedTime.toISOString());
      } else if (selectedAction === 'editSessionEnd' && selectedSession) {
        await terminalEndpoints.adminEditSession(selectedSession.id, undefined, selectedTime.toISOString());
      } else if (selectedAction === 'endBreak' && selectedSession && selectedBreak) {
        await terminalEndpoints.adminEndBreak(selectedSession.id, selectedBreak.id, selectedTime.toISOString());
      } else if (selectedAction === 'editBreakStart' && selectedSession && selectedBreak) {
        await terminalEndpoints.adminEditBreak(selectedSession.id, selectedBreak.id, selectedTime.toISOString());
      } else if (selectedAction === 'editBreakEnd' && selectedSession && selectedBreak) {
        await terminalEndpoints.adminEditBreak(selectedSession.id, selectedBreak.id, undefined, selectedTime.toISOString());
      }

      // Dohvati ažurirane sesije
      await fetchSessions();
    } catch (error) {
      console.error('Greška:', error);
      toast.showToast(
        'Došlo je do greške prilikom spremanja vremena',
        'Greška',
        'error'
      );
    } finally {
      setLoadingAction(false);
    }
  };

  // Funkcija za resetiranje stanja modala
  const resetModalState = () => {
    setShowTimeModal(false);
    setSelectedAction(null);
    setSelectedSession(null);
    setSelectedBreak(null);
  };

  const handleBreakTypeSelect = (type: string) => {
    setBreakType(type);
    setShowBreakTypeModal(false);
    setShowTimeModal(true);
  };

  // Dohvati dostupne tipove pauza
  const fetchBreakTypes = async () => {
    try {
      const types = await terminalEndpoints.getBreakTypes();
      
      if (types && types.length > 0) {
        // Izvuci samo imena pauza
        const breakNames = types.map((breakType: any) => breakType.name);
        setBreakTypes(breakNames);
      }
    } catch (error) {
      console.error('Greška kod dohvaćanja tipova pauza:', error);
    }
  };

  const handleDeleteSession = async (session: Session) => {
    // Prikaži potvrdu prije brisanja
    Alert.alert(
      'Potvrda brisanja',
      'Jeste li sigurni da želite obrisati ovaj work block?',
      [
        {
          text: 'Odustani',
          style: 'cancel'
        },
        {
          text: 'Obriši',
          style: 'destructive',
          onPress: async () => {
            try {
              setLoadingAction(true);
              await terminalEndpoints.adminDeleteSession(session.id);
              
              await fetchSessions();
              
              toast.showToast(
                'Work block je uspješno obrisan',
                'Uspjeh',
                'success'
              );
            } catch (error: any) {
              console.error('Greška kod brisanja sesije:', error);
              toast.showToast(
                'Došlo je do greške prilikom brisanja work blocka',
                'Greška',
                'error'
              );
            } finally {
              setLoadingAction(false);
            }
          }
        }
      ]
    );
  };

  return (
    <ScrollView style={styles.container}>
      {/* Komponenta za filtriranje datuma */}
      <DateFilters
        displayDate={displayDate}
        onDateChange={setDisplayDate}
        quickFilters={quickFilters}
        selectedFilter={selectedFilter}
        onFilterSelect={(filter, date) => {
          setSelectedFilter(filter);
          handleQuickFilter(date);
        }}
        showDatePicker={showDatePicker}
        onDatePickerShow={() => {
          setTempDate(new Date(displayDate));
          setShowDatePicker(true);
        }}
        onDatePickerHide={() => setShowDatePicker(false)}
        tempDate={tempDate}
        onTempDateChange={setTempDate}
        onDateConfirm={handleDateConfirm}
        onDateCancel={handleDateCancel}
      />

      {/* Gumb za ručnu prijavu */}
      <Button
        mode="contained"
        icon="login"
        onPress={() => handleAdminAction('checkIn')}
        style={styles.checkInButton}
        loading={loadingAction}
        disabled={loadingAction}
      >
        Ručna prijava
      </Button>

      {/* Lista sesija */}
      <SessionsList
        sessions={sessions}
        loading={loading}
        onCheckOut={(session) => handleAdminAction('checkOut', session)}
        onEndBreak={(session, breakItem) => handleAdminAction('endBreak', session, breakItem)}
        onEditSessionStart={(session) => handleAdminAction('editSessionStart', session)}
        onEditSessionEnd={(session) => handleAdminAction('editSessionEnd', session)}
        onEditBreakStart={(session, breakItem) => handleAdminAction('editBreakStart', session, breakItem)}
        onEditBreakEnd={(session, breakItem) => handleAdminAction('editBreakEnd', session, breakItem)}
        onDeleteSession={(session) => handleDeleteSession(session)}
      />

      {/* Modali */}
      <TimePickerModal
        visible={showTimeModal}
        date={timeModalDate}
        onDateChange={onTimeChange}
        onConfirm={handleTimeConfirm}
        onCancel={() => setShowTimeModal(false)}
        loading={loadingAction}
        title={
          selectedAction === 'checkIn' ? 'Vrijeme prijave' :
          selectedAction === 'checkOut' ? 'Vrijeme odjave' :
          selectedAction === 'endBreak' ? 'Vrijeme završetka pauze' :
          selectedAction === 'editSessionStart' ? 'Uredi vrijeme prijave' :
          selectedAction === 'editSessionEnd' ? 'Uredi vrijeme odjave' :
          selectedAction === 'editBreakStart' ? 'Uredi vrijeme početka pauze' :
          selectedAction === 'editBreakEnd' ? 'Uredi vrijeme završetka pauze' :
          'Odaberi vrijeme'
        }
        minTime={
          selectedAction === 'checkOut' && selectedSession ? 
            new Date(selectedSession.startTime) : 
          selectedAction === 'editSessionEnd' && selectedSession ? 
            new Date(selectedSession.startTime) :
          selectedAction === 'editBreakEnd' && selectedBreak ? 
            new Date(selectedBreak.startTime) :
          selectedAction === 'editBreakStart' && selectedSession ? 
            new Date(selectedSession.startTime) :
          undefined
        }
        maxTime={
          selectedAction === 'editBreakStart' && selectedSession && selectedSession.endTime ? 
            new Date(selectedSession.endTime) : 
          new Date()
        }
      />

      <BreakTypeModal
        visible={showBreakTypeModal}
        onSelect={handleBreakTypeSelect}
        onCancel={() => {
          setShowBreakTypeModal(false);
          setSelectedAction(null);
          setSelectedSession(null);
        }}
        breakTypes={breakTypes}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  sessionContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    elevation: 2,
  },
  sessionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  sessionTime: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  actionButtons: {
    flexDirection: 'row',
  },
  breakContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: '#f5f5f5',
    borderRadius: 4,
    marginVertical: 4,
  },
  breakTime: {
    fontSize: 14,
  },
  breakType: {
    fontSize: 14,
    color: '#666',
  },
  sessionButtons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  actionButton: {
    marginLeft: 8,
  },
  checkInButton: {
    marginBottom: 16,
  },
});
