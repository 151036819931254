import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, TouchableOpacity, Platform, Animated } from 'react-native';
import { Text } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useAuthContext } from '../../../context/AuthContext';
import { useChangelog } from '../../../context/ChangelogContext';

interface Props {
  activeScreen: string;
  isMobile?: boolean;
  onClose?: () => void;
}

export const WebSidebar: React.FC<Props> = ({ activeScreen, isMobile, onClose }) => {
  const navigation = useNavigation();
  const { owner, logout } = useAuthContext();
  const { hasUnreadChangelog, isViewingChangelog } = useChangelog();
  
  const menuItems = [
    {
      name: 'Dashboard',
      icon: 'view-dashboard-outline',
      route: 'Dashboard',
    },
    {
      name: 'Poslovnice',
      icon: 'office-building-outline',
      route: 'Locations',
    },
    {
      name: 'Djelatnici',
      icon: 'account-group-outline',
      route: 'Employees',
    },
    {
      name: 'Pauze',
      icon: 'timer-outline',
      route: 'Breaks',
    },
    {
      name: 'Postavke',
      icon: 'cog-outline',
      route: 'Settings',
    },
  ];
  
  const handleLogout = () => {
    logout();
    if (Platform.OS === 'web') {
      window.location.href = '/';
    }
  };

  const handleNavigate = (route: string) => {
    navigation.navigate(route as never);
    if (isMobile && onClose) {
      onClose();
    }
  };

  const handleAboutClick = () => {
    if (Platform.OS === 'web') {
      window.location.href = '/settings/about';
    } else {
      navigation.navigate('Settings', { screen: 'About' });
    }
  };

  const borderAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.timing(borderAnimation, {
        toValue: 1,
        duration: 4000,
        useNativeDriver: false,
      })
    ).start();
  }, []);

  const borderColor = borderAnimation.interpolate({
    inputRange: [0, 0.2, 0.4, 0.6, 0.8, 1],
    outputRange: ['#4A5568', '#3182CE', '#4A5568', '#2B6CB0', '#4A5568', '#4A5568']
  });

  return (
    <View style={styles.container}>
      {isMobile && (
        <View style={styles.closeButtonContainer}>
          <TouchableOpacity onPress={onClose} style={styles.closeButton}>
            <MaterialCommunityIcons name="close" size={24} color="#E2E8F0" />
          </TouchableOpacity>
        </View>
      )}
      
      <View style={styles.logoContainer}>
        <View style={styles.logoTextContainer}>
          <Text style={styles.logoPart}>clock</Text>
          <Text style={styles.logoDot}>.</Text>
          <Text style={styles.logoHr}>hr</Text>
        </View>
      </View>
      
      <View style={styles.menuContainer}>
        {menuItems.map((item) => (
          <TouchableOpacity
            key={item.name}
            style={[
              styles.menuItem,
              activeScreen === item.route && styles.menuItemActive
            ]}
            onPress={() => handleNavigate(item.route)}
          >
            <MaterialCommunityIcons
              name={item.icon as any}
              size={24}
              color={activeScreen === item.route ? '#fff' : '#E2E8F0'}
            />
            <View style={styles.menuItemContent}>
              <Text
                style={activeScreen === item.route ? styles.menuItemTextActive : styles.menuItemText}
              >
                {item.name}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>

      {hasUnreadChangelog && !isViewingChangelog && (
        <TouchableOpacity 
          onPress={handleAboutClick}
        >
          <Animated.View style={[styles.updateNotice, { borderColor }]}>
            <View style={styles.updateContent}>
              <Text style={styles.updateTitle}>Nova verzija aplikacije</Text>
              <Text style={styles.updateDescription}>
                Kliknite ovdje za pregled{'\n'}novih značajki i poboljšanja
              </Text>
            </View>
          </Animated.View>
        </TouchableOpacity>
      )}
      
      <View style={styles.bottomContainer}>
        <View style={styles.userContainer}>
          <TouchableOpacity style={styles.powerButton} onPress={handleLogout}>
            <MaterialCommunityIcons name="power" size={24} color="#E2E8F0" />
          </TouchableOpacity>
          <View style={styles.userInfo}>
            <Text style={styles.userName}>{owner?.name || 'Korisnik'}</Text>
            <Text style={styles.userRole}>Administrator</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 280,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: '#1A202C',
    height: '100vh',
    margin: 0,
    borderRadius: 0,
  },
  closeButtonContainer: {
    alignItems: 'flex-end',
    marginBottom: 8,
  },
  closeButton: {
    padding: 8,
  },
  logoContainer: {
    marginTop: 16,
    marginBottom: 40,
    alignItems: 'center',
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoPart: {
    fontSize: 28,
    fontWeight: '600',
    color: '#FFFFFF',
    letterSpacing: 1,
  },
  logoDot: {
    fontSize: 28,
    fontWeight: '800',
    color: '#4299E1',
    marginHorizontal: 1,
  },
  logoHr: {
    fontSize: 28,
    fontWeight: '800',
    color: '#FFFFFF',
    letterSpacing: 1,
  },
  menuContainer: {
    flex: 1,
  },
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginBottom: 8,
    borderRadius: 8,
  },
  menuItemActive: {
    backgroundColor: '#4299E1',
  },
  menuItemContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 12,
  },
  menuItemText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#E2E8F0',
  },
  menuItemTextActive: {
    fontSize: 16,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  updateNotice: {
    backgroundColor: '#2D3748',
    borderRadius: 12,
    padding: 16,
    marginBottom: 24,
    borderWidth: 0.8,
  },
  updateContent: {
    flex: 1,
  },
  updateTitle: {
    color: '#FFFFFF',
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 6,
    letterSpacing: 0.3,
  },
  updateDescription: {
    color: '#A0AEC0',
    fontSize: 13,
    lineHeight: 18,
    letterSpacing: 0.2,
  },
  bottomContainer: {
    borderTopWidth: 1,
    borderTopColor: '#2D3748',
    paddingTop: 24,
  },
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  userInfo: {
    flex: 1,
  },
  userName: {
    fontSize: 16,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  userRole: {
    fontSize: 14,
    color: '#A0AEC0',
  },
  powerButton: {
    padding: 8,
    marginRight: 12,
  },
});
