import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useLocationStats } from '../../../hooks/useLocationStats';
import { formatMinutes, formatCurrency } from '../../../utils/formatters';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { ErrorScreen } from '../../../components/ErrorScreen';
import { Location } from '../../../types/location';

interface Props {
  locations: Location[];
}

export const LocationStatsGrid: React.FC<Props> = ({ locations }) => {
  const theme = useTheme();
  const { data: stats, isLoading, error, refetch } = useLocationStats();

  React.useEffect(() => {
    refetch();
  }, [locations]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!stats || !locations) {
    return null;
  }

  // Odredi boju za performanse
  const getPerformanceColor = (performance: number) => {
    if (performance >= 95) return '#2E7D32';
    if (performance >= 85) return '#37474F';
    return '#FF0000';
  };

  return (
    <View style={styles.container}>
      {locations.map(location => {
        const locationStats = stats?.[location._id] || {
          performance: 0,
          totalWorkMinutes: 0,
          totalBreakMinutes: 0,
          totalEarnings: 0
        };

        const performanceValue = parseFloat(locationStats.performance?.toString() || '0');
        const performanceColor = getPerformanceColor(performanceValue);

        return (
          <Card 
            key={location._id} 
            style={[
              styles.card, 
              { 
                backgroundColor: theme.colors.surface,
                borderRadius: 20,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 4,
                },
                shadowOpacity: 0.15,
                shadowRadius: 8,
                elevation: 8,
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,0.05)',
              }
            ]} 
          >
            <Card.Content style={styles.cardContent}>
              <View style={styles.headerContainer}>
                <Text style={styles.locationName}>
                  {location.name}
                </Text>
              </View>
              
              <View style={styles.statsContainer}>
                <View style={styles.row}>
                  {/* Performanse */}
                  <View style={[styles.statItem]}>
                    <View style={[styles.iconContainer, { 
                      backgroundColor: 'rgba(55, 71, 79, 0.08)',
                      borderWidth: 1,
                      borderColor: 'rgba(55, 71, 79, 0.12)',
                    }]}>
                      <MaterialCommunityIcons 
                        name="chart-line" 
                        size={24} 
                        color={'#2E7D32'}
                      />
                    </View>
                    <Text style={[styles.label, { color: '#37474F' }]}>Performanse</Text>
                    <Text style={[styles.value, { color: performanceColor }]}>
                      {locationStats.performance}%
                    </Text>
                  </View>

                  {/* Aktivni radnici */}
                  <View style={[styles.statItem]}>
                    <View style={[styles.iconContainer, { 
                      backgroundColor: 'rgba(55, 71, 79, 0.08)',
                      borderWidth: 1,
                      borderColor: 'rgba(55, 71, 79, 0.12)',
                    }]}>
                      <MaterialCommunityIcons 
                        name="account-group" 
                        size={24} 
                        color={'#37474F'}
                      />
                    </View>
                    <Text style={[styles.label, { color: '#37474F' }]}>Na poslu</Text>
                    <Text style={[styles.value, { color: '#37474F' }]}>
                      {locationStats.activeEmployees}
                    </Text>
                  </View>

                  {/* Ukupno djelatnika */}
                  <View style={[styles.statItem]}>
                    <View style={[styles.iconContainer, { 
                      backgroundColor: 'rgba(55, 71, 79, 0.08)',
                      borderWidth: 1,
                      borderColor: 'rgba(55, 71, 79, 0.12)',
                    }]}>
                      <MaterialCommunityIcons 
                        name="account-multiple" 
                        size={24} 
                        color={'#37474F'}
                      />
                    </View>
                    <Text style={[styles.label, { color: '#37474F' }]}>Ukupno</Text>
                    <Text style={[styles.value, { color: '#37474F' }]}>
                      {locationStats.totalEmployees}
                    </Text>
                  </View>
                </View>
              </View>
            </Card.Content>
          </Card>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  card: {
    marginBottom: 16,
  },
  cardContent: {
    padding: 24,
  },
  headerContainer: {
    marginBottom: 24,
  },
  locationName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  statsContainer: {
    paddingHorizontal: 8,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 24,
  },
  statItem: {
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  label: {
    fontSize: 14,
    opacity: 0.7,
    marginBottom: 6,
    textAlign: 'center',
  },
  value: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
