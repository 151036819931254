import React from 'react';
import { View, Text, StyleSheet, useWindowDimensions, Image } from 'react-native';
import { HelpLayout } from '../components/HelpLayout';
import { Ionicons } from '@expo/vector-icons';

const Feature = ({ icon, title, description }: { icon: string; title: string; description: string }) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <View style={[styles.feature, isMobile && styles.featureMobile]}>
      <View style={styles.featureIcon}>
        <Ionicons name={icon as any} size={24} color="#4C6FFF" />
      </View>
      <View style={styles.featureContent}>
        <Text style={styles.featureTitle}>{title}</Text>
        <Text style={styles.featureDescription}>{description}</Text>
      </View>
    </View>
  );
};

export const AboutUs = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const features = [
    {
      icon: 'time-outline',
      title: 'Evidencija radnog vremena',
      description: 'Naše rješenje omogućuje jednostavno i precizno praćenje radnog vremena zaposlenika. Sustav automatski bilježi dolaske, odlaske i pauze, čime se eliminira potreba za ručnim vođenjem evidencije.'
    },
    {
      icon: 'stats-chart-outline',
      title: 'Napredna analitika',
      description: 'Generirajte detaljne izvještaje o radnim satima, prekovremenom radu, bolovanjima i godišnjim odmorima. Naša analitika pomaže vam u optimizaciji radnih procesa i donošenju informiranih odluka.'
    },
    {
      icon: 'location-outline',
      title: 'Više poslovnica',
      description: 'Clock.hr je dizajniran za tvrtke s jednom ili više lokacija. Jednostavno pratite i upravljajte evidencijom rada za sve vaše poslovnice kroz jedinstveno sučelje, uz mogućnost zasebnih postavki za svaku lokaciju.'
    },
    {
      icon: 'phone-portrait-outline',
      title: 'Mobilna dostupnost',
      description: 'Pristupite svojim podacima bilo kada i bilo gdje putem naše moderne mobilne Android aplikacije. Zaposlenici mogu prijaviti dolazak ili odlazak s posla isključivo na terminalu u poslovnici ili to možete učinit Vi.'
    },
    {
      icon: 'shield-checkmark-outline',
      title: 'Sigurnost i usklađenost',
      description: 'Vaši podaci su zaštićeni najmodernijim sigurnosnim protokolima.'
    },
    {
      icon: 'cloud-outline',
      title: 'Cloud tehnologija',
      description: 'Bez potrebe za skupom IT infrastrukturom - naše cloud rješenje omogućuje vam trenutni pristup svim funkcionalnostima kroz web preglednik, uz automatske nadogradnje i sigurnosne kopije podataka.'
    }
  ];

  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Tko smo mi?</Text>
            <Text style={styles.sectionText}>
              Clock.hr je nastao iz potrebe za modernim i jednostavnim sustavom evidencije radnog vremena prilagođenim hrvatskom tržištu. 
              Naš tim stručnjaka kombinira dugogodišnje iskustvo u razvoju aplikacija s dubokim razumijevanjem potreba hrvatskih poslodavaca 
              i zakonskih regulativa.
            </Text>
            <Text style={[styles.sectionText, { marginTop: 16 }]}>
              Od malih poduzeća do velikih korporacija, pomažemo tvrtkama diljem Hrvatske da digitaliziraju i optimiziraju svoje procese 
              praćenja radnog vremena, čime štede vrijeme i resurse.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Naše rješenje</Text>
            <View style={[styles.featuresGrid, isMobile && styles.featuresGridMobile]}>
              {features.map((feature, index) => (
                <Feature key={index} {...feature} />
              ))}
            </View>
          </View>

          <View style={[styles.section, styles.lastSection]}>
            <Text style={styles.sectionTitle}>Naša predanost</Text>
            <Text style={styles.sectionText}>
              U Clock.hr-u vjerujemo da svaka hrvatska tvrtka zaslužuje pristup modernoj tehnologiji koja će olakšati 
              svakodnevno poslovanje. Kontinuirano radimo na poboljšanju našeg sustava, osluškujući potrebe korisnika 
              i prateći najnovije tehnološke trendove.
            </Text>
            <Text style={[styles.sectionText, { marginTop: 16 }]}>
              Naš cilj je ne samo pružiti aplikaciju, već biti partner u digitalnoj transformaciji vašeg poslovanja. 
              Ponosni smo što već brojnim hrvatskim tvrtkama pomažemo u modernizaciji njihovih procesa i radujemo 
              se mogućnosti da i vama pokažemo kako jednostavna i učinkovita evidencija radnog vremena može biti.
            </Text>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
  },
  section: {
    marginBottom: 48,
    paddingBottom: 48,
    borderBottomWidth: 1,
    borderBottomColor: '#F1F5F9',
  },
  lastSection: {
    marginBottom: 0,
    paddingBottom: 0,
    borderBottomWidth: 0,
  },
  sectionTitle: {
    fontSize: 28,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 24,
  },
  sectionText: {
    fontSize: 16,
    lineHeight: 28,
    color: '#475569',
  },
  featuresGrid: {
    flexDirection: 'column',
    gap: 32,
  },
  featuresGridMobile: {
    gap: 24,
  },
  feature: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 20,
  },
  featureMobile: {
    flexDirection: 'column',
    gap: 16,
  },
  featureIcon: {
    width: 48,
    height: 48,
    borderRadius: 12,
    backgroundColor: '#F0F3FF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  featureContent: {
    flex: 1,
  },
  featureTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 8,
  },
  featureDescription: {
    fontSize: 16,
    lineHeight: 24,
    color: '#475569',
  },
});
