import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Button } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

interface Props {
  title: string;
  description: string;
  actionText: string;
  iconName: string;
  navigationRoute: string;
}

export const WebEmptyState: React.FC<Props> = ({ 
  title, 
  description, 
  actionText, 
  iconName, 
  navigationRoute 
}) => {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <MaterialCommunityIcons 
          name={iconName as any} 
          size={64} 
          color="#A0AEC0" 
          style={styles.icon} 
        />
        <Text style={styles.title}>
          {title}
        </Text>
        <Text style={styles.description}>
          {description}
        </Text>
        <Button 
          mode="contained" 
          onPress={() => navigation.navigate(navigationRoute as never)}
          style={styles.actionButton}
          contentStyle={styles.actionButtonContent}
          labelStyle={{ color: '#FFFFFF' }}
          buttonColor="#1F2937"
          icon={({ size, color }) => (
            <MaterialCommunityIcons name="domain-plus" size={20} color={color} />
          )}
        >
          {actionText}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 500,
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    padding: 32,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  icon: {
    marginBottom: 24,
  },
  title: {
    fontSize: 22,
    fontWeight: '600',
    color: '#2D3748',
    textAlign: 'center',
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    color: '#718096',
    textAlign: 'center',
    marginBottom: 32,
    paddingHorizontal: 20,
    lineHeight: 24,
  },
  actionButton: {
    borderRadius: 8,
    width: 200,
    height: 40,
  },
  actionButtonContent: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
  },
});
