import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface ChangelogContextType {
  hasUnreadChangelog: boolean;
  isViewingChangelog: boolean;
  markChangelogAsRead: () => Promise<void>;
  setIsViewingChangelog: (viewing: boolean) => void;
  checkChangelogStatus: () => Promise<void>;
}

const ChangelogContext = createContext<ChangelogContextType | undefined>(undefined);

export const useChangelog = () => {
  const context = useContext(ChangelogContext);
  if (!context) {
    throw new Error('useChangelog mora biti korišten unutar ChangelogProvider-a');
  }
  return context;
};

export const ChangelogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasUnreadChangelog, setHasUnreadChangelog] = useState(false);
  const [isViewingChangelog, setIsViewingChangelog] = useState(false);

  const getAuthToken = async () => {
    try {
      const token = await AsyncStorage.getItem('@auth_token');
      console.log('Token:', token ? 'Postoji' : 'Ne postoji');
      return token;
    } catch (error) {
      console.error('Greška pri dohvaćanju tokena:', error);
      return null;
    }
  };

  const checkChangelogStatus = async () => {
    console.log('Provjera changelog statusa...');
    try {
      const token = await getAuthToken();
      if (!token) {
        console.log('Nema tokena, prekidam provjeru');
        return;
      }

      console.log('Šaljem zahtjev na:', `${API_URL}/changelog/status`);
      const response = await axios.get(`${API_URL}/changelog/status`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Odgovor s backenda:', response.data);
      setHasUnreadChangelog(response.data.hasUnread);
    } catch (error) {
      console.error('Greška pri provjeri changelog statusa:', error);
    }
  };

  const markChangelogAsRead = async () => {
    try {
      const token = await getAuthToken();
      if (!token) return;

      await axios.post(`${API_URL}/changelog/read`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setHasUnreadChangelog(false);
    } catch (error) {
      console.error('Greška pri označavanju changelog-a kao pročitanog:', error);
    }
  };

  useEffect(() => {
    console.log('ChangelogProvider useEffect se pokreće');
    checkChangelogStatus();
  }, []);

  return (
    <ChangelogContext.Provider
      value={{
        hasUnreadChangelog,
        isViewingChangelog,
        markChangelogAsRead,
        setIsViewingChangelog,
        checkChangelogStatus,
      }}
    >
      {children}
    </ChangelogContext.Provider>
  );
};
