import React from 'react';
import { Helmet } from 'react-helmet';
import { generateOrganizationSchema, generateSoftwareApplicationSchema } from '../utils/seoHelpers';

interface SchemaMarkupProps {
  type: 'organization' | 'software' | 'both';
}

export const SchemaMarkup: React.FC<SchemaMarkupProps> = ({ type }) => {
  const getSchemaMarkup = () => {
    switch (type) {
      case 'organization':
        return [generateOrganizationSchema()];
      case 'software':
        return [generateSoftwareApplicationSchema()];
      case 'both':
        return [generateOrganizationSchema(), generateSoftwareApplicationSchema()];
      default:
        return [];
    }
  };

  const schemas = getSchemaMarkup();

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({ '@graph': schemas })}
      </script>
    </Helmet>
  );
};