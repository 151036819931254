import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity, Platform, Modal, TouchableWithoutFeedback } from 'react-native';
import { Text, Button } from 'react-native-paper';
import { format, subDays } from 'date-fns';
import { hr } from 'date-fns/locale';
import { MaterialIcons } from '@expo/vector-icons';
import { employeesAPI } from '../../../services/api';
import { useQuery } from '@tanstack/react-query';
import DateTimePicker from '@react-native-community/datetimepicker';

interface Break {
  breakId: string;
  startTime: string;
  endTime?: string;
  durationMinutes: number;
  allowedMinutes: number;
  overMinutes: number;
  paid: boolean;
  breakName: string;
}

interface WorkBlock {
  id: string;
  startTime: string;
  endTime?: string;
  deviceId: string;
  crossesMidnight: boolean;
  breaks: Break[];
  hourlyRate: number;
  stats: {
    workMinutes: number;
    breakMinutes: number;
    overBreakMinutes: number;
    effectiveMinutes: number;
    paidMinutes: number;
    totalPaidBreakMinutes: number;
    totalUnpaidBreakMinutes: number;
    overPaidBreakMinutes: number;
    overUnpaidBreakMinutes: number;
    hourlyRate: number;
    earnings: number;
    deductions: number;
  };
}

interface DailyStats {
  totalWorkMinutes: number;
  effectiveWorkMinutes: number;
  totalBreakMinutes: number;
  overBreakMinutes: number;
  paidMinutes: number;
  totalPaidBreakMinutes: number;
  totalUnpaidBreakMinutes: number;
  overPaidBreakMinutes: number;
  overUnpaidBreakMinutes: number;
  hourlyRate: number;
  earnings: number;
  deductions: number;
}

interface Props {
  employeeId: string;
}

const formatTime = (date: string) => {
  return format(new Date(date), 'HH:mm', { locale: hr });
};

const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes - Math.floor(minutes)) * 60);
  
  if (hours === 0) {
    if (secs === 0) return `${mins}min`;
    return `${mins}min ${secs}sek`;
  }
  
  if (secs === 0) return `${hours}h ${mins}min`;
  return `${hours}h ${mins}min ${secs}sek`;
};

const formatMoney = (amount: number | undefined) => {
  if (amount === undefined) return '0.00 €';
  return `${amount.toFixed(2)} €`;
};

const BreakItem: React.FC<{ breakData: Break }> = ({ breakData }) => (
  <View style={styles.breakRow}>
    <View style={styles.breakHeader}>
      <Text style={styles.breakTitle}>
        {formatTime(breakData.startTime)} - {breakData.endTime ? formatTime(breakData.endTime) : 'U tijeku'}
      </Text>
    </View>

    <View style={styles.breakDetail}>
      <Text style={styles.breakDetailLabel}>Trajanje:</Text>
      <Text style={styles.breakDetailValue}>{formatDuration(breakData.durationMinutes || 0)}</Text>
    </View>
    <View style={styles.breakDetail}>
      <Text style={styles.breakDetailLabel}>Dozvoljeno:</Text>
      <Text style={styles.breakDetailValue}>{formatDuration(breakData.allowedMinutes || 0)}</Text>
    </View>
    {(breakData.overMinutes || 0) > 0 && (
      <View style={styles.breakDetail}>
        <Text style={styles.breakDetailLabel}>Prekoračenje:</Text>
        <Text style={[styles.breakDetailValue, styles.warning]}>{formatDuration(breakData.overMinutes || 0)}</Text>
      </View>
    )}
    <View style={styles.breakDetail}>
      <Text style={styles.breakDetailLabel}>Naziv pauze:</Text>
      <Text style={styles.breakDetailValue}>{breakData.breakName}</Text>
    </View>
  </View>
);

export const EmployeeStats: React.FC<Props> = ({ employeeId }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState(new Date());
  const [displayDate, setDisplayDate] = useState(new Date());

  const { data: stats, isLoading } = useQuery({
    queryKey: ['employeeStats', employeeId, format(displayDate, 'yyyy-MM-dd')],
    queryFn: () => employeesAPI.getEmployeeStats(employeeId, format(displayDate, 'yyyy-MM-dd')),
  });

  const quickFilters = [
    { label: 'Danas', date: new Date() },
    { label: 'Jučer', date: subDays(new Date(), 1) },
    { label: 'Prekjučer', date: subDays(new Date(), 2) },
  ];

  const handleQuickFilter = (newDate: Date) => {
    setDisplayDate(newDate);
    setTempDate(newDate);
  };

  const handleDateChange = (event: any, selectedDate?: Date) => {
    if (Platform.OS === 'android') {
      setShowDatePicker(false);
    }
    
    if (selectedDate) {
      setTempDate(selectedDate);
      
      if (Platform.OS === 'android') {
        setDisplayDate(selectedDate);
      }
    }
  };

  const handleSave = () => {
    setDisplayDate(tempDate);
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setTempDate(displayDate);
    setShowDatePicker(false);
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Učitavanje...</Text>
      </View>
    );
  }

  if (!stats) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Nema podataka za odabrani datum</Text>
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <TouchableOpacity 
        style={styles.header}
        onPress={() => {
          setTempDate(displayDate);
          setShowDatePicker(true);
        }}
      >
        <View style={styles.headerContent}>
          <Text style={styles.headerTitle}>
            Statistika rada za {format(displayDate, 'dd.MM.yyyy.', { locale: hr })}
          </Text>
          <MaterialIcons name="date-range" size={20} color="#2563eb" style={styles.calendarIcon} />
        </View>
      </TouchableOpacity>

      <ScrollView 
        horizontal 
        showsHorizontalScrollIndicator={false}
        style={styles.filtersContainer}
        contentContainerStyle={styles.filtersContent}
      >
        {quickFilters.map((filter, index) => (
          <TouchableOpacity
            key={filter.label}
            style={[
              styles.filterChip,
              format(filter.date, 'yyyy-MM-dd') === format(displayDate, 'yyyy-MM-dd') && styles.activeFilterChip
            ]}
            onPress={() => handleQuickFilter(filter.date)}
          >
            <Text style={[
              styles.filterText,
              format(filter.date, 'yyyy-MM-dd') === format(displayDate, 'yyyy-MM-dd') && styles.activeFilterText
            ]}>
              {filter.label}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>

      {showDatePicker && (
        <Modal
          visible={showDatePicker}
          transparent={true}
          animationType="fade"
        >
          <TouchableWithoutFeedback onPress={handleCancel}>
            <View style={styles.modalOverlay}>
              <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>Odaberi datum</Text>
                
                {Platform.OS === 'ios' ? (
                  <DateTimePicker
                    testID="dateTimePicker"
                    value={tempDate}
                    mode="date"
                    display="spinner"
                    onChange={handleDateChange}
                    style={{ width: 320, height: 180 }}
                    locale="hr"
                  />
                ) : (
                  <DateTimePicker
                    testID="dateTimePicker"
                    value={tempDate}
                    mode="date"
                    display="default"
                    onChange={handleDateChange}
                    locale="hr"
                  />
                )}
                
                <View style={styles.buttonContainer}>
                  <Button 
                    mode="outlined" 
                    onPress={handleCancel}
                    style={{ marginRight: 10 }}
                  >
                    Odustani
                  </Button>
                  
                  {Platform.OS === 'ios' && (
                    <Button 
                      mode="contained" 
                      onPress={handleSave}
                    >
                      Spremi
                    </Button>
                  )}
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      )}

      <View style={styles.section}>
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Generalno</Text>
          <View style={styles.statRow}>
            <Text style={styles.label}>Ukupno vrijeme:</Text>
            <Text style={styles.value}>{formatDuration(stats.dailyStats?.totalWorkMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Efektivno vrijeme:</Text>
            <Text style={styles.value}>{formatDuration(stats.dailyStats?.effectiveWorkMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Plaćeno vrijeme:</Text>
            <Text style={styles.value}>{formatDuration(stats.dailyStats?.paidMinutes || 0)}</Text>
          </View>
        </View>

        <View style={styles.card}>
          <Text style={styles.cardTitle}>Pauze</Text>
          <View style={styles.statRow}>
            <Text style={styles.label}>Ukupno pauza:</Text>
            <Text style={styles.value}>{formatDuration(stats.dailyStats?.totalBreakMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Plaćene pauze:</Text>
            <Text style={styles.value}>{formatDuration(stats.dailyStats?.totalPaidBreakMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Neplaćene pauze:</Text>
            <Text style={styles.value}>{formatDuration(stats.dailyStats?.totalUnpaidBreakMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Prekoračenje ukupno:</Text>
            <Text style={[styles.value, styles.warning]}>{formatDuration(stats.dailyStats?.overBreakMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Prekoračenje plaćenih:</Text>
            <Text style={[styles.value, styles.warning]}>{formatDuration(stats.dailyStats?.overPaidBreakMinutes || 0)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Prekoračenje neplaćenih:</Text>
            <Text style={[styles.value, styles.warning]}>{formatDuration(stats.dailyStats?.overUnpaidBreakMinutes || 0)}</Text>
          </View>
        </View>

        <View style={styles.card}>
          <Text style={styles.cardTitle}>Zarada</Text>
          <View style={styles.statRow}>
            <Text style={styles.label}>Satnica:</Text>
            <Text style={styles.value}>{formatMoney(stats.dailyStats?.hourlyRate)}/h</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Bruto zarada:</Text>
            <Text style={styles.value}>{formatMoney((stats.dailyStats?.earnings || 0) + (stats.dailyStats?.deductions || 0))}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Odbici:</Text>
            <Text style={[styles.value, styles.warning]}>{formatMoney(stats.dailyStats?.deductions)}</Text>
          </View>
          <View style={styles.statRow}>
            <Text style={styles.label}>Neto zarada:</Text>
            <Text style={styles.value}>{formatMoney(stats.dailyStats?.earnings)}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.cardTitle}>Radni blokovi</Text>
        {stats.workBlocks?.map((block, index) => (
          <View key={block.id} style={styles.card}>
            <View style={styles.detailsHeader}>
              <Text style={styles.detailsTitle}>Radni blok {index + 1}</Text>
              <Text style={styles.timeRange}>
                {formatTime(block.startTime)} - {block.endTime ? formatTime(block.endTime) : 'U tijeku'}
              </Text>
            </View>

            <View style={styles.statRow}>
              <Text style={styles.label}>Vrijeme rada:</Text>
              <Text style={styles.value}>{formatDuration(block.stats?.workMinutes || 0)}</Text>
            </View>
            <View style={styles.statRow}>
              <Text style={styles.label}>Efektivno vrijeme:</Text>
              <Text style={styles.value}>{formatDuration(block.stats?.effectiveMinutes || 0)}</Text>
            </View>
            <View style={styles.statRow}>
              <Text style={styles.label}>Plaćeno vrijeme:</Text>
              <Text style={styles.value}>{formatDuration(block.stats?.paidMinutes || 0)}</Text>
            </View>

            {block.breaks && block.breaks.length > 0 && (
              <>
                <View style={styles.divider} />
                <Text style={styles.cardTitle}>Pauze</Text>
                {block.breaks.map((breakItem, breakIndex) => (
                  <BreakItem key={`${breakItem.breakId}-${breakIndex}`} breakData={breakItem} />
                ))}
              </>
            )}

            <View style={styles.divider} />
            <Text style={styles.cardTitle}>Zarada</Text>
            <View style={styles.statRow}>
              <Text style={styles.label}>Satnica:</Text>
              <Text style={styles.value}>{formatMoney(block.hourlyRate)}/h</Text>
            </View>
            <View style={styles.statRow}>
              <Text style={styles.label}>Bruto zarada:</Text>
              <Text style={styles.value}>{formatMoney((block.stats?.earnings || 0) + (block.stats?.deductions || 0))}</Text>
            </View>
            {(block.stats?.deductions || 0) > 0 && (
              <View style={styles.statRow}>
                <Text style={styles.label}>Odbici:</Text>
                <Text style={[styles.value, styles.warning]}>{formatMoney(block.stats?.deductions)}</Text>
              </View>
            )}
            <View style={styles.statRow}>
              <Text style={styles.label}>Neto zarada:</Text>
              <Text style={styles.value}>{formatMoney(block.stats?.earnings)}</Text>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    padding: 16,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    backgroundColor: '#f8fafc',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: '500',
  },
  calendarIcon: {
    marginLeft: 8,
  },
  filtersContainer: {
    marginBottom: 16,
  },
  filtersContent: {
    paddingRight: 8,
  },
  filterChip: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    backgroundColor: '#f1f5f9',
    borderRadius: 16,
    marginRight: 8,
  },
  activeFilterChip: {
    backgroundColor: '#dbeafe',
  },
  filterText: {
    fontSize: 13,
    color: '#334155',
    fontWeight: '500',
  },
  section: {
    marginBottom: 16,
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    padding: 16,
    marginBottom: 12,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 1,
    elevation: 1,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 12,
    color: '#1e293b',
  },
  statRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#f1f5f9',
  },
  label: {
    fontSize: 14,
    color: '#64748b',
  },
  value: {
    fontSize: 14,
    fontWeight: '500',
    color: '#334155',
  },
  warning: {
    color: '#ef4444',
  },
  success: {
    color: '#10b981',
  },
  detailsCard: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    padding: 16,
    marginBottom: 12,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 1,
    elevation: 1,
  },
  detailsHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  detailsTitle: {
    fontSize: 15,
    fontWeight: '600',
    color: '#1e293b',
  },
  detailsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 6,
  },
  detailsLabel: {
    fontSize: 13,
    color: '#64748b',
  },
  detailsValue: {
    fontSize: 13,
    fontWeight: '500',
    color: '#334155',
  },
  breakRow: {
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#f1f5f9',
  },
  breakHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  breakTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: '#1e293b',
  },
  breakTime: {
    fontSize: 13,
    color: '#64748b',
  },
  breakDetail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  breakDetailLabel: {
    fontSize: 13,
    color: '#64748b',
  },
  breakDetailValue: {
    fontSize: 13,
    fontWeight: '500',
    color: '#334155',
  },
  earningSummary: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 12,
    marginTop: 8,
    borderTopWidth: 1,
    borderTopColor: '#e2e8f0',
  },
  summaryLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: '#1e293b',
  },
  summaryValue: {
    fontSize: 14,
    fontWeight: '600',
    color: '#10b981',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  dateButtons: {
    flexDirection: 'column',
    marginBottom: 20,
    width: '100%',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
});
