import { useState, useEffect, useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../services/api';
import { NavigationProp } from '@react-navigation/native';

interface AuthData {
  email: string;
  password: string;
}

interface RegisterData extends AuthData {
  name: string;
}

interface Owner {
  id: string;
  name: string;
  email: string;
}

interface Company {
  name: string;
  oib: string;
  country: string;
  city: string;
  address: string;
  responsiblePerson: string;
}

interface AuthState {
  token: string | null;
  owner: Owner | null;
  company: Company | null;
  isAuthenticated: boolean;
}

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    token: null,
    owner: null,
    company: null,
    isAuthenticated: false
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const initialLoadDone = useRef(false);
  const justLoggedIn = useRef(false);

  useEffect(() => {
  }, [authState.isAuthenticated]);

  // Učitaj token samo jednom pri pokretanju
  useEffect(() => {
    if (!initialLoadDone.current && !authState.isAuthenticated && !justLoggedIn.current) {
      loadToken();
      initialLoadDone.current = true;
    }
  }, []);

  const loadToken = async () => {
    try {
      const storedToken = await AsyncStorage.getItem('@auth_token');
      const storedOwner = await AsyncStorage.getItem('@auth_owner');
      const storedCompany = await AsyncStorage.getItem('@auth_company');
      
      if (storedToken && storedOwner) {
        const parsedOwner = JSON.parse(storedOwner);
        const parsedCompany = storedCompany ? JSON.parse(storedCompany) : null;
        
        setAuthState({
          token: storedToken,
          owner: parsedOwner,
          company: parsedCompany,
          isAuthenticated: true
        });
        
        api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      }
    } catch (err) {
      setError('Greška pri učitavanju tokena');
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (data: AuthData): Promise<{success: boolean; error?: string}> => {
    try {
      const response = await api.post('/auth/login', data);
      const { token: newToken, owner: newOwner } = response.data;

      // Prvo postavljamo API headers i spremamo u AsyncStorage
      api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      
      await Promise.all([
        AsyncStorage.setItem('@auth_token', newToken),
        AsyncStorage.setItem('@auth_owner', JSON.stringify(newOwner))
      ]);

      // Označavamo da smo upravo prijavili korisnika
      justLoggedIn.current = true;

      setAuthState({
        token: newToken,
        owner: newOwner,
        company: null,
        isAuthenticated: true
      });

      return { success: true };
    } catch (err: any) {
      setError(err.response?.data?.message || 'Neispravni podaci za prijavu');
      return { 
        success: false, 
        error: err.response?.data?.message || 'Neispravni podaci za prijavu'
      };
    }
  };

  const register = async (data: RegisterData): Promise<{success: boolean; error?: string}> => {
    try {
      const response = await api.post('/auth/register', data);
      const { token: newToken, owner: newOwner } = response.data;

      await AsyncStorage.setItem('@auth_token', newToken);
      await AsyncStorage.setItem('@auth_owner', JSON.stringify(newOwner));

      api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

      setAuthState({
        token: newToken,
        owner: newOwner,
        company: null,
        isAuthenticated: true
      });

      return { success: true };
    } catch (err: any) {
      setError(err.response?.data?.message || 'Neispravni podaci za registraciju');
      return { 
        success: false, 
        error: err.response?.data?.message || 'Neispravni podaci za registraciju'
      };
    }
  };

  const logout = async (navigation?: NavigationProp<any>) => {
    try {
      await AsyncStorage.multiRemove(['@auth_token', '@auth_owner', '@auth_company']);
      
      delete api.defaults.headers.common['Authorization'];
      
      setAuthState({
        token: null,
        owner: null,
        company: null,
        isAuthenticated: false
      });

      // Resetiramo zastavice
      initialLoadDone.current = false;
      justLoggedIn.current = false;
      
      // Pričekaj da se state ažurira prije navigacije
      if (navigation) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Login' }],
          });
        }, 100);
      }
    } catch (err) {
      setError('Greška pri odjavi');
    }
  };

  const setCompanyData = async (companyData: Company) => {
    try {
      await AsyncStorage.setItem('@auth_company', JSON.stringify(companyData));
      setAuthState(prev => ({
        ...prev,
        company: companyData
      }));
    } catch (err) {
      setError('Greška pri postavljanju podataka tvrtke');
    }
  };

  const setOwner = async (ownerData: Owner) => {
    try {
      await AsyncStorage.setItem('@auth_owner', JSON.stringify(ownerData));
      setAuthState(prev => ({
        ...prev,
        owner: ownerData
      }));
    } catch (err) {
      setError('Greška pri postavljanju podataka vlasnika');
    }
  };

  return {
    isAuthenticated: authState.isAuthenticated,
    token: authState.token,
    owner: authState.owner,
    company: authState.company,
    isLoading,
    error,
    login,
    register,
    logout,
    setCompanyData,
    setOwner
  };
};
