import React from 'react';
import { View, StyleSheet, Modal, TouchableWithoutFeedback, Platform, FlatList } from 'react-native';
import { Text, Button, List, ActivityIndicator } from 'react-native-paper';

interface BreakTypeModalProps {
  visible: boolean;
  breakTypes: string[];
  onSelect: (type: string) => void;
  onCancel: () => void;
  loading: boolean;
}

export const BreakTypeModal: React.FC<BreakTypeModalProps> = ({
  visible,
  breakTypes,
  onSelect,
  onCancel,
  loading
}) => {
  
  // Funkcija za odabir koja će se izvršiti samo jednom
  const handleSelect = (type: string) => {
    if (!loading) {
      onSelect(type);
    }
  };

  // Funkcija za odustajanje koja će se izvršiti samo jednom
  const handleCancel = () => {
    if (!loading) {
      onCancel();
    }
  };

  return (
    <Modal 
      visible={visible} 
      transparent 
      animationType="fade"
      onRequestClose={handleCancel}
    >
      <TouchableWithoutFeedback onPress={handleCancel}>
        <View style={styles.modalOverlay}>
          <TouchableWithoutFeedback>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Odaberi tip pauze</Text>
              
              {breakTypes.length === 0 ? (
                <Text style={styles.noBreaksText}>Nema dostupnih tipova pauza</Text>
              ) : (
                <FlatList
                  data={breakTypes}
                  keyExtractor={(item, index) => `break-${index}`}
                  renderItem={({ item }) => (
                    <List.Item
                      title={item}
                      onPress={() => handleSelect(item)}
                      style={styles.listItem}
                      titleStyle={styles.listItemTitle}
                      left={props => <List.Icon {...props} icon="clock-outline" />}
                    />
                  )}
                  style={styles.breakList}
                />
              )}
              
              <View style={styles.buttonContainer}>
                {loading ? (
                  <ActivityIndicator size="small" style={styles.loader} />
                ) : null}
                
                <Button
                  mode="outlined"
                  onPress={handleCancel}
                  disabled={loading}
                >
                  Odustani
                </Button>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    maxHeight: '70%'
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center'
  },
  breakList: {
    maxHeight: 300
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#f0f0f0'
  },
  listItemTitle: {
    fontSize: 16
  },
  noBreaksText: {
    textAlign: 'center',
    marginVertical: 20,
    fontStyle: 'italic',
    color: '#666'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 20
  },
  loader: {
    marginHorizontal: 10
  }
});
