import React, { useState, useEffect } from 'react';
import { View, Modal, TouchableWithoutFeedback } from 'react-native';
import { Text, Button, ActivityIndicator, useTheme } from 'react-native-paper';
import { WebCustomTimePicker } from './WebCustomTimePicker';

interface WebTimePickerModalProps {
  visible: boolean;
  date: Date;
  onDateChange: (date: Date) => void;
  onConfirm: (date: Date) => void;
  onCancel: () => void;
  loading: boolean;
  title: string;
  minTime?: Date;
  maxTime?: Date;
}

export const WebTimePickerModal: React.FC<WebTimePickerModalProps> = ({
  visible,
  date,
  onDateChange,
  onConfirm,
  onCancel,
  loading,
  title,
  minTime,
  maxTime
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(date);

  // Reset selectedDate kada se modal otvori
  useEffect(() => {
    if (visible) {
      setSelectedDate(date);
    }
  }, [visible, date]);

  const handleTimeChange = (newDate: Date) => {
    setSelectedDate(newDate);
    onDateChange(newDate);
  };

  const handleConfirm = () => {
    if (!loading) {
      onConfirm(selectedDate);
    }
  };

  if (!visible) return null;

  return (
    <Modal 
      visible={visible} 
      transparent 
      animationType="fade"
      onRequestClose={onCancel}
    >
      <TouchableWithoutFeedback onPress={onCancel}>
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }}>
          <TouchableWithoutFeedback>
            <View style={{
              borderRadius: 16,
              padding: 20,
              width: '90%',
              maxWidth: 360,
              alignItems: 'center',
              backgroundColor: theme.colors.surface,
              elevation: 5,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84
            }}>
              <Text style={{
                fontSize: 18,
                fontWeight: 'bold',
                marginBottom: 16,
                textAlign: 'center',
                color: theme.colors.primary
              }}>{title}</Text>
              
              <WebCustomTimePicker
                value={selectedDate}
                onChange={handleTimeChange}
                minTime={minTime}
                maxTime={maxTime}
              />
              
              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 20
              }}>
                <Button
                  mode="outlined"
                  onPress={onCancel}
                  disabled={loading}
                  style={{
                    minWidth: 100,
                    borderRadius: 8,
                    borderColor: theme.colors.primary
                  }}
                >
                  Odustani
                </Button>

                {loading ? (
                  <ActivityIndicator size="small" color={theme.colors.primary} style={{ marginHorizontal: 16 }} />
                ) : (
                  <Button
                    mode="contained"
                    onPress={handleConfirm}
                    style={{
                      minWidth: 100,
                      borderRadius: 8,
                      backgroundColor: theme.colors.primary
                    }}
                    labelStyle={{ color: 'white' }}
                  >
                    Potvrdi
                  </Button>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};
