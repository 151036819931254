import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text, useTheme, Button, ActivityIndicator, DataTable, Portal, Dialog, IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { logsAPI, LogEntry } from '../../../../services/api/logsAPI';
import { isToday, format } from 'date-fns';
import { hr } from 'date-fns/locale';
import { DateFilters } from '../../employees/components/admin-controls/DateFilters';
import { useRoute, RouteProp } from '@react-navigation/native';

type Location = {
  _id: string;
  name: string;
};

type LocationLogsParams = {
  LocationLogs: {
    locationId: string;
    locationName: string;
  };
};

const getActionText = (action: LogEntry['action'], breakName?: string) => {
  switch (action) {
    case 'login':
      return 'Prijava u smjenu';
    case 'logout':
      return 'Odjava iz smjene';
    case 'break_start':
      return `Odlazak na pauzu${breakName ? ` (${breakName})` : ''}`;
    case 'break_end':
      return `Povratak s pauze${breakName ? ` (${breakName})` : ''}`;
    case 'admin_checkin':
      return 'Admin: Ručna prijava u smjenu';
    case 'admin_checkout':
      return 'Admin: Ručna odjava iz smjene';
    case 'admin_break_start':
      return `Admin: Ručni početak pauze${breakName ? ` (${breakName})` : ''}`;
    case 'admin_break_end':
      return `Admin: Ručni završetak pauze${breakName ? ` (${breakName})` : ''}`;
    case 'admin_edit_session':
      return 'Admin: Uređivanje vremena sesije';
    case 'admin_edit_break':
      return `Admin: Uređivanje vremena pauze${breakName ? ` (${breakName})` : ''}`;
  }
};

const getActionIcon = (action: LogEntry['action']) => {
  switch (action) {
    case 'login':
      return 'login';
    case 'logout':
      return 'logout';
    case 'break_start':
      return 'coffee';
    case 'break_end':
      return 'keyboard-return';
    case 'admin_checkin':
      return 'plus-circle';
    case 'admin_checkout':
      return 'close-circle';
    case 'admin_break_start':
    case 'admin_break_end':
      return 'timer';
    case 'admin_edit_session':
    case 'admin_edit_break':
      return 'pencil';
  }
};

const getActionColor = (action: LogEntry['action']) => {
  switch (action) {
    case 'login':
      return '#4CAF50'; // zelena
    case 'logout':
      return '#F44336'; // crvena
    case 'break_start':
    case 'break_end':
      return '#FF9800'; // narančasta
    case 'admin_checkin':
    case 'admin_checkout':
    case 'admin_break_start':
    case 'admin_break_end':
    case 'admin_edit_session':
    case 'admin_edit_break':
      return '#673AB7'; // ljubičasta za admin akcije
  }
};

const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('hr-HR', { hour: '2-digit', minute: '2-digit' });
};

export const WebLocationLogsScreen = () => {
  const theme = useTheme();
  const route = useRoute<RouteProp<LocationLogsParams, 'LocationLogs'>>();
  const locationId = route.params?.locationId;
  const locationName = route.params?.locationName;
  const location = { _id: locationId, name: locationName };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tempDate, setTempDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  const fetchLogs = async (date: Date) => {
    if (!location?._id) return;
    
    try {
      setLoading(true);
      setError(null);
      const data = await logsAPI.getLocationLogs(location._id, date);
      setLogs(data);
    } catch (err) {
      console.error('Greška pri dohvaćanju logova:', err);
      setError('Došlo je do greške pri dohvaćanju logova');
    } finally {
      setLoading(false);
    }
  };

  // Dohvati današnje logove pri učitavanju
  useEffect(() => {
    if (!location?._id) {
      setError('Nije odabrana poslovnica');
      return;
    }
    const today = new Date();
    setSelectedDate(today);
    fetchLogs(today);
  }, []);

  if (!location?._id) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Greška: Nije odabrana poslovnica</Text>
      </View>
    );
  }

  const quickFilters = [
    { label: 'Danas', date: new Date() },
    { label: 'Jučer', date: new Date(new Date().setDate(new Date().getDate() - 1)) },
    { label: 'Prekjučer', date: new Date(new Date().setDate(new Date().getDate() - 2)) },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <Text style={styles.titleMain}>Logovi</Text>
            <MaterialCommunityIcons 
              name="chevron-right" 
              size={24} 
              color="#64748b"
              style={styles.titleIcon} 
            />
            <Text style={styles.titleLocation}>{location.name}</Text>
          </View>
          <DateFilters
            displayDate={selectedDate}
            onDateChange={(date) => {
              setSelectedDate(date);
              fetchLogs(date);
            }}
            tempDate={tempDate}
            onTempDateChange={setTempDate}
            showDatePicker={showDatePicker}
            onToggleDatePicker={setShowDatePicker}
            quickFilters={quickFilters}
            selectedFilter={null}
            onQuickFilterSelect={(_, date) => {
              setSelectedDate(date);
              fetchLogs(date);
            }}
          />
        </View>

        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        ) : error ? (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{error}</Text>
            <Button 
              mode="contained" 
              onPress={() => fetchLogs(selectedDate)}
              style={styles.retryButton}
            >
              Pokušaj ponovno
            </Button>
          </View>
        ) : logs.length === 0 ? (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nema logova za odabrani datum</Text>
          </View>
        ) : (
          <View style={styles.tableCard}>
            <ScrollView style={styles.scrollView}>
              <DataTable style={styles.table}>
                <DataTable.Header style={styles.tableHeader}>
                  <DataTable.Title style={[styles.column, styles.timeColumn]}>Vrijeme</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.employeeColumn]}>Djelatnik</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.actionColumn]}>Radnja</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.startedByColumn]}>Započeo</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.endedByColumn]}>Završio</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.deviceColumn]}>Uređaj</DataTable.Title>
                </DataTable.Header>

                {logs.map((log) => (
                  <DataTable.Row key={log.id} style={styles.tableRow}>
                    <DataTable.Cell style={[styles.column, styles.timeColumn]}>
                      <View style={styles.timeContainer}>
                        <MaterialCommunityIcons 
                          name={getActionIcon(log.action)} 
                          size={18} 
                          color={getActionColor(log.action)} 
                        />
                        <Text style={styles.timeText}>{formatTime(log.timestamp)}</Text>
                        {log.originalTime && (
                          <Text style={styles.originalTimeText}>
                            (orig: {formatTime(log.originalTime)})
                          </Text>
                        )}
                      </View>
                    </DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.employeeColumn]}>
                      <Text>{log.employeeName}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.actionColumn]}>
                      <Text>{getActionText(log.action, log.breakName)}</Text>
                      {log.adminName && (
                        <Text style={styles.adminText}>Admin: {log.adminName}</Text>
                      )}
                    </DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.startedByColumn]}>
                      <Text>{log.startedBy || '-'}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.endedByColumn]}>
                      <Text>{log.endedBy || '-'}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell style={[styles.column, styles.deviceColumn]}>
                      <Text selectable>{log.deviceId || '-'}</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
              </DataTable>
            </ScrollView>
          </View>
        )}
      </View>

      <Portal>
        <Dialog visible={!!selectedDeviceId} onDismiss={() => setSelectedDeviceId(null)}>
          <Dialog.Title>ID Uređaja</Dialog.Title>
          <Dialog.Content>
            <Text selectable>{selectedDeviceId}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setSelectedDeviceId(null)}>Zatvori</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
    padding: 24,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 16,
    borderRadius: 8,
    marginBottom: 24,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleMain: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1a202c',
  },
  titleIcon: {
    marginHorizontal: 8,
  },
  titleLocation: {
    fontSize: 24,
    fontWeight: '600',
    color: '#2563eb',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 24,
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  errorText: {
    color: '#EF4444',
    fontSize: 16,
    marginBottom: 16,
    textAlign: 'center',
  },
  retryButton: {
    borderRadius: 8,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 24,
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  emptyText: {
    color: '#6B7280',
    fontSize: 16,
    textAlign: 'center',
  },
  tableCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    overflow: 'hidden',
  },
  scrollView: {
    flex: 1,
  },
  table: {
    flex: 1,
  },
  tableHeader: {
    backgroundColor: '#F9FAFB',
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
    minHeight: 48,
  },
  column: {
    justifyContent: 'center',
  },
  timeColumn: {
    flex: 0.8,
  },
  employeeColumn: {
    flex: 1,
  },
  actionColumn: {
    flex: 1.5,
  },
  startedByColumn: {
    flex: 0.8,
  },
  endedByColumn: {
    flex: 0.8,
  },
  deviceColumn: {
    flex: 1.5,
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  timeText: {
    fontSize: 14,
  },
  originalTimeText: {
    fontSize: 12,
    color: '#666',
    fontStyle: 'italic',
  },
  adminText: {
    fontSize: 12,
    color: '#666',
    marginTop: 2,
  },
});
