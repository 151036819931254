import { useQuery } from '@tanstack/react-query';
import { dashboardAPI } from '../services/api';
import { LocationStatsMap } from '../types/dashboard';

export const useLocationStats = () => {
  return useQuery<LocationStatsMap>({
    queryKey: ['locationStats'],
    queryFn: async () => {
      const response = await dashboardAPI.getLocationStats();
      return response;
    },
  });
};
