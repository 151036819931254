import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { WebSidebar } from '../screens/dashboard/WebSidebar';
import { useRoute } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// Konstanta koja definira breakpoint za mobilni prikaz
export const MOBILE_BREAKPOINT = 992;

interface WebLayoutProps {
  children: React.ReactNode;
}

export const WebLayout: React.FC<WebLayoutProps> = ({ children }) => {
  const route = useRoute();
  const activeScreen = route.name;
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      setWindowWidth(Dimensions.get('window').width);
      if (Dimensions.get('window').width > MOBILE_BREAKPOINT) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    updateDimensions();
    Dimensions.addEventListener('change', updateDimensions);

    return () => {
      // Clean up (not needed in newer React Native versions)
    };
  }, []);

  const isMobile = windowWidth <= MOBILE_BREAKPOINT;

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <View style={styles.container}>
      <View style={[
        styles.mainContainer,
        isMobile ? styles.mobileMainContainer : styles.desktopMainContainer
      ]}>
        {children}
      </View>

      {isMobile && (
        <>
          <TouchableOpacity onPress={toggleSidebar} style={styles.floatingMenuButton}>
            <MaterialCommunityIcons name="menu" size={24} color="#fff" />
          </TouchableOpacity>

          {sidebarOpen && (
            <>
              <View style={styles.mobileSidebar}>
                <WebSidebar 
                  activeScreen={activeScreen} 
                  isMobile={true}
                  onClose={toggleSidebar}
                />
              </View>
              <Pressable style={styles.sidebarOverlay} onPress={toggleSidebar} />
            </>
          )}
        </>
      )}

      {!isMobile && (
        <View style={styles.desktopSidebar}>
          <WebSidebar activeScreen={activeScreen} isMobile={false} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    minHeight: '100vh',
  },
  mainContainer: {
    flex: 1,
    paddingTop: 0,
  },
  mobileMainContainer: {
    marginLeft: 0,
    paddingHorizontal: 24,
  },
  desktopMainContainer: {
    marginLeft: 312,
    paddingHorizontal: 24,
  },
  desktopSidebar: {
    width: 312,
    backgroundColor: '#FFFFFF',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
  },
  mobileSidebar: {
    width: '80%',
    maxWidth: 312,
    height: '100vh',
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1010,
  },
  sidebarOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1005,
  },
  floatingMenuButton: {
    position: 'fixed',
    top: 16,
    right: 16,
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#4299E1',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1015,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  }
});
