import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Alert,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Dimensions,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Device from 'expo-device';
import { Ionicons } from '@expo/vector-icons';
import { ActivityIndicator } from 'react-native-paper';
import { useTerminalActivation } from '../hooks/useTerminalActivation';

const { height } = Dimensions.get('window');

export const TerminalActivationScreen = () => {
  const navigation = useNavigation();
  const [pin, setPin] = useState('');
  const [deviceId, setDeviceId] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [isActivating, setIsActivating] = useState(false);
  const { activateTerminal } = useTerminalActivation();

  const generateDeviceId = async () => {
    try {
      const existingDeviceId = await AsyncStorage.getItem('@device_id');
      if (existingDeviceId) {
        setDeviceId(existingDeviceId);
        return existingDeviceId;
      }

      const newDeviceId = [
        Device.modelName || 'unknown',
        Device.deviceName || 'device',
        Device.deviceYearClass || new Date().getFullYear(),
        Date.now()
      ].join('-');

      await AsyncStorage.setItem('@device_id', newDeviceId);
      setDeviceId(newDeviceId);
      return newDeviceId;
    } catch (error) {
      const fallbackId = `device-${Date.now()}`;
      setDeviceId(fallbackId);
      return fallbackId;
    }
  };

  useEffect(() => {
    const init = async () => {
      const deviceId = await generateDeviceId();
      await checkTerminalStatus();
    };
    init();
  }, []);

  const checkTerminalStatus = async () => {
    try {
      const [locationId, terminalActive, deviceId] = await Promise.all([
        AsyncStorage.getItem('locationId'),
        AsyncStorage.getItem('terminalActive'),
        AsyncStorage.getItem('@device_id')
      ]);
      
      if (locationId && terminalActive === 'true' && deviceId) {
        navigation.replace('Terminal');
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleActivate = async () => {
    if (isActivating) return;
    
    if (pin.length !== 6) {
      Alert.alert('Greška', 'Aktivacijski kod je neispravan, iskorišten ili je istekao', [
        { text: 'OK', onPress: () => setPin('') }
      ]);
      return;
    }

    if (!deviceId) {
      Alert.alert('Greška', 'Provjerite internetsku vezu', [
        { text: 'OK', onPress: () => setPin('') }
      ]);
      return;
    }

    try {
      setIsActivating(true);
      const success = await activateTerminal(pin, () => setPin(''));
      if (!success) {
        return;
      }
    } catch (error) {
      Alert.alert('Greška', 'Aktivacijski kod je neispravan, iskorišten ili je istekao', [
        { text: 'OK', onPress: () => setPin('') }
      ]);
    } finally {
      setIsActivating(false);
    }
  };

  const handleBackToLogin = () => {
    navigation.navigate('Login');
  };

  return (
    <SafeAreaView style={styles.container}>
      {loading ? (
        <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      ) : (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{ flex: 1 }}
        >
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={{ flex: 1 }}>
              <TouchableOpacity 
                style={styles.backButton}
                onPress={handleBackToLogin}
              >
                <Ionicons name="arrow-back" size={24} color="#333" />
                <Text style={styles.backButtonText}>Povratak na prijavu</Text>
              </TouchableOpacity>

              <View style={styles.mainContainer}>
                <View style={styles.content}>
                  <View style={styles.logoContainer}>
                    <Text style={styles.logoPart}>clock</Text>
                    <Text style={styles.logoDot}>.</Text>
                    <Text style={styles.logoHr}>hr</Text>
                  </View>

                  <View style={styles.formContainer}>
                    <Text style={styles.title}>Aktivacija terminala</Text>
                    <Text style={styles.subtitle}>Unesite aktivacijski kod</Text>

                    <TextInput
                      style={styles.input}
                      value={pin}
                      onChangeText={text => {
                        if (text.length <= 6) {
                          setPin(text.toUpperCase());
                        }
                      }}
                      placeholder="Unesite kod"
                      placeholderTextColor="#999"
                      maxLength={6}
                      autoCapitalize="characters"
                      autoCorrect={false}
                    />

                    <TouchableOpacity
                      style={[
                        styles.activateButton,
                        pin.length === 6 ? styles.activateButtonEnabled : styles.activateButtonDisabled
                      ]}
                      onPress={handleActivate}
                      disabled={pin.length !== 6 || isActivating}
                    >
                      <Text style={styles.activateButtonText}>Aktiviraj terminal</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f7f9fc',
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
  },
  backButtonText: {
    marginLeft: 8,
    fontSize: 16,
    color: '#2c3e50',
    fontWeight: '500',
  },
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -height * 0.15, // povećano na 15% od visine ekrana
  },
  content: {
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
    padding: 24,
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 48,
    transform: [{ scale: 1.1 }],
  },
  formContainer: {
    width: '100%',
    alignItems: 'center',
  },
  logoPart: {
    fontSize: 42,
    fontWeight: '600',
    color: '#1a1a1a',
    letterSpacing: 1.2,
  },
  logoDot: {
    fontSize: 42,
    fontWeight: '800',
    color: '#1a1a1a',
    marginHorizontal: 1,
  },
  logoHr: {
    fontSize: 42,
    fontWeight: '800',
    color: '#1a1a1a',
    letterSpacing: 1.2,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 12,
    color: '#2c3e50',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 16,
    color: '#7f8c8d',
    marginBottom: 40,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    maxWidth: 320,
    height: 56,
    borderWidth: 1.5,
    borderColor: '#e0e0e0',
    borderRadius: 12,
    paddingHorizontal: 20,
    fontSize: 18,
    backgroundColor: '#ffffff',
    color: '#2c3e50',
    textAlign: 'center',
    marginBottom: 24,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,
    elevation: 2,
  },
  activateButton: {
    width: '100%',
    maxWidth: 320,
    height: 56,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 3,
    backgroundColor: '#1a1a1a',
  },
  activateButtonEnabled: {
    backgroundColor: '#1a1a1a',
  },
  activateButtonDisabled: {
    backgroundColor: '#bdc3c7',
  },
  activateButtonText: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 0.5,
  },
});
