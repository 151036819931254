/**
 * Jednostavna implementacija EventEmitter-a za globalne događaje u aplikaciji
 */

// Deklaracija za proširenje globalnog objekta
declare global {
  var EventEmitter: EventEmitter;
}

class EventEmitter {
  private events: { [key: string]: Function[] } = {};

  /**
   * Registrira slušatelja za određeni događaj
   * @param event Naziv događaja
   * @param callback Funkcija koja će se izvršiti kada se događaj emitira
   * @returns Funkcija za uklanjanje slušatelja
   */
  on(event: string, callback: Function): () => void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);

    // Vraća funkciju za uklanjanje slušatelja
    return () => {
      this.events[event] = this.events[event].filter(cb => cb !== callback);
    };
  }

  /**
   * Emitira događaj s opcionalnim argumentima
   * @param event Naziv događaja
   * @param args Argumenti koji će biti proslijeđeni slušateljima
   */
  emit(event: string, ...args: any[]): void {
    if (this.events[event]) {
      this.events[event].forEach(callback => {
        callback(...args);
      });
    }
  }

  /**
   * Uklanja sve slušatelje za određeni događaj
   * @param event Naziv događaja
   */
  removeAllListeners(event: string): void {
    if (this.events[event]) {
      delete this.events[event];
    }
  }
}

// Kreiramo singleton instancu
const eventEmitter = new EventEmitter();

// Postavljamo globalno dostupnu instancu
if (typeof global !== 'undefined') {
  (global as any).EventEmitter = eventEmitter;
}

export default eventEmitter;
