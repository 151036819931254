import React from 'react';
import { View, Modal, TouchableWithoutFeedback, FlatList } from 'react-native';
import { Text, Button, List, ActivityIndicator, useTheme } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';

interface WebBreakTypeModalProps {
  visible: boolean;
  breakTypes: string[];
  onSelect: (type: string) => void;
  onCancel: () => void;
  loading: boolean;
}

export const WebBreakTypeModal: React.FC<WebBreakTypeModalProps> = ({
  visible,
  breakTypes,
  onSelect,
  onCancel,
  loading
}) => {
  const theme = useTheme();
  
  // Funkcija za odabir koja će se izvršiti samo jednom
  const handleSelect = (type: string) => {
    if (!loading) {
      onSelect(type);
    }
  };

  // Funkcija za odustajanje koja će se izvršiti samo jednom
  const handleCancel = () => {
    if (!loading) {
      onCancel();
    }
  };

  if (!visible) return null;

  return (
    <Modal 
      visible={visible} 
      transparent 
      animationType="fade"
      onRequestClose={handleCancel}
    >
      <TouchableWithoutFeedback onPress={handleCancel}>
        <View style={{
          flex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <TouchableWithoutFeedback>
            <View style={{
              backgroundColor: 'white',
              borderRadius: 10,
              padding: 20,
              width: '80%',
              maxWidth: 400,
              maxHeight: '70%'
            }}>
              <Text style={{
                fontSize: 18,
                fontWeight: 'bold',
                marginBottom: 15,
                textAlign: 'center',
                color: theme.colors.primary
              }}>Odaberi tip pauze</Text>
              
              {breakTypes.length === 0 ? (
                <Text style={{
                  textAlign: 'center',
                  marginVertical: 20,
                  fontStyle: 'italic',
                  color: '#666'
                }}>Nema dostupnih tipova pauza</Text>
              ) : (
                <FlatList
                  data={breakTypes}
                  keyExtractor={(item, index) => `break-${index}`}
                  renderItem={({ item }) => (
                    <List.Item
                      title={item}
                      onPress={() => handleSelect(item)}
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor: '#f0f0f0'
                      }}
                      titleStyle={{
                        fontSize: 16
                      }}
                      left={props => <MaterialIcons name="schedule" size={24} color={theme.colors.primary} />}
                    />
                  )}
                  style={{
                    maxHeight: 300
                  }}
                />
              )}
              
              <View style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 20
              }}>
                {loading ? (
                  <ActivityIndicator size="small" color={theme.colors.primary} style={{ marginHorizontal: 10 }} />
                ) : null}
                
                <Button
                  mode="outlined"
                  onPress={handleCancel}
                  disabled={loading}
                  style={{
                    borderColor: theme.colors.primary,
                    borderRadius: 8
                  }}
                >
                  Odustani
                </Button>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};
