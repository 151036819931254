import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, RefreshControl } from 'react-native';
import { Text, Button, useTheme, ActivityIndicator } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LocationItem } from '../../components/LocationItem';
import { SafeAreaView } from 'react-native-safe-area-context';
import { employeesAPI } from '../../services/api';

interface Location {
  _id: string;
  name: string;
  address: string;
  city: string;
  companyName: string;
  active: boolean;
  activatedDevices: Array<{
    deviceId: string;
    activatedAt: string;
    lastUsed: string;
    active: boolean;
  }>;
}

export const LocationsScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const theme = useTheme();
  const [locations, setLocations] = useState<Location[]>([]);
  const [employeeCounts, setEmployeeCounts] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchData = async (isRefreshing = false) => {
    try {
      if (!isRefreshing) {
        setLoading(true);
      }
      setError(null);
      
      const locations = await employeesAPI.getLocations();
      const counts: Record<string, number> = {};
      
      for (const location of locations) {
        try {
          counts[location._id] = await employeesAPI.getLocationEmployeeCount(location._id);
        } catch (err) {
          counts[location._id] = 0;
        }
      }

      setLocations(locations);
      setEmployeeCounts(counts);
    } catch (err) {
      setError('Došlo je do greške pri dohvaćanju podataka');
    } finally {
      setLoading(false);
    }
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchData(true).finally(() => setRefreshing(false));
  }, []);

  useEffect(() => {
    if (loading || (route.params as any)?.refresh) {
      fetchData(false);
    }
  }, [(route.params as any)?.refresh]);

  const handleLocationPress = (locationId: string) => {
    navigation.navigate('LocationDetails' as never, { 
      locationId,
      showActivationInfo: true 
    } as never);
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={theme.colors.primary} />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{error}</Text>
        <Button mode="contained" onPress={fetchData} style={styles.retryButton}>
          Pokušaj ponovno
        </Button>
      </View>
    );
  }

  return (
    <SafeAreaView style={[styles.safeArea, { backgroundColor: '#fff' }]} edges={['left', 'right']}>
      {locations.length === 0 ? (
        <View style={styles.emptyStateContainer}>
          <View style={styles.emptyStateContent}>
            <Text style={styles.emptyStateText}>
              Nemate još dodanih poslovnica
            </Text>
            <Text style={styles.emptyStateSubtext}>
              Dodajte svoju prvu poslovnicu kako biste mogli pratiti rad djelatnika
            </Text>
            <Button 
              mode="contained" 
              onPress={() => navigation.navigate('CreateLocation' as never)}
              style={styles.createButton}
              contentStyle={styles.createButtonContent}
            >
              Dodaj poslovnicu
            </Button>
          </View>
        </View>
      ) : (
        <FlatList
          data={locations}
          renderItem={({ item }) => (
            <LocationItem
              location={item}
              employeeCount={employeeCounts[item._id] || 0}
              onPress={() => handleLocationPress(item._id)}
              style={styles.locationItem}
            />
          )}
          keyExtractor={item => item._id}
          contentContainerStyle={styles.listContent}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 0,
    paddingTop: 12,
    paddingBottom: 16,
  },
  locationItem: {
    borderRadius: 12,
    marginVertical: 4,
    marginHorizontal: 16,
    elevation: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  errorText: {
    fontSize: 16,
    color: '#f44336',
    textAlign: 'center',
    marginBottom: 16,
  },
  retryButton: {
    paddingHorizontal: 16,
  },
  emptyStateContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStateContent: {
    width: '100%',
    paddingHorizontal: 24,
    alignItems: 'center',
    marginTop: -30, 
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#37474F',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: '#78909C',
    textAlign: 'center',
    marginBottom: 32,
    paddingHorizontal: 20,
  },
  createButton: {
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  createButtonContent: {
    minWidth: 180,
  },
});
