import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const Privacy = () => {
  return (
    <HelpLayout>
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Zaštita podataka</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Politika privatnosti</Text>
            <Text style={styles.description}>
              Detaljan opis načina na koji prikupljamo, obrađujemo i štitimo osobne podatke korisnika sustava Clock.hr 
              u skladu s GDPR regulativom.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Uvod</Text>
              <Text style={styles.paragraph}>
                Clock.hr (u daljnjem tekstu: "mi", "naš" ili "Clock.hr") poštuje vašu privatnost i predani smo zaštiti 
                vaših osobnih podataka. Ova Politika zaštite osobnih podataka opisuje kako prikupljamo, koristimo, 
                obrađujemo i čuvamo osobne podatke kroz naš sustav za evidenciju radnog vremena u skladu s Općom uredbom 
                o zaštiti podataka (GDPR) i Zakonom o provedbi Opće uredbe o zaštiti podataka Republike Hrvatske.
              </Text>

              <Text style={styles.heading}>2. Uloga u obradi podataka</Text>
              <Text style={styles.paragraph}>
                Clock.hr nastupa kao izvršitelj obrade osobnih podataka zaposlenika u ime poslodavaca koji koriste našu aplikaciju. 
                Poslodavac je voditelj obrade osobnih podataka svojih zaposlenika. Za sva pitanja vezana uz zaštitu osobnih 
                podataka možete nas kontaktirati na:
              </Text>
              <Text style={styles.bulletPoint}>• Email: info@rakicgroup.com</Text>
              <Text style={styles.bulletPoint}>• Adresa: Put Čikole, 22320 Drniš</Text>
              

              <Text style={styles.heading}>3. Vrste osobnih podataka koje prikupljamo</Text>
              <Text style={styles.paragraph}>Prikupljamo i obrađujemo sljedeće kategorije osobnih podataka:</Text>
              <Text style={styles.bulletPoint}>• Identifikacijski podaci zaposlenika: ime, prezime, OIB, ID zaposlenika u sustavu</Text>
              <Text style={styles.bulletPoint}>• Poslovni kontakt podaci: poslovna email adresa, poslovni telefonski broj</Text>
              <Text style={styles.bulletPoint}>• Podaci o zaposlenju: naziv radnog mjesta, odjel, ID poslovnice/lokacije rada, nadređena osoba</Text>
              <Text style={styles.bulletPoint}>• Podaci o radnom vremenu: vrijeme dolaska, vrijeme odlaska, trajanje i vrsta pauza, ukupno trajanje rada, prekovremeni sati</Text>
              <Text style={styles.bulletPoint}>• Podaci vezani uz obračun: satnica, ostvareni radni sati, dodaci na plaću vezani uz radno vrijeme</Text>
              <Text style={styles.bulletPoint}>• Podaci o uređaju: ID uređaja tvrtke s kojeg je zabilježena prijava/odjava (ne prikupljamo podatke o osobnim uređajima zaposlenika)</Text>

              <Text style={styles.heading}>4. Pravna osnova za obradu podataka</Text>
              <Text style={styles.paragraph}>Vaše osobne podatke obrađujemo na temelju sljedećih pravnih osnova:</Text>
              <Text style={styles.bulletPoint}>• Izvršavanje ugovora o radu - obrada je nužna za ispunjenje obveza iz ugovora o radu</Text>
              <Text style={styles.bulletPoint}>• Poštivanje pravnih obveza - obrada je nužna za ispunjavanje zakonskih obveza kojima podliježe poslodavac, posebno Zakona o radu koji propisuje obvezu vođenja evidencije radnog vremena</Text>
              <Text style={styles.bulletPoint}>• Legitimni interes - kada je obrada nužna za potrebe legitimnih interesa poslodavca, primjerice za upravljanje ljudskim resursima, organizaciju rada i praćenje učinkovitosti</Text>

              <Text style={styles.heading}>5. Svrhe obrade osobnih podataka</Text>
              <Text style={styles.paragraph}>Vaše osobne podatke obrađujemo isključivo u sljedeće svrhe:</Text>
              <Text style={styles.bulletPoint}>• Vođenje zakonski propisane evidencije radnog vremena</Text>
              <Text style={styles.bulletPoint}>• Obračun plaća, naknada i dodataka vezanih uz radno vrijeme</Text>
              <Text style={styles.bulletPoint}>• Planiranje radnog vremena i organizacija rada</Text>
              <Text style={styles.bulletPoint}>• Upravljanje ljudskim resursima i praćenje učinkovitosti</Text>
              <Text style={styles.bulletPoint}>• Izrada zakonski propisanih izvještaja i evidencija</Text>
              <Text style={styles.bulletPoint}>• Osiguravanje sigurnosti i integriteta sustava</Text>

              <Text style={styles.heading}>6. Razdoblje čuvanja podataka</Text>
              <Text style={styles.paragraph}>Osobne podatke čuvamo samo onoliko dugo koliko je potrebno za ispunjenje svrhe obrade ili koliko to zahtijevaju važeći propisi:</Text>
              <Text style={styles.bulletPoint}>• Evidencije radnog vremena čuvamo najmanje 6 godina, sukladno Zakonu o radu i poreznim propisima</Text>
              <Text style={styles.bulletPoint}>• Nakon isteka zakonski propisanog razdoblja čuvanja, podaci se brišu ili anonimiziraju</Text>

              <Text style={styles.heading}>7. Primatelji osobnih podataka</Text>
              <Text style={styles.paragraph}>Pristup vašim osobnim podacima imaju:</Text>
              <Text style={styles.bulletPoint}>• Ovlaštene osobe poslodavca (HR odjel, rukovoditelji, administratori sustava)</Text>
              <Text style={styles.bulletPoint}>• Pružatelji usluga koji djeluju kao izvršitelji obrade u naše ime, a koji su ugovorno obvezani čuvati povjerljivost podataka</Text>
              <Text style={styles.bulletPoint}>• Državna tijela kada je to propisano zakonom (npr. inspekcija rada, porezna uprava)</Text>
              <Text style={styles.paragraph}>Ne dijelimo vaše osobne podatke s trećim stranama za marketinške svrhe.</Text>

              <Text style={styles.heading}>8. Sigurnosne mjere</Text>
              <Text style={styles.paragraph}>Implementirali smo odgovarajuće tehničke i organizacijske mjere kako bismo osigurali zaštitu vaših osobnih podataka:</Text>
              <Text style={styles.bulletPoint}>• Stroga kontrola pristupa i upravljanje korisničkim pravima</Text>
              <Text style={styles.bulletPoint}>• Redovito sigurnosno kopiranje podataka</Text>
              <Text style={styles.bulletPoint}>• Periodičke provjere sigurnosti sustava</Text>
              <Text style={styles.bulletPoint}>• Interne politike i procedure za zaštitu podataka</Text>
              <Text style={styles.bulletPoint}>• Edukacija zaposlenika o sigurnosti i zaštiti podataka</Text>

              <Text style={styles.heading}>9. Vaša prava</Text>
              <Text style={styles.paragraph}>U skladu s GDPR-om imate sljedeća prava:</Text>
              <Text style={styles.bulletPoint}>• Pravo na pristup: imate pravo dobiti potvrdu obrađujemo li vaše osobne podatke te, ako da, pristup tim podacima</Text>
              <Text style={styles.bulletPoint}>• Pravo na ispravak: imate pravo zatražiti ispravak netočnih osobnih podataka</Text>
              <Text style={styles.bulletPoint}>• Pravo na brisanje: imate pravo zatražiti brisanje osobnih podataka pod određenim uvjetima</Text>
              <Text style={styles.bulletPoint}>• Pravo na ograničenje obrade: imate pravo zatražiti ograničenje obrade vaših osobnih podataka</Text>
              <Text style={styles.bulletPoint}>• Pravo na prenosivost podataka: imate pravo zatražiti kopiju svojih podataka u strukturiranom, uobičajeno upotrebljavanom i strojno čitljivom formatu</Text>
              <Text style={styles.bulletPoint}>• Pravo na prigovor: imate pravo prigovoriti obradi osobnih podataka koja se temelji na legitimnom interesu</Text>
              <Text style={styles.bulletPoint}>• Pravo na povlačenje privole: ako se obrada temelji na privoli, imate pravo povući privolu u bilo kojem trenutku</Text>
              <Text style={styles.paragraph}>Za ostvarivanje svojih prava ili ako imate pitanja o tome kako obrađujemo vaše osobne podatke, obratite se vašem HR odjelu ili na info@rakicgroup.com.</Text>

              <Text style={styles.heading}>10. Pravo na pritužbu nadzornom tijelu</Text>
              <Text style={styles.paragraph}>
                Ako smatrate da obrada vaših osobnih podataka nije u skladu s propisima o zaštiti podataka, imate pravo podnijeti pritužbu Agenciji za zaštitu osobnih podataka (AZOP):
              </Text>
              <Text style={styles.paragraph}>
                Agencija za zaštitu osobnih podataka{'\n'}
                Selska cesta 136{'\n'}
                10000 Zagreb{'\n'}
                azop@azop.hr{'\n'}
                www.azop.hr
              </Text>

              <Text style={styles.heading}>11. Automatizirano donošenje odluka</Text>
              <Text style={styles.paragraph}>Ne koristimo automatizirano donošenje odluka ili profiliranje u obradi vaših osobnih podataka.</Text>

              <Text style={styles.heading}>12. Prijenos podataka u treće zemlje</Text>
              <Text style={styles.paragraph}>Vaše osobne podatke ne prenosimo izvan Europskog gospodarskog prostora (EGP).</Text>

              <Text style={styles.heading}>13. Izmjene politike zaštite osobnih podataka</Text>
              <Text style={styles.paragraph}>Ovu Politiku zaštite osobnih podataka možemo povremeno ažurirati. O svim važnim promjenama bit ćete obaviješteni na odgovarajući način. Trenutna verzija Politike uvijek je dostupna u aplikaciji.</Text>

              <Text style={styles.heading}>14. Kontakt</Text>
              <Text style={styles.paragraph}>Ako imate pitanja ili nedoumice u vezi s našom Politikom zaštite osobnih podataka ili obradom vaših osobnih podataka, molimo vas da nas kontaktirate na:</Text>
              <Text style={styles.bulletPoint}>• Email: info@rakicgroup.com</Text>
              <Text style={styles.paragraph}>Datum zadnjeg ažuriranja: 10.03.2025</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Izjava o digitalnim tehnologijama</Text>
            <Text style={styles.description}>
              Informacije o korištenju tehnologija za pohranu podataka u našoj aplikaciji, njihovoj svrsi i načinu 
              upravljanja postavkama.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Tehnologije koje koristimo</Text>
              <Text style={styles.paragraph}>
                Clock.hr koristi lokalno pohranjivanje podataka za optimalno funkcioniranje aplikacije. Ne koristimo kolačiće 
                za praćenje korisnika ili u marketinške svrhe.
              </Text>

              <Text style={styles.heading}>2. Lokalno pohranjivanje</Text>
              <Text style={styles.bulletPoint}>• Za rad aplikacije pohranjujemo nužne podatke o sesiji</Text>
              <Text style={styles.bulletPoint}>• Ne dijelimo ove podatke s trećim stranama</Text>
              <Text style={styles.bulletPoint}>• Podaci se koriste isključivo za funkcionalnost aplikacije</Text>

              <Text style={styles.heading}>3. Pristanak</Text>
              <Text style={styles.paragraph}>
                Korištenjem Clock.hr aplikacije pristajete na lokalno pohranjivanje nužnih podataka za funkcioniranje aplikacije.
              </Text>

              <Text style={styles.heading}>4. Sigurnost pohranjenih podataka</Text>
              <Text style={styles.paragraph}>
                Svi lokalno pohranjeni podaci zaštićeni su i dostupni samo ovlaštenim korisnicima kroz sigurnosne mehanizme aplikacije.
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 48,
  },
  section: {
    marginBottom: 48,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 24,
    fontStyle: 'italic',
  },
  contentContainer: {
    backgroundColor: '#F8FAFC',
    padding: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginTop: 24,
    marginBottom: 12,
  },
  paragraph: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 12,
  },
  bulletPoint: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 8,
    paddingLeft: 20,
  },
});