import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

interface Activity {
  id: string;
  time: string;
  employee: string;
  action: string;
  type: 'success' | 'warning' | 'info';
}

const ActivityLogPreview = () => {
  const [selectedType, setSelectedType] = useState<Activity['type'] | 'all'>('all');

  const allActivities: Activity[] = [
    // Dolasci
    { 
      id: '1', 
      time: '07:45', 
      employee: 'Ivan Horvat',
      action: 'Dolazak na posao',
      type: 'success'
    },
    // Pauze
    { 
      id: '2', 
      time: '12:00', 
      employee: 'Ana Novak',
      action: 'Odlazak na pauzu',
      type: 'info'
    },
    { 
      id: '3', 
      time: '12:30', 
      employee: 'Ana Novak',
      action: 'Povratak s pauze',
      type: 'info'
    },
    // Odlasci
    { 
      id: '4', 
      time: '16:00', 
      employee: 'Ivan Horvat',
      action: 'Odlazak s posla',
      type: 'warning'
    }
  ];

  const getStatusColor = (type: Activity['type']) => {
    switch (type) {
      case 'success':
        return '#10B981';
      case 'warning':
        return '#F59E0B';
      case 'info':
        return '#3B82F6';
      default:
        return '#6B7280';
    }
  };

  const getFilterLabel = (type: Activity['type'] | 'all') => {
    switch (type) {
      case 'success':
        return 'Dolasci';
      case 'warning':
        return 'Odlasci';
      case 'info':
        return 'Pauze';
      default:
        return 'Sve';
    }
  };

  // Logika za filtriranje i prikazivanje samo 2 loga
  const activities = selectedType === 'all'
    ? [allActivities[0], allActivities[2]] // Dolazak na posao i povratak s pauze
    : allActivities.filter(activity => activity.type === selectedType).slice(0, 2);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.locationText}>Poslovnica Šibenik</Text>
      </View>

      <View style={styles.subHeader}>
        <View style={styles.subHeaderTop}>
          <Text style={styles.subHeaderTitle}>Današnje aktivnosti</Text>
          <Text style={styles.date}>Petak, 9. ožujka</Text>
        </View>
        
        <View style={styles.filterContainer}>
          {(['all', 'success', 'info', 'warning'] as const).map((type) => (
            <TouchableOpacity
              key={type}
              onPress={() => setSelectedType(type)}
              style={[
                styles.filterButton,
                selectedType === type && styles.filterButtonActive,
                type !== 'all' && { borderColor: getStatusColor(type) }
              ]}
            >
              <Text style={[
                styles.filterButtonText,
                selectedType === type && styles.filterButtonTextActive,
                type !== 'all' && { color: getStatusColor(type) }
              ]}>
                {getFilterLabel(type)}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>

      <View style={styles.content}>
        {activities.map((activity) => (
          <TouchableOpacity 
            key={activity.id} 
            style={styles.activityItem}
            onPress={() => {}}
          >
            <View style={[styles.activityStatus, { backgroundColor: getStatusColor(activity.type) }]} />
            <View style={styles.activityInfo}>
              <View style={styles.activityHeader}>
                <Text style={styles.time}>{activity.time}</Text>
                <Text style={styles.employeeName}>{activity.employee}</Text>
              </View>
              <Text style={styles.actionText}>{activity.action}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: '#F1F5F9',
    backgroundColor: '#FFFFFF',
  },
  locationText: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    letterSpacing: -0.5,
  },
  subHeader: {
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#F1F5F9',
  },
  subHeaderTop: {
    marginBottom: 16,
  },
  subHeaderTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 4,
  },
  date: {
    fontSize: 14,
    color: '#64748B',
  },
  filterContainer: {
    flexDirection: 'row',
    gap: 8,
  },
  filterButton: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    backgroundColor: '#FFFFFF',
  },
  filterButtonActive: {
    backgroundColor: '#F1F5F9',
  },
  filterButtonText: {
    fontSize: 14,
    color: '#64748B',
    fontWeight: '500',
  },
  filterButtonTextActive: {
    color: '#1E293B',
    fontWeight: '600',
  },
  content: {
    flex: 1,
    padding: 24,
  },
  activityItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 12,
    gap: 12,
  },
  activityStatus: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginTop: 6,
  },
  activityInfo: {
    flex: 1,
  },
  activityHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    marginBottom: 4,
  },
  time: {
    fontSize: 14,
    fontWeight: '600',
    color: '#1E293B',
  },
  employeeName: {
    fontSize: 14,
    color: '#64748B',
  },
  actionText: {
    fontSize: 14,
    color: '#1E293B',
  },
});

export default ActivityLogPreview;
