import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Text, Button, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { terminalEndpoints } from '../api/terminalApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from '../../../context/ToastContext';

interface ActiveBreakProps {
  route: {
    params: {
      sessionId: string;
      breakId: string;
      breakName: string;
      startTime: string;
      allowedMinutes: number;
      employeeName: string;
    }
  }
}

export const ActiveBreakScreen = ({ route }: ActiveBreakProps) => {
  const { sessionId, breakId, breakName, startTime, allowedMinutes, employeeName } = route.params;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [overMinutes, setOverMinutes] = useState(0);
  const theme = useTheme();
  const navigation = useNavigation();
  const { showToast, hideToastImmediately } = useToast();

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
    
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const start = new Date(startTime).getTime();
      const now = new Date().getTime();
      const diff = (now - start) / 1000; // razlika u sekundama
      
      const seconds = diff;
      setElapsedTime(seconds);
      
      if (allowedMinutes && seconds > allowedMinutes * 60) {
        const over = seconds - allowedMinutes * 60;
        setOverMinutes(over);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime, allowedMinutes]);

  const handleEndBreak = async () => {
    try {
      // Debug log je uklonjen

      const response = await terminalEndpoints.endBreak(sessionId, breakId);
      
      let remainingTimeMessage = "";
      
      // Koristimo vrijednost remainingMinutes koja dolazi iz baze podataka
      if (response.remainingMinutes !== undefined) {
        // Preostalo vrijeme u minutama iz baze
        const remainingTimeMinutes = response.remainingMinutes;
        
        if (remainingTimeMinutes > 0) {
          // Razdvajamo na minute i sekunde za prikazivanje
          const minutes = Math.floor(remainingTimeMinutes);
          const seconds = Math.round((remainingTimeMinutes - minutes) * 60);
          
          if (seconds > 0) {
            remainingTimeMessage = `Preostalo vrijeme za ovu pauzu: ${minutes} min ${seconds} sek`;
          } else {
            remainingTimeMessage = `Preostalo vrijeme za ovu pauzu: ${minutes} min`;
          }
        } else {
          remainingTimeMessage = "Iskorišteno je cijelo vrijeme pauze";
        }
      }
      // Ako nemamo remainingMinutes iz baze (stariji backend), koristimo lokalni izračun
      else if (allowedMinutes) {
        if (elapsedTime < allowedMinutes * 60) {
          const remainingSeconds = allowedMinutes * 60 - elapsedTime;
          const minutes = Math.floor(remainingSeconds / 60);
          const seconds = Math.floor(remainingSeconds % 60); // Zaokružujem na cijeli broj
          
          if (seconds > 0) {
            remainingTimeMessage = `Preostalo vrijeme za ovu pauzu: ${minutes} min ${seconds} sek`;
          } else {
            remainingTimeMessage = `Preostalo vrijeme za ovu pauzu: ${minutes} min`;
          }
        } else {
          remainingTimeMessage = "Iskorišteno je cijelo vrijeme pauze";
        }
      }
      
      // Sakrij sve aktivne toast poruke
      hideToastImmediately();
      
      // Prikaži vlastiti toast za završavanje pauze
      showToast(
        'Pauza završena', 
        remainingTimeMessage, 
        'success', 
        5000
      );
      
      // Navigiraj na Terminal ekran
      navigation.navigate('Terminal');
    } catch (error) {
      console.error('Greška prilikom završavanja pauze:', error);
      
      // Prikaži toast za grešku
      hideToastImmediately();
      showToast(
        'Greška', 
        'Nije moguće završiti pauzu. Pokušajte ponovno.', 
        'error', 
        5000
      );
    }
  };

  const handleCancel = () => {
    hideToastImmediately(); // Sakrij sve aktivne toast poruke
    navigation.navigate('Terminal');
  };

  return (
    <SafeAreaView style={styles.container}>
      {/* Jednostavan, direktan prikaz bez ugnježđenih kontejnera */}
      <Text 
        style={styles.title} 
        numberOfLines={1} 
        ellipsizeMode="middle"
      >
        {employeeName}
      </Text>
      <Text 
        style={styles.subTitle} 
        numberOfLines={1} 
        ellipsizeMode="middle"
      >
        {breakName}
      </Text>
      
      <Text style={[
        styles.timer, 
        elapsedTime > allowedMinutes * 60 && styles.overTimeTimer
      ]}>
        {formatTime(elapsedTime)}
      </Text>
      
      {elapsedTime > allowedMinutes * 60 && (
        <View style={styles.overTimeContainer}>
          <Text style={styles.overTimeLabel}>Prekoračenje:</Text>
          <Text style={styles.overTime}>
            {formatTime(elapsedTime - (allowedMinutes * 60))}
          </Text>
        </View>
      )}

      <View style={styles.buttonContainer}>
        <Button 
          mode="contained" 
          onPress={handleEndBreak}
          style={[styles.button, styles.endButton]}
          contentStyle={styles.buttonContent}
          labelStyle={styles.buttonLabel}
          buttonColor="#FF4444"
        >
          Završi pauzu
        </Button>
        
        <Button 
          mode="outlined" 
          onPress={handleCancel}
          style={[styles.button, styles.cancelButton]}
          contentStyle={styles.buttonContent}
          labelStyle={styles.buttonLabel}
        >
          Odustani
        </Button>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 12,
    textAlign: 'center',
    fontSize: Platform.OS === 'web' ? 32 : 28,
    fontWeight: 'bold',
    color: '#333', // Mijenjam boju u tamniju
    width: '90%',
    maxWidth: 320,
  },
  subTitle: {
    marginBottom: 24,
    textAlign: 'center',
    fontSize: Platform.OS === 'web' ? 28 : 24,
    color: '#FF4444', // Mijenjam boju u crvenu
    width: '90%',
    maxWidth: 320,
  },
  timer: {
    marginTop: 16,
    marginBottom: 16,
    fontVariant: ['tabular-nums'],
    fontSize: Platform.OS === 'web' ? 64 : 48,
    textAlign: 'center',
  },
  overTimeTimer: {
    color: '#FF4444',
  },
  overTimeContainer: {
    alignItems: 'center',
    marginBottom: 32,
  },
  overTimeLabel: {
    fontSize: 18,
    color: '#FF4444',
  },
  overTime: {
    fontSize: 24,
    color: '#FF4444',
  },
  buttonContainer: {
    width: '100%',
    paddingHorizontal: 16,
    gap: 16,
  },
  button: {
    borderRadius: 12,
    height: 72,
  },
  endButton: {
    width: '100%',
  },
  cancelButton: {
    width: '100%',
    borderWidth: 2,
  },
  buttonContent: {
    height: 72,
    paddingVertical: 8,
  },
  buttonLabel: {
    fontSize: 24,
    textAlign: 'center',
    lineHeight: 32,
  },
});
