import React, { useState, useEffect } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

interface WebCustomTimePickerProps {
  value: Date;
  onChange: (date: Date) => void;
  minTime?: Date;
  maxTime?: Date;
}

export const WebCustomTimePicker: React.FC<WebCustomTimePickerProps> = ({
  value,
  onChange,
  minTime,
  maxTime
}) => {
  const theme = useTheme();
  const [selectedHour, setSelectedHour] = useState(value.getHours());
  const [selectedMinute, setSelectedMinute] = useState(value.getMinutes());

  useEffect(() => {
    setSelectedHour(value.getHours());
    setSelectedMinute(value.getMinutes());
  }, [value]);

  const generateTimeOptions = (start: number, end: number) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i.toString().padStart(2, '0'));
    }
    return options;
  };

  const getValidHours = () => {
    let start = 0;
    let end = 23;

    if (minTime) {
      start = minTime.getHours();
    }
    if (maxTime) {
      end = maxTime.getHours();
    }

    // Ograničenje za trenutno vrijeme - ne dopuštamo odabir vremena u budućnosti
    const now = new Date();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Ako je odabrani datum danas, ograničimo maksimalni sat na trenutni sat
    if (value.getDate() === now.getDate() && 
        value.getMonth() === now.getMonth() && 
        value.getFullYear() === now.getFullYear()) {
      end = Math.min(end, now.getHours());
    }

    return generateTimeOptions(start, end);
  };

  const getValidMinutes = (hour: number) => {
    let start = 0;
    let end = 59;

    if (minTime && hour === minTime.getHours()) {
      start = minTime.getMinutes();
    }
    if (maxTime && hour === maxTime.getHours()) {
      end = maxTime.getMinutes();
    }

    // Ograničenje za trenutno vrijeme - ne dopuštamo odabir vremena u budućnosti
    const now = new Date();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Ako je odabrani datum danas i odabrani sat je trenutni sat, ograničimo minute
    if (value.getDate() === now.getDate() && 
        value.getMonth() === now.getMonth() && 
        value.getFullYear() === now.getFullYear() &&
        hour === now.getHours()) {
      end = Math.min(end, now.getMinutes());
    }

    return generateTimeOptions(start, end);
  };

  const handleTimeSelect = (type: 'hour' | 'minute', selectedValue: number) => {
    const newDate = new Date(value.getTime());
    if (type === 'hour') {
      setSelectedHour(selectedValue);
      newDate.setHours(selectedValue, selectedMinute);
    } else {
      setSelectedMinute(selectedValue);
      newDate.setHours(selectedHour, selectedValue);
    }
    onChange(newDate);
  };

  const TimeColumn = ({ 
    values, 
    selected, 
    onSelect, 
    label 
  }: { 
    values: string[], 
    selected: number, 
    onSelect: (value: number) => void,
    label: string 
  }) => {
    return (
      <View style={{
        alignItems: 'center',
        width: 80
      }}>
        <Text style={{
          fontSize: 14,
          fontWeight: '500',
          marginBottom: 8,
          color: theme.colors.primary
        }}>{label}</Text>
        <View style={{
          height: 176,
          width: 70,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: theme.colors.primary,
          overflow: 'hidden'
        }}>
          <ScrollView
            style={{ flex: 1 }}
            showsVerticalScrollIndicator={true}
          >
            <View style={{ height: 20 }} />
            {values.map((value) => {
              const numValue = parseInt(value);
              const isSelected = numValue === selected;
              return (
                <TouchableOpacity
                  key={value}
                  style={{
                    height: 44,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: isSelected ? theme.colors.primaryContainer : 'transparent'
                  }}
                  onPress={() => onSelect(numValue)}
                >
                  <Text style={{
                    fontSize: 16,
                    color: isSelected ? theme.colors.primary : theme.colors.onBackground,
                    fontWeight: isSelected ? 'bold' : 'normal'
                  }}>
                    {value}
                  </Text>
                </TouchableOpacity>
              );
            })}
            <View style={{ height: 20 }} />
          </ScrollView>
        </View>
      </View>
    );
  };

  return (
    <View style={{
      width: '100%',
      alignItems: 'center'
    }}>
      <View style={{
        marginBottom: 20,
        padding: 8,
        borderRadius: 8,
        backgroundColor: '#f5f5f5'
      }}>
        <Text style={{
          fontSize: 32,
          fontWeight: 'bold',
          color: theme.colors.primary
        }}>
          {selectedHour.toString().padStart(2, '0')}:{selectedMinute.toString().padStart(2, '0')}
        </Text>
      </View>
      
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%'
      }}>
        <TimeColumn
          values={getValidHours()}
          selected={selectedHour}
          onSelect={(value) => handleTimeSelect('hour', value)}
          label="Sati"
        />
        <TimeColumn
          values={getValidMinutes(selectedHour)}
          selected={selectedMinute}
          onSelect={(value) => handleTimeSelect('minute', value)}
          label="Minute"
        />
      </View>
    </View>
  );
};
