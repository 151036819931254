import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { Text } from 'react-native-paper';
import { MOBILE_BREAKPOINT } from '../../layout/WebLayout';

interface WebHeaderProps {
  title?: string;
  subtitle?: string;
  rightComponent?: React.ReactNode;
}

export const WebHeader: React.FC<WebHeaderProps> = ({
  title,
  subtitle,
  rightComponent,
}) => {
  const [isMobile, setIsMobile] = useState(Dimensions.get('window').width <= MOBILE_BREAKPOINT);

  useEffect(() => {
    const updateDimension = () => {
      setIsMobile(Dimensions.get('window').width <= MOBILE_BREAKPOINT);
    };
    
    Dimensions.addEventListener('change', updateDimension);
    
    return () => {
      // Cleanup (not needed in newer React Native versions)
    };
  }, []);

  return (
    <View style={[
      styles.headerContainer,
      isMobile && styles.mobileHeaderContainer
    ]}>
      {title && (
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
          {subtitle ? <Text style={styles.subtitle}>{subtitle}</Text> : null}
        </View>
      )}

      {rightComponent && (
        <View style={styles.rightContainer}>{rightComponent}</View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    backgroundColor: '#fff',
  },
  mobileHeaderContainer: {
    width: '100%',
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A202C',
  },
  subtitle: {
    fontSize: 14,
    color: '#718096',
    marginTop: 2,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
