export interface KeywordGroup {
  primary: string[];
  secondary: string[];
  related: string[];
}

export const keywords: KeywordGroup = {
  primary: [
    'evidencija radnog vremena',
    'praćenje radnog vremena',
    'clock.hr',
    'evidencija prisutnosti zaposlenika',
    'sustav za praćenje radnog vremena'
  ],
  secondary: [
    'upravljanje zaposlenicima',
    'praćenje prisutnosti',
    'evidencija dolazaka',
    'evidencija odlazaka',
    'praćenje pauza',
    'evidencija prekovremenih sati'
  ],
  related: [
    'HR softver',
    'upravljanje ljudskim resursima',
    'poslovni softver',
    'optimizacija radnog vremena',
    'praćenje produktivnosti',
    'evidencija rada od kuće'
  ]
};

export const metaDescription = 
  'Clock.hr - Moderno rješenje za evidenciju radnog vremena. ' +
  'Jednostavno pratite prisutnost zaposlenika, pauze i prekovremene sate. ' +
  'Optimizirajte radne procese i povećajte produktivnost vaše tvrtke.';

export const pageDescriptions = {
  home: 'Najbolja aplikacija za evidenciju radnog vremena u Hrvatskoj. Jednostavno praćenje prisutnosti zaposlenika, pauza i prekovremenih sati.',
  features: 'Napredne funkcionalnosti za praćenje radnog vremena. Automatska evidencija, izvještaji, integracije i još mnogo toga.',
  pricing: 'Transparentne cijene bez skrivenih troškova. Isprobajte Clock.hr besplatno 7 dana.',
  about: 'Clock.hr je hrvatski proizvod razvijen za potrebe domaćeg tržišta. Saznajte više o nama i našoj misiji.',
  contact: 'Trebate pomoć? Kontaktirajte našu korisničku podršku dostupnu 24/7.'
};