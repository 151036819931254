import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const TerminalSetup = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Aktivacija terminala</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Što je terminal?</Text>
            <Text style={styles.description}>
              Terminal je uređaj (tablet, mobitel ili računalo) koji će vaši djelatnici koristiti za prijavu i odjavu s posla.
              Svaka lokacija mora imati barem jedan terminal.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Kako aktivirati terminal?</Text>
            <Text style={styles.description}>
              1. Na uređaju koji želite koristiti kao terminal otvorite web preglednik
            </Text>
            <Text style={styles.description}>
              2. Posjetite stranicu terminal.clock.hr
            </Text>
            <Text style={styles.description}>
              3. Unesite aktivacijski kod koji možete pronaći u postavkama lokacije
            </Text>
            <Text style={styles.description}>
              4. Terminal je spreman za korištenje
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Savjeti za postavljanje terminala</Text>
            <Text style={styles.description}>
              • Postavite terminal na mjesto koje je lako dostupno svim djelatnicima
            </Text>
            <Text style={styles.description}>
              • Osigurajte stabilnu internet vezu
            </Text>
            <Text style={styles.description}>
              • Preporučujemo korištenje tableta s punjačem za najbolje korisničko iskustvo
            </Text>
            <Text style={styles.description}>
              • Terminal možete zaključati u "kiosk mode" kako bi se koristio samo za Clock.hr
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Važne napomene</Text>
            <View style={styles.noteBox}>
              <Text style={styles.noteText}>
                • Jedan terminal može biti aktivan samo na jednoj lokaciji
              </Text>
              <Text style={styles.noteText}>
                • Aktivacijski kod je jedinstven za svaku lokaciju
              </Text>
              <Text style={styles.noteText}>
                • U slučaju problema s terminalom, možete ga deaktivirati i ponovno aktivirati s novim kodom
              </Text>
            </View>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: '#444',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  noteBox: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderLeftColor: '#007bff',
  },
  noteText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
  },
});
