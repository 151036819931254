import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';

interface ClockDisplayProps {
  currentTime: Date;
  isTablet?: boolean;
}

export const ClockDisplay: React.FC<ClockDisplayProps> = ({ 
  currentTime,
  isTablet = false
}) => {
  const dynamicStyles = {
    timeText: {
      fontSize: isTablet ? 72 : 56,
    }
  };

  return (
    <View style={styles.header}>
      <View style={styles.timeContainer}>
        <Text style={[styles.timeText, dynamicStyles.timeText]}>
          {currentTime.toLocaleTimeString('hr-HR', { 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit' 
          })}
        </Text>
        <Text style={styles.dateText}>
          {currentTime.toLocaleDateString('hr-HR', { 
            weekday: 'long', 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
          })}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.08)',
    marginBottom: 20,
  },
  timeContainer: {
    alignItems: 'center',
  },
  timeText: {
    fontSize: 48,
    fontWeight: '300',
    fontFamily: Platform.OS === 'ios' ? 'Courier' : 'monospace',
    letterSpacing: 2,
  },
  dateText: {
    fontSize: 16,
    marginTop: 8,
    fontWeight: '400',
    fontFamily: Platform.OS === 'ios' ? 'System' : 'Roboto',
    textTransform: 'capitalize',
    opacity: 0.8,
  },
});
