import React, { useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import { Text, Button } from 'react-native-paper';
import { format } from 'date-fns';
import { hr } from 'date-fns/locale';
import { MaterialIcons } from '@expo/vector-icons';

interface QuickFilter {
  label: string;
  date: Date;
}

interface DateFiltersProps {
  displayDate: Date;
  onDateChange: (date: Date) => void;
  tempDate: Date;
  onTempDateChange: (date: Date) => void;
  showDatePicker: boolean;
  onToggleDatePicker: (show: boolean) => void;
  quickFilters: QuickFilter[];
  selectedFilter: string | null;
  onQuickFilterSelect: (filter: string, date: Date) => void;
  hideCalendar?: boolean;
}

export const DateFilters: React.FC<DateFiltersProps> = ({
  displayDate,
  onDateChange,
  tempDate,
  onTempDateChange,
  showDatePicker,
  onToggleDatePicker,
  quickFilters,
  selectedFilter,
  onQuickFilterSelect,
  hideCalendar = false,
}) => {
  const formattedDate = format(displayDate, 'dd.MM.yyyy.');
  
  const confirmDate = useCallback(() => {
    onDateChange(tempDate);
    onToggleDatePicker(false);
  }, [onDateChange, onToggleDatePicker, tempDate]);
  
  const cancelDate = useCallback(() => {
    onTempDateChange(displayDate);
    onToggleDatePicker(false);
  }, [displayDate, onTempDateChange, onToggleDatePicker]);

  return (
    <View style={styles.container}>
      <View style={styles.dateRow}>
        <View style={styles.quickFiltersContainer}>
          {!hideCalendar && (
            <TouchableOpacity
              onPress={() => onToggleDatePicker(true)}
              style={[
                styles.filterButton,
                styles.marginRight,
                styles.dateButton,
              ]}
            >
              <View style={styles.dateButtonContent}>
                <MaterialIcons name="calendar-today" size={16} color="#64748b" />
                <Text style={styles.filterButtonText}>{formattedDate}</Text>
              </View>
            </TouchableOpacity>
          )}

          {quickFilters.map((filter, index) => (
            <TouchableOpacity
              key={filter.label}
              style={[
                styles.filterButton,
                selectedFilter === filter.label && styles.activeFilterButton,
                index < quickFilters.length - 1 && styles.marginRight,
              ]}
              onPress={() => onQuickFilterSelect(filter.label, filter.date)}
            >
              <Text
                style={[
                  styles.filterButtonText,
                  selectedFilter === filter.label && styles.activeFilterText,
                ]}
              >
                {filter.label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>

      {!hideCalendar && (
        <Modal
          visible={showDatePicker}
          transparent={true}
          animationType="fade"
          onRequestClose={() => onToggleDatePicker(false)}
        >
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <View style={styles.modalHeader}>
                <MaterialIcons name="event" size={24} color="#2563eb" />
                <Text style={styles.modalTitle}>Odaberi datum</Text>
              </View>
              
              <input
                type="date"
                value={format(tempDate, 'yyyy-MM-dd')}
                onChange={(e) => {
                  if (e.target.value) {
                    onTempDateChange(new Date(e.target.value));
                  }
                }}
                style={{
                  width: '100%',
                  padding: '12px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  border: '1px solid #e2e8f0',
                  marginBottom: '24px',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                  outline: 'none',
                  color: '#334155',
                  maxWidth: '100%',
                  boxSizing: 'border-box'
                }}
              />
              
              <View style={styles.buttonContainer}>
                <Button 
                  mode="outlined" 
                  onPress={cancelDate}
                  style={{ marginRight: 12 }}
                >
                  Odustani
                </Button>
                <Button 
                  mode="contained" 
                  onPress={confirmDate}
                  buttonColor="#2563eb"
                >
                  Potvrdi
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quickFiltersContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  filterButton: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: '#F1F5F9',
    borderRadius: 6,
    marginBottom: 4,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  activeFilterButton: {
    backgroundColor: '#DBEAFE',
    borderColor: '#2563EB',
  },
  marginRight: {
    marginRight: 8,
  },
  filterButtonText: {
    fontSize: 14,
    color: '#64748B',
    fontWeight: '500',
  },
  activeFilterText: {
    color: '#2563EB',
    fontWeight: '600',
  },
  dateButtonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  dateButton: {
    backgroundColor: '#EFF6FF',
    borderColor: '#BFDBFE',
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    padding: 24,
    width: '90%',
    maxWidth: 400,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 12,
    elevation: 5,
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginLeft: 8,
    color: '#1e293b',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
