import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useLocationStats } from '../../../../hooks/useLocationStats';
import { LoadingScreen } from '../../../../components/LoadingScreen';
import { ErrorScreen } from '../../../../components/ErrorScreen';
import { Location } from '../../../../types/location';

interface Props {
  locations: Location[];
}

export const WebLocationStatsGrid: React.FC<Props> = ({ locations }) => {
  const theme = useTheme();
  const { data: stats, isLoading, error, refetch } = useLocationStats();

  React.useEffect(() => {
    refetch();
  }, [locations]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!stats || !locations) {
    return null;
  }

  // Odredi boju za performanse
  const getPerformanceColor = (performance: number) => {
    if (performance >= 95) return '#2E7D32';
    if (performance >= 85) return '#F57C00';
    return '#D32F2F';
  };

  return (
    <View style={styles.container}>
      {locations.map(location => {
        const locationStats = stats?.[location._id] || {
          performance: 0,
          totalWorkMinutes: 0,
          totalBreakMinutes: 0,
          totalEarnings: 0,
          activeEmployees: 0,
          totalEmployees: 0
        };

        const performanceValue = parseFloat(locationStats.performance?.toString() || '0');
        const performanceColor = getPerformanceColor(performanceValue);

        return (
          <View key={location._id} style={styles.locationContainer}>
            {/* Naziv poslovnice */}
            <Card style={styles.card}>
              <Card.Content style={styles.cardContent}>
                <View style={styles.locationNameContainer}>
                  <View style={[styles.iconContainer, { backgroundColor: 'rgba(55, 71, 79, 0.08)', borderColor: 'rgba(55, 71, 79, 0.12)' }]}>
                    <MaterialCommunityIcons 
                      name="store" 
                      size={24} 
                      color={theme.colors.primary} 
                    />
                  </View>
                  <View style={styles.statContent}>
                    <Text style={styles.statLabel}>Poslovnica</Text>
                    <Text style={styles.statValue}>{location.name}</Text>
                  </View>
                </View>
              </Card.Content>
            </Card>

            {/* Performanse */}
            <Card style={styles.card}>
              <Card.Content style={styles.cardContent}>
                <View style={styles.statItem}>
                  <View style={[styles.iconContainer, { backgroundColor: 'rgba(55, 71, 79, 0.08)', borderColor: 'rgba(55, 71, 79, 0.12)' }]}>
                    <MaterialCommunityIcons name="chart-line" size={24} color={performanceColor} />
                  </View>
                  <View style={styles.statContent}>
                    <Text style={styles.statLabel}>Performanse</Text>
                    <Text style={[styles.statValue, { color: performanceColor }]}>{locationStats.performance}%</Text>
                  </View>
                </View>
              </Card.Content>
            </Card>

            {/* Na poslu */}
            <Card style={styles.card}>
              <Card.Content style={styles.cardContent}>
                <View style={styles.statItem}>
                  <View style={[styles.iconContainer, { backgroundColor: 'rgba(55, 71, 79, 0.08)', borderColor: 'rgba(55, 71, 79, 0.12)' }]}>
                    <MaterialCommunityIcons name="account-group" size={24} color="#37474F" />
                  </View>
                  <View style={styles.statContent}>
                    <Text style={styles.statLabel}>Na poslu</Text>
                    <Text style={styles.statValue}>{locationStats.activeEmployees || 0}</Text>
                  </View>
                </View>
              </Card.Content>
            </Card>

            {/* Ukupno djelatnika */}
            <Card style={styles.card}>
              <Card.Content style={styles.cardContent}>
                <View style={styles.statItem}>
                  <View style={[styles.iconContainer, { backgroundColor: 'rgba(55, 71, 79, 0.08)', borderColor: 'rgba(55, 71, 79, 0.12)' }]}>
                    <MaterialCommunityIcons name="account-multiple" size={24} color="#37474F" />
                  </View>
                  <View style={styles.statContent}>
                    <Text style={styles.statLabel}>Ukupno djelatnika</Text>
                    <Text style={styles.statValue}>{locationStats.totalEmployees || 0}</Text>
                  </View>
                </View>
              </Card.Content>
            </Card>

            {/* Ukupno sati */}
            <Card style={styles.card}>
              <Card.Content style={styles.cardContent}>
                <View style={styles.statItem}>
                  <View style={[styles.iconContainer, { backgroundColor: 'rgba(55, 71, 79, 0.08)', borderColor: 'rgba(55, 71, 79, 0.12)' }]}>
                    <MaterialCommunityIcons name="clock-outline" size={24} color="#37474F" />
                  </View>
                  <View style={styles.statContent}>
                    <Text style={styles.statLabel}>Ukupno sati</Text>
                    <Text style={styles.statValue}>{Math.round(locationStats.totalWorkMinutes / 60)}h</Text>
                  </View>
                </View>
              </Card.Content>
            </Card>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    display: 'flex' as any,
    gap: 24,
  },
  locationContainer: {
    display: 'flex' as any,
    flexDirection: 'row',
    flexWrap: 'wrap' as any,
    gap: 16,
    alignItems: 'stretch',
  },
  card: {
    flex: 1,
    minWidth: 200,
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  cardContent: {
    padding: 16,
  },
  locationNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  statItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
  },
  statContent: {
    flex: 1,
  },
  statLabel: {
    fontSize: 14,
    color: '#4A5568',
    marginBottom: 2,
  },
  statValue: {
    fontSize: 16,
    fontWeight: '600',
    color: '#37474F',
  },
});
