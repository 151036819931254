import React, { useState } from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  Text,
  StyleSheet,
  ActivityIndicator,
  Alert,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback,
  Keyboard,
  StatusBar,
} from 'react-native';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { useAuthContext } from '../../context/AuthContext';
import * as Notifications from 'expo-notifications';

interface RouteParams {
  isDarkMode: boolean;
}

type RootStackParamList = {
  Register: { isDarkMode: boolean };
};

type RegisterScreenRouteProp = RouteProp<RootStackParamList, 'Register'>;

export const RegisterScreen = () => {
  const navigation = useNavigation();
  const route = useRoute<RegisterScreenRouteProp>();
  const isDarkMode = route.params?.isDarkMode ?? false;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { register } = useAuthContext();

  const theme = {
    background: isDarkMode ? '#000000' : '#ffffff',
    text: isDarkMode ? '#ffffff' : '#1a1a1a',
    secondaryText: isDarkMode ? '#999999' : '#666666',
    inputBackground: isDarkMode ? '#1a1a1a' : '#ffffff',
    inputBorder: isDarkMode ? '#333333' : '#e6e6e6',
    buttonBackground: isDarkMode ? '#ffffff' : '#1a1a1a',
    buttonText: isDarkMode ? '#000000' : '#ffffff',
  };

  const showErrorNotification = async (message: string) => {
    await Notifications.scheduleNotificationAsync({
      content: {
        title: 'Greška',
        body: message,
        sound: 'default',
      },
      trigger: null,
    });
  };

  const handleRegister = async () => {
    try {
      setHasError(false);
      const response = await register({ name, email, password });
      if (!response.success) {
        setHasError(true);
        setPassword('');
        await showErrorNotification(response.error || 'Neispravni podaci za registraciju');
      }
    } catch (error) {
      setHasError(true);
      setPassword('');
      await showErrorNotification('Neispravni podaci za registraciju');
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <SafeAreaView style={[styles.container, isDarkMode ? styles.darkContainer : styles.lightContainer]}>
        <StatusBar
          barStyle={isDarkMode ? 'light-content' : 'dark-content'}
          backgroundColor={isDarkMode ? '#000000' : '#ffffff'}
        />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.content}
        >
          <View style={styles.logoContainer}>
            <View style={styles.logoTextContainer}>
              <Text style={[styles.logoPart, { color: theme.text }]}>clock</Text>
              <Text style={[styles.logoDot, { color: theme.text }]}>.</Text>
              <Text style={[styles.logoHr, { color: theme.text }]}>hr</Text>
            </View>
            <Text style={[styles.title, { color: theme.text }]}>Registracija</Text>
          </View>

          <View style={styles.form}>
            <View style={styles.inputContainer}>
              <Ionicons
                name="person-outline"
                size={20}
                color={hasError ? '#ff4444' : theme.secondaryText}
                style={styles.inputIcon}
              />
              <TextInput
                style={[
                  styles.input,
                  { 
                    backgroundColor: theme.inputBackground,
                    borderColor: hasError ? '#ff4444' : theme.inputBorder,
                    color: theme.text,
                  },
                ]}
                placeholder="Ime i prezime"
                value={name}
                onChangeText={(text) => {
                  setName(text);
                  setHasError(false);
                }}
                placeholderTextColor={theme.secondaryText}
              />
            </View>

            <View style={styles.inputContainer}>
              <Ionicons
                name="mail-outline"
                size={20}
                color={hasError ? '#ff4444' : theme.secondaryText}
                style={styles.inputIcon}
              />
              <TextInput
                style={[
                  styles.input,
                  { 
                    backgroundColor: theme.inputBackground,
                    borderColor: hasError ? '#ff4444' : theme.inputBorder,
                    color: theme.text,
                  },
                ]}
                placeholder="Email"
                value={email}
                onChangeText={(text) => {
                  setEmail(text);
                  setHasError(false);
                }}
                autoCapitalize="none"
                keyboardType="email-address"
                placeholderTextColor={theme.secondaryText}
              />
            </View>

            <View style={styles.inputContainer}>
              <Ionicons
                name="lock-closed-outline"
                size={20}
                color={hasError ? '#ff4444' : theme.secondaryText}
                style={styles.inputIcon}
              />
              <TextInput
                style={[
                  styles.input,
                  { 
                    backgroundColor: theme.inputBackground,
                    borderColor: hasError ? '#ff4444' : theme.inputBorder,
                    color: theme.text,
                  },
                ]}
                placeholder="Lozinka"
                value={password}
                onChangeText={(text) => {
                  setPassword(text);
                  setHasError(false);
                }}
                secureTextEntry={!showPassword}
                placeholderTextColor={theme.secondaryText}
              />
              <TouchableOpacity
                onPress={() => setShowPassword(!showPassword)}
                style={styles.eyeIcon}
              >
                <Ionicons
                  name={showPassword ? "eye-off-outline" : "eye-outline"}
                  size={20}
                  color={theme.secondaryText}
                />
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={[styles.button, { backgroundColor: theme.buttonBackground }]}
              onPress={handleRegister}
            >
              <Text style={[styles.buttonText, { color: theme.buttonText }]}>
                Registracija
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.loginButton}
              onPress={() => navigation.navigate('Login' as never)}
            >
              <Text style={[styles.loginText, { color: theme.text }]}>
                Već imate račun? Prijavite se
              </Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  darkContainer: {
    backgroundColor: '#000000',
  },
  lightContainer: {
    backgroundColor: '#ffffff',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 50,
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  logoPart: {
    fontSize: 32,
    fontWeight: '600',
    letterSpacing: 1.2,
  },
  logoDot: {
    fontSize: 32,
    fontWeight: '800',
    marginHorizontal: 1,
  },
  logoHr: {
    fontSize: 32,
    fontWeight: '800',
    letterSpacing: 1.2,
  },
  title: {
    fontSize: 38,
    fontWeight: '700',
  },
  form: {
    width: '100%',
  },
  inputContainer: {
    marginBottom: 20,
    position: 'relative',
  },
  input: {
    height: 55,
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 45,
    fontSize: 16,
  },
  inputIcon: {
    position: 'absolute',
    left: 15,
    top: 17,
    zIndex: 1,
  },
  eyeIcon: {
    position: 'absolute',
    right: 15,
    top: 17,
    zIndex: 1,
  },
  button: {
    height: 55,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '600',
  },
  loginButton: {
    marginTop: 20,
    alignItems: 'center',
  },
  loginText: {
    fontSize: 16,
    fontWeight: '500',
  },
});
