import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { PriceCalculator } from './landing/components/PriceCalculator';
import { WebLandingHeader } from './landing/components/WebLandingHeader';
import ActivityLogPreview from './landing/components/ActivityLogPreview';
import { WebFooter } from './landing/components/WebFooter';
import { MetaTags } from '../seo/components/MetaTags';
import { SchemaMarkup } from '../seo/components/SchemaMarkup';
import { pageDescriptions } from '../seo/config/keywords';

const DESKTOP_BREAKPOINT = 1000; // Za header
const WIDE_SCREEN_BREAKPOINT = 1250; // Za activity log pored heroa

export const WebLanding = () => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const isMobile = width < DESKTOP_BREAKPOINT;
  const isWideScreen = width >= WIDE_SCREEN_BREAKPOINT;

  const Feature = ({ icon, title, description }: { icon: string; title: string; description: string }) => (
    <View style={styles.featureCard}>
      <View style={styles.featureIconContainer}>
        <Ionicons name={icon as any} size={24} color="#4C6FFF" />
      </View>
      <Text style={styles.featureTitle}>{title}</Text>
      <Text style={styles.featureDescription}>{description}</Text>
    </View>
  );

  return (
    <View style={styles.container}>
      {/* SEO komponente */}
      <MetaTags 
        title="Evidencija Radnog Vremena | Clock.hr"
        description={pageDescriptions.home}
        path="/"
      />
      <SchemaMarkup type="both" />

      <WebLandingHeader />
      <ScrollView 
        contentContainerStyle={styles.contentContainer}
        style={styles.scrollView}
      >
        {/* Hero sekcija */}
        <View style={styles.heroSection}>
          <View style={[styles.heroContent, !isWideScreen && styles.heroContentMobile]}>
            <View style={[styles.heroLeft, !isWideScreen && styles.heroLeftMobile]}>
              <Text style={[styles.heroTitle, !isWideScreen && styles.heroTitleMobile]}>
                Aplikacija za praćenje radnog vremena djelatnika.
              </Text>
              <Text style={[styles.heroSubtitle, !isWideScreen && styles.heroSubtitleMobile]}>
                Optimizirajte radne procese s naprednom evidencijom radnog vremena za maksimalnu produktivnost i performance poslovnice.
              </Text>
              <TouchableOpacity 
                style={[styles.ctaButton, !isWideScreen && styles.ctaButtonMobile]}
                onPress={() => navigation.navigate('Register' as never)}
              >
                <Text style={styles.ctaButtonText}>Besplatno 7 dana →</Text>
              </TouchableOpacity>
            </View>
            {isWideScreen && (
              <View style={styles.heroRight}>
                <View style={styles.appPreviewContainer}>
                  <View style={styles.appPreviewHeader}>
                    <View style={styles.appPreviewDots}>
                      <View style={[styles.dot, styles.dotRed]} />
                      <View style={[styles.dot, styles.dotYellow]} />
                      <View style={[styles.dot, styles.dotGreen]} />
                    </View>
                    <View style={styles.appPreviewAddressBar}>
                      <View style={styles.appPreviewUrlContainer}>
                        <Text style={styles.appPreviewUrl}>www.clock.hr/logs</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.appPreviewContent}>
                    <ActivityLogPreview />
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>

        {/* Activity Log za uže ekrane */}
        {!isWideScreen && (
          <View style={styles.mobilePreviewContainer}>
            <View style={styles.appPreviewContainer}>
              <View style={styles.appPreviewHeader}>
                <View style={styles.appPreviewDots}>
                  <View style={[styles.dot, styles.dotRed]} />
                  <View style={[styles.dot, styles.dotYellow]} />
                  <View style={[styles.dot, styles.dotGreen]} />
                </View>
                <View style={styles.appPreviewAddressBar}>
                  <View style={styles.appPreviewUrlContainer}>
                    <Text style={styles.appPreviewUrl}>www.clock.hr/logs</Text>
                  </View>
                </View>
              </View>
              <View style={styles.appPreviewContent}>
                <ActivityLogPreview />
              </View>
            </View>
          </View>
        )}

        {/* Značajke sekcija */}
        <View style={[styles.section, isMobile && styles.sectionMobile]} nativeID="znacajke">
          <View style={[styles.sectionContent, isMobile && styles.sectionContentMobile]}>
            <Text style={[styles.sectionTitle, isMobile && styles.sectionTitleMobile]}>Sve što vam treba</Text>
            <View style={[styles.featuresGrid, isMobile && styles.featuresGridMobile]}>
              <Feature
                icon="time-outline"
                title="Precizno praćenje"
                description="Automatsko bilježenje dolazaka i odlazaka s detaljnim pregledom radnih sati"
              />
              <Feature
                icon="stats-chart-outline"
                title="Detaljni izvještaji"
                description="Izvještaji u stvarnom vremenu za bolji uvid u produktivnost tima"
              />
              <Feature
                icon="people-outline"
                title="Upravljanje timom"
                description="Jednostavno upravljanje zaposlenicima i njihovim radnim vremenom"
              />
              <Feature
                icon="notifications-outline"
                title="Pametne obavijesti"
                description="Automatske obavijesti o kašnjenjima i prekovremenim satima"
              />
              <Feature
                icon="shield-checkmark-outline"
                title="Sigurnost podataka"
                description="Najviša razina zaštite podataka vaših zaposlenika"
              />
              <Feature
                icon="document-text-outline"
                title="Kvalitetna obrada"
                description="Automatsko praćenje i izvještavanje u skladu sa korištenjem terminala"
              />
            </View>
          </View>
        </View>

        {/* Kako radi sekcija */}
        <View style={[styles.howItWorksSection, isMobile && styles.howItWorksSectionMobile]} nativeID="kako-radi">
          <View style={[styles.sectionContent, isMobile && styles.sectionContentMobile]}>
            <View style={[styles.sectionHeader, isMobile && styles.sectionHeaderMobile]}>
              <Text style={[styles.sectionTitle, isMobile && styles.sectionTitleMobile]}>Kako radi?</Text>
            </View>
            <View style={[styles.stepsGrid, isMobile && styles.stepsGridMobile]}>
              <View style={[styles.stepCard, isMobile && styles.stepCardMobile]}>
                <View style={styles.stepIconContainer}>
                  <Ionicons name="create-outline" size={24} color="#4C6FFF" />
                  <View style={styles.stepNumberBadge}>
                    <Text style={styles.stepNumberText}>1</Text>
                  </View>
                </View>
                <Text style={[styles.stepTitle, isMobile && styles.stepTitleMobile]}>Kreirajte poslovnicu</Text>
                <Text style={[styles.stepDescription, isMobile && styles.stepDescriptionMobile]}>
                  Unesite osnovne podatke o vašoj poslovnici i tjednom fondu sati. Sustav će se automatski prilagoditi vašim potrebama.
                </Text>
                <View style={styles.stepFeatures}>
                  <View style={styles.stepFeature}>
                    <Ionicons name="checkmark-circle" size={16} color="#10B981" />
                    <Text style={styles.stepFeatureText}>Osnovne informacije</Text>
                  </View>
                  <View style={styles.stepFeature}>
                    <Ionicons name="checkmark-circle" size={16} color="#10B981" />
                    <Text style={styles.stepFeatureText}>Upravljanje tjednim fondom sati</Text>
                  </View>
                </View>
              </View>

              <View style={[styles.stepCard, isMobile && styles.stepCardMobile]}>
                <View style={styles.stepIconContainer}>
                  <Ionicons name="people-outline" size={24} color="#4C6FFF" />
                  <View style={styles.stepNumberBadge}>
                    <Text style={styles.stepNumberText}>2</Text>
                  </View>
                </View>
                <Text style={[styles.stepTitle, isMobile && styles.stepTitleMobile]}>Dodajte djelatnike</Text>
                <Text style={[styles.stepDescription, isMobile && styles.stepDescriptionMobile]}>
                  Dodajte svoje djelatnike u sustav i dodijelite im pristupne podatke. Mogu koristiti aplikaciju na mobitelu ili računalu.
                </Text>
                <View style={styles.stepFeatures}>
                  <View style={styles.stepFeature}>
                    <Ionicons name="checkmark-circle" size={16} color="#10B981" />
                    <Text style={styles.stepFeatureText}>Jednostavno dodavanje</Text>
                  </View>
                  <View style={styles.stepFeature}>
                    <Ionicons name="checkmark-circle" size={16} color="#10B981" />
                    <Text style={styles.stepFeatureText}>Pristup svugdje</Text>
                  </View>
                </View>
              </View>

              <View style={[styles.stepCard, isMobile && styles.stepCardMobile]}>
                <View style={styles.stepIconContainer}>
                  <Ionicons name="time-outline" size={24} color="#4C6FFF" />
                  <View style={styles.stepNumberBadge}>
                    <Text style={styles.stepNumberText}>3</Text>
                  </View>
                </View>
                <Text style={[styles.stepTitle, isMobile && styles.stepTitleMobile]}>Pratite statistiku</Text>
                <Text style={[styles.stepDescription, isMobile && styles.stepDescriptionMobile]}>
                  Pregledavajte detaljne izvještaje o radnom vremenu, kašnjenjima i prekovremenim satima u stvarnom vremenu.
                </Text>
                <View style={styles.stepFeatures}>
                  <View style={styles.stepFeature}>
                    <Ionicons name="checkmark-circle" size={16} color="#10B981" />
                    <Text style={styles.stepFeatureText}>Detaljni izvještaji</Text>
                  </View>
                  <View style={styles.stepFeature}>
                    <Ionicons name="checkmark-circle" size={16} color="#10B981" />
                    <Text style={styles.stepFeatureText}>Izvoz podataka</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* Case Studies sekcija */}
        <View style={[styles.section, styles.caseStudiesSection, isMobile && styles.sectionMobile]} nativeID="case-studies">
          <View style={[styles.sectionContent, isMobile && styles.sectionContentMobile]}>
            <Text style={[styles.sectionTitle, isMobile && styles.sectionTitleMobile]}>Uspješne priče naših klijenata</Text>
            <View style={[styles.caseStudiesGrid, isMobile && styles.caseStudiesGridMobile]}>
              <View style={styles.caseStudyCard}>
                <Image 
                  source={{ uri: 'https://placehold.co/600x400/F8F9FF/1E293B?text=Kozmetički+salon' }}
                  style={styles.caseStudyImage}
                  resizeMode="cover"
                />
                <View style={styles.caseStudyContent}>
                  <Text style={styles.caseStudyTitle}>Kozmetički salon Chup Chup</Text>
                  <Text style={styles.caseStudyDescription}>
                    "Clock.hr nam je omogućio precizno praćenje radnog vremena naših kozmetičarki i optimizaciju rasporeda tretmana. 
                    Sustav je jednostavan za korištenje i značajno je poboljšao našu organizaciju rada."
                  </Text>
                </View>
              </View>

              <View style={styles.caseStudyCard}>
                <Image 
                  source={{ uri: 'https://placehold.co/600x400/F8F9FF/1E293B?text=Frizerski+salon' }}
                  style={styles.caseStudyImage}
                  resizeMode="cover"
                />
                <View style={styles.caseStudyContent}>
                  <Text style={styles.caseStudyTitle}>Frizerski salon Gogh</Text>
                  <Text style={styles.caseStudyDescription}>
                    "Otkako koristimo Clock.hr, naša evidencija radnog vremena je besprijekorna. 
                    Posebno nam se sviđa mogućnost praćenja prekovremenih sati i automatske obavijesti o kašnjenjima."
                  </Text>
                </View>
              </View>

              <View style={styles.caseStudyCard}>
                <Image 
                  source={{ uri: 'https://placehold.co/600x400/F8F9FF/1E293B?text=Tvornica' }}
                  style={styles.caseStudyImage}
                  resizeMode="cover"
                />
                <View style={styles.caseStudyContent}>
                  <Text style={styles.caseStudyTitle}>Svijet stakla grupa d.o.o.</Text>
                  <Text style={styles.caseStudyDescription}>
                    "Kao tvornica s više smjena, trebali smo pouzdan sustav za praćenje radnog vremena. 
                    Clock.hr nam pruža detaljan uvid u rad svake smjene i olakšava upravljanje većim brojem zaposlenika."
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* Sekcija s kalkulatorom cijena */}
        <View style={[styles.section, isMobile && styles.sectionMobile]} nativeID="cijene">
          <View style={[styles.sectionContent, isMobile && styles.sectionContentMobile]}>
            <View style={[styles.sectionHeader, isMobile && styles.sectionHeaderMobile]}>
              <Text style={[styles.sectionTitle, isMobile && styles.sectionTitleMobile]}>
                Transparentne cijene bez skrivenih troškova
              </Text>
              <Text style={[styles.sectionSubtitle, isMobile && styles.sectionSubtitleMobile]}>
                Odaberite plan koji najbolje odgovara vašim potrebama
              </Text>
            </View>
            <PriceCalculator />
          </View>
        </View>

        {/* Prednosti sekcija */}
        <View style={styles.benefitsSection}>
          <View style={styles.sectionContent}>
            <Text style={styles.sectionTitle}>Zašto odabrati Clock.hr?</Text>
            <View style={styles.benefitsGrid}>
              <View style={styles.benefitCard}>
                <View style={styles.benefitIcon}>
                  <Ionicons name="checkmark-circle" size={24} color="#4C6FFF" />
                </View>
                <Text style={styles.benefitTitle}>100% zadovoljnih korisnika</Text>
                <Text style={styles.benefitDescription}>
                  Naši korisnici su iznimno zadovoljni jednostavnošću korištenja i pouzdanošću sustava.
                </Text>
              </View>
              <View style={styles.benefitCard}>
                <View style={styles.benefitIcon}>
                  <Ionicons name="time-outline" size={24} color="#4C6FFF" />
                </View>
                <Text style={styles.benefitTitle}>15 min ušteda vremena dnevno</Text>
                <Text style={styles.benefitDescription}>
                  Prosječna ušteda vremena po zaposleniku na dnevnoj razini korištenjem našeg sustava.
                </Text>
              </View>
              <View style={styles.benefitCard}>
                <View style={styles.benefitIcon}>
                  <Ionicons name="clock-outline" size={24} color="#4C6FFF" />
                </View>
                <Text style={styles.benefitTitle}>24/7 dostupnost</Text>
                <Text style={styles.benefitDescription}>
                  Naš sustav je dostupan u bilo koje vrijeme, s bilo kojeg uređaja.
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* FAQ sekcija */}
        <View style={styles.faqSection} nativeID="faq">
          <View style={styles.sectionContent}>
            <Text style={styles.sectionTitle}>Česta pitanja</Text>
            <View style={styles.faqContainer}>
              <View style={styles.faqItem}>
                <Text style={styles.faqQuestion}>Je li potrebna instalacija?</Text>
                <Text style={styles.faqAnswer}>
                  Ne, naš sustav je potpuno web baziran i ne zahtijeva nikakvu instalaciju.
                </Text>
              </View>
              <View style={styles.faqItem}>
                <Text style={styles.faqQuestion}>Kako se obračunava cijena?</Text>
                <Text style={styles.faqAnswer}>
                  Cijena se obračunava prema broju zaposlenika, s transparentnim mjesečnim plaćanjem.
                </Text>
              </View>
              <View style={styles.faqItem}>
                <Text style={styles.faqQuestion}>Mogu li izvesti podatke?</Text>
                <Text style={styles.faqAnswer}>
                  Da, podaci se mogu izvesti u različitim formatima (PDF, Excel, CSV).
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* CTA sekcija */}
        <View style={[styles.ctaSection, isMobile && styles.ctaSectionMobile]}>
          <View style={[styles.sectionContent, isMobile && styles.sectionContentMobile]}>
            <View style={[styles.ctaContent, isMobile && styles.ctaContentMobile]}>
              <Text style={[styles.ctaTitle, isMobile && styles.ctaTitleMobile]}>
                Spremni ste započeti?
              </Text>
              <Text style={[styles.ctaSubtitle, isMobile && styles.ctaSubtitleMobile]}>
                Isprobajte Clock besplatno 7 dana i uvjerite se u sve prednosti koje vam pruža.
              </Text>
              <View style={[styles.ctaButtons, isMobile && styles.ctaButtonsMobile]}>
                <TouchableOpacity 
                  style={[styles.ctaMainButton, isMobile && styles.ctaMainButtonMobile]}
                  onPress={() => navigation.navigate('Register' as never)}
                >
                  <Text style={styles.ctaMainButtonText}>Započnite besplatno →</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  style={[styles.ctaSecondaryButton, isMobile && styles.ctaSecondaryButtonMobile]}
                  onPress={() => navigation.navigate('Login' as never)}
                >
                  <Text style={styles.ctaSecondaryButtonText}>Već imate račun?</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <WebFooter />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  contentContainer: {
    flexGrow: 1,
  },
  scrollView: {
    flex: 1,
  },
  section: {
    width: '100%',
    paddingVertical: 80,
    alignItems: 'center',
    marginTop: -40, // Dodano za konzistentan razmak
  },
  sectionMobile: {
    paddingVertical: 24,
    marginTop: 0, // Dodano za mobilnu verziju
  },
  sectionContent: {
    width: '100%',
    maxWidth: 1200,
    paddingHorizontal: 24,
    alignItems: 'center',
  },
  sectionContentMobile: {
    paddingHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 36,
    fontWeight: '700',
    color: '#1E293B',
    textAlign: 'center',
    marginBottom: 48,
  },
  sectionTitleMobile: {
    fontSize: 28,
    marginBottom: 32,
  },
  featuresGrid: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 24,
  },
  featuresGridMobile: {
    gap: 16,
  },
  howItWorksSection: {
    width: '100%',
    paddingVertical: 80,
    backgroundColor: '#F8F9FF',
    alignItems: 'center',
  },
  howItWorksSectionMobile: {
    paddingVertical: 40,
  },
  stepsGrid: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 24,
  },
  stepsGridMobile: {
    gap: 16,
  },
  heroSection: {
    width: '100%',
    minHeight: '60vh',
    paddingHorizontal: 24,
    paddingTop: 40,
    paddingBottom: 0,
    backgroundColor: '#FFFFFF',
  },
  heroContent: {
    maxWidth: 1200,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  heroContentMobile: {
    flexDirection: 'column',
    paddingBottom: 0,
  },
  heroLeft: {
    flex: 1,
    maxWidth: 560,
  },
  heroLeftMobile: {
    maxWidth: '100%',
    alignItems: 'center',
  },
  heroRight: {
    flex: 1,
    maxWidth: 560,
    alignItems: 'center',
  },
  heroTitle: {
    fontSize: 48,
    lineHeight: 56,
    fontWeight: '700',
    color: '#1E293B',
    marginBottom: 24,
  },
  heroTitleMobile: {
    fontSize: 32,
    lineHeight: 40,
    textAlign: 'center',
    marginBottom: 16,
  },
  heroSubtitle: {
    fontSize: 18,
    lineHeight: 28,
    color: '#475569',
    marginBottom: 32,
  },
  heroSubtitleMobile: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 24,
  },
  ctaButton: {
    backgroundColor: '#4C6FFF',
    paddingHorizontal: 32,
    paddingVertical: 16,
    borderRadius: 12,
    marginTop: 32,
  },
  ctaButtonMobile: {
    width: '100%',
    marginTop: 24,
  },
  ctaButtonText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: '600',
  },
  sectionHeader: {
    marginBottom: 64,
    alignItems: 'center',
  },
  sectionHeaderMobile: {
    marginBottom: 32,
  },
  sectionSubtitle: {
    fontSize: 18,
    color: '#64748B',
    textAlign: 'center',
    maxWidth: 600,
    marginHorizontal: 'auto',
    marginTop: 16,
  },
  sectionSubtitleMobile: {
    fontSize: 16,
    marginTop: 12,
    paddingHorizontal: 24,
  },
  featureCard: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    borderRadius: 16,
    width: '30%',
    minWidth: 280,
    maxWidth: 350,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    elevation: 4,
  },
  featureIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#F0F3FF',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  featureTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  featureDescription: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
  },
  pricingSection: {
    width: '100%',
    paddingVertical: 80,
    backgroundColor: '#FFFFFF',
  },
  pricingSectionMobile: {
    paddingVertical: 40,
  },
  ctaSection: {
    width: '100%',
    backgroundColor: '#F8FAFF',
    paddingVertical: 80,
    alignItems: 'center',
  },
  ctaSectionMobile: {
    paddingVertical: 48,
  },
  ctaContent: {
    width: '100%',
    maxWidth: 1200,
    alignItems: 'center',
    textAlign: 'center',
  },
  ctaContentMobile: {
    paddingHorizontal: 24,
  },
  ctaTitle: {
    fontSize: 36,
    fontWeight: '700',
    color: '#1E293B',
    textAlign: 'center',
    marginBottom: 16,
  },
  ctaTitleMobile: {
    fontSize: 28,
    marginBottom: 12,
  },
  ctaSubtitle: {
    fontSize: 18,
    color: '#64748B',
    textAlign: 'center',
    marginBottom: 32,
    maxWidth: 600,
  },
  ctaSubtitleMobile: {
    fontSize: 16,
    marginBottom: 24,
  },
  ctaButtons: {
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  ctaButtonsMobile: {
    flexDirection: 'column',
    width: '100%',
  },
  ctaMainButton: {
    backgroundColor: '#4C6FFF',
    paddingVertical: 16,
    paddingHorizontal: 32,
    borderRadius: 12,
  },
  ctaMainButtonMobile: {
    width: '100%',
    alignItems: 'center',
  },
  ctaMainButtonText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: '600',
  },
  ctaSecondaryButton: {
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  ctaSecondaryButtonMobile: {
    width: '100%',
    alignItems: 'center',
  },
  ctaSecondaryButtonText: {
    color: '#4C6FFF',
    fontSize: 16,
    fontWeight: '500',
  },
  stepCard: {
    backgroundColor: '#FFFFFF',
    padding: 32,
    borderRadius: 16,
    width: '30%',
    minWidth: 280,
    maxWidth: 350,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    elevation: 4,
  },
  stepCardMobile: {
    padding: 24,
    width: '100%',
  },
  stepIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#F0F3FF',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
    position: 'relative',
  },
  stepNumberBadge: {
    position: 'absolute',
    top: -4,
    right: -4,
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: '#4C6FFF',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepNumberText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: '600',
  },
  stepTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  stepTitleMobile: {
    fontSize: 18,
  },
  stepDescription: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
    marginBottom: 24,
  },
  stepDescriptionMobile: {
    marginBottom: 20,
  },
  stepFeatures: {
    gap: 12,
  },
  stepFeature: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  stepFeatureText: {
    fontSize: 14,
    color: '#1E293B',
  },
  benefitsSection: {
    width: '100%',
    paddingVertical: 80,
    backgroundColor: '#F8FAFC',
    alignItems: 'center',
  },
  benefitsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 24,
    width: '100%',
    maxWidth: 1000,
    marginHorizontal: 'auto',
  },
  benefitCard: {
    backgroundColor: '#FFFFFF',
    padding: 32,
    borderRadius: 16,
    width: 300,
    alignItems: 'center',
    textAlign: 'center',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  } as any,
  benefitIcon: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#F0F4FF',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  benefitTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
    textAlign: 'center',
  },
  benefitDescription: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
    textAlign: 'center',
  },
  faqSection: {
    width: '100%',
    paddingVertical: 80,
    backgroundColor: '#F8FAFC',
    alignItems: 'center',
  },
  faqContainer: {
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
    gap: 24,
    alignItems: 'center',
  },
  faqItem: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  } as any,
  faqQuestion: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  faqAnswer: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
  },
  appPreviewContainer: {
    width: '100%',
    maxWidth: 600,
    height: 400,
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.15)',
  },
  appPreviewHeader: {
    height: 44,
    backgroundColor: '#F8FAFC',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  appPreviewDots: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 8,
    transition: 'opacity 0.2s ease',
    ':hover': {
      opacity: 0.8,
    } as any,
  },
  dotRed: {
    backgroundColor: '#FF5F57',
    boxShadow: '0 0 0 1px rgba(255, 95, 87, 0.1)',
  },
  dotYellow: {
    backgroundColor: '#FFBD2E',
    boxShadow: '0 0 0 1px rgba(255, 189, 46, 0.1)',
  },
  dotGreen: {
    backgroundColor: '#28CA41',
    boxShadow: '0 0 0 1px rgba(40, 202, 65, 0.1)',
  },
  appPreviewAddressBar: {
    flex: 1,
    height: 28,
    backgroundColor: '#F1F5F9',
    borderRadius: 6,
    paddingHorizontal: 12,
    justifyContent: 'center',
  },
  appPreviewUrlContainer: {
    backgroundColor: '#F1F5F9',
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 200,
  },
  appPreviewUrl: {
    fontSize: 14,
    color: '#64748B',
    fontWeight: '500',
  },
  appPreviewContent: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  appPreviewPlaceholder: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F8FAFC',
  },
  appPreviewPlaceholderText: {
    fontSize: 18,
    color: '#64748B',
    fontWeight: '500',
  },
  mobilePreviewContainer: {
    width: '100%',
    marginTop: -32,
    paddingHorizontal: 24,
    paddingBottom: 32,
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  caseStudiesSection: {
    backgroundColor: '#F8F9FF',
  },
  caseStudiesGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 24,
  },
  caseStudiesGridMobile: {
    gap: 16,
  },
  caseStudyCard: {
    backgroundColor: '#FFFFFF',
    padding: 32,
    borderRadius: 16,
    width: '30%',
    minWidth: 280,
    maxWidth: 350,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    elevation: 4,
  },
  caseStudyImage: {
    width: '100%',
    height: 200,
    borderRadius: 16,
    marginBottom: 24,
  },
  caseStudyContent: {
    padding: 24,
  },
  caseStudyTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  caseStudyDescription: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
  },
});
