import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Card, DataTable, useTheme } from 'react-native-paper';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns';
import { hr } from 'date-fns/locale';
import { useQuery } from '@tanstack/react-query';
import { employeesAPI } from '../../../../services/api';
import { DateFilters } from './admin-controls';
import { WebEmployeeDetailedStatsPDF } from './WebEmployeeDetailedStatsPDF';

interface Props {
  employeeId: string;
}

interface DayStats {
  date: string;
  employeeName: string;
  locationName: string;
  totalWorkMinutes: number;
  effectiveWorkMinutes: number;
  totalBreakMinutes: number;
  paidTime: number;
  totalPaidBreakMinutes: number;
  totalUnpaidBreakMinutes: number;
  overPaidBreakMinutes: number;
  overUnpaidBreakMinutes: number;
  earnings: number;
  deductions: number;
}

const formatDuration = (minutes: number) => {
  if (minutes === 0) return '0min';
  
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes % 1) * 60);
  
  let result = '';
  if (hours > 0) result += `${hours}h `;
  if (mins > 0 || hours > 0) result += `${mins}min `;
  if (secs > 0 || (minutes > 0 && minutes < 1)) result += `${secs}s`;
  
  return result.trim();
};

const formatMoney = (amount: number) => {
  return `${amount.toFixed(2)} €`;
};

export const WebEmployeeDetailedStats: React.FC<Props> = ({ employeeId }) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState({
    start: startOfWeek(selectedDate, { locale: hr }),
    end: endOfWeek(selectedDate, { locale: hr })
  });

  const quickFilters = [
    { label: 'Ovaj tjedan', date: startOfWeek(new Date(), { locale: hr }) },
    { label: 'Prošli tjedan', date: startOfWeek(new Date(), { locale: hr }) },
    { label: 'Ovaj mjesec', date: startOfMonth(new Date()) },
    { label: 'Prošli mjesec', date: startOfMonth(new Date()) },
  ];

  const handleQuickFilter = (filter: string, date: Date) => {
    setSelectedFilter(filter);
    setSelectedDate(date);

    let start, end;
    switch (filter) {
      case 'Ovaj tjedan':
        start = startOfWeek(new Date(), { locale: hr });
        end = endOfWeek(new Date(), { locale: hr });
        break;
      case 'Prošli tjedan':
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        start = startOfWeek(lastWeek, { locale: hr });
        end = endOfWeek(lastWeek, { locale: hr });
        break;
      case 'Ovaj mjesec':
        start = startOfMonth(new Date());
        end = endOfMonth(new Date());
        break;
      case 'Prošli mjesec':
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        start = startOfMonth(lastMonth);
        end = endOfMonth(lastMonth);
        break;
      default:
        start = startOfWeek(date, { locale: hr });
        end = endOfWeek(date, { locale: hr });
    }
    
    setDateRange({ start, end });
  };

  const { data: periodStats, isLoading } = useQuery({
    queryKey: ['employeeWeeklyStats', employeeId, format(dateRange.start, 'yyyy-MM-dd')],
    queryFn: () => employeesAPI.getEmployeeWeeklyStats(
      employeeId,
      format(dateRange.start, 'yyyy-MM-dd'),
      format(dateRange.end, 'yyyy-MM-dd')
    ),
  });

  const daysInPeriod = eachDayOfInterval({
    start: dateRange.start,
    end: dateRange.end
  });

  // Izračunaj ukupne statistike za period
  const periodTotals = periodStats?.days.reduce((acc, day) => ({
    totalWorkMinutes: acc.totalWorkMinutes + (day.totalWorkMinutes || 0),
    effectiveWorkMinutes: acc.effectiveWorkMinutes + (day.effectiveWorkMinutes || 0),
    totalBreakMinutes: acc.totalBreakMinutes + (day.totalBreakMinutes || 0),
    paidTime: acc.paidTime + (day.paidTime || 0),
    totalPaidBreakMinutes: acc.totalPaidBreakMinutes + (day.totalPaidBreakMinutes || 0),
    totalUnpaidBreakMinutes: acc.totalUnpaidBreakMinutes + (day.totalUnpaidBreakMinutes || 0),
    overPaidBreakMinutes: acc.overPaidBreakMinutes + (day.overPaidBreakMinutes || 0),
    overUnpaidBreakMinutes: acc.overUnpaidBreakMinutes + (day.overUnpaidBreakMinutes || 0),
    totalOverBreakMinutes: acc.totalOverBreakMinutes + ((day.overPaidBreakMinutes || 0) + (day.overUnpaidBreakMinutes || 0)),
    deductions: acc.deductions + (day.deductions || 0),
    earnings: acc.earnings + (day.earnings || 0)
  }), {
    totalWorkMinutes: 0,
    effectiveWorkMinutes: 0,
    totalBreakMinutes: 0,
    paidTime: 0,
    totalPaidBreakMinutes: 0,
    totalUnpaidBreakMinutes: 0,
    overPaidBreakMinutes: 0,
    overUnpaidBreakMinutes: 0,
    totalOverBreakMinutes: 0,
    deductions: 0,
    earnings: 0
  });

  return (
    <View style={styles.container}>
      <Card style={styles.tableCard}>
        <Card.Title title="Pregled po danima" />
        <Card.Content>
          <View style={styles.filterContainer}>
            <View style={styles.filterRow}>
              <DateFilters
                displayDate={selectedDate}
                onDateChange={setSelectedDate}
                tempDate={selectedDate}
                onTempDateChange={setSelectedDate}
                showDatePicker={false}
                onToggleDatePicker={() => {}}
                quickFilters={quickFilters}
                selectedFilter={selectedFilter}
                onQuickFilterSelect={handleQuickFilter}
                hideCalendar={true}
              />
              {periodStats && !isLoading && (
                <WebEmployeeDetailedStatsPDF
                  employeeId={employeeId}
                  startDate={dateRange.start}
                  endDate={dateRange.end}
                  periodStats={periodStats}
                />
              )}
            </View>
          </View>
          <DataTable>
            <DataTable.Header style={styles.tableHeader}>
              <DataTable.Title style={[styles.dateColumn, styles.headerCell]}>
                <Text style={styles.headerText}>Datum</Text>
              </DataTable.Title>
              <DataTable.Title style={[styles.nameColumn, styles.headerCell]}>
                <Text style={styles.headerText}>Ime i{'\n'}prezime</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Ukupno{'\n'}rad</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Efektivno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Placeno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Pauze{'\n'}ukupno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Placene{'\n'}pauze</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Neplacene{'\n'}pauze</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Preko.{'\n'}placenih</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Preko.{'\n'}neplacenih</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Preko.{'\n'}ukupno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Odbitak{'\n'}(€)</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Zarada{'\n'}(€)</Text>
              </DataTable.Title>
            </DataTable.Header>

            {isLoading ? (
              <DataTable.Row>
                <DataTable.Cell>Učitavanje...</DataTable.Cell>
              </DataTable.Row>
            ) : (
              daysInPeriod.map(day => {
                const dayStats = periodStats?.days.find(
                  d => d.date === format(day, 'yyyy-MM-dd')
                );
                
                return (
                  <DataTable.Row key={format(day, 'yyyy-MM-dd')} style={styles.tableRow}>
                    <DataTable.Cell style={[styles.dateColumn, styles.cell]}>
                      {format(day, 'dd.MM.', { locale: hr })}
                    </DataTable.Cell>
                    <DataTable.Cell style={[styles.nameColumn, styles.cell]}>
                      {dayStats?.employeeName ?? '-'}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.totalWorkMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.effectiveWorkMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.paidTime ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.totalBreakMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.totalPaidBreakMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.totalUnpaidBreakMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.overPaidBreakMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration(dayStats?.overUnpaidBreakMinutes ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatDuration((dayStats?.overPaidBreakMinutes ?? 0) + (dayStats?.overUnpaidBreakMinutes ?? 0))}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatMoney(dayStats?.deductions ?? 0)}
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                      {formatMoney(dayStats?.earnings ?? 0)}
                    </DataTable.Cell>
                  </DataTable.Row>
                );
              })
            )}

            {periodTotals && (
              <DataTable.Row style={styles.totalRow}>
                <DataTable.Cell style={[styles.dateColumn, styles.cell]}>Ukupno</DataTable.Cell>
                <DataTable.Cell style={[styles.nameColumn, styles.cell]}></DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.totalWorkMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.effectiveWorkMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.paidTime)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.totalBreakMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.totalPaidBreakMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.totalUnpaidBreakMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.overPaidBreakMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.overUnpaidBreakMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatDuration(periodTotals.totalOverBreakMinutes)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatMoney(periodTotals.deductions)}
                </DataTable.Cell>
                <DataTable.Cell numeric style={[styles.dataCell, styles.cell]}>
                  {formatMoney(periodTotals.earnings)}
                </DataTable.Cell>
              </DataTable.Row>
            )}
          </DataTable>
        </Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    gap: 16,
  },
  tableCard: {
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
  },
  filterContainer: {
    marginBottom: 16,
  },
  filterRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableHeader: {
    backgroundColor: '#f3f4f6',
    minHeight: 56,
  },
  headerCell: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
  },
  headerText: {
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 16,
  },
  cell: {
    minHeight: 40,
    justifyContent: 'center',
    paddingVertical: 4,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
  },
  totalRow: {
    backgroundColor: '#f3f4f6',
    borderTopWidth: 2,
    borderTopColor: '#e5e7eb',
    fontWeight: 'bold',
  },
  dateColumn: {
    flex: 0.4,
  },
  nameColumn: {
    flex: 1.2,
  },
  dataCell: {
    flex: 0.6,
    paddingHorizontal: 2,
    textAlign: 'right',
  },
});
