import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Alert,
} from 'react-native';
import { useAuthContext } from '../../context/AuthContext';
import { Ionicons } from '@expo/vector-icons';

export const SettingsScreen = ({ navigation }: any) => {
  const { owner, logout } = useAuthContext();

  const handleLogout = async () => {
    Alert.alert(
      'Odjava',
      'Jeste li sigurni da se želite odjaviti?',
      [
        {
          text: 'Odustani',
          style: 'cancel',
          onPress: () => {}
        },
        {
          text: 'Odjavi se',
          style: 'destructive',
          onPress: async () => {
            await logout(navigation);
          }
        }
      ]
    );
  };

  const renderSettingsItem = (
    title: string,
    subtitle: string,
    iconName: any,
    onPress: () => void,
    showArrow: boolean = true
  ) => (
    <TouchableOpacity style={styles.settingsItem} onPress={onPress}>
      <View style={styles.settingsItemLeft}>
        <View style={styles.iconContainer}>
          <Ionicons name={iconName} size={24} color="#007AFF" />
        </View>
        <View style={styles.settingsItemContent}>
          <Text style={styles.settingsItemTitle}>{title}</Text>
          <Text style={styles.settingsItemSubtitle}>{subtitle}</Text>
        </View>
      </View>
      {showArrow && (
        <Ionicons name="chevron-forward" size={24} color="#C7C7CC" />
      )}
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Račun</Text>
          <TouchableOpacity
            style={styles.settingsItem}
            onPress={() => navigation.navigate('AccountSettings')}
          >
            <View style={styles.settingsItemLeft}>
              <View style={styles.iconContainer}>
                <Ionicons name="person-circle-outline" size={24} color="#666" />
              </View>
              <View style={styles.settingsItemContent}>
                <Text style={styles.settingsItemTitle}>Račun</Text>
                <Text style={styles.settingsItemSubtitle}>Ime, email i osobni podaci</Text>
              </View>
            </View>
            <Ionicons name="chevron-forward" size={24} color="#666" />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingsItem}
            onPress={() => navigation.navigate('CompanySettings')}
          >
            <View style={styles.settingsItemLeft}>
              <View style={styles.iconContainer}>
                <Ionicons name="business-outline" size={24} color="#666" />
              </View>
              <View style={styles.settingsItemContent}>
                <Text style={styles.settingsItemTitle}>Tvrtka</Text>
                <Text style={styles.settingsItemSubtitle}>Podaci o tvrtki</Text>
              </View>
            </View>
            <Ionicons name="chevron-forward" size={24} color="#666" />
          </TouchableOpacity>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Ostalo</Text>
          <TouchableOpacity
            style={styles.settingsItem}
            onPress={() => navigation.navigate('Logs')}
          >
            <View style={styles.settingsItemLeft}>
              <View style={styles.iconContainer}>
                <Ionicons name="list-outline" size={24} color="#666" />
              </View>
              <View style={styles.settingsItemContent}>
                <Text style={styles.settingsItemTitle}>Logovi djelatnika</Text>
                <Text style={styles.settingsItemSubtitle}>Povijest aktivnosti djelatnika</Text>
              </View>
            </View>
            <Ionicons name="chevron-forward" size={24} color="#666" />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingsItem}
            onPress={() => navigation.navigate('About')}
          >
            <View style={styles.settingsItemLeft}>
              <View style={styles.iconContainer}>
                <Ionicons name="information-circle-outline" size={24} color="#666" />
              </View>
              <View style={styles.settingsItemContent}>
                <Text style={styles.settingsItemTitle}>O aplikaciji</Text>
                <Text style={styles.settingsItemSubtitle}>Verzija i novosti</Text>
              </View>
            </View>
            <Ionicons name="chevron-forward" size={24} color="#666" />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingsItem}
            onPress={() => navigation.navigate('FAQ')}
          >
            <View style={styles.settingsItemLeft}>
              <View style={styles.iconContainer}>
                <Ionicons name="help-circle-outline" size={24} color="#666" />
              </View>
              <View style={styles.settingsItemContent}>
                <Text style={styles.settingsItemTitle}>Pomoć</Text>
                <Text style={styles.settingsItemSubtitle}>Upute i česta pitanja</Text>
              </View>
            </View>
            <Ionicons name="chevron-forward" size={24} color="#666" />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingsItem}
            onPress={() => navigation.navigate('Legal')}
          >
            <View style={styles.settingsItemLeft}>
              <View style={styles.iconContainer}>
                <Ionicons name="document-text-outline" size={24} color="#666" />
              </View>
              <View style={styles.settingsItemContent}>
                <Text style={styles.settingsItemTitle}>Pravni dokumenti</Text>
                <Text style={styles.settingsItemSubtitle}>Uvjeti korištenja i privatnost</Text>
              </View>
            </View>
            <Ionicons name="chevron-forward" size={24} color="#666" />
          </TouchableOpacity>
        </View>
      </ScrollView>
      <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
        <Ionicons name="log-out-outline" size={24} color="#FF3B30" />
        <Text style={styles.logoutText}>Odjava</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  section: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  sectionTitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 8,
  },
  settingsItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#C7C7CC',
  },
  settingsItemLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    width: 30,
    alignItems: 'center',
    marginRight: 10,
  },
  settingsItemContent: {
    flex: 1,
  },
  settingsItemTitle: {
    fontSize: 17,
    color: '#000',
  },
  settingsItemSubtitle: {
    fontSize: 14,
    color: '#666',
    marginTop: 2,
  },
  logoutButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    marginHorizontal: 16,
    marginBottom: 32,
    backgroundColor: '#FFF0F0',
    borderRadius: 12,
  },
  logoutText: {
    marginLeft: 8,
    fontSize: 17,
    fontWeight: '600',
    color: '#FF3B30',
  },
});
