import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, useTheme, ActivityIndicator, List, Divider, Button } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation, NavigationProp, ParamListBase } from '@react-navigation/native';
import { employeesAPI } from '../../../services/api';
import { WebEmptyState } from '../dashboard/WebEmptyState';

type Location = {
  _id: string;
  name: string;
};

type RootStackParamList = {
  LocationLogs: {
    locationId: string;
    locationName: string;
  };
};

export const WebLogsScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<ParamListBase, 'WebLogs'>>();

  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await employeesAPI.getLocations();
      setLocations(data);
    } catch (err) {
      console.error('Greška pri dohvaćanju poslovnica:', err);
      setError('Došlo je do greške pri dohvaćanju poslovnica');
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        ) : error ? (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{error}</Text>
            <Button 
              mode="contained" 
              onPress={fetchLocations}
              style={styles.retryButton}
            >
              Pokušaj ponovno
            </Button>
          </View>
        ) : locations.length === 0 ? (
          <WebEmptyState 
            title="Nemate još dodanih poslovnica"
            description="Dodajte svoju prvu poslovnicu kako biste mogli pratiti logove"
            actionText="Dodaj poslovnicu"
            iconName="fingerprint-off"
            navigationRoute="CreateLocation"
          />
        ) : (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Odaberite poslovnicu</Text>
            <View style={styles.locationsContainer}>
              {locations.map((location, index) => (
                <React.Fragment key={location._id}>
                  <List.Item
                    title={location.name}
                    left={props => (
                      <MaterialCommunityIcons
                        name="office-building"
                        size={24}
                        color={theme.colors.primary}
                        style={styles.locationIcon}
                      />
                    )}
                    right={props => (
                      <MaterialCommunityIcons
                        name="chevron-right"
                        size={24}
                        color="#666"
                        style={styles.chevronIcon}
                      />
                    )}
                    onPress={() => {
                      if (location._id) {
                        navigation.navigate('LocationLogs', { 
                          locationId: location._id,
                          locationName: location.name
                        });
                      }
                    }}
                    style={styles.locationItem}
                  />
                  {index < locations.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
    padding: 24,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 24,
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  errorText: {
    color: '#EF4444',
    fontSize: 16,
    marginBottom: 16,
    textAlign: 'center',
  },
  retryButton: {
    borderRadius: 8,
  },
  section: {
    flex: 1,
  },
  sectionTitle: {
    fontSize: 13,
    color: '#666',
    marginBottom: 8,
    letterSpacing: 0.1,
  },
  locationsContainer: {
    backgroundColor: 'white',
    borderRadius: 16,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  locationItem: {
    paddingVertical: 12,
    cursor: 'pointer',
  },
  locationIcon: {
    marginLeft: 8,
    marginRight: 8,
  },
  chevronIcon: {
    marginRight: 8,
  },
});
