import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Button, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface ErrorScreenProps {
  error: Error | unknown;
  onRetry?: () => void;
}

export const ErrorScreen = ({ error, onRetry }: ErrorScreenProps) => {
  const theme = useTheme();
  
  return (
    <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
      <MaterialCommunityIcons 
        name="alert-circle-outline" 
        size={64} 
        color={theme.colors.error} 
      />
      
      <Text variant="titleMedium" style={[styles.message, { color: theme.colors.error }]}>
        Došlo je do greške
      </Text>
      
      <Text variant="bodyMedium" style={styles.details}>
        {error instanceof Error ? error.message : 'Nepoznata greška'}
      </Text>
      
      {onRetry && (
        <Button 
          mode="contained" 
          onPress={onRetry}
          style={styles.button}
        >
          Pokušaj ponovno
        </Button>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  message: {
    marginTop: 16,
    fontWeight: 'bold',
  },
  details: {
    marginTop: 8,
    textAlign: 'center',
    opacity: 0.7,
  },
  button: {
    marginTop: 24,
  },
});
