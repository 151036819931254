import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const Terms = () => {
  return (
    <HelpLayout>
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Uvjeti korištenja</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Opći uvjeti poslovanja</Text>
            <Text style={styles.description}>
              Pravila i uvjeti korištenja Clock.hr sustava, uključujući prava i obveze korisnika te ograničenja odgovornosti.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Predmet uvjeta</Text>
              <Text style={styles.paragraph}>
                Ovi Opći uvjeti reguliraju prava i obveze između Clock.hr (u daljnjem tekstu: "Pružatelj usluge") i poslovnih 
                subjekata koji koriste Clock.hr sustav za evidenciju radnog vremena svojih zaposlenika 
                (u daljnjem tekstu: "Poslodavac" ili "Korisnik").
              </Text>

              <Text style={styles.heading}>2. Opis usluge</Text>
              <Text style={styles.paragraph}>Clock.hr je sustav za evidenciju radnog vremena koji omogućuje:</Text>
              <Text style={styles.bulletPoint}>• Evidenciju dolazaka i odlazaka zaposlenika putem PIN-a na terminalima</Text>
              <Text style={styles.bulletPoint}>• Praćenje pauza i odsutnosti zaposlenika</Text>
              <Text style={styles.bulletPoint}>• Generiranje izvještaja o radnom vremenu</Text>
              <Text style={styles.bulletPoint}>• Izračun radnih sati i obračun plaća na temelju radnog vremena</Text>
              <Text style={styles.bulletPoint}>• Upravljanje pravima pristupa za različite razine korisnika</Text>
              <Text style={styles.bulletPoint}>• Administraciju od strane poslodavca (unos i upravljanje podacima djelatnika)</Text>

              <Text style={styles.heading}>3. Registracija i korisnički račun</Text>
              <Text style={styles.paragraph}>
                3.1. Da bi koristio Clock.hr uslugu, Poslodavac se mora registrirati i stvoriti korisnički račun.
              </Text>
              <Text style={styles.paragraph}>
                3.2. Prilikom registracije, Poslodavac je dužan pružiti točne, ažurne i potpune informacije o svojoj tvrtki.
              </Text>
              <Text style={styles.paragraph}>
                3.3. Poslodavac je odgovoran za održavanje sigurnosti svojih pristupnih podataka i pristupnih podataka 
                svojih zaposlenika. Pružatelj usluge ne snosi odgovornost za neovlašteni pristup koji je rezultat 
                nemarnog čuvanja pristupnih podataka.
              </Text>
              <Text style={styles.paragraph}>
                3.4. Poslodavac je dužan odmah obavijestiti Pružatelja usluge o bilo kakvoj neovlaštenoj upotrebi 
                ili sumnji na neovlaštenu upotrebu svog računa.
              </Text>

              <Text style={styles.heading}>4. Obveze poslodavca</Text>
              <Text style={styles.paragraph}>Poslodavac se obvezuje:</Text>
              <Text style={styles.paragraph}>
                4.1. Koristiti sustav u skladu s ovim Uvjetima korištenja i svim primjenjivim zakonima i propisima, 
                posebno onima koji se odnose na radno pravo i zaštitu osobnih podataka.
              </Text>
              <Text style={styles.paragraph}>
                4.2. Osigurati da su svi podaci koje unosi u sustav (podaci o zaposlenicima, radnom vremenu, pauzama, itd.) 
                točni i ažurni.
              </Text>
              <Text style={styles.paragraph}>
                4.3. Educirate svoje zaposlenike o pravilnom korištenju sustava za evidenciju radnog vremena putem PIN-a.
              </Text>
              <Text style={styles.paragraph}>
                4.4. Čuvati povjerljivost pristupnih podataka (administratorskih i PIN-ova zaposlenika).
              </Text>
              <Text style={styles.paragraph}>
                4.5. Ne koristiti sustav za bilo kakve nezakonite, štetne ili prijevarne aktivnosti.
              </Text>
              <Text style={styles.paragraph}>
                4.6. Odmah prijaviti eventualne nepravilnosti ili probleme u radu sustava Pružatelju usluge.
              </Text>

              <Text style={styles.heading}>5. Obveze zaposlenika poslodavca</Text>
              <Text style={styles.paragraph}>
                5.1. Zaposlenici Poslodavca koji koriste Clock.hr putem PIN-a na terminalima dužni su:
              </Text>
              <Text style={styles.bulletPoint}>• Točno evidentirati svoje dolaske, odlaske i pauze</Text>
              <Text style={styles.bulletPoint}>• Koristiti isključivo svoj osobni PIN za evidenciju</Text>
              <Text style={styles.bulletPoint}>• Ne dijeliti svoj PIN s drugim osobama</Text>
              <Text style={styles.bulletPoint}>• Odmah prijaviti Poslodavcu bilo kakve probleme pri korištenju sustava</Text>
              <Text style={styles.paragraph}>
                5.2. Poslodavac je odgovoran osigurati da njegovi zaposlenici razumiju i poštuju ove obveze.
              </Text>

              <Text style={styles.heading}>6. Prava i obveze pružatelja usluge</Text>
              <Text style={styles.paragraph}>Pružatelj usluge se obvezuje:</Text>
              <Text style={styles.paragraph}>
                6.1. Pružiti funkcionalnu i sigurnu uslugu za evidenciju radnog vremena koja je u skladu sa zakonskim 
                propisima Republike Hrvatske.
              </Text>
              <Text style={styles.paragraph}>
                6.2. Održavati sustav i osigurati njegovu dostupnost, uz moguće povremene prekide zbog održavanja.
              </Text>
              <Text style={styles.paragraph}>
                6.3. Pružati tehničku podršku Poslodavcu za korištenje sustava.
              </Text>
              <Text style={styles.paragraph}>
                6.4. Čuvati povjerljivost podataka Poslodavca i njegovih zaposlenika u skladu s Politikom privatnosti.
              </Text>
              <Text style={styles.paragraph}>
                6.5. Obavijestiti Poslodavca o planiranim prekidima u radu sustava zbog održavanja.
              </Text>

              <Text style={styles.heading}>7. Cijene i plaćanje</Text>
              <Text style={styles.paragraph}>
                7.1. Cijene korištenja Clock.hr sustava navedene su u važećem Cjeniku dostupnom na službenoj web stranici 
                ili kroz direktnu ponudu.
              </Text>
              <Text style={styles.paragraph}>
                7.2. Pružatelj usluge zadržava pravo izmjene cijena uz prethodnu najavu Poslodavcu najmanje 30 dana unaprijed.
              </Text>
              <Text style={styles.paragraph}>
                7.3. Plaćanje se vrši mjesečno ili godišnje, prema odabranom planu, putem predračuna, kreditne kartice ili 
                drugog dogovorenog načina plaćanja.
              </Text>
              <Text style={styles.paragraph}>
                7.4. U slučaju kašnjenja s plaćanjem, Pružatelj usluge zadržava pravo ograničiti ili privremeno obustaviti 
                pružanje usluge.
              </Text>

              <Text style={styles.heading}>8. Ograničenje odgovornosti</Text>
              <Text style={styles.paragraph}>8.1. Pružatelj usluge nije odgovoran za:</Text>
              <Text style={styles.bulletPoint}>• Netočne podatke unesene od strane Poslodavca ili njegovih zaposlenika</Text>
              <Text style={styles.bulletPoint}>• Prekide u radu zbog više sile, tehničkih problema izvan kontrole Pružatelja usluge ili planiranog održavanja</Text>
              <Text style={styles.bulletPoint}>• Indirektne štete nastale korištenjem ili nemogućnošću korištenja sustava</Text>
              <Text style={styles.bulletPoint}>• Gubitak podataka koji nije nastao krivnjom Pružatelja usluge</Text>
              <Text style={styles.bulletPoint}>• Zloupotrebe sustava od strane Poslodavca ili njegovih zaposlenika</Text>
              <Text style={styles.paragraph}>
                8.2. Maksimalna odgovornost Pružatelja usluge ograničena je na iznos koji je Poslodavac platio za uslugu 
                u posljednjih 12 mjeseci.
              </Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Dodatne odredbe</Text>
            <Text style={styles.description}>
              Dodatne važne informacije o korištenju sustava i odgovornostima korisnika.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>9. Intelektualno vlasništvo</Text>
              <Text style={styles.paragraph}>
                9.1. Sva prava intelektualnog vlasništva na Clock.hr sustavu pripadaju Pružatelju usluge.
              </Text>
              <Text style={styles.paragraph}>
                9.2. Poslodavac dobiva samo neisključivo, neprenosivo pravo korištenja sustava u skladu s ovim Uvjetima.
              </Text>
              <Text style={styles.paragraph}>
                9.3. Poslodavcu nije dopušteno kopirati, modificirati, distribuirati ili stvarati izvedena djela temeljena 
                na Clock.hr sustavu bez izričitog pismenog odobrenja Pružatelja usluge.
              </Text>

              <Text style={styles.heading}>10. Zaštita podataka</Text>
              <Text style={styles.paragraph}>
                10.1. Pružatelj usluge će obrađivati osobne podatke Poslodavca i njegovih zaposlenika u skladu s 
                Politikom privatnosti i važećim propisima o zaštiti osobnih podataka.
              </Text>
              <Text style={styles.paragraph}>
                10.2. Poslodavac ostaje voditelj obrade osobnih podataka svojih zaposlenika, dok je Pružatelj usluge 
                izvršitelj obrade tih podataka.
              </Text>
              <Text style={styles.paragraph}>
                10.3. Poslodavac je odgovoran osigurati da ima pravnu osnovu za obradu osobnih podataka svojih zaposlenika 
                kroz Clock.hr sustav.
              </Text>
              <Text style={styles.paragraph}>
                10.4. Detalji o obradi osobnih podataka navedeni su u Politici privatnosti.
              </Text>

            <Text style={styles.heading}>11. Trajanje i raskid ugovora</Text>
<Text style={styles.paragraph}>
  11.1. Ugovor o korištenju Clock.hr usluge sklapa se na neodređeno vrijeme ili na određeno vrijeme prema 
  odabranom planu.
</Text>
<Text style={styles.paragraph}>
  11.2. Poslodavac može raskinuti ugovor slanjem pisanog zahtjeva za raskid najmanje 30 dana prije željenog 
  datuma raskida.
</Text>
<Text style={styles.paragraph}>11.3. Pružatelj usluge može raskinuti ugovor u sljedećim slučajevima:</Text>
<Text style={styles.bulletPoint}>• Ako Poslodavac ne poštuje ove Uvjete korištenja</Text>
<Text style={styles.bulletPoint}>• Ako Poslodavac ne plati naknadu za uslugu nakon opomene</Text>
<Text style={styles.bulletPoint}>• Ako Poslodavac koristi sustav za nezakonite aktivnosti</Text>
<Text style={styles.paragraph}>
  11.4. U slučaju raskida ugovora, Pružatelj usluge će omogućiti Poslodavcu izvoz podataka o evidenciji radnog vremena 
  u standardnom formatu (npr. CSV ili Excel) pod sljedećim uvjetima:
</Text>
<Text style={styles.bulletPoint}>• Poslodavac mora podnijeti pisani zahtjev za izvoz podataka u roku od 15 dana od datuma raskida ugovora</Text>
<Text style={styles.bulletPoint}>• Izvoz podataka bit će proveden u roku od 30 dana od primitka zahtjeva</Text>
<Text style={styles.bulletPoint}>• Nakon izvoza, ili po isteku roka za podnošenje zahtjeva, podaci će biti trajno uklonjeni iz sustava</Text>
<Text style={styles.bulletPoint}>• Izvoz podataka ograničen je isključivo na podatke koje je Poslodavac unio u sustav, a koji se odnose na evidenciju radnog vremena zaposlenika</Text>
<Text style={styles.bulletPoint}>• Pružatelj usluge zadržava pravo naplatiti razumnu naknadu za izvoz podataka prema važećem cjeniku</Text>

              <Text style={styles.heading}>12. Izmjene uvjeta korištenja</Text>
              <Text style={styles.paragraph}>
                12.1. Pružatelj usluge zadržava pravo izmjene ovih Uvjeta korištenja uz prethodnu obavijest Poslodavcu 
                najmanje 30 dana prije stupanja izmjena na snagu.
              </Text>
              <Text style={styles.paragraph}>
                12.2. Ako se Poslodavac ne slaže s izmjenama, ima pravo raskinuti ugovor prije stupanja izmjena na snagu. 
                Nastavak korištenja usluge nakon stupanja izmjena na snagu smatra se prihvaćanjem izmijenjenih Uvjeta.
              </Text>

              <Text style={styles.heading}>13. Nadležnost i rješavanje sporova</Text>
              <Text style={styles.paragraph}>
                13.1. Za sve sporove koji proizlaze iz ili su povezani s ovim Uvjetima korištenja nadležan je stvarno 
                nadležni sud u Zagrebu.
              </Text>
              <Text style={styles.paragraph}>
                13.2. Primjenjuje se hrvatsko pravo.
              </Text>

              <Text style={styles.heading}>14. Kontakt</Text>
              <Text style={styles.paragraph}>Za sva pitanja vezana uz ove Uvjete korištenja, molimo kontaktirajte nas na:</Text>
              <Text style={styles.bulletPoint}>• Email: info@rakicgroup.com</Text>
              <Text style={styles.bulletPoint}>• Adresa: Put Čikole, 22320 Drniš</Text>
              <Text style={styles.paragraph}>Datum zadnjeg ažuriranja: 10.03.2025</Text>  
            </View>
          </View>
        </View>
      </ScrollView>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 48,
  },
  section: {
    marginBottom: 48,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 24,
    fontStyle: 'italic',
  },
  contentContainer: {
    backgroundColor: '#F8FAFC',
    padding: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginTop: 24,
    marginBottom: 12,
  },
  paragraph: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 12,
  },
  bulletPoint: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 8,
    paddingLeft: 20,
  },
});