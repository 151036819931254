import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions, TextInput, ScrollView } from 'react-native';
import { Text, Button, useTheme, ActivityIndicator, DataTable } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { employeesAPI } from '../../../services/api';
import { format } from 'date-fns';
import { hr } from 'date-fns/locale';
import { terminalEndpoints } from '../../../modules/terminal/api/terminalApi';
import { MOBILE_BREAKPOINT } from '../../layout/WebLayout';

interface Employee {
  _id: string;
  name: string;
  surname: string;
  dateOfBirth?: string;
  pin?: string;
  position?: string;
  locationId?: {
    _id: string;
    name: string;
  };
  location?: {
    _id: string;
    name: string;
  };
  email?: string;
  phone?: string;
  createdAt?: string;
  updatedAt?: string;
  status?: {
    isActive: boolean;
    isOnBreak: boolean;
    checkInTime?: string;
    checkOutTime?: string;
    currentBreak?: {
      type: string;
      startTime: string;
    };
  };
  oib?: string;
}

export const WebEmployeesScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [searchQuery, setSearchQuery] = useState('');
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [visiblePins, setVisiblePins] = useState<{[key: string]: boolean}>({});
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);
  
  useEffect(() => {
    const updateDimensions = () => {
      setWindowWidth(Dimensions.get('window').width);
    };
    
    // Set initial dimensions
    updateDimensions();
    
    // Add event listener for window resize
    Dimensions.addEventListener('change', updateDimensions);
    
    // Clean up
    return () => {
      // Clean up (not needed in newer React Native versions)
    };
  }, []);
  
  const isMobile = windowWidth <= MOBILE_BREAKPOINT;

  const fetchEmployees = async () => {
    try {
      const response = await employeesAPI.getEmployees();
      
      // Dohvati status za svakog djelatnika
      const employeesWithStatus = await Promise.all(
        response.map(async (employee: Employee) => {
          try {
            const locationId = employee.locationId?._id || employee.location?._id;
            if (!locationId) return employee;
            
            const statusResponse = await terminalEndpoints.getEmployeeInfo(
              employee._id, 
              locationId
            );
            
            return {
              ...employee,
              status: statusResponse.data
            };
          } catch (error) {
            console.error(`Greška pri dohvaćanju statusa za djelatnika ${employee._id}:`, error);
            return employee;
          }
        })
      );
      
      setEmployees(employeesWithStatus);
    } catch (error) {
      console.error('Greška pri dohvaćanju djelatnika:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchEmployees();
    setRefreshing(false);
  }, []);

  const filteredEmployees = employees.filter(employee => {
    const fullName = `${employee.name} ${employee.surname}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  const handleEmployeePress = (employeeId: string) => {
    // @ts-ignore
    navigation.navigate('EmployeeDetails', { employeeId });
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return '-';
    try {
      return format(new Date(dateString), 'dd.MM.yyyy.', { locale: hr });
    } catch (error) {
      return '-';
    }
  };

  const formatTime = (dateString?: string) => {
    if (!dateString) return '-';
    try {
      return format(new Date(dateString), 'HH:mm:ss', { locale: hr });
    } catch (error) {
      return '-';
    }
  };

  const getLocationName = (employee: Employee) => {
    return employee.locationId?.name || employee.location?.name || '-';
  };

  const togglePinVisibility = (employeeId: string) => {
    setVisiblePins(prev => ({
      ...prev,
      [employeeId]: !prev[employeeId]
    }));
  };

  const renderPin = (employee: Employee) => {
    if (!employee.pin) return <Text style={styles.placeholderText}>-</Text>;
    
    const isVisible = visiblePins[employee._id] || false;
    
    return (
      <View style={styles.pinContainer}>
        <Text style={styles.pinText}>
          {isVisible ? employee.pin : '••••'}
        </Text>
        <TouchableOpacity onPress={() => togglePinVisibility(employee._id)}>
          <MaterialCommunityIcons
            name={isVisible ? 'eye-off' : 'eye'}
            size={18}
            color="#718096"
            style={styles.eyeIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };

  // Renderira Data Table Header ovisno o veličini ekrana
  const renderTableHeader = () => {
    if (isMobile) {
      return (
        <DataTable.Header style={styles.tableHeader}>
          <DataTable.Title style={[styles.column, styles.nameColumn]}>Djelatnik</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.pinColumn]}>PIN</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.locationColumn]}>Lokacija</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.actionsColumn]}>Akcije</DataTable.Title>
        </DataTable.Header>
      );
    } else {
      return (
        <DataTable.Header style={styles.tableHeader}>
          <DataTable.Title style={[styles.column, styles.nameColumn]}>Djelatnik</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.birthDateColumn]}>Datum rođenja</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.pinColumn]}>PIN</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.positionColumn]}>Status</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.locationColumn]}>Lokacija</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.contactColumn]}>Kontakt</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.dateColumn]}>Datum kreiranja</DataTable.Title>
          <DataTable.Title style={[styles.column, styles.actionsColumn]}>Akcije</DataTable.Title>
        </DataTable.Header>
      );
    }
  };
  
  // Renderira redak u tablici ovisno o veličini ekrana
  const renderTableRow = (employee: Employee) => {
    if (isMobile) {
      return (
        <DataTable.Row 
          key={employee._id} 
          style={styles.tableRow}
          onPress={() => handleEmployeePress(employee._id)}
        >
          <DataTable.Cell style={[styles.column, styles.nameColumn]}>
            <View style={styles.employeeNameContainer}>
              <Text style={styles.employeeName}>
                {employee.name} {employee.surname}
              </Text>
              <Text style={styles.mobileOibText}>{employee.oib || '-'}</Text>
            </View>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.pinColumn]}>
            {renderPin(employee)}
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.locationColumn]}>
            <View>
              <Text style={styles.locationText}>
                {getLocationName(employee)}
              </Text>
              {employee.status && (
                <View>
                  {employee.status.isActive ? (
                    employee.status.isOnBreak ? (
                      <Text style={{ color: '#FF9800' }}>Na pauzi</Text>
                    ) : (
                      <Text style={{ color: '#4CAF50' }}>Na poslu</Text>
                    )
                  ) : (
                    <Text style={{ color: '#F44336' }}>Nije na poslu</Text>
                  )}
                </View>
              )}
            </View>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.actionsColumn]}>
            <MaterialCommunityIcons
              name="chevron-right"
              size={24}
              color="#666"
            />
          </DataTable.Cell>
        </DataTable.Row>
      );
    } else {
      return (
        <DataTable.Row 
          key={employee._id} 
          style={styles.tableRow}
          onPress={() => handleEmployeePress(employee._id)}
        >
          <DataTable.Cell style={[styles.column, styles.nameColumn]}>
            <View style={styles.employeeNameContainer}>
              <Text style={styles.employeeName}>
                {employee.name} {employee.surname}
              </Text>
            </View>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.birthDateColumn]}>
            <Text style={styles.birthDateText}>
              {formatDate(employee.dateOfBirth)}
            </Text>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.pinColumn]}>
            {renderPin(employee)}
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.positionColumn]}>
            {employee.status ? (
              <View>
                {employee.status.isActive ? (
                  employee.status.isOnBreak ? (
                    <View>
                      <Text style={{ color: '#FF9800' }}>Na pauzi</Text>
                      <Text style={{ color: '#718096', fontSize: 14 }}>
                        {employee.status.currentBreak?.type} - od {formatTime(employee.status.currentBreak?.startTime)}
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text style={{ color: '#4CAF50' }}>Na poslu</Text>
                      <Text style={{ color: '#718096', fontSize: 14 }}>
                        Od {formatTime(employee.status.checkInTime)}
                      </Text>
                    </View>
                  )
                ) : (
                  <View>
                    <Text style={{ color: '#F44336' }}>Nije na poslu</Text>
                    {employee.status.checkOutTime && (
                      <Text style={{ color: '#718096', fontSize: 14 }}>
                        Odlazak: {formatTime(employee.status.checkOutTime)}
                      </Text>
                    )}
                  </View>
                )}
              </View>
            ) : (
              employee.position || '-'
            )}
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.locationColumn]}>
            <Text style={styles.locationText}>
              {getLocationName(employee)}
            </Text>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.contactColumn]}>
            <Text style={styles.contactText}>
              {employee.email || employee.phone || '-'}
            </Text>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.dateColumn]}>
            <Text style={styles.dateText}>{formatDate(employee.createdAt)}</Text>
          </DataTable.Cell>
          <DataTable.Cell style={[styles.column, styles.actionsColumn]}>
            <MaterialCommunityIcons
              name="chevron-right"
              size={24}
              color="#666"
            />
          </DataTable.Cell>
        </DataTable.Row>
      );
    }
  };

  const handleAddEmployee = () => {
    navigation.navigate('CreateEmployee');
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {!isMobile && (
          <View style={styles.searchContainer}>
            <View style={styles.searchBarContainer}>
              <View style={styles.searchBar}>
                <MaterialCommunityIcons name="magnify" size={20} color="#6B7280" style={styles.searchIcon} />
                <TextInput
                  placeholder="Pretraži djelatnike"
                  onChangeText={(query) => setSearchQuery(query)}
                  value={searchQuery}
                  style={styles.searchInput}
                  placeholderTextColor="#6B7280"
                />
              </View>
            </View>
            <View style={styles.buttonContainer}>
              <Button 
                mode="contained"
                onPress={handleAddEmployee}
                style={styles.addButton}
                buttonColor="#1F2937"
                textColor="white"
                icon={({ size, color }) => (
                  <MaterialCommunityIcons name="plus" size={20} color={color} />
                )}
              >
                Dodaj djelatnika
              </Button>
            </View>
          </View>
        )}
        
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        ) : (
          <View style={styles.tableContainer}>
            <ScrollView style={styles.scrollView}>
              <DataTable style={styles.table}>
                {renderTableHeader()}

                {filteredEmployees.length === 0 ? (
                  <DataTable.Row>
                    <DataTable.Cell style={styles.noResultsCell}>
                      <Text style={styles.noResultsText}>Nema pronađenih djelatnika</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                ) : (
                  filteredEmployees.map((employee) => renderTableRow(employee))
                )}
              </DataTable>
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
    padding: 24,
  },
  searchContainer: {
    marginBottom: 24,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 24,
  },
  searchBarContainer: {
    flex: 1,
  },
  buttonContainer: {
    width: 200,
  },
  searchBar: {
    backgroundColor: '#FFFFFF',
    elevation: 0,
    borderRadius: 4,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 14,
    height: '100%',
    padding: 0,
    backgroundColor: 'transparent',
    outlineStyle: 'none',
  },
  tableContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    overflow: 'hidden',
    elevation: 0,
    shadowOpacity: 0,
    shadowRadius: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  scrollView: {
    flex: 1,
  },
  table: {
    flex: 1,
  },
  tableHeader: {
    backgroundColor: '#F1F5F9',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  tableRow: {
    minHeight: 48,
    backgroundColor: '#FFFFFF',
  },
  column: {
    justifyContent: 'flex-start',
    padding: 0,
  },
  nameColumn: {
    flex: 2.5,
  },
  birthDateColumn: {
    flex: 2,
  },
  pinColumn: {
    flex: 1.5,
  },
  positionColumn: {
    flex: 2,
  },
  locationColumn: {
    flex: 2,
  },
  contactColumn: {
    flex: 2,
  },
  dateColumn: {
    flex: 2,
  },
  actionsColumn: {
    flex: 1,
  },
  employeeNameContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  employeeName: {
    color: '#1F2937',
    fontSize: 14,
  },
  birthDateText: {
    color: '#1F2937',
    fontSize: 14,
  },
  pinContainer: {
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'flex-start',
  },
  pinText: {
    color: '#4A5568',
    fontSize: 14,
    marginRight: 8,
  },
  eyeIcon: {
    opacity: 0.7,
  },
  positionText: {
    color: '#4A5568',
    fontSize: 14,
  },
  locationText: {
    color: '#1F2937',
    fontSize: 14,
  },
  contactText: {
    color: '#1F2937',
    fontSize: 14,
  },
  dateText: {
    color: '#1F2937',
    fontSize: 14,
  },
  placeholderText: {
    color: '#A0AEC0',
    fontSize: 14,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateText: {
    color: '#2D3748',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    color: '#718096',
    textAlign: 'center',
    marginBottom: 24,
  },
  createButton: {
    borderRadius: 4,
    paddingHorizontal: 24,
  },
  noResultsCell: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 24,
  },
  noResultsText: {
    color: '#718096',
    fontSize: 16,
  },
  mobileOibText: {
    fontSize: 14,
    color: '#1F2937',
    marginTop: 2,
  },
  mobileStatusText: {
    fontSize: 12,
    marginTop: 2,
  },
  statusActive: {
    color: '#4CAF50',
  },
  statusOnBreak: {
    color: '#FF9800',
  },
  statusInactive: {
    color: '#F44336',
  },
  buttonContent: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonLabel: {
    fontSize: 14,
    fontWeight: '500',
  },
  addButton: {
    height: 40,
    borderRadius: 4,
  },
});
