import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Linking,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons } from '@expo/vector-icons';
import { List } from 'react-native-paper';

const HELP_SECTIONS = [
  {
    title: 'Dokumentacija',
    icon: 'book-outline',
    url: 'https://docs.clock.hr',
    description: 'Detaljna dokumentacija s uputama za korištenje'
  },
  {
    title: 'Česta pitanja',
    icon: 'help-circle-outline',
    url: 'https://docs.clock.hr/faq',
    description: 'Odgovori na najčešća pitanja'
  },
  {
    title: 'Video tutoriali',
    icon: 'play-circle-outline',
    url: 'https://docs.clock.hr/tutorials',
    description: 'Video upute za korištenje aplikacije'
  },
  {
    title: 'Centar za podršku',
    icon: 'headset-outline',
    url: 'https://support.clock.hr',
    description: 'Kontaktirajte našu korisničku podršku'
  }
];

export const FAQScreen = ({ navigation }: any) => {
  const handleOpenLink = (url: string) => {
    Linking.openURL(url);
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Pomoć</Text>
        <View style={{ width: 32 }} />
      </View>

      <ScrollView style={styles.container}>
        <View style={styles.introContainer}>
          <Text style={styles.introTitle}>Trebate pomoć?</Text>
          <Text style={styles.introText}>
            Odaberite jednu od opcija ispod za detaljne upute i podršku za korištenje Clock.hr aplikacije.
          </Text>
        </View>

        {HELP_SECTIONS.map((section, index) => (
          <TouchableOpacity
            key={section.title}
            style={styles.sectionButton}
            onPress={() => handleOpenLink(section.url)}
          >
            <View style={styles.sectionContent}>
              <View style={styles.sectionIcon}>
                <Ionicons name={section.icon as any} size={24} color="#007AFF" />
              </View>
              <View style={styles.sectionText}>
                <Text style={styles.sectionTitle}>{section.title}</Text>
                <Text style={styles.sectionDescription}>{section.description}</Text>
              </View>
              <Ionicons name="chevron-forward" size={24} color="#999" />
            </View>
          </TouchableOpacity>
        ))}

        <View style={styles.offlineNote}>
          <Ionicons name="information-circle-outline" size={20} color="#666" />
          <Text style={styles.offlineText}>
            Za pristup dokumentaciji potrebna je internet veza. Ako nemate pristup internetu, 
            kontaktirajte podršku na 0800 123 456.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    backgroundColor: '#fff',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: '600',
  },
  container: {
    flex: 1,
  },
  introContainer: {
    padding: 20,
    backgroundColor: '#f8f9fa',
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
  },
  introTitle: {
    fontSize: 22,
    fontWeight: '600',
    color: '#333',
    marginBottom: 8,
  },
  introText: {
    fontSize: 15,
    color: '#666',
    lineHeight: 22,
  },
  sectionButton: {
    backgroundColor: '#fff',
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
  },
  sectionContent: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  sectionIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#f0f7ff',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  sectionText: {
    flex: 1,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    marginBottom: 4,
  },
  sectionDescription: {
    fontSize: 14,
    color: '#666',
  },
  offlineNote: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#f8f9fa',
    padding: 16,
    margin: 16,
    borderRadius: 8,
  },
  offlineText: {
    fontSize: 14,
    color: '#666',
    marginLeft: 8,
    flex: 1,
    lineHeight: 20,
  },
});
