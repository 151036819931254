import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, ActivityIndicator, ScrollView, Alert } from 'react-native';
import { Text, Divider, useTheme, Appbar } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { breaksAPI } from '../../../services/api';

interface BreakDetailsProps {
  route?: {
    params: {
      breakId: string;
    };
  };
}

interface Break {
  id: string;
  name: string;
  duration: number;
  isPaid: boolean;
  isUnlimited: boolean;
  allowReuseAfterExpiry: boolean;
  restrictTimeEnabled: boolean;
  minWorkMinutes?: number;
  isTimeRestrictionEnabled: boolean;
  restrictedHours: number[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

export const BreakDetailsScreen: React.FC<BreakDetailsProps> = ({ route }) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [breakDetails, setBreakDetails] = useState<Break | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleEditPress = () => {
    Alert.alert(
      'Uređivanje pauze',
      'Uređivanje postojeće pauze nije moguće. Ako želite napraviti promjene, kreirajte novu pauzu, a ovu možete arhivirati.',
      [
        { text: 'U redu', style: 'default' }
      ]
    );
  };

  useEffect(() => {
    const loadBreakDetails = async () => {
      try {
        const response = await breaksAPI.getBreakDetails(route?.params?.breakId || '');
        setBreakDetails(response.data);
      } catch (err) {
        setError('Došlo je do greške pri dohvaćanju detalja pauze');
      } finally {
        setIsLoading(false);
      }
    };

    loadBreakDetails();
  }, [route?.params?.breakId]);
  
  const InfoRow = ({ icon, label, value }: { icon: string; label: string; value: string }) => (
    <View style={styles.infoRow}>
      <MaterialCommunityIcons name={icon} size={24} color={theme.colors.primary} style={styles.icon} />
      <View style={styles.infoContent}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  );

  if (isLoading) {
    return (
      <View style={[styles.safeArea, styles.centerContent]}>
        <ActivityIndicator size="large" color={theme.colors.primary} />
      </View>
    );
  }

  if (error || !breakDetails) {
    return (
      <View style={[styles.safeArea, styles.centerContent]}>
        <Text style={{ color: theme.colors.error }}>{error || 'Pauza nije pronađena'}</Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea} edges={['left', 'right']}>
      <Appbar.Header style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Appbar.Content title="Detalji pauze" titleStyle={styles.headerTitle} />
        <TouchableOpacity onPress={handleEditPress} style={styles.editButton}>
          <Ionicons name="create-outline" size={24} color="#007AFF" />
        </TouchableOpacity>
      </Appbar.Header>

      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Osnovne informacije</Text>
          <View style={styles.detailsContainer}>
            <InfoRow 
              icon="coffee" 
              label="Naziv pauze"
              value={breakDetails.name}
            />
            <Divider style={styles.divider} />
            
            <InfoRow 
              icon="clock-outline" 
              label="Trajanje"
              value={breakDetails.isUnlimited ? 'Neograničeno' : `${breakDetails.duration} minuta`}
            />
            <Divider style={styles.divider} />

            <InfoRow 
              icon="cash" 
              label="Plaćena"
              value={breakDetails.isPaid ? 'Da' : 'Ne'}
            />
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Ograničenja</Text>
          <View style={styles.detailsContainer}>
            <InfoRow 
              icon="infinity" 
              label="Korištenje"
              value={breakDetails.isUnlimited ? 'Višekratno' : 'Jednokratno'}
            />
            <Divider style={styles.divider} />

            <InfoRow 
              icon="refresh" 
              label="Korištenje nakon isteka"
              value={breakDetails.allowReuseAfterExpiry ? 'Dozvoljeno' : 'Nije dozvoljeno'}
            />
            <Divider style={styles.divider} />

            <InfoRow 
              icon="clock-check" 
              label="Potrebno raditi prije korištenja pauze"
              value={breakDetails.restrictTimeEnabled ? 'Uključeno' : 'Isključeno'}
            />
            <Divider style={styles.divider} />

            {breakDetails.restrictTimeEnabled ? (
              <>
                <InfoRow 
                  icon="clock-check-outline" 
                  label="Minimalno vrijeme rada"
                  value={`${breakDetails.minWorkMinutes} minuta`}
                />
                <Divider style={styles.divider} />
              </>
            ) : (
              <>
                <InfoRow 
                  icon="clock-check-outline" 
                  label="Minimalno vrijeme rada"
                  value="Nije postavljeno"
                />
                <Divider style={styles.divider} />
              </>
            )}

            <InfoRow 
              icon="clock-alert" 
              label="Zabrana korištenja u određenim satima"
              value={breakDetails.isTimeRestrictionEnabled ? 'Uključeno' : 'Isključeno'}
            />
            <Divider style={styles.divider} />

            {breakDetails.isTimeRestrictionEnabled ? (
              <InfoRow 
                icon="clock-alert-outline" 
                label="Pauza se ne može koristiti u satima"
                value={breakDetails.restrictedHours && breakDetails.restrictedHours.length > 0 
                  ? breakDetails.restrictedHours.sort((a, b) => a - b).join(', ')
                  : 'Nije odabrano'}
              />
            ) : (
              <InfoRow 
                icon="clock-alert-outline" 
                label="Pauza se ne može koristiti u satima"
                value="Nije postavljeno"
              />
            )}
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Ostale informacije</Text>
          <View style={styles.detailsContainer}>
            <InfoRow 
              icon="calendar" 
              label="Kreirano"
              value={new Date(breakDetails.createdAt).toLocaleString('hr')}
            />
            <Divider style={styles.divider} />

            <InfoRow 
              icon="calendar-clock" 
              label="Zadnja izmjena"
              value={new Date(breakDetails.updatedAt).toLocaleString('hr')}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  centerContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  headerTitle: {
    fontSize: 20,
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    padding: 8,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  editButton: {
    marginRight: 8,
    padding: 8,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingBottom: 20,
  },
  section: {
    marginVertical: 12,
    marginRight: 16,
    marginLeft: 8,
  },
  sectionTitle: {
    fontSize: 13,
    color: '#666',
    marginBottom: 8,
    letterSpacing: 0.1,
    marginLeft: 4,
  },
  detailsContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 16,
    paddingLeft: 8,
    elevation: 1,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  icon: {
    marginRight: 12,
    marginLeft: 4,
  },
  infoContent: {
    flex: 1,
  },
  label: {
    fontSize: 13,
    color: '#666',
    marginBottom: 2,
  },
  value: {
    fontSize: 15,
    color: '#000',
  },
  divider: {
    marginVertical: 4,
  },
});
