import React, { createContext, useContext, useReducer } from 'react';
import { TerminalState } from '../types/terminal.types';
import { terminalReducer, initialState } from '../store/terminalStore';

interface TerminalContextType {
  state: TerminalState;
  dispatch: React.Dispatch<any>;
}

const TerminalContext = createContext<TerminalContextType | undefined>(undefined);

export function TerminalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(terminalReducer, initialState);

  return (
    <TerminalContext.Provider value={{ state, dispatch }}>
      {children}
    </TerminalContext.Provider>
  );
}

export function useTerminalContext() {
  const context = useContext(TerminalContext);
  if (context === undefined) {
    throw new Error('useTerminalContext must be used within a TerminalProvider');
  }
  return context;
}

export default TerminalContext;
