import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { HelpLayout } from '../components/HelpLayout';

interface HelpCardProps {
  title: string;
  description: string;
  icon: string;
  route: string;
}

const HelpCard = ({ title, description, icon, route }: HelpCardProps) => {
  const navigation = useNavigation();
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = [
    styles.card,
    Platform.OS === 'web' && isHovered && styles.cardHovered,
  ];

  const arrowStyle = [
    styles.cardArrow,
    Platform.OS === 'web' && isHovered && styles.cardArrowHovered,
  ];

  return (
    <TouchableOpacity 
      style={cardStyle}
      onPress={() => navigation.navigate(route as never)}
      onMouseEnter={() => Platform.OS === 'web' && setIsHovered(true)}
      onMouseLeave={() => Platform.OS === 'web' && setIsHovered(false)}
    >
      <View style={styles.cardContent}>
        <View style={styles.cardHeader}>
          <View style={styles.cardIcon}>
            <Ionicons name={icon as any} size={24} color="#4C6FFF" />
          </View>
          <View style={arrowStyle}>
            <Ionicons 
              name="arrow-forward" 
              size={20} 
              color={Platform.OS === 'web' && isHovered ? '#FFFFFF' : '#4C6FFF'} 
            />
          </View>
        </View>
        <Text style={styles.cardTitle}>{title}</Text>
        <Text style={styles.cardDescription}>{description}</Text>
      </View>
    </TouchableOpacity>
  );
};

export const WebHelp = () => {
  const helpCategories: HelpCardProps[] = [
    {
      title: 'O nama',
      description: 'Saznajte više o našoj misiji i viziji te kako pomažemo tvrtkama u Hrvatskoj.',
      icon: 'business-outline',
      route: 'AboutUs'
    },
    {
      title: 'Početak rada',
      description: 'Vodič za prve korake s Clock.hr aplikacijom.',
      icon: 'play-outline',
      route: 'Guide'
    },
    {
      title: 'Česta pitanja',
      description: 'Odgovori na najčešće postavljena pitanja o našoj aplikaciji.',
      icon: 'help-circle-outline',
      route: 'FAQ'
    },
    {
      title: 'Pravni dokumenti',
      description: 'Uvjeti korištenja, politika privatnosti i ostali pravni dokumenti.',
      icon: 'document-text-outline',
      route: 'LegalDocuments'
    },
    {
      title: 'Kontakt podrška',
      description: 'Trebate pomoć? Kontaktirajte našu korisničku podršku.',
      icon: 'mail-outline',
      route: 'Contact'
    }
  ];

  // Podijeli kartice u redove od po dvije
  const rows = [];
  for (let i = 0; i < helpCategories.length; i += 2) {
    rows.push(helpCategories.slice(i, i + 2));
  }

  return (
    <HelpLayout>
      <View style={styles.container}>
        {rows.map((row, rowIndex) => (
          <View key={rowIndex} style={styles.row}>
            {row.map((category, index) => (
              <View key={index} style={styles.cardWrapper}>
                <HelpCard {...category} />
              </View>
            ))}
          </View>
        ))}
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 32,
    backgroundColor: '#ffffff',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 32,
    backgroundColor: '#ffffff',
  },
  cardWrapper: {
    flex: 1,
    marginHorizontal: 12,
    backgroundColor: '#ffffff',
  },
  card: {
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    height: '100%',
    borderWidth: 1,
    borderColor: '#F1F5F9',
    borderRadius: 8,
    ...Platform.select({
      web: {
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
      },
      default: {
        elevation: 2,
      }
    }),
  } as any,
  cardHovered: {
    ...Platform.select({
      web: {
        transform: [{ translateY: -2 }],
        borderColor: '#E2E8F0',
      }
    }),
  },
  cardContent: {
    padding: 24,
    height: '100%',
    backgroundColor: '#ffffff',
  },
  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 20,
    backgroundColor: '#ffffff',
  },
  cardIcon: {
    width: 48,
    height: 48,
    borderRadius: 12,
    backgroundColor: '#F0F3FF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardArrow: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: '#F0F3FF',
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      web: {
        transition: 'all 0.2s ease-in-out',
      }
    }),
  } as any,
  cardArrowHovered: {
    backgroundColor: '#4C6FFF',
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 8,
  },
  cardDescription: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
  },
});
