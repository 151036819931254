import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  ScrollView,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { useAuth } from '../../hooks/useAuth';
import { Ionicons } from '@expo/vector-icons';
import { companyAPI } from '../../services/api';

export const CompanySettingsScreen = ({ navigation }: any) => {
  const { owner, company, isLoading, error, setCompanyData } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    oib: '',
    country: '',
    city: '',
    address: '',
    responsiblePerson: '',
  });

  useEffect(() => {
    const loadCompanyData = async () => {
      try {
        const response = await companyAPI.getCompanyData();
        if (response) {
          setCompanyData(response);
          setFormData({
            name: response.name || '',
            oib: response.oib || '',
            country: response.country || '',
            city: response.city || '',
            address: response.address || '',
            responsiblePerson: response.responsiblePerson || '',
          });
        }
      } catch (err) {
        console.error('[COMPANY] Greška pri učitavanju podataka tvrtke:', err);
      }
    };

    loadCompanyData();
  }, []);

  useEffect(() => {
    if (company) {
      setFormData({
        name: company.name || '',
        oib: company.oib || '',
        country: company.country || '',
        city: company.city || '',
        address: company.address || '',
        responsiblePerson: company.responsiblePerson || '',
      });
    }
  }, [company]);

  const validateForm = () => {
    const { name, oib, country, city, address, responsiblePerson } = formData;
    
    if (!name || !oib || !country || !city || !address || !responsiblePerson) {
      Alert.alert('Greška', 'Molimo popunite sva polja');
      return false;
    }

    if (oib.length !== 11 || !/^\d+$/.test(oib)) {
      Alert.alert('Greška', 'OIB mora sadržavati točno 11 brojeva');
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        const updatedCompany = await companyAPI.updateCompanyData(formData);
        await setCompanyData(updatedCompany);
        Alert.alert('Uspjeh', 'Podaci tvrtke su uspješno ažurirani');
        setIsEditing(false);
      } catch (err) {
        console.error('[COMPANY] Greška pri spremanju:', err);
        Alert.alert('Greška', 'Došlo je do greške pri spremanju podataka');
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (company) {
      setFormData({
        name: company.name || '',
        oib: company.oib || '',
        country: company.country || '',
        city: company.city || '',
        address: company.address || '',
        responsiblePerson: company.responsiblePerson || '',
      });
    }
  };

  const renderInput = (label: string, key: keyof typeof formData, keyboardType: any = 'default') => (
    <View style={styles.inputContainer}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.inputWrapper}>
        <TextInput
          style={[styles.input, !isEditing && styles.disabledInput]}
          value={formData[key]}
          onChangeText={(text) => setFormData(prev => ({ ...prev, [key]: text }))}
          editable={isEditing}
          placeholder={`Unesite ${label.toLowerCase()}`}
          placeholderTextColor="#999"
          keyboardType={keyboardType}
        />
      </View>
    </View>
  );

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <KeyboardAvoidingView 
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.keyboardAvoidingView}
      >
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={24} color="#000" />
            <Text style={styles.backText}>Natrag</Text>
          </TouchableOpacity>
          <Text style={styles.title}>Postavke tvrtke</Text>
          {!isEditing ? (
            <TouchableOpacity onPress={() => setIsEditing(true)} style={styles.editButton}>
              <Ionicons name="create-outline" size={24} color="#007AFF" />
            </TouchableOpacity>
          ) : (
            <View style={styles.actionButtons}>
              <TouchableOpacity onPress={handleCancel} style={styles.cancelButton}>
                <Ionicons name="close" size={24} color="#FF3B30" />
              </TouchableOpacity>
              <TouchableOpacity onPress={handleSave} style={styles.saveButton}>
                <Ionicons name="checkmark" size={24} color="#34C759" />
              </TouchableOpacity>
            </View>
          )}
        </View>

        <ScrollView style={styles.container} keyboardShouldPersistTaps="handled">
          {renderInput('Naziv tvrtke', 'name')}
          {renderInput('OIB', 'oib', 'numeric')}
          {renderInput('Država', 'country')}
          {renderInput('Grad', 'city')}
          {renderInput('Adresa', 'address')}
          {renderInput('Odgovorna osoba', 'responsiblePerson')}
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    paddingTop: Platform.OS === 'android' ? 35 : 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    backgroundColor: '#fff',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  title: {
    fontSize: 20,
  },
  editButton: {
    padding: 5,
  },
  actionButtons: {
    flexDirection: 'row',
    gap: 10,
  },
  cancelButton: {
    padding: 5,
  },
  saveButton: {
    padding: 5,
  },
  inputContainer: {
    marginBottom: 20,
  },
  label: {
    fontSize: 16,
    marginBottom: 8,
    color: '#333',
  },
  inputWrapper: {
    borderWidth: 1,
    borderColor: '#E5E5E5',
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  input: {
    padding: 12,
    fontSize: 16,
    color: '#333',
  },
  disabledInput: {
    backgroundColor: '#F5F5F5',
    color: '#666',
  },
});
