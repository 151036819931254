import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const AccessManagement = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Pristupni podaci</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Slanje PIN-ova djelatnicima</Text>
            <Text style={styles.description}>
              Nakon što ste unijeli djelatnike u sustav, trebate im dostaviti njihove PIN-ove za prijavu:
            </Text>
            <Text style={styles.description}>
              1. Otvorite izbornik "Djelatnici"
            </Text>
            <Text style={styles.description}>
              2. Kliknite na gumb "Pošalji PIN-ove"
            </Text>
            <Text style={styles.description}>
              3. Odaberite način slanja:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Email - automatski šalje PIN svakom djelatniku na email</Text>
              <Text style={styles.bulletItem}>• SMS - šalje PIN putem SMS poruke (ako je unesen broj mobitela)</Text>
              <Text style={styles.bulletItem}>• Ispis - generira PDF dokument s PIN-ovima za ručnu podjelu</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Sigurnosne preporuke</Text>
            <Text style={styles.description}>
              Pri distribuciji PIN-ova važno je:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Svaki djelatnik treba dobiti svoj PIN nasamo</Text>
              <Text style={styles.bulletItem}>• Uputite djelatnike da promijene PIN pri prvoj prijavi</Text>
              <Text style={styles.bulletItem}>• Naglasite da je PIN strogo osobni podatak</Text>
              <Text style={styles.bulletItem}>• Objasnite postupak u slučaju zaboravljenog PIN-a</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Promjena i resetiranje PIN-a</Text>
            <Text style={styles.description}>
              Djelatnici mogu:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Samostalno promijeniti PIN na terminalu</Text>
              <Text style={styles.bulletItem}>• Zatražiti novi PIN u slučaju da ga zaborave</Text>
            </View>
            <Text style={styles.description}>
              Administrator može:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Resetirati PIN pojedinog djelatnika</Text>
              <Text style={styles.bulletItem}>• Generirati novi PIN i poslati ga djelatniku</Text>
              <Text style={styles.bulletItem}>• Privremeno blokirati pristup ako je potrebno</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Važne napomene</Text>
            <View style={styles.noteBox}>
              <Text style={styles.noteText}>
                • PIN mora imati najmanje 4 znamenke
              </Text>
              <Text style={styles.noteText}>
                • Sustav pamti povijest promjena PIN-ova
              </Text>
              <Text style={styles.noteText}>
                • Nakon 3 neuspješna pokušaja prijave, račun se privremeno zaključava
              </Text>
              <Text style={styles.noteText}>
                • Preporučljivo je periodično mijenjati PIN-ove
              </Text>
            </View>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: '#444',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  bulletList: {
    marginLeft: 20,
    marginTop: 8,
    marginBottom: 16,
  },
  bulletItem: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  noteBox: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderLeftColor: '#007bff',
  },
  noteText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
  },
});
