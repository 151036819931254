import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../config';
import { LoginCredentials, RegisterCredentials, AuthResponse } from '../types/auth';
import eventEmitter from '../utils/eventEmitter';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor za dodavanje tokena
api.interceptors.request.use(async (config) => {
  const token = await AsyncStorage.getItem('@auth_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor za upravljanje greškama u odgovorima
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // Ako je greška 403 (Forbidden) i zahtjev nije već bio ponovno poslan
    if (error.response?.status === 403 && !originalRequest._retry) {
      // Očisti podatke sesije odmah
      await AsyncStorage.multiRemove(['@auth_token', '@auth_owner', '@auth_company']);
      delete api.defaults.headers.common['Authorization'];
      
      // Emitiranje događaja za istek sesije
      eventEmitter.emit('SESSION_EXPIRED');
      
      return Promise.reject(new Error('Session expired'));
    }
    
    return Promise.reject(error);
  }
);

export const employeesAPI = {
  getLocations: async () => {
    const response = await api.get('/locations');
    return response.data;
  },

  getEmployees: async () => {
    const response = await api.get('/employees?include=location');
    return response.data;
  },

  getEmployeeDetails: async (employeeId: string) => {
    const response = await api.get(`/employees/${employeeId}?include=location`);
    return response.data;
  },

  createEmployee: async (employeeData: any) => {
    const response = await api.post('/employees', employeeData);
    return response.data;
  },

  getLocationEmployeeCount: async (locationId: string) => {
    const response = await api.get(`/employees?locationId=${locationId}`);
    return response.data.length;
  },

  getLocationPins: async (locationId: string) => {
    const response = await api.get(`/locations/${locationId}/pins`);
    return response.data;
  },

  createLocation: async (locationData: any) => {
    const response = await api.post('/locations', locationData);
    return response.data;
  },

  getLocationDetails: async (locationId: string) => {
    const response = await api.get(`/locations/${locationId}`);
    return response.data;
  },

  updateLocation: async (locationId: string, locationData: any) => {
    const response = await api.put(`/locations/${locationId}`, locationData);
    return response.data;
  },

  generateActivationCode: async (locationId: string) => {
    const response = await api.post(`/locations/${locationId}/activation-code`);
    return response.data;
  },

  checkPin: async (locationId: string, pin: string, employeeId?: string) => {
    const endpoint = employeeId 
      ? `/employees/locations/${locationId}/check-pin/${pin}/${employeeId}`
      : `/employees/locations/${locationId}/check-pin/${pin}`;
    const response = await api.get(endpoint);
    return response.data.available;
  },

  checkOib: async (locationId: string, oib: string) => {
    const response = await api.get(`/employees/locations/${locationId}/check-oib/${oib}`);
    return response.data;
  },

  getEmployeeStats: async (employeeId: string, date: string) => {
    const response = await api.get(`/employees/${employeeId}/stats`, {
      params: { date }
    });
    return response.data;
  },

  getEmployeeWeeklyStats: async (employeeId: string, startDate: string, endDate: string) => {
    const response = await api.get(`/employees/${employeeId}/stats/weekly`, {
      params: { startDate, endDate }
    });
    return response.data;
  },

  updateEmployee: async (employeeId: string, employeeData: any) => {
    const response = await api.put(`/employees/${employeeId}`, employeeData);
    return response.data;
  },
};

export const breaksAPI = {
  getBreaks: async () => {
    const response = await api.get('/breaks');
    return response.data;
  },
  async createBreak(breakData: {
    name: string;
    duration: number;
    isPaid: boolean;
    isUnlimited: boolean;
    allowReuseAfterExpiry: boolean;
    restrictTimeEnabled: boolean;
    minWorkMinutes?: number;
    isTimeRestrictionEnabled: boolean;
    restrictedHours?: number[];
  }) {
    const response = await api.post('/breaks', breakData);
    return response.data;
  },
  getBreakDetails: (breakId: string) => {
    return api.get(`/breaks/${breakId}`);
  }
};

export const dashboardAPI = {
  getTotalStats: async () => {
    const response = await api.get('/dashboard/total');
    return response.data;
  },

  getLocationStats: async () => {
    const response = await api.get('/dashboard/locations');
    return response.data;
  },

  getDailyChart: async () => {
    const response = await api.get('/dashboard/daily-chart');
    return response.data;
  },

  getEmployeeHoursStats: async () => {
    const response = await api.get('/dashboard/employee-hours');
    return response.data;
  },
};

export const ownerAPI = {
  updateProfile: async (profileData: any) => {
    const response = await api.put('/auth/update-profile', profileData);
    return response.data;
  },
};

export const companyAPI = {
  updateCompanyData: async (companyData: any) => {
    const response = await api.post('/company', companyData);
    return response.data;
  },
  getCompanyData: async () => {
    const response = await api.get('/company');
    return response.data;
  }
};

export default api;
