import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Ionicons from 'react-native-vector-icons/Ionicons';

const DESKTOP_BREAKPOINT = 1000; // Nova breakpoint vrijednost

export const WebLandingHeader = () => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [menuOpacity, setMenuOpacity] = useState(0);
  const isMobile = width < DESKTOP_BREAKPOINT;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      setMenuOpacity(1);
      setMenuHeight(400); // Približna visina menija
    } else {
      setMenuOpacity(0);
      setMenuHeight(0);
    }
  }, [isMobileMenuOpen]);

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMobileMenuOpen(false);
  };

  const NavigationLinks = () => (
    <>
      <TouchableOpacity onPress={() => scrollToSection('znacajke')}>
        <Text style={[styles.navLink, isScrolled && styles.navLinkScrolled]}>Značajke</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => scrollToSection('kako-radi')}>
        <Text style={[styles.navLink, isScrolled && styles.navLinkScrolled]}>Kako radi</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => scrollToSection('cijene')}>
        <Text style={[styles.navLink, isScrolled && styles.navLinkScrolled]}>Cijene</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => scrollToSection('faq')}>
        <Text style={[styles.navLink, isScrolled && styles.navLinkScrolled]}>FAQ</Text>
      </TouchableOpacity>
    </>
  );

  return (
    <View style={[
      styles.header,
      isScrolled && styles.headerScrolled,
    ]}>
      <View style={styles.headerContent}>
        <TouchableOpacity style={styles.logoContainer} onPress={() => scrollToSection('top')}>
          <View style={styles.logoTextContainer}>
            <Text style={[styles.logoPart, isScrolled && styles.logoPartScrolled]}>clock</Text>
            <Text style={[styles.logoDot, isScrolled && styles.logoDotScrolled]}>.</Text>
            <Text style={[styles.logoHr, isScrolled && styles.logoHrScrolled]}>hr</Text>
          </View>
        </TouchableOpacity>

        {isMobile ? (
          <>
            <TouchableOpacity 
              style={styles.mobileMenuButton}
              onPress={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <Text style={[styles.mobileMenuIcon, isScrolled && styles.mobileMenuIconScrolled]}>
                {isMobileMenuOpen ? '✕' : '☰'}
              </Text>
            </TouchableOpacity>

            {isMobileMenuOpen && (
              <View style={[
                styles.mobileMenu,
                {
                  height: menuHeight,
                  opacity: menuOpacity,
                  transition: 'all 0.3s ease-in-out',
                  position: 'fixed',
                  top: 80,
                  left: 0,
                  right: 0,
                  zIndex: 9999,
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                } as any,
              ]}>
                <View style={styles.mobileMenuContent}>
                  <View style={styles.mobileNavLinks}>
                    <TouchableOpacity 
                      style={styles.mobileNavLink} 
                      onPress={() => scrollToSection('znacajke')}
                    >
                      <Ionicons name="grid-outline" size={20} color="#4C6FFF" />
                      <Text style={styles.mobileNavLinkText}>Značajke</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                      style={styles.mobileNavLink} 
                      onPress={() => scrollToSection('kako-radi')}
                    >
                      <Ionicons name="help-circle-outline" size={20} color="#4C6FFF" />
                      <Text style={styles.mobileNavLinkText}>Kako radi</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                      style={styles.mobileNavLink} 
                      onPress={() => scrollToSection('cjenik')}
                    >
                      <Ionicons name="pricetag-outline" size={20} color="#4C6FFF" />
                      <Text style={styles.mobileNavLinkText}>Cijene</Text>
                    </TouchableOpacity>
                  </View>
                  
                  <View style={styles.mobileAuthSection}>
                    <Text style={styles.mobileAuthTitle}>Korisnički račun</Text>
                    <TouchableOpacity 
                      style={[styles.mobileAuthButton, styles.mobileTerminalButton]}
                      onPress={() => {
                        navigation.navigate('TerminalActivation' as never);
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      <Ionicons name="desktop-outline" size={20} color="#fff" />
                      <Text style={[styles.mobileAuthButtonText, styles.mobileTerminalButtonText]}>
                        Terminal
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                      style={styles.mobileAuthButton}
                      onPress={() => {
                        navigation.navigate('Login' as never);
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      <Ionicons name="log-in-outline" size={20} color="#1E293B" />
                      <Text style={styles.mobileAuthButtonText}>Prijava</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                      style={[styles.mobileAuthButton, styles.mobileAuthButtonPrimary]}
                      onPress={() => {
                        navigation.navigate('Register' as never);
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      <Ionicons name="person-add-outline" size={20} color="#FFFFFF" />
                      <Text style={[styles.mobileAuthButtonText, styles.mobileAuthButtonTextPrimary]}>
                        Registracija
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            )}
          </>
        ) : (
          <>
            <View style={styles.navLinks}>
              <NavigationLinks />
            </View>

            <View style={styles.buttons}>
              <TouchableOpacity 
                style={[styles.terminalButton]}
                onPress={() => navigation.navigate('TerminalActivation' as never)}
              >
                <Ionicons name="desktop-outline" size={20} color="#fff" style={styles.terminalIcon} />
                <Text style={styles.terminalButtonText}>Terminal</Text>
              </TouchableOpacity>
              <TouchableOpacity 
                style={[styles.loginButton, isScrolled ? styles.buttonFilledScrolled : styles.buttonFilled]}
                onPress={() => navigation.navigate('Login' as never)}
              >
                <Text style={[styles.loginButtonText, isScrolled && styles.loginButtonTextScrolled]}>
                  Prijava
                </Text>
              </TouchableOpacity>
              <TouchableOpacity 
                style={[styles.registerButton, isScrolled ? styles.buttonOutlineScrolled : styles.buttonOutline]}
                onPress={() => navigation.navigate('Register' as never)}
              >
                <Text style={[styles.registerButtonText, isScrolled && styles.registerButtonTextScrolled]}>
                  Registracija
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease',
    paddingVertical: 20,
    width: '100%',
    borderBottom: '1px solid rgba(76, 111, 255, 0.1)',
    position: 'relative',
    zIndex: 9999,
  } as any,
  headerScrolled: {
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 12px rgba(76, 111, 255, 0.1)',
  } as any,
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1200,
    marginHorizontal: 'auto',
    paddingHorizontal: 24,
    width: '100%',
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoPart: {
    fontSize: 28,
    fontWeight: '600',
    color: '#1E293B',
    letterSpacing: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoPartScrolled: {
    color: '#4C6FFF',
  },
  logoDot: {
    fontSize: 28,
    fontWeight: '800',
    color: '#4299E1',
    marginHorizontal: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoDotScrolled: {
    color: '#4299E1',
  },
  logoHr: {
    fontSize: 28,
    fontWeight: '800',
    color: '#1E293B',
    letterSpacing: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoHrScrolled: {
    color: '#4C6FFF',
  },
  navLinks: {
    flexDirection: 'row',
    gap: 40,
  },
  navLink: {
    fontSize: 16,
    color: '#1a1a1a',
    fontWeight: '500',
    transition: 'color 0.3s ease',
    opacity: 0.8,
  } as any,
  navLinkScrolled: {
    color: '#1a1a1a',
    opacity: 1,
  },
  buttons: {
    flexDirection: 'row',
    gap: 16,
  },
  terminalButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1e293b',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 8,
    marginRight: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    transform: [{ translateY: 0 }],
    transition: 'all 0.2s ease',
  } as any,
  terminalIcon: {
    marginRight: 8,
  },
  terminalButtonText: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '600',
    letterSpacing: 0.3,
  },
  buttonFilled: {
    backgroundColor: '#4C6FFF',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 8,
    transition: 'all 0.3s ease',
  } as any,
  buttonFilledScrolled: {
    backgroundColor: '#4C6FFF',
  },
  buttonOutline: {
    backgroundColor: 'transparent',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 8,
    borderWidth: 1.5,
    borderColor: '#4C6FFF',
    transition: 'all 0.3s ease',
  } as any,
  buttonOutlineScrolled: {
    borderColor: '#4C6FFF',
  },
  loginButton: {},
  loginButtonText: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: '600',
    transition: 'color 0.3s ease',
  } as any,
  loginButtonTextScrolled: {
    color: '#ffffff',
  },
  registerButton: {},
  registerButtonText: {
    color: '#4C6FFF',
    fontSize: 14,
    fontWeight: '600',
    transition: 'color 0.3s ease',
  } as any,
  registerButtonTextScrolled: {
    color: '#4C6FFF',
  },
  mobileMenuButton: {
    padding: 8,
    zIndex: 1001,
  },
  mobileMenuIcon: {
    fontSize: 24,
    color: '#1E293B',
    transition: 'color 0.3s ease',
  } as any,
  mobileMenuIconScrolled: {
    color: '#4C6FFF',
  },
  mobileMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#FFFFFF',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    elevation: 4,
    zIndex: 1000,
  },
  mobileMenuContent: {
    padding: 24,
  },
  mobileNavLinks: {
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    paddingBottom: 24,
    marginBottom: 24,
  },
  mobileNavLink: {
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  mobileNavLinkText: {
    fontSize: 18,
    color: '#1E293B',
    fontWeight: '500',
  },
  mobileAuthSection: {
    gap: 16,
  },
  mobileAuthTitle: {
    fontSize: 16,
    color: '#64748B',
    fontWeight: '500',
    marginBottom: 8,
  },
  mobileAuthButton: {
    paddingVertical: 14,
    paddingHorizontal: 24,
    borderRadius: 12,
    backgroundColor: '#F1F5F9',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
  },
  mobileAuthButtonPrimary: {
    backgroundColor: '#4C6FFF',
  },
  mobileAuthButtonText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
  },
  mobileAuthButtonTextPrimary: {
    color: '#FFFFFF',
  },
  mobileTerminalButton: {
    backgroundColor: '#1e293b',
    marginBottom: 12,
  },
  mobileTerminalButtonText: {
    color: '#fff',
  },
});
