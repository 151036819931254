import React from 'react';
import { View, Text, StyleSheet, ScrollView, Pressable, TextInput } from 'react-native';
import { HelpLayout } from '../components/HelpLayout';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

interface ContactMethod {
  icon: string;
  title: string;
  value: string;
  link?: string;
}

const contactMethods: ContactMethod[] = [
  {
    icon: 'call-outline',
    title: 'Telefon',
    value: 'Nedostupno',
    link: ''
  },
  {
    icon: 'mail-outline',
    title: 'Email',
    value: 'info@rakicgroup.com',
    link: 'mailto:info@rakicgroup.com'
  },
  {
    icon: 'location-outline',
    title: 'Adresa',
    value: 'Put Čikole, 22320 Drniš'
  },
  {
    icon: 'time-outline',
    title: 'Radno vrijeme',
    value: 'Pon - Sub: 08:00 - 20:00'
  }
];

export const Contact = () => {
  const navigation = useNavigation();

  return (
    <HelpLayout>
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Kontaktirajte nas</Text>
          <Text style={styles.subtitle}>
            Naš tim za podršku je tu da vam pomogne. Odgovorit ćemo na vaš upit u najkraćem mogućem roku.
          </Text>

          {/* Kontakt informacije */}
          <View style={styles.contactGrid}>
            {contactMethods.map((method, index) => (
              <Pressable
                key={index}
                style={styles.contactCard}
                onPress={() => method.link && window.open(method.link, '_blank')}
              >
                <View style={styles.iconContainer}>
                  <Ionicons name={method.icon} size={24} color="#2563EB" />
                </View>
                <Text style={styles.methodTitle}>{method.title}</Text>
                <Text style={styles.methodValue}>{method.value}</Text>
              </Pressable>
            ))}
          </View>

          {/* Kontakt forma */}
          <View style={styles.formContainer}>
            <Text style={styles.formTitle}>Pošaljite nam poruku</Text>
            
            <View style={styles.formGrid}>
              <View style={styles.formColumn}>
                <View style={styles.inputGroup}>
                  <Text style={styles.label}>Ime i prezime</Text>
                  <TextInput
                    style={styles.input}
                    placeholder="Unesite vaše ime i prezime"
                  />
                </View>

                <View style={styles.inputGroup}>
                  <Text style={styles.label}>Email adresa</Text>
                  <TextInput
                    style={styles.input}
                    placeholder="Unesite vašu email adresu"
                    keyboardType="email-address"
                  />
                </View>

                <View style={styles.inputGroup}>
                  <Text style={styles.label}>Telefon (opcionalno)</Text>
                  <TextInput
                    style={styles.input}
                    placeholder="Unesite vaš broj telefona"
                    keyboardType="phone-pad"
                  />
                </View>
              </View>

              <View style={styles.formColumn}>
                <View style={styles.inputGroup}>
                  <Text style={styles.label}>Vaša poruka</Text>
                  <TextInput
                    style={[styles.input, styles.textArea]}
                    placeholder="Opišite vaš upit..."
                    multiline
                    numberOfLines={6}
                  />
                </View>
              </View>
            </View>

            <Pressable style={styles.submitButton}>
              <Text style={styles.submitButtonText}>Pošalji poruku</Text>
            </Pressable>
          </View>

          {/* Dodatne informacije */}
          <View style={styles.infoContainer}>
            <View style={styles.infoCard}>
              <Ionicons name="help-circle-outline" size={24} color="#2563EB" />
              <Text style={styles.infoTitle}>Često postavljena pitanja</Text>
              <Text style={styles.infoText}>
                Prije nego nas kontaktirate, pregledajte našu FAQ sekciju gdje možete pronaći 
                odgovore na najčešća pitanja.
              </Text>
              <Pressable 
                style={styles.infoButton}
                onPress={() => navigation.navigate('FAQ')}
              >
                <Text style={styles.infoButtonText}>Pregledaj FAQ</Text>
              </Pressable>
            </View>

            <View style={styles.infoCard}>
              <Ionicons name="document-text-outline" size={24} color="#2563EB" />
              <Text style={styles.infoTitle}>Dokumentacija</Text>
              <Text style={styles.infoText}>
                U našoj dokumentaciji možete pronaći detaljne upute za korištenje Clock.hr sustava.
              </Text>
              <Pressable 
                style={styles.infoButton}
                onPress={() => navigation.navigate('Guide')}
              >
                <Text style={styles.infoButtonText}>Pregledaj dokumentaciju</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </ScrollView>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 48,
  },
  contactGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -12,
    marginBottom: 48,
  },
  contactCard: {
    width: 'calc(25% - 24px)',
    marginHorizontal: 12,
    marginBottom: 24,
    padding: 24,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#EFF6FF',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  methodTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 8,
    textAlign: 'center',
  },
  methodValue: {
    fontSize: 14,
    color: '#64748B',
    textAlign: 'center',
  },
  formContainer: {
    backgroundColor: '#F8FAFC',
    padding: 32,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    marginBottom: 48,
  },
  formTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 32,
  },
  formGrid: {
    flexDirection: 'row',
    marginHorizontal: -12,
  },
  formColumn: {
    flex: 1,
    marginHorizontal: 12,
  },
  inputGroup: {
    marginBottom: 24,
  },
  label: {
    fontSize: 14,
    fontWeight: '500',
    color: '#475569',
    marginBottom: 8,
  },
  input: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#CBD5E1',
    borderRadius: 6,
    padding: 12,
    fontSize: 14,
    color: '#1E293B',
  },
  textArea: {
    minHeight: 144,
    textAlignVertical: 'top',
  },
  submitButton: {
    backgroundColor: '#2563EB',
    padding: 16,
    borderRadius: 6,
    alignItems: 'center',
    marginTop: 16,
  },
  submitButtonText: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: '600',
  },
  infoContainer: {
    flexDirection: 'row',
    marginHorizontal: -12,
  },
  infoCard: {
    flex: 1,
    marginHorizontal: 12,
    padding: 24,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    alignItems: 'center',
  },
  infoTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginTop: 16,
    marginBottom: 8,
    textAlign: 'center',
  },
  infoText: {
    fontSize: 14,
    color: '#64748B',
    textAlign: 'center',
    marginBottom: 16,
  },
  infoButton: {
    backgroundColor: '#EFF6FF',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6,
  },
  infoButtonText: {
    color: '#2563EB',
    fontSize: 14,
    fontWeight: '500',
  },
});
