import { useQuery } from '@tanstack/react-query';
import { employeesAPI } from '../services/api';

export const useLocations = () => {
  return useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      const response = await employeesAPI.getLocations();
      return response;
    },
  });
};
