import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, useTheme, Divider } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useDashboardStats } from '../../../hooks/useDashboardStats';
import { formatCurrency, formatMinutes } from '../../../utils/formatters';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { ErrorScreen } from '../../../components/ErrorScreen';

export const TotalStatsCard = () => {
  const theme = useTheme();
  const { data: stats, isLoading, error } = useDashboardStats();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!stats) {
    return null;
  }

  // Odredi boju za performanse
  const getPerformanceColor = (performance: number) => {
    if (performance >= 95) return '#2E7D32'; // tamno zelena
    if (performance >= 85) return '#F57C00'; // narančasta
    return '#D32F2F'; // crvena
  };

  const performanceValue = parseFloat(stats.performance);
  const performanceColor = getPerformanceColor(performanceValue);

  // Zajedničke boje
  const neutralColor = '#37474F'; // tamno siva za tekst
  const iconBgColor = 'rgba(55, 71, 79, 0.08)'; // prozirna siva za pozadinu
  const iconBorderColor = 'rgba(55, 71, 79, 0.12)'; // prozirna siva za border

  // Dohvati trenutni mjesec
  const mjeseci = [
    'Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj',
    'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'
  ];
  const trenutniMjesec = mjeseci[new Date().getMonth()];

  return (
    <View style={styles.container}>
      <View style={styles.monthTitleContainer}>
        <MaterialCommunityIcons 
          name="calendar-month" 
          size={24} 
          color={neutralColor} 
          style={{ marginRight: 8 }}
        />
        <Text style={styles.monthTitle}>
          Statistika za: {trenutniMjesec}
        </Text>
      </View>

      {/* Performanse i prekoračenja */}
      <Card 
        style={[
          styles.card, 
          { 
            backgroundColor: theme.colors.surface,
            borderRadius: 16,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 3.84,
            elevation: 5,
          }
        ]} 
      >
        <Card.Content style={styles.cardContent}>
          <View style={styles.row}>
            {/* Performanse */}
            <View style={styles.statContainer}>
              <View style={[styles.iconContainer, { backgroundColor: iconBgColor, borderWidth: 1, borderColor: iconBorderColor }]}>
                <MaterialCommunityIcons 
                  name="chart-line" 
                  size={24} 
                  color={performanceColor}
                />
              </View>
              <Text style={[styles.label, { color: neutralColor }]}>Performanse</Text>
              <Text style={[styles.value, { color: performanceColor, fontSize: 24 }]}>
                {stats.performance}%
              </Text>
            </View>

            <View style={styles.separator} />

            {/* Prekoračenja */}
            <View style={styles.statContainer}>
              <View style={[styles.iconContainer, { backgroundColor: iconBgColor, borderWidth: 1, borderColor: iconBorderColor }]}>
                <MaterialCommunityIcons 
                  name="timer-sand" 
                  size={24} 
                  color={neutralColor}
                />
              </View>
              <Text style={[styles.label, { color: neutralColor }]}>Prekoračenja</Text>
              <Text style={[styles.value, { color: neutralColor, fontSize: 24 }]}>
                {formatMinutes(stats.overBreakMinutes)}
              </Text>
            </View>
          </View>
        </Card.Content>
      </Card>

      {/* Bruto i odbici */}
      <Card 
        style={[
          styles.card, 
          { 
            backgroundColor: theme.colors.surface,
            borderRadius: 16,
            marginTop: 16,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 3.84,
            elevation: 5,
          }
        ]} 
      >
        <Card.Content style={styles.cardContent}>
          <View style={styles.row}>
            {/* Bruto */}
            <View style={styles.statContainer}>
              <View style={[styles.iconContainer, { backgroundColor: iconBgColor, borderWidth: 1, borderColor: iconBorderColor }]}>
                <MaterialCommunityIcons 
                  name="cash-plus" 
                  size={24} 
                  color={'#2E7D32'} // zelena za bruto
                />
              </View>
              <Text style={[styles.label, { color: neutralColor }]}>Bruto</Text>
              <Text style={[styles.value, { color: '#2E7D32', fontSize: 24 }]}>
                {formatCurrency(stats.totalEarnings)}
              </Text>
            </View>

            <View style={styles.separator} />

            {/* Odbici */}
            <View style={styles.statContainer}>
              <View style={[styles.iconContainer, { backgroundColor: iconBgColor, borderWidth: 1, borderColor: iconBorderColor }]}>
                <MaterialCommunityIcons 
                  name="cash-minus" 
                  size={24} 
                  color={'#D32F2F'} // crvena za odbitke
                />
              </View>
              <Text style={[styles.label, { color: neutralColor }]}>Odbici</Text>
              <Text style={[styles.value, { color: '#D32F2F', fontSize: 24 }]}>
                - {formatCurrency(stats.totalDeductions)}
              </Text>
            </View>
          </View>
        </Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  monthTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    marginLeft: 4,
  },
  monthIcon: {
    marginRight: 8,
  },
  monthTitle: {
    fontSize: 16,
    fontWeight: '500',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    marginBottom: 8,
    borderRadius: 12,
  },
  cardContent: {
    paddingVertical: 16,
    paddingHorizontal: 12,
  },
  statContainer: {
    flex: 1,
    alignItems: 'center',
  },
  iconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  icon: {
    marginRight: 6,
    opacity: 0.8,
  },
  label: {
    fontSize: 12,
    color: '#666',
    marginBottom: 2,
  },
  value: {
    fontSize: 16,
    fontWeight: '500',
  },
  separator: {
    width: 1,
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    marginHorizontal: 16,
  },
  earningsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 48,
  },
  earningsItem: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  smallIcon: {
    marginRight: 8,
  },
  earningsLabel: {
    fontSize: 12,
    color: '#666',
    marginBottom: 2,
  },
  earningsValue: {
    fontSize: 16,
    fontWeight: '500',
  },
  verticalDivider: {
    width: 1,
    height: 36,
    marginHorizontal: 12,
  },
  netItem: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  netValue: {
    fontSize: 16,
    fontWeight: '500',
  },
});
