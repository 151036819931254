import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useTheme, Button, ActivityIndicator } from 'react-native-paper';
import { useRoute, RouteProp, useNavigation, NavigationProp } from '@react-navigation/native';
import { employeesAPI } from '../../../../services/api';
import {
  hasAnyActiveDevices,
  shouldShowActivationBar,
  shouldShowNewDeviceInfo,
  shouldShowAddDeviceButton,
  shouldShowGenerateCodeButton
} from '../../../../screens/locations/utils/locationConditions';
import { WebLocationStats } from './WebLocationStats';

interface LocationDetails {
  _id: string;
  name: string;
  address: string;
  city: string;
  companyName: string;
  companyOIB: string;
  weeklyHours: number;
  active: boolean;
  activationCode: string;
  activationCodeExpiresAt: string;
  activationCodeUsed: boolean;
  deactivationPin: string | null;
  deactivationPinExpiresAt: string | null;
  deactivationPinUsed: boolean;
  activatedDevices: Array<{
    deviceId: string;
    activatedAt: string;
    lastUsed: string;
    active: boolean;
  }>;
}

type RootStackParamList = {
  LocationDetails: { locationId: string };
  EditLocation: { locationId: string };
};

type LocationDetailsParams = {
  LocationDetails: {
    locationId: string;
  };
};

export const WebLocationDetailsScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<LocationDetailsParams, 'LocationDetails'>>();
  const { locationId } = route.params;
  
  const [location, setLocation] = useState<LocationDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  const [deactivationPin, setDeactivationPin] = useState<string | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [showActivationInfo, setShowActivationInfo] = useState(false);
  const [deviceToDeactivate, setDeviceToDeactivate] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<'info' | 'stats'>('info');

  const calculateTimeLeft = (expiryDate: string) => {
    const now = new Date().getTime();
    const expiry = new Date(expiryDate).getTime();
    const difference = expiry - now;
    
    if (difference <= 0) {
      setIsCodeExpired(true);
      return '00:00';
    }
    
    setIsCodeExpired(false);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const fetchLocationDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const locationData = await employeesAPI.getLocationDetails(locationId);
      setLocation(locationData);
    } catch (error) {
      console.error('Greška pri dohvaćanju detalja lokacije:', error);
      setError('Došlo je do greške pri dohvaćanju detalja lokacije');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNewCode = async () => {
    try {
      const response = await employeesAPI.generateActivationCode(locationId);
      setLocation(prev => prev ? {
        ...prev,
        activationCode: response.activationCode,
        activationCodeExpiresAt: response.activationCodeExpiresAt,
        activationCodeUsed: false
      } : null);
      setShowActivationInfo(true);
    } catch (error) {
      console.error('Greška pri generiranju koda:', error);
      setError('Greška pri generiranju aktivacijskog koda');
    }
  };

  useEffect(() => {
    fetchLocationDetails();
  }, [locationId]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (location?.activationCode && !location.activationCodeUsed) {
      const updateTimer = () => {
        const timeLeft = calculateTimeLeft(location.activationCodeExpiresAt);
        setTimeLeft(timeLeft);
        
        if (timeLeft === '00:00') {
          setIsCodeExpired(true);
        }
      };
      
      updateTimer();
      timer = setInterval(updateTimer, 1000);
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [location?.activationCode, location?.activationCodeExpiresAt]);

  // Funkcija za renderiranje informacija o lokaciji
  const renderLocationInfo = () => (
    <>
      {shouldShowActivationBar(location) && (
        <View style={styles.activationBar}>
          <View style={styles.activationContent}>
            <MaterialCommunityIcons name="alert-circle-outline" size={20} color="#fff" />
            <Text style={styles.activationText}>
              {location.activationCode && !isCodeExpired 
                ? `Aktivirajte poslovnicu s kodom: ${location.activationCode} (${timeLeft})`
                : 'Aktivirajte poslovnicu'}
            </Text>
          </View>
          {shouldShowGenerateCodeButton(location, isCodeExpired) && (
            <Button 
              mode="contained" 
              onPress={handleGenerateNewCode}
              style={styles.generateButton}
            >
              Generiraj novi kod
            </Button>
          )}
          {shouldShowAddDeviceButton(location) && (
            <Button
              mode="contained"
              onPress={handleGenerateNewCode}
              style={styles.generateButton}
            >
              Dodaj novi uređaj
            </Button>
          )}
        </View>
      )}

      {shouldShowNewDeviceInfo(location, showActivationInfo, isCodeExpired) && (
        <View style={styles.infoBar}>
          <View style={styles.infoContent}>
            <MaterialCommunityIcons name="information" size={20} color="#fff" />
            <Text style={styles.infoText}>
              Dodaj novi uređaj s kodom: {location.activationCode} ({timeLeft})
            </Text>
          </View>
          <MaterialCommunityIcons 
            name="close" 
            size={20} 
            color="#fff" 
            onPress={() => setShowActivationInfo(false)}
            style={styles.closeIcon}
          />
        </View>
      )}

      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>Informacije o poslovnici</Text>
          <TouchableOpacity 
            style={styles.editButton} 
            onPress={() => {
              if (location?._id) {
                navigation.navigate('EditLocation', { locationId: location._id });
              }
            }}
          >
            <Text style={styles.editButtonText}>Uredi</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.infoRow}>
            <View style={styles.labelColumn}>
              <Text style={styles.infoLabel}>IME I PREZIME</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.infoValue}>{location.name}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={styles.labelColumn}>
              <Text style={styles.infoLabel}>ADRESA</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.infoValue}>{location.address || '-'}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={styles.labelColumn}>
              <Text style={styles.infoLabel}>GRAD</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.infoValue}>{location.city || '-'}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={styles.labelColumn}>
              <Text style={styles.infoLabel}>TVRTKA</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.infoValue}>{location.companyName || '-'}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={styles.labelColumn}>
              <Text style={styles.infoLabel}>OIB</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.infoValue}>{location.companyOIB || '-'}</Text>
            </View>
          </View>
          <View style={styles.infoRow}>
            <View style={styles.labelColumn}>
              <Text style={styles.infoLabel}>TJEDNI FOND SATI</Text>
            </View>
            <View style={styles.valueColumn}>
              <Text style={styles.infoValue}>{location.weeklyHours ? `${location.weeklyHours}h` : '-'}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Aktivirani uređaji</Text>
        <View style={styles.devicesContainer}>
          {shouldShowAddDeviceButton(location) && (
            <TouchableOpacity 
              style={styles.addDeviceButton}
              onPress={handleGenerateNewCode}
            >
              <MaterialCommunityIcons 
                name="plus-circle" 
                size={24} 
                color="#3B82F6"
              />
              <Text style={styles.addDeviceText}>Dodaj novi uređaj</Text>
            </TouchableOpacity>
          )}
          {location.activatedDevices.length === 0 ? (
            <View style={styles.noDevicesContainer}>
              <Text style={styles.noDevicesText}>Nema aktiviranih uređaja</Text>
            </View>
          ) : (
            <View style={styles.devicesGrid}>
              {location.activatedDevices.map((device, index) => (
                <View key={`device-${device.deviceId}-${index}`} style={styles.deviceCard}>
                  <View style={styles.deviceIconContainer}>
                    <MaterialCommunityIcons name="cellphone" size={24} color="#1E293B" />
                  </View>
                  <View style={styles.deviceContent}>
                    <Text style={styles.deviceName}>{device.deviceId}</Text>
                    <Text style={styles.deviceId}>Aktiviran: {new Date(device.activatedAt).toLocaleDateString()}</Text>
                    <Text style={styles.deviceId}>Zadnje korišten: {new Date(device.lastUsed).toLocaleDateString()}</Text>
                  </View>
                  <View style={[styles.statusBadge, device.active ? styles.statusActive : styles.statusInactive]}>
                    <Text style={[styles.statusText, device.active ? styles.statusTextActive : styles.statusTextInactive]}>
                      {device.active ? 'Aktivan' : 'Neaktivan'}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    </>
  );

  if (loading) {
    return (
      <View style={styles.container}>
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={theme.colors.primary} />
        </View>
      </View>
    );
  }

  if (error || !location) {
    return (
      <View style={styles.container}>
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error || 'Poslovnica nije pronađena'}</Text>
          <Button 
            mode="contained" 
            onPress={fetchLocationDetails}
            style={styles.retryButton}
          >
            Pokušaj ponovno
          </Button>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {/* Tabovi za navigaciju */}
        <View style={styles.tabContainer}>
          <TouchableOpacity
            style={[styles.tab, selectedTab === 'info' && styles.activeTab]}
            onPress={() => setSelectedTab('info')}
          >
            <Text style={[styles.tabText, selectedTab === 'info' && styles.activeTabText]}>
              Informacije
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.tab, selectedTab === 'stats' && styles.activeTab]}
            onPress={() => setSelectedTab('stats')}
          >
            <Text style={[styles.tabText, selectedTab === 'stats' && styles.activeTabText]}>
              Statistika
            </Text>
          </TouchableOpacity>
        </View>

        {/* Prikaz odabranog taba */}
        {selectedTab === 'info' && renderLocationInfo()}
        {selectedTab === 'stats' && (
          <View style={styles.statsContainer}>
            <WebLocationStats locationId={locationId} />
          </View>
        )}
      </View>

      {error && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 24,
  },
  content: {
    flex: 1,
  },
  tabContainer: {
    flexDirection: 'row',
    marginBottom: 24,
  },
  activationBar: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#2196F3',
    borderRadius: 8,
    padding: 16,
    marginBottom: 24,
  },
  activationContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  activationText: {
    color: '#fff',
    marginLeft: 12,
    fontSize: 14,
  },
  generateButton: {
    marginLeft: 16,
  },
  infoBar: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#4CAF50',
    borderRadius: 8,
    padding: 16,
    marginBottom: 24,
  },
  infoContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  infoText: {
    color: '#fff',
    marginLeft: 12,
    fontSize: 14,
  },
  closeIcon: {
    cursor: 'pointer',
  },
  section: {
    marginBottom: 32,
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  editButton: {
    backgroundColor: '#1D4ED8',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 6,
  },
  editButtonText: {
    color: 'white',
    fontSize: 14,
    fontWeight: '500',
  },
  infoContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    elevation: 0,
    shadowOpacity: 0,
    shadowRadius: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },
  infoRow: {
    flexDirection: 'row',
    minHeight: 48,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
  },
  labelColumn: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: '#FFFFFF',
  },
  valueColumn: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: '#FFFFFF',
    borderLeftWidth: 1,
    borderLeftColor: '#E5E7EB',
  },
  infoLabel: {
    fontSize: 13,
    color: '#4B5563',
    fontWeight: '500',
  },
  infoValue: {
    fontSize: 13,
    color: '#1F2937',
  },
  devicesContainer: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    padding: 24,
  },
  devicesGrid: {
    gap: 16,
  },
  deviceCard: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  deviceIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deviceContent: {
    flex: 1,
    gap: 8,
  },
  deviceName: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
  },
  deviceId: {
    fontSize: 14,
    color: '#64748B',
    lineHeight: 20,
  },
  statusBadge: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 4,
    minWidth: 100,
    alignItems: 'center',
  },
  statusActive: {
    backgroundColor: '#E8F5E9',
  },
  statusInactive: {
    backgroundColor: '#FFEBEE',
  },
  statusText: {
    fontSize: 14,
    fontWeight: '500',
  },
  statusTextActive: {
    color: '#2E7D32',
  },
  statusTextInactive: {
    color: '#C62828',
  },
  addDeviceButton: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    alignSelf: 'flex-start',
    marginBottom: 24,
  },
  addDeviceText: {
    fontSize: 14,
    color: '#3B82F6',
    fontWeight: '500',
  },
  noDevicesContainer: {
    padding: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDevicesText: {
    fontSize: 14,
    color: '#64748B',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  errorText: {
    fontSize: 16,
    color: '#EF4444',
    marginBottom: 16,
    textAlign: 'center',
  },
  retryButton: {
    borderRadius: 8,
  },
  statsContainer: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 4,
    overflow: 'hidden',
  },
  tab: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginRight: 8,
    backgroundColor: '#FFFFFF',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#3B82F6',
  },
  tabText: {
    fontSize: 14,
    color: '#64748B',
  },
  activeTabText: {
    color: '#1E293B',
    fontWeight: '500',
  },
});
