import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Alert,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { Appbar, TextInput, Text } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { employeesAPI } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { useQueryClient } from '@tanstack/react-query';

interface LocationData {
  name: string;
  address: string;
  country: string;
  city: string;
  companyName: string;
  companyOIB: string;
  weeklyHours: number;
  ownerId: string;
}

interface LocationResponse {
  _id: string;
  activationCode: string;
  activationCodeExpiresAt: string;
}

export const CreateLocationScreen = () => {
  const navigation = useNavigation();
  const { owner } = useAuth();
  const queryClient = useQueryClient();
  const [locationData, setLocationData] = useState<LocationData>({
    name: '',
    address: '',
    country: 'Hrvatska',
    city: '',
    companyName: '',
    companyOIB: '',
    weeklyHours: 40,
    ownerId: owner?.id || '',
  });

  const handleSave = async () => {
    if (!owner) {
      Alert.alert('Greška', 'Niste prijavljeni');
      return;
    }

    // Validacija
    if (!locationData.name.trim()) {
      Alert.alert('Greška', 'Molimo unesite ime poslovnice');
      return;
    }
    if (!locationData.address.trim()) {
      Alert.alert('Greška', 'Molimo unesite adresu poslovnice');
      return;
    }
    if (!locationData.city.trim()) {
      Alert.alert('Greška', 'Molimo unesite grad');
      return;
    }
    if (!locationData.companyName.trim()) {
      Alert.alert('Greška', 'Molimo unesite ime tvrtke');
      return;
    }
    if (!locationData.companyOIB.trim() || locationData.companyOIB.length !== 11) {
      Alert.alert('Greška', 'Molimo unesite ispravan OIB (11 znamenki)');
      return;
    }

    try {
      const response = await employeesAPI.createLocation({
        ...locationData,
        ownerId: owner.id
      });
      
      await Promise.all([
        queryClient.invalidateQueries(['locations']),
        queryClient.invalidateQueries(['dashboard']),
        queryClient.invalidateQueries(['locationStats']),
        queryClient.invalidateQueries(['totalStats']),
        queryClient.invalidateQueries(['settings']),
        queryClient.invalidateQueries(['locationLogs']),
        queryClient.invalidateQueries(['breaks'])
      ]);
      
      Alert.alert('Uspjeh', 'Poslovnica je uspješno kreirana', [
        { 
          text: 'OK', 
          onPress: () => {
            navigation.navigate('Locations');
          }
        }
      ]);
    } catch (error) {
      Alert.alert(
        'Greška', 
        'Došlo je do greške prilikom kreiranja poslovnice. ' +
        (error.response?.data?.message || 'Molimo pokušajte ponovno.')
      );
    }
  };

  return (
    <SafeAreaView style={styles.safeArea} edges={['top', 'left', 'right']}>
      <Appbar.Header style={styles.header} statusBarHeight={0}>
        <TouchableOpacity 
          style={styles.backButton}
          onPress={() => navigation.goBack()}
        >
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Appbar.Content title="Nova poslovnica" titleStyle={styles.headerTitle} />
        <TouchableOpacity 
          style={styles.saveButton}
          onPress={handleSave}
        >
          <Text style={styles.saveButtonText}>Kreiraj</Text>
        </TouchableOpacity>
      </Appbar.Header>

      <KeyboardAvoidingView 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 20}
      >
        <ScrollView 
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.container}>
            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Ime poslovnice"
                value={locationData.name}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, name: text }))}
                placeholder="npr. Šibenik - Podi"
                style={styles.input}
                contentStyle={styles.contentStyle}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Adresa"
                value={locationData.address}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, address: text }))}
                placeholder="npr. Ul. Velimira Škorpika 24"
                style={styles.input}
                contentStyle={styles.contentStyle}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Država"
                value={locationData.country}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, country: text }))}
                placeholder="npr. Hrvatska"
                style={styles.input}
                contentStyle={styles.contentStyle}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Grad"
                value={locationData.city}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, city: text }))}
                placeholder="npr. Šibenik"
                style={styles.input}
                contentStyle={styles.contentStyle}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Ime tvrtke"
                value={locationData.companyName}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, companyName: text }))}
                placeholder="npr. Cascade d.o.o."
                style={styles.input}
                contentStyle={styles.contentStyle}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="OIB tvrtke"
                value={locationData.companyOIB}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, companyOIB: text }))}
                placeholder="Unesite 11 znamenki"
                keyboardType="numeric"
                maxLength={11}
                style={styles.input}
                contentStyle={styles.contentStyle}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <View style={styles.labelContainer}>
                <TextInput
                  mode="outlined"
                  label="Tjedni fond sati"
                  value={locationData.weeklyHours === 0 ? '' : locationData.weeklyHours.toString()}
                  onChangeText={(text) => {
                    const hours = parseInt(text);
                    if (text === '') {
                      setLocationData(prev => ({ ...prev, weeklyHours: 0 }));
                    } else if (!isNaN(hours)) {
                      setLocationData(prev => ({ ...prev, weeklyHours: hours }));
                    }
                  }}
                  placeholder="npr. 40"
                  keyboardType="numeric"
                  style={styles.input}
                  contentStyle={styles.contentStyle}
                  dense
                  right={
                    <TextInput.Icon 
                      icon="information-outline" 
                      size={20}
                      onPress={() => Alert.alert(
                        "Tjedni fond sati",
                        "Unesite zakonski definirani tjedni fond sati (npr. 40 sati). Ova informacija se koristi za detaljniju statistiku evidencije radnog vremena djelatnika.",
                        [{ text: "U redu" }]
                      )}
                    />
                  }
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#fff',
    elevation: 0,
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    padding: 8,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  saveButton: {
    marginRight: 16,
  },
  saveButtonText: {
    color: '#007AFF',
    fontSize: 17,
    fontWeight: '600',
  },
  headerTitle: {
    fontSize: 20,
  },
  scrollView: {
    flex: 1,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    padding: 16,
  },
  inputGroup: {
    marginBottom: 0,
  },
  labelContainer: {
    flexDirection: 'row',
  },
  input: {
    backgroundColor: '#fff',
    height: 44,
    marginBottom: 8,
  },
  contentStyle: {
    paddingVertical: 8,
  },
});
