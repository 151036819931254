import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
// @ts-ignore
import autoTable from 'jspdf-autotable';
import { companyAPI, employeesAPI } from '../../../../services/api';

interface Props {
  employeeId: string;
  startDate: Date;
  endDate: Date;
  periodStats?: any;
  employeeName?: string;
  locationName?: string;
}

// Helper funkcije za formatiranje podataka
const formatDuration = (minutes: number) => {
  if (minutes === 0) return '0min';
  
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes % 1) * 60);
  
  let result = '';
  if (hours > 0) result += `${hours}h `;
  if (mins > 0 || hours > 0) result += `${mins}min `;
  if (secs > 0 || (minutes > 0 && minutes < 1)) result += `${secs}s`;
  
  return result.trim();
};

const formatMoney = (amount: number) => {
  return `${amount.toFixed(2)} €`;
};

export const WebEmployeeDetailedStatsPDF: React.FC<Props> = ({ 
  employeeId, 
  startDate, 
  endDate, 
  periodStats,
  employeeName,
  locationName
}) => {
  const generatePDF = async () => {
    try {
      // Dohvati podatke o kompaniji
      let companyData = { name: 'Tvrtka' };
      try {
        const companyResponse = await companyAPI.getCompanyData();
        if (companyResponse) {
          companyData = companyResponse;
        }
      } catch (error) {
        console.error('Greška pri dohvaćanju podataka o kompaniji:', error);
      }
      
      // Dohvati podatke o zaposleniku ako nisu proslijeđeni
      let empName = employeeName;
      let locName = locationName || 'Lokacija';
      let empOIB = ''; // Dodana varijabla za OIB zaposlenika
      
      if (!empName || !locName) {
        try {
          const employeeData = await employeesAPI.getEmployeeDetails(employeeId);
          if (employeeData) {
            empName = `${employeeData.name || ''} ${employeeData.surname || ''}`.trim() || 'Zaposlenik';
            locName = (employeeData.location?.name || employeeData.locationId?.name || 'Lokacija');
            empOIB = employeeData.oib || ''; // Dohvaćanje OIB-a zaposlenika
          }
        } catch (error) {
          console.error('Greška pri dohvaćanju podataka o zaposleniku:', error);
          empName = empName || 'Zaposlenik';
        }
      }
      
      // Dohvati statistiku za period ako nije proslijeđena
      let stats = periodStats;
      if (!stats) {
        try {
          stats = await employeesAPI.getEmployeeWeeklyStats(
            employeeId,
            format(startDate, 'yyyy-MM-dd'),
            format(endDate, 'yyyy-MM-dd')
          );
        } catch (error) {
          console.error('Greška pri dohvaćanju statistike:', error);
          alert('Došlo je do greške pri dohvaćanju podataka. Molimo pokušajte ponovno.');
          return;
        }
      }
      
      if (!stats || !stats.days || !Array.isArray(stats.days)) {
        alert('Nema podataka za odabrani period.');
        return;
      }
      
      // Kreiraj PDF dokument
      const doc = new jsPDF({
        orientation: 'landscape', // Promijenjeno na landscape za više prostora
        unit: 'mm',
        format: 'a4'
      });
      
      // Dodaj zaglavlje
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0); // Crna boja za "clock"
      doc.setFont(undefined, 'bold');
      doc.text('clock', 14, 15);
      
      // Dodaj plavu točku
      doc.setTextColor(0, 123, 255); // Plava boja za točku
      doc.text('.', 14 + doc.getTextWidth('clock'), 15);
      
      // Dodaj "hr" u crnoj boji
      doc.setTextColor(0, 0, 0); // Crna boja za "hr"
      doc.text('hr', 14 + doc.getTextWidth('clock.'), 15);
      doc.setFont(undefined, 'normal');
      
      // Uklonjen datum iz gornjeg desnog kuta
      
      // Dodaj naslov
      doc.setFontSize(14);
      doc.text('Detaljni izvjestaj', doc.internal.pageSize.width / 2, 30, { align: 'center' });
      
      // Dodaj podatke o tvrtki, poslovnici i zaposleniku
      const leftMargin = 14;
      let yPosition = 45;
      const lineHeight = 6;
      
      // Funkcija za uklanjanje dijakritičkih znakova
      const removeDiacritics = (str: string): string => {
        return str
          .replace(/č/g, 'c')
          .replace(/ć/g, 'c')
          .replace(/đ/g, 'd')
          .replace(/š/g, 's')
          .replace(/ž/g, 'z')
          .replace(/Č/g, 'C')
          .replace(/Ć/g, 'C')
          .replace(/Đ/g, 'D')
          .replace(/Š/g, 'S')
          .replace(/Ž/g, 'Z');
      };
      
      // Tvrtka
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Tvrtka:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(companyData?.name ? removeDiacritics(companyData.name) : 'Tvrtka', leftMargin + 30, yPosition);
      yPosition += lineHeight;
      
      doc.setFontSize(9);
      doc.text(`OIB: ${companyData?.oib || '12345678901'}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 2;
      
      // Poslovnica
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Poslovnica:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(locName ? removeDiacritics(locName) : 'Poslovni pode', leftMargin + 30, yPosition);
      yPosition += lineHeight;
      
      doc.setFontSize(9);
      doc.text(`Adresa: ${companyData?.address ? removeDiacritics(companyData.address) : 'Ulica 123, Zagreb'}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 2;
      
      // Zaposlenik
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Zaposlenik:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(empName ? removeDiacritics(empName) : 'Ime i prezime', leftMargin + 30, yPosition);
      yPosition += lineHeight;
      
      doc.setFontSize(9);
      doc.text(`OIB: ${empOIB}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 2;
      
      // Period
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Period:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(`${format(startDate, 'dd.MM.yyyy.')} - ${format(endDate, 'dd.MM.yyyy.')}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 5;
      
      // Funkcija za formatiranje vremena - prikazuje sate, minute i sekunde
      const formatDuration = (minutes: number | undefined | null): string => {
        if (!minutes || minutes === 0) return '0min';
        
        // Pretvaranje minuta u sekunde
        const totalSeconds = Math.round(minutes * 60);
        
        const hours = Math.floor(totalSeconds / 3600);
        const mins = Math.floor((totalSeconds % 3600) / 60);
        const secs = totalSeconds % 60;
        
        let result = '';
        
        if (hours > 0) {
          result += `${hours}h `;
        }
        
        if (mins > 0 || hours > 0) {
          result += `${mins}min `;
        }
        
        if (secs > 0) {
          result += `${secs}sec`;
        }
        
        return result.trim();
      };
      
      // Generiraj sve dane u odabranom periodu
      const allDaysInPeriod = [];
      let currentDay = new Date(startDate);
      const lastDay = new Date(endDate);
      
      while (currentDay <= lastDay) {
        allDaysInPeriod.push(new Date(currentDay));
        currentDay.setDate(currentDay.getDate() + 1);
      }
      
      // Kreiraj mapu postojećih podataka za brže pretraživanje
      const statsMap = new Map();
      stats.days.forEach((day: any) => {
        const dateKey = format(new Date(day.date), 'yyyy-MM-dd');
        statsMap.set(dateKey, day);
      });
      
      // Pripremi podatke za tablicu za sve dane u periodu
      const tableData = allDaysInPeriod.map((date: Date) => {
        const dateKey = format(date, 'yyyy-MM-dd');
        const dayStats = statsMap.get(dateKey);
        
        if (dayStats) {
          return [
            format(date, 'dd.MM.'),
            getDayName(date),
            empName ? removeDiacritics(empName) : '-',
            dayStats.totalWorkMinutes ? formatDuration(dayStats.totalWorkMinutes) : '0min',
            dayStats.effectiveWorkMinutes ? formatDuration(dayStats.effectiveWorkMinutes) : '0min',
            dayStats.paidTime ? formatDuration(dayStats.paidTime) : '0min',
            dayStats.totalBreakMinutes ? formatDuration(dayStats.totalBreakMinutes) : '0min',
            dayStats.totalPaidBreakMinutes ? formatDuration(dayStats.totalPaidBreakMinutes) : '0min',
            dayStats.totalUnpaidBreakMinutes ? formatDuration(dayStats.totalUnpaidBreakMinutes) : '0min',
            dayStats.overPaidBreakMinutes ? formatDuration(dayStats.overPaidBreakMinutes) : '0min',
            dayStats.overUnpaidBreakMinutes ? formatDuration(dayStats.overUnpaidBreakMinutes) : '0min',
            (dayStats.overPaidBreakMinutes + dayStats.overUnpaidBreakMinutes) ? formatDuration(dayStats.overPaidBreakMinutes + dayStats.overUnpaidBreakMinutes) : '0min',
            (dayStats.deductions || 0).toFixed(2) + ' €',
            (dayStats.earnings || 0).toFixed(2) + ' €'
          ];
        } else {
          return [
            format(date, 'dd.MM.'),
            getDayName(date),
            empName ? removeDiacritics(empName) : '-',
            '0min',
            '0min',
            '0min',
            '0min',
            '0min',
            '0min',
            '0min',
            '0min',
            '0min',
            '0.00 €',
            '0.00 €'
          ];
        }
      });
      
      // Izračunaj ukupne statistike za period
      const periodTotals = stats.days.reduce((acc: any, day: any) => ({
        totalWorkMinutes: acc.totalWorkMinutes + (day.totalWorkMinutes || 0),
        effectiveWorkMinutes: acc.effectiveWorkMinutes + (day.effectiveWorkMinutes || 0),
        totalBreakMinutes: acc.totalBreakMinutes + (day.totalBreakMinutes || 0),
        paidTime: acc.paidTime + (day.paidTime || 0),
        totalPaidBreakMinutes: acc.totalPaidBreakMinutes + (day.totalPaidBreakMinutes || 0),
        totalUnpaidBreakMinutes: acc.totalUnpaidBreakMinutes + (day.totalUnpaidBreakMinutes || 0),
        overPaidBreakMinutes: acc.overPaidBreakMinutes + (day.overPaidBreakMinutes || 0),
        overUnpaidBreakMinutes: acc.overUnpaidBreakMinutes + (day.overUnpaidBreakMinutes || 0),
        earnings: acc.earnings + (day.earnings || 0),
        deductions: acc.deductions + (day.deductions || 0)
      }), {
        totalWorkMinutes: 0,
        effectiveWorkMinutes: 0,
        totalBreakMinutes: 0,
        paidTime: 0,
        totalPaidBreakMinutes: 0,
        totalUnpaidBreakMinutes: 0,
        overPaidBreakMinutes: 0,
        overUnpaidBreakMinutes: 0,
        earnings: 0,
        deductions: 0
      });
      
      // Dodaj redak s ukupnim vrijednostima
      tableData.push([
        'Ukupno',
        '',
        '',
        periodTotals.totalWorkMinutes ? formatDuration(periodTotals.totalWorkMinutes) : '0min',
        periodTotals.effectiveWorkMinutes ? formatDuration(periodTotals.effectiveWorkMinutes) : '0min',
        periodTotals.paidTime ? formatDuration(periodTotals.paidTime) : '0min',
        periodTotals.totalBreakMinutes ? formatDuration(periodTotals.totalBreakMinutes) : '0min',
        periodTotals.totalPaidBreakMinutes ? formatDuration(periodTotals.totalPaidBreakMinutes) : '0min',
        periodTotals.totalUnpaidBreakMinutes ? formatDuration(periodTotals.totalUnpaidBreakMinutes) : '0min',
        periodTotals.overPaidBreakMinutes ? formatDuration(periodTotals.overPaidBreakMinutes) : '0min',
        periodTotals.overUnpaidBreakMinutes ? formatDuration(periodTotals.overUnpaidBreakMinutes) : '0min',
        (periodTotals.overPaidBreakMinutes + periodTotals.overUnpaidBreakMinutes) ? formatDuration(periodTotals.overPaidBreakMinutes + periodTotals.overUnpaidBreakMinutes) : '0min',
        (periodTotals.deductions || 0).toFixed(2) + ' €',
        (periodTotals.earnings - (periodTotals.deductions || 0)).toFixed(2) + ' €'
      ]);
      
      // Generiraj tablicu
      autoTable(doc, {
        startY: yPosition,
        head: [
          ['Datum', 'Dan', 'Ime i prezime', 'Ukupno rad', 'Efektivno', 'Plaćeno', 'Pauze ukupno', 'Plaćene pauze', 'Neplaćene pauze', 'Preko. plaćenih', 'Preko. neplaćenih', 'Preko. ukupno', 'Odbitak (€)', 'Zarada (€)']
            .map((header) => removeDiacritics(header))
        ],
        body: tableData,
        headStyles: {
          fillColor: [240, 240, 240],
          textColor: [0, 0, 0],
          fontSize: 8,
          fontStyle: 'bold',
          halign: 'center',
          cellPadding: 1,
          lineWidth: 0.1,
          lineColor: [0, 0, 0]
        },
        bodyStyles: {
          fontSize: 8,
          cellPadding: 1,
          lineWidth: 0.1,
          lineColor: [0, 0, 0]
        },
        theme: 'grid',
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          minCellHeight: 6,
          maxCellHeight: 8,
          valign: 'middle',
          halign: 'center'
        },
        columnStyles: {
          0: { cellWidth: 'auto' }, // Datum
          1: { cellWidth: 'auto' }, // Dan
          2: { cellWidth: 'auto' }, // Ime i prezime
          3: { cellWidth: 'auto', halign: 'right' }, // Ukupno rad
          4: { cellWidth: 'auto', halign: 'right' }, // Efektivno
          5: { cellWidth: 'auto', halign: 'right' }, // Plaćeno
          6: { cellWidth: 'auto', halign: 'right' }, // Pauze ukupno
          7: { cellWidth: 'auto', halign: 'right' }, // Plaćene pauze
          8: { cellWidth: 'auto', halign: 'right' }, // Neplaćene pauze
          9: { cellWidth: 'auto', halign: 'right' }, // Preko. plaćenih
          10: { cellWidth: 'auto', halign: 'right' }, // Preko. neplaćenih
          11: { cellWidth: 'auto', halign: 'right' }, // Preko. ukupno
          12: { cellWidth: 'auto', halign: 'right' }, // Odbitak
          13: { cellWidth: 'auto', halign: 'right' }  // Zarada
        },
        didParseCell: function(data) {
          // Posebno oblikovanje za redak s ukupnim vrijednostima
          if (data.row.index === tableData.length - 1) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.fillColor = [240, 240, 240]; // Siva pozadina za ukupno redak
          }
        },
        margin: { left: 5, right: 5 },
        tableWidth: 'auto',
        showHead: 'everyPage',
        showFoot: 'everyPage',
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0]
      });
      
      // Dodaj podnožje
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        
        // Pozicija podnožja
        const footerY = doc.internal.pageSize.height - 10;
        
        // Dodaj broj stranice u sredini i datum generiranja na desno
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text(
          `Stranica ${i} od ${pageCount}`,
          doc.internal.pageSize.width / 2,
          footerY,
          { align: 'center', angle: 0 }
        );

        // Dodaj datum i vrijeme generiranja
        const generatedDateTime = format(new Date(), 'dd.MM.yyyy. HH:mm:ss');
        doc.text(
          `Generirano: ${generatedDateTime}`,
          doc.internal.pageSize.width - 14,
          footerY,
          { align: 'right' }
        );
      }
      
      // Spremi PDF
      doc.save(`Izvjestaj_${empName}_${format(startDate, 'dd-MM-yyyy')}_${format(endDate, 'dd-MM-yyyy')}.pdf`);
      
    } catch (error) {
      console.error('Greška pri generiranju PDF-a:', error);
      alert('Došlo je do greške pri generiranju PDF-a. Molimo pokušajte ponovno.');
    }
  };
  
  // Pomoćna funkcija za dobivanje imena dana bez hrvatskih znakova
  const getDayName = (date: Date): string => {
    const dayIndex = date.getDay();
    const days = ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Cetvrtak', 'Petak', 'Subota'];
    return days[dayIndex];
  };

  return (
    <TouchableOpacity style={styles.pdfButton} onPress={generatePDF}>
      <MaterialIcons name="picture-as-pdf" size={20} color="#FFFFFF" />
      <Text style={styles.pdfButtonText}>Preuzmi PDF</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  pdfButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#DC2626',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    justifyContent: 'center',
    gap: 8,
  },
  pdfButtonText: {
    color: '#FFFFFF',
    fontWeight: '500',
    fontSize: 14,
  },
});
