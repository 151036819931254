import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
  Dimensions,
  TouchableWithoutFeedback,
  Keyboard,
  useColorScheme,
  StatusBar,
} from 'react-native';
import { useAuthContext } from '../../context/AuthContext';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { API_URL } from '../../config';
import * as Notifications from 'expo-notifications';

const { width } = Dimensions.get('window');

type RootStackParamList = {
  Register: { isDarkMode: boolean };
  Login: undefined;
  Onboarding: undefined;
  TerminalActivation: undefined;
  Terminal: undefined;
  Main: undefined;
};

type LoginScreenNavigationProp = NavigationProp<RootStackParamList>;

export const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { login, error, isAuthenticated } = useAuthContext();
  const navigation = useNavigation<LoginScreenNavigationProp>();

  useEffect(() => {
    loadThemePreference();
    if (isAuthenticated && !isLoading) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Main' }],
      });
    }
  }, [isAuthenticated, isLoading]);

  const loadThemePreference = async () => {
    try {
      const theme = await AsyncStorage.getItem('@theme_preference');
      setIsDarkMode(theme === 'dark');
    } catch (error) {
      console.error('Greška pri učitavanju teme:', error);
    }
  };

  const toggleTheme = async () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    try {
      await AsyncStorage.setItem('@theme_preference', newTheme ? 'dark' : 'light');
    } catch (error) {
      console.error('Greška pri spremanju teme:', error);
    }
  };

  const showErrorNotification = async (message: string) => {
    await Notifications.scheduleNotificationAsync({
      content: {
        title: 'Greška',
        body: message,
        sound: 'default',
      },
      trigger: null,
    });
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await login({ email, password });
      if (!response.success) {
        setHasError(true);
        setPassword('');
        await showErrorNotification(response.error || 'Neispravni podaci za prijavu');
      } 
    } catch (error) {
      setHasError(true);
      setPassword('');
      await showErrorNotification('Neispravni podaci za prijavu');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerminalMode = async () => {
    // Provjeri sve potrebne podatke za terminal
    const [terminalPin, locationId, deviceId] = await Promise.all([
      AsyncStorage.getItem('@terminal_pin'),
      AsyncStorage.getItem('locationId'),
      AsyncStorage.getItem('@device_id')
    ]);

    // Ako imamo sve podatke, terminal je aktivan
    if (terminalPin && locationId && deviceId) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Terminal' }],
      });
    } else {
      navigation.navigate('TerminalActivation' as never);
    }
  };

  const theme = {
    background: isDarkMode ? '#000000' : '#ffffff',
    text: isDarkMode ? '#ffffff' : '#1a1a1a',
    secondaryText: isDarkMode ? '#999999' : '#666666',
    inputBackground: isDarkMode ? '#1a1a1a' : '#ffffff',
    inputBorder: isDarkMode ? '#333333' : '#e6e6e6',
    buttonBackground: isDarkMode ? '#ffffff' : '#1a1a1a',
    buttonText: isDarkMode ? '#000000' : '#ffffff',
    toggleBackground: isDarkMode ? '#1a1a1a' : '#e6e6e6',
  };

  return (
    <SafeAreaView style={[styles.container, isDarkMode ? styles.darkContainer : styles.lightContainer]}>
      <StatusBar
        barStyle={isDarkMode ? 'light-content' : 'dark-content'}
        backgroundColor={isDarkMode ? '#000000' : '#ffffff'}
      />
      <TouchableOpacity
        style={[styles.iconButton, styles.leftButton, { backgroundColor: theme.toggleBackground }]}
        onPress={() => navigation.navigate('Onboarding' as never, { isDarkMode } as never)}
      >
        <Ionicons
          name="boat-outline"
          size={24}
          color={theme.text}
        />
      </TouchableOpacity>

      <TouchableOpacity
        style={[styles.iconButton, styles.rightButton, { backgroundColor: theme.toggleBackground }]}
        onPress={toggleTheme}
      >
        <Ionicons
          name={isDarkMode ? 'sunny-outline' : 'moon-outline'}
          size={24}
          color={theme.text}
        />
      </TouchableOpacity>

      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.content}
        >
          <View style={styles.logoContainer}>
            <View style={styles.logoTextContainer}>
              <Text style={[styles.logoPart, { color: theme.text }]}>clock</Text>
              <Text style={[styles.logoDot, { color: theme.text }]}>.</Text>
              <Text style={[styles.logoHr, { color: theme.text }]}>hr</Text>
            </View>
            <Text style={[styles.title, { color: theme.text }]}>Prijava</Text>
          </View>

          <View style={styles.form}>
            <View style={styles.inputContainer}>
              <TextInput
                style={[
                  styles.input,
                  { 
                    backgroundColor: theme.inputBackground,
                    borderColor: hasError ? '#ff4444' : theme.inputBorder,
                    color: theme.text,
                  },
                ]}
                placeholder="Email"
                value={email}
                onChangeText={(text) => {
                  setEmail(text);
                  setHasError(false);
                }}
                autoCapitalize="none"
                keyboardType="email-address"
                placeholderTextColor={theme.secondaryText}
              />
              <Ionicons 
                name="mail-outline" 
                size={20} 
                color={hasError ? '#ff4444' : theme.secondaryText} 
                style={styles.inputIcon} 
              />
            </View>

            <View style={styles.inputContainer}>
              <TextInput
                style={[
                  styles.input,
                  { 
                    backgroundColor: theme.inputBackground,
                    borderColor: hasError ? '#ff4444' : theme.inputBorder,
                    color: theme.text,
                  },
                ]}
                placeholder="Lozinka"
                value={password}
                onChangeText={(text) => {
                  setPassword(text);
                  setHasError(false);
                }}
                secureTextEntry={!showPassword}
                placeholderTextColor={theme.secondaryText}
              />
              <Ionicons 
                name="lock-closed-outline" 
                size={20} 
                color={hasError ? '#ff4444' : theme.secondaryText} 
                style={styles.inputIcon} 
              />
              <TouchableOpacity 
                onPress={() => setShowPassword(!showPassword)}
                style={styles.eyeIcon}
              >
                <Ionicons 
                  name={showPassword ? "eye-off-outline" : "eye-outline"} 
                  size={20} 
                  color={theme.secondaryText}
                />
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={[styles.button, { backgroundColor: theme.buttonBackground }]}
              onPress={handleLogin}
              disabled={isLoading}
            >
              {isLoading ? (
                <ActivityIndicator color={theme.buttonText} />
              ) : (
                <Text style={[styles.buttonText, { color: theme.buttonText }]}>Prijava</Text>
              )}
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.registerButton}
              onPress={() => navigation.navigate('Register' as never, { isDarkMode } as never)}
            >
              <Text style={[styles.registerText, { color: theme.text }]}>
                Nemate račun? Registrirajte se
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.terminalButton}
              onPress={handleTerminalMode}
            >
              <Text style={[styles.terminalText, { color: theme.secondaryText }]}>
                Aktivacija terminala
              </Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  darkContainer: {
    backgroundColor: '#000000',
  },
  lightContainer: {
    backgroundColor: '#ffffff',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 50,
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  logoPart: {
    fontSize: 32,
    fontWeight: '600',
    letterSpacing: 1.2,
  },
  logoDot: {
    fontSize: 32,
    fontWeight: '800',
    marginHorizontal: 1,
  },
  logoHr: {
    fontSize: 32,
    fontWeight: '800',
    letterSpacing: 1.2,
  },
  title: {
    fontSize: 38,
    fontWeight: '700',
  },
  form: {
    width: '100%',
  },
  inputContainer: {
    marginBottom: 20,
    position: 'relative',
  },
  input: {
    height: 55,
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 45,
    fontSize: 16,
  },
  inputIcon: {
    position: 'absolute',
    left: 15,
    top: 17,
  },
  eyeIcon: {
    position: 'absolute',
    right: 15,
    top: 17,
  },
  button: {
    height: 55,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '600',
  },
  registerButton: {
    marginTop: 20,
    alignItems: 'center',
  },
  registerText: {
    fontSize: 16,
    fontWeight: '500',
  },
  terminalButton: {
    marginTop: 15,
    alignItems: 'center',
  },
  terminalText: {
    fontSize: 14,
    fontWeight: '500',
  },
  iconButton: {
    position: 'absolute',
    padding: 10,
    borderRadius: 20,
    zIndex: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
  },
  leftButton: {
    top: 50,
    left: 20,
  },
  rightButton: {
    top: 50,
    right: 20,
  },
});
