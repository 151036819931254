import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const EmployeeSetup = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Kreiranje djelatnika</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Dodavanje novog djelatnika</Text>
            <Text style={styles.description}>
              Za dodavanje novog djelatnika u sustav, pratite ove korake:
            </Text>
            <Text style={styles.description}>
              1. U glavnom izborniku odaberite "Djelatnici"
            </Text>
            <Text style={styles.description}>
              2. Kliknite na gumb "Dodaj djelatnika"
            </Text>
            <Text style={styles.description}>
              3. Ispunite osnovne podatke:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Ime i prezime</Text>
              <Text style={styles.bulletItem}>• Email adresa (opcionalno)</Text>
              <Text style={styles.bulletItem}>• Broj mobitela (opcionalno)</Text>
              <Text style={styles.bulletItem}>• OIB</Text>
              <Text style={styles.bulletItem}>• Lokacija rada</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Postavke radnog vremena</Text>
            <Text style={styles.description}>
              Za svakog djelatnika možete definirati:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Radno vrijeme (npr. 8-16h, 7-15h)</Text>
              <Text style={styles.bulletItem}>• Radne dane u tjednu</Text>
              <Text style={styles.bulletItem}>• Dozvoljene pauze</Text>
              <Text style={styles.bulletItem}>• Prekovremene sate</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>PIN za prijavu</Text>
            <Text style={styles.description}>
              Svaki djelatnik dobiva jedinstveni PIN za prijavu na terminal:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• PIN se automatski generira</Text>
              <Text style={styles.bulletItem}>• Može se poslati djelatniku na email</Text>
              <Text style={styles.bulletItem}>• Djelatnik može promijeniti svoj PIN</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Važne napomene</Text>
            <View style={styles.noteBox}>
              <Text style={styles.noteText}>
                • Djelatnik može raditi na više lokacija
              </Text>
              <Text style={styles.noteText}>
                • Svaki djelatnik mora imati jedinstveni OIB u sustavu
              </Text>
              <Text style={styles.noteText}>
                • Možete privremeno deaktivirati djelatnika bez brisanja
              </Text>
              <Text style={styles.noteText}>
                • Svi podaci o prisutnosti se čuvaju i nakon deaktivacije
              </Text>
            </View>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: '#444',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  bulletList: {
    marginLeft: 20,
    marginTop: 8,
  },
  bulletItem: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  noteBox: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderLeftColor: '#007bff',
  },
  noteText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
  },
});
