import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, Animated, Dimensions, TouchableOpacity, Platform } from 'react-native';
import { Audio } from 'expo-av';
import { MaterialIcons } from '@expo/vector-icons';

interface CustomToastProps {
  visible: boolean;
  message: string;
  title?: string;
  type?: 'success' | 'error' | 'info' | 'checkout' | 'checkin';
  duration?: number;
  onHide?: () => void;
  onHideImmediately?: () => void;
  toastId?: number;
}

export const CustomToast: React.FC<CustomToastProps> = ({
  visible,
  message,
  title,
  type = 'success',
  duration = 3000,
  onHide,
  onHideImmediately,
  toastId,
}) => {
  const [sound, setSound] = useState<Audio.Sound | null>(null);
  const translateY = useRef(new Animated.Value(-100)).current;
  const opacity = useRef(new Animated.Value(0)).current;

  const playSound = async () => {
    try {
      // Za info tip nećemo reproducirati zvuk
      if (type === 'info') {
        return;
      }
      
      let soundFile;
      if (type === 'success' || type === 'checkin') {
        soundFile = require('../../assets/sounds/success.mp3');
      } else if (type === 'checkout') {
        soundFile = require('../../assets/sounds/checkout.mp3');
      } else {
        soundFile = require('../../assets/sounds/error.mp3');
      }
      
      const { sound } = await Audio.Sound.createAsync(soundFile);
      setSound(sound);
      await sound.playAsync();
    } catch (error) {
      console.error('Greška pri reprodukciji zvuka:', error);
    }
  };

  useEffect(() => {
    if (visible) {
      // Prikaži toast
      Animated.parallel([
        Animated.timing(translateY, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();

      // Reproduciraj zvuk
      playSound();

      // Sakrij toast nakon određenog vremena
      const timer = setTimeout(() => {
        hideToast();
      }, duration);

      return () => {
        clearTimeout(timer);
        // Zaustavi zvuk ako se komponenta unmountira ili se prikaže novi toast
        if (sound) {
          sound.stopAsync().catch(() => {});
          sound.unloadAsync().catch(() => {});
        }
      };
    }
  }, [visible, toastId]);

  // Funkcija za skrivanje toasta s animacijom
  const hideToast = () => {
    Animated.parallel([
      Animated.timing(translateY, {
        toValue: -100,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(opacity, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start(() => {
      if (onHide) {
        onHide();
      }
    });
  };

  // Funkcija za trenutno skrivanje toasta bez animacije
  const hideToastImmediately = () => {
    translateY.setValue(-100);
    opacity.setValue(0);
    if (onHideImmediately) {
      onHideImmediately();
    } else if (onHide) {
      onHide();
    }
  };

  if (!visible) return null;

  const getIconName = () => {
    switch (type) {
      case 'success':
        return 'check-circle';
      case 'error':
        return 'error';
      case 'info':
        return 'info';
      case 'checkout':
        return 'logout';
      case 'checkin':
        return 'login';
      default:
        return 'check-circle';
    }
  };

  const getIconColor = () => {
    switch (type) {
      case 'success':
        return '#4CAF50';
      case 'error':
        return '#F44336';
      case 'info':
        return '#2196F3';
      case 'checkout':
        return '#F44336';
      case 'checkin':
        return '#4CAF50';
      default:
        return '#4CAF50';
    }
  };

  // Funkcija za dobivanje lokaliziranog naslova
  const getLocalizedTitle = () => {
    if (title) return title;
    
    switch (type) {
      case 'success':
        return 'Uspješno';
      case 'error':
        return 'Greška';
      case 'info':
        return 'Informacija';
      case 'checkout':
        return 'Odjava';
      case 'checkin':
        return 'Prijava';
      default:
        return 'Uspješno';
    }
  };

  const { width } = Dimensions.get('window');
  const isWeb = Platform.OS === 'web';

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      alignItems: 'center',
      paddingTop: isWeb ? 20 : 40,
    },
    content: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      padding: 16,
      width: isWeb ? 400 : width - 32,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    icon: {
      marginRight: 12,
    },
    textContainer: {
      flex: 1,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 16,
      color: '#212121',
      marginBottom: 4,
    },
    message: {
      fontSize: 14,
      color: '#757575',
    },
    closeButton: {
      padding: 4,
    },
  });

  return (
    <Animated.View
      key={toastId}
      style={[
        styles.container,
        {
          transform: [{ translateY }],
          opacity,
        },
      ]}
    >
      <View style={styles.content}>
        <MaterialIcons name={getIconName()} size={24} color={getIconColor()} style={styles.icon} />
        <View style={styles.textContainer}>
          <Text style={styles.title}>{getLocalizedTitle()}</Text>
          <Text style={styles.message}>{message}</Text>
        </View>
        <TouchableOpacity onPress={hideToastImmediately} style={styles.closeButton}>
          <MaterialIcons name="close" size={20} color="#757575" />
        </TouchableOpacity>
      </View>
    </Animated.View>
  );
};
