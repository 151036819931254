import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Alert } from 'react-native';
import { Text, Portal, Button, Dialog } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { API_URL } from '../../../config';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { terminalEndpoints } from '../api/terminalApi';
// Uklonjen import Notifications
// import * as Notifications from 'expo-notifications';
import { SafeAreaView } from 'react-native-safe-area-context';
import { BreakCard } from './components/BreakCard';
import { ConfirmDialog, ErrorDialog } from './components/BreakDialogs';
import { useToast } from '../../../context/ToastContext';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

export interface Break {
  id: string;
  name: string;
  duration: number;
  isPaid: boolean;
  isUnlimited: boolean;
  minWorkMinutes?: number;
  isTimeRestrictionEnabled: boolean;
  restrictedHours?: number[];
  allowReuseAfterExpiry: boolean;
  remainingTime?: number;
  usedCount?: number;
}

interface Props {
  navigation: any;
}

export const BreakListScreen = ({ navigation }: Props) => {
  const [breaks, setBreaks] = useState<Break[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBreak, setSelectedBreak] = useState<Break | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [sessionId, setSessionId] = useState<string>('');
  const [employeeName, setEmployeeName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [showLogoutConfirmDialog, setShowLogoutConfirmDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const { showToast, hideToastImmediately } = useToast();
  const navigationProp = useNavigation();

  useEffect(() => {
    // Maknut hook koji postavlja setShowErrorDialog(true) kad stigne error
    // Ne želimo prikazati ErrorDialog za greške kod pauza
    /*
    if (error) {
      setShowErrorDialog(true);
    }
    */
  }, [error]);

  useFocusEffect(
    React.useCallback(() => {
      const fetchData = async () => {
        try {
          const [locationId, employeeId] = await Promise.all([
            AsyncStorage.getItem('locationId'),
            AsyncStorage.getItem('currentEmployeeId')
          ]);
          
          if (!locationId || !employeeId) {
            setError('Nedostaju podaci o lokaciji ili zaposleniku');
            return;
          }

          const [breaksResponse, sessionResponse] = await Promise.all([
            axios.get(`${API_URL}/breaks/available/${locationId}?employeeId=${employeeId}`),
            terminalEndpoints.getActiveSession(employeeId, locationId)
          ]);

          setBreaks(breaksResponse.data);
          if (sessionResponse?._id) {
            setSessionId(sessionResponse._id);
          }
          if (sessionResponse?.employee) {
            setEmployeeName(`${sessionResponse.employee.name} ${sessionResponse.employee.surname}`);
          }
          if (sessionResponse?.location) {
            setLocationName(sessionResponse.location.name);
          }
        } catch (error) {
          setError('Greška pri dohvaćanju podataka');
        } finally {
          setLoading(false);
        }
      };

      const refreshBreaksList = async () => {
        try {
          const [locationId, employeeId] = await Promise.all([
            AsyncStorage.getItem('locationId'),
            AsyncStorage.getItem('currentEmployeeId')
          ]);
          
          if (!locationId || !employeeId) {
            setError('Nedostaju podaci o lokaciji ili zaposleniku');
            return;
          }

          const breaksResponse = await axios.get(`${API_URL}/breaks/available/${locationId}?employeeId=${employeeId}`);
          setBreaks(breaksResponse.data);
        } catch (error) {
          
        } finally {
          setLoading(false);
        }
      };

      fetchData();
      refreshBreaksList();

      return () => {};
    }, [])
  );

  const handleStartBreak = async (breakItem: Break) => {
    setSelectedBreak(breakItem);
    
    // Provjera je li pauza iskorištena ali se može ponovno koristiti (bilježit će se prekoračenje)
    const isBreakUsedButReusable = 
      breakItem.allowReuseAfterExpiry &&
      breakItem.remainingTime !== undefined && 
      breakItem.remainingTime === 0;
    
    if (isBreakUsedButReusable) {
      // Za pauze koje su iskorištene ali dozvoljavaju ponovno korištenje
      setDialogTitle('Upozorenje o prekoračenju');
      setDialogMessage(`Pauza "${breakItem.name}" je već iskorištena u cijelosti, ali je možete ponovno započeti. Bilježit će se prekoračenje pauze. Želite li nastaviti?`);
    } else {
      // Standardni dijalog za potvrdu
      setDialogTitle('Potvrda pauze');
      setDialogMessage(`Želite li započeti pauzu "${breakItem.name}"?`);
    }
    
    setShowConfirmDialog(true);
  };

  const handleConfirmBreak = async () => {
    if (!selectedBreak) return;

    try {
      const deviceId = await AsyncStorage.getItem('@device_id');
      
      if (!deviceId) {
        setError('Nedostaje terminal ID');
        return;
      }

      // Koristimo _id ako postoji, inače id
      const breakId = (selectedBreak as any)._id || selectedBreak.id;
      
      await terminalEndpoints.startBreak(sessionId, breakId);
      
      setShowConfirmDialog(false);

      // Sakrij sve aktivne toast poruke
      hideToastImmediately();
      
      // Prikaži vlastiti toast za započinjanje pauze
      showToast(
        `Pauza započeta!`, 
        `${selectedBreak.name} je uspješno započeta.`, 
        'success', 
        5000
      );
      
      navigationProp.navigate('Terminal');

    } catch (error) {
      
      if (error.response?.data?.message) {
        setError(error.response.data.message);
      } else if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        setError(`Greška: ${error.message}`);
      }
      
      // Sakrij sve aktivne toast poruke
      hideToastImmediately();
      
      // Prikaži toast za grešku - za error vezan za restrikcije ne prikazujemo dijalog, samo toast
      showToast(
        'Greška kod pauze', 
        error.response?.data?.message || error.response?.data?.error || error.message, 
        'error', 
        5000
      );
      
      setShowConfirmDialog(false);
      
      // Ne prikazujemo grešku u dijalogu - korisnik želi samo toast
      // setShowErrorDialog(true);
    }
  };

  const handleEndShift = async () => {
    setShowLogoutConfirmDialog(true);
  };

  const confirmEndShift = async () => {
    try {
      setLoading(true);
      
      const deviceId = await AsyncStorage.getItem('@device_id');
      if (!deviceId) {
        setError('Nedostaje terminal ID');
        return;
      }
      
      if (!sessionId) {
        setError('Nedostaju podaci o sesiji');
        return;
      }

      await terminalEndpoints.endSession(sessionId);
      
      // Prvo navigiraj na Terminal ekran
      hideToastImmediately(); // Sakrij sve aktivne toast poruke
      navigationProp.navigate('Terminal');
      
      // Zatim prikaži toast s malim kašnjenjem za bolji vizualni prijelaz
      setTimeout(() => {
        showToast(`Uspješno ste se odjavili iz smjene u poslovnici ${locationName}`, `${employeeName} - uspješna odjava`, 'checkout', 5000);
      }, 500); // 500ms kašnjenja
    } catch (error) {
      // Prikaži crveni toast za grešku pri završavanju smjene
      showToast('Greška', 'Greška pri završavanju smjene', 'error', 3000);
      setError('Greška pri završavanju smjene');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <SafeAreaView edges={['top', 'bottom']} style={styles.safeArea}>
        <View style={[styles.container, styles.centered]}>
          <Text>Učitavanje...</Text>
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.safeArea}>
      <View style={styles.container}>
        <FlatList
          data={breaks}
          renderItem={({ item }) => (
            <BreakCard
              breakItem={item}
              onStartBreak={handleStartBreak}
            />
          )}
          keyExtractor={(item) => (item as any)._id?.toString() || item.id?.toString() || Math.random().toString()}
          contentContainerStyle={styles.listContent}
          ListHeaderComponent={
            <View style={styles.headerContainer}>
              <Text variant="titleMedium" style={styles.headerText}>
                {employeeName}, odaberite radnju
              </Text>
            </View>
          }
          ListEmptyComponent={
            <View style={styles.emptyContainer}>
              <Text>Nema dostupnih pauza</Text>
            </View>
          }
        />

        <Portal>
          <Dialog 
            visible={showLogoutConfirmDialog} 
            onDismiss={() => setShowLogoutConfirmDialog(false)}
            style={styles.dialog}
          >
            <Dialog.Title style={styles.dialogTitle}>Potvrda odjave</Dialog.Title>
            <Dialog.Content>
              <Text variant="bodyLarge" style={styles.dialogText}>
                Jeste li sigurni da se želite odjaviti iz smjene?
              </Text>
            </Dialog.Content>
            <Dialog.Actions style={styles.dialogActions}>
              <View style={styles.dialogButtonsContainer}>
                <Button 
                  onPress={() => {
                    setShowLogoutConfirmDialog(false);
                    confirmEndShift();
                  }}
                  mode="contained"
                  buttonColor="#FF4444"
                  textColor="white"
                  style={styles.dialogButton}
                  contentStyle={styles.dialogButtonContent}
                  labelStyle={styles.dialogButtonLabel}
                >
                  Da, odjavi
                </Button>
                <Button 
                  onPress={() => setShowLogoutConfirmDialog(false)}
                  mode="text"
                  style={styles.dialogButton}
                  contentStyle={styles.dialogButtonContent}
                  labelStyle={styles.dialogButtonLabel}
                >
                  Ne, odustani
                </Button>
              </View>
            </Dialog.Actions>
          </Dialog>

          <ConfirmDialog
            visible={showConfirmDialog}
            onDismiss={() => setShowConfirmDialog(false)}
            onConfirm={handleConfirmBreak}
            selectedBreak={selectedBreak}
            title={dialogTitle}
            message={dialogMessage}
          />

          <ErrorDialog
            visible={!!error && showErrorDialog}
            onDismiss={() => {
              setError(null);
              setShowErrorDialog(false);
            }}
            error={error}
          />
        </Portal>

        <View style={styles.buttonContainer}>
          <Button 
            icon={({ size, color }) => (
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="logout"
                  size={24}
                  color={color}
                  style={styles.icon}
                />
              </View>
            )}
            onPress={() => handleEndShift()}
            style={styles.button}
            mode="contained"
            buttonColor="#FF4444"
            textColor="white"
            contentStyle={styles.buttonContent}
            labelStyle={styles.buttonLabel}
          >
            Završi smjenu
          </Button>
          <Button 
            icon={({ size, color }) => (
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="keyboard-return"
                  size={24}
                  color={color}
                  style={styles.icon}
                />
              </View>
            )}
            onPress={() => navigationProp.navigate('Terminal')}
            style={[styles.button]}
            mode="text"
            contentStyle={styles.buttonContent}
            labelStyle={styles.buttonLabel}
          >
            Odustani
          </Button>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  listContent: {
    paddingVertical: 12,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
  },
  buttonContainer: {
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 2,
    gap: 12,
  },
  button: {
    borderRadius: 12,
  },
  buttonContent: {
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '600',
  },
  dialog: {
    borderRadius: 16,
    backgroundColor: '#fff',
    marginHorizontal: 24,
  },
  dialogTitle: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: 16,
  },
  dialogText: {
    fontSize: 18,
    textAlign: 'center',
    marginVertical: 8,
  },
  dialogActions: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  dialogButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
    width: '100%',
  },
  dialogButton: {
    flex: 1,
    borderRadius: 12,
  },
  dialogButtonContent: {
    height: 48,
    justifyContent: 'center',
  },
  dialogButtonLabel: {
    fontSize: 16,
    fontWeight: '600',
  },
  headerContainer: {
    padding: 16,
    paddingBottom: 8,
    marginBottom: 4,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },
  iconContainer: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
  icon: {
    WebkitFontSmoothing: 'antialiased',
  },
});
