import React, { useEffect, useState, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { View, ActivityIndicator, Text, Button, StyleSheet } from 'react-native';
import { PaperProvider, DefaultTheme } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import eventEmitter from './src/utils/eventEmitter';

// Contexts
import { AuthProvider, useAuthContext } from './src/context/AuthContext';
import { TerminalProvider } from './src/modules/terminal/context/TerminalContext';
import { ToastProvider } from './src/context/ToastContext';
import { ChangelogProvider } from './src/context/ChangelogContext';

// Web Screens
import { WebLoginScreen } from './src/web/screens/WebLoginScreen';
import { WebRegisterScreen } from './src/web/screens/WebRegisterScreen';
import { WebDashboardScreen } from './src/web/screens/dashboard/WebDashboardScreen';
import { WebTerminalActivationScreen } from './src/web/screens/terminal/WebTerminalActivationScreen';
import { WebEmployeesScreen } from './src/web/screens/employees/WebEmployeesScreen';
import { WebEmployeeDetailsScreen } from './src/web/screens/employees/components/WebEmployeeDetailsScreen';
import { WebLocationsScreen } from './src/web/screens/locations/WebLocationsScreen';
import { WebBreaksScreen } from './src/web/screens/breaks/WebBreaksScreen';
import { WebBreakDetailsScreen } from './src/web/screens/breaks/components/WebBreakDetailsScreen';
import { WebSettingsScreen } from './src/web/screens/settings/WebSettingsScreen';
import { WebLogsScreen } from './src/web/screens/settings/WebLogsScreen';
import { WebLocationLogsScreen } from './src/web/screens/settings/location-logs/WebLocationLogsScreen';
import { WebLocationDetailsScreen } from './src/web/screens/locations/components/WebLocationDetailsScreen';
import { WebCreateLocationScreen } from './src/web/screens/locations/components/WebCreateLocationScreen';
import { WebLanding } from './src/web/screens/WebLanding';
import { WebHelp } from './src/web/help/screens/WebHelp';
import { AboutUs } from './src/web/help/screens/AboutUs';
import { Guide } from './src/web/help/screens/Guide';
import { Registration } from './src/web/help/screens/guide/Registration';
import { LocationSetup } from './src/web/help/screens/guide/LocationSetup';
import { TerminalSetup } from './src/web/help/screens/guide/TerminalSetup';
import { EmployeeSetup } from './src/web/help/screens/guide/EmployeeSetup';
import { BreakSetup } from './src/web/help/screens/guide/BreakSetup';
import { SystemTesting } from './src/web/help/screens/guide/SystemTesting';
import { AccessManagement } from './src/web/help/screens/guide/AccessManagement';
import { GettingStartedTips } from './src/web/help/screens/guide/GettingStartedTips';
import { FAQ } from './src/web/help/screens/FAQ';
import { Contact } from './src/web/help/screens/Contact';
import { Privacy } from './src/web/help/screens/legal/Privacy';
import { Terms } from './src/web/help/screens/legal/Terms';
import { Compliance } from './src/web/help/screens/legal/Compliance';
import { Security } from './src/web/help/screens/legal/Security';
import { LegalDocuments } from './src/web/help/screens/LegalDocuments';

// Main Screens
import { LocationsScreen } from './src/screens/locations/LocationsScreen';
import { EmployeesScreen } from './src/screens/employees/EmployeesScreen';
import { BreaksScreen } from './src/screens/breaks/BreaksScreen';

// Settings Screens
import { AccountSettingsScreen } from './src/screens/settings/AccountSettingsScreen';
import { CompanySettingsScreen } from './src/screens/settings/CompanySettingsScreen';
import { LogsScreen } from './src/screens/settings/LogsScreen';
import { LocationLogsScreen } from './src/screens/settings/LocationLogsScreen';
import { AboutScreen } from './src/screens/settings/AboutScreen';
import { FAQScreen } from './src/screens/settings/FAQScreen';
import { LegalScreen } from './src/screens/settings/LegalScreen';

// Location Screens
import { CreateLocationScreen } from './src/screens/locations/CreateLocationScreen';
import { EditLocationScreen } from './src/screens/locations/EditLocationScreen';

// Employee Screens
import { CreateEmployeeScreen } from './src/screens/employees/components/CreateEmployeeScreen';
import { EmployeeDetailsScreen } from './src/screens/employees/components/EmployeeDetailsScreen';
import { EditEmployeeScreen } from './src/screens/employees/components/EditEmployeeScreen';

// Break Screens
import { CreateBreakScreen } from './src/screens/breaks/CreateBreakScreen';
import { BreakDetailsScreen } from './src/screens/breaks/components/BreakDetailsScreen';

// Terminal Screens
import { TerminalActivationScreen } from './src/modules/terminal/screens/TerminalActivationScreen';
import { TerminalScreen } from './src/modules/terminal/screens/TerminalScreen';
import { BreakListScreen } from './src/modules/terminal/screens/BreakListScreen';
import { ActiveBreakScreen } from './src/modules/terminal/screens/ActiveBreakScreen';

// Web Layout
import { WebLayout } from './src/web/layout/WebLayout';

const Stack = createNativeStackNavigator();

// Theme configuration
const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#2196F3', // Material Design Blue
    primaryContainer: '#E3F2FD', // Light Blue background
    secondary: '#1976D2', // Darker Blue
    secondaryContainer: '#BBDEFB', // Light Blue background
    surface: '#FFFFFF',
    background: '#F5F5F5',
    error: '#FF5252',
    onSurface: '#212121',
    onBackground: '#212121',
    onPrimary: '#FFFFFF',
    onSecondary: '#FFFFFF',
    outline: '#BDBDBD'
  },
};

const queryClient = new QueryClient();

const linking = {
  prefixes: ['http://localhost:19006', 'https://app.clock.hr'],
  config: {
    screens: {
      Landing: '',
      Help: 'help',
      AboutUs: 'about',
      Guide: 'guide',
      FAQ: 'faq',
      Contact: 'contact',
      LegalDocuments: 'legal',
      'legal/privacy': 'legal/privacy',
      'legal/terms': 'legal/terms',
      'legal/compliance': 'legal/compliance',
      'legal/security': 'legal/security',
      'guide/registration': 'guide/registration',
      'guide/location-setup': 'guide/location-setup',
      'guide/terminal-setup': 'guide/terminal-setup',
      'guide/employee-setup': 'guide/employee-setup',
      'guide/break-setup': 'guide/break-setup',
      'guide/system-testing': 'guide/system-testing',
      'guide/access-management': 'guide/access-management',
      'guide/getting-started-tips': 'guide/getting-started-tips',
      Login: 'login',
      Register: 'register',
      Main: 'app/*',
      Terminal: 'terminal',
      Main: {
        screens: {
          Dashboard: 'dashboard',
          Locations: 'locations',
          Employees: 'employees',
          Breaks: 'breaks',
          Settings: 'settings',
          LocationDetails: {
            path: 'locations/:locationId',
            parse: {
              locationId: (id: string) => id
            }
          },
          CreateLocation: 'locations/create',
          EditLocation: 'locations/:id/edit',
          EmployeeDetails: {
            path: 'employees/details/:employeeId',
            parse: {
              employeeId: (id: string) => id
            }
          },
          CreateEmployee: 'employees/create',
          EditEmployee: 'employees/:id/edit',
          BreakDetails: 'breaks/:id',
          CreateBreak: 'breaks/create',
          AccountSettings: 'settings/account',
          CompanySettings: 'settings/company',
          Logs: 'settings/logs',
          LocationLogs: {
            path: 'settings/location-logs/:locationId/:locationName',
            parse: {
              locationId: (id: string) => id,
              locationName: (name: string) => decodeURIComponent(name)
            }
          },
          About: 'settings/about',
          FAQ: 'settings/faq',
          Legal: 'settings/legal'
        }
      },
    },
  },
};

// Web Main content with sidebar
const WebMain = () => {
  return (
    <ChangelogProvider>
      <WebLayout>
        <Stack.Navigator 
          screenOptions={{ 
            headerShown: false,
            contentStyle: { backgroundColor: '#F7FAFC' }
          }}
        >
          <Stack.Screen name="Dashboard" component={WebDashboardScreen} />
          <Stack.Screen name="Locations" component={WebLocationsScreen} />
          <Stack.Screen name="Employees" component={WebEmployeesScreen} />
          <Stack.Screen name="Breaks" component={WebBreaksScreen} />
          <Stack.Screen name="Settings" component={WebSettingsScreen} />
          
          {/* Location routes */}
          <Stack.Screen name="LocationDetails" component={WebLocationDetailsScreen} />
          <Stack.Screen name="CreateLocation" component={WebCreateLocationScreen} />
          <Stack.Screen name="EditLocation" component={EditLocationScreen} />
          
          {/* Employee routes */}
          <Stack.Screen name="EmployeeDetails" component={WebEmployeeDetailsScreen} />
          <Stack.Screen name="CreateEmployee" component={CreateEmployeeScreen} />
          <Stack.Screen name="EditEmployee" component={EditEmployeeScreen} />
          
          {/* Break routes */}
          <Stack.Screen name="BreakDetails" component={WebBreakDetailsScreen} />
          <Stack.Screen name="CreateBreak" component={CreateBreakScreen} />
          
          {/* Settings routes */}
          <Stack.Screen name="AccountSettings" component={AccountSettingsScreen} />
          <Stack.Screen name="CompanySettings" component={CompanySettingsScreen} />
          <Stack.Screen name="Logs" component={WebLogsScreen} />
          <Stack.Screen name="LocationLogs" component={WebLocationLogsScreen} />
          <Stack.Screen name="About" component={AboutScreen} />
          <Stack.Screen name="FAQ" component={FAQScreen} />
          <Stack.Screen name="Legal" component={LegalScreen} />
        </Stack.Navigator>
      </WebLayout>
    </ChangelogProvider>
  );
};

const WebAppContent = () => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const [initialRouteName, setInitialRouteName] = useState('Landing');
  const [isCheckingTerminal, setIsCheckingTerminal] = useState(true);
  const [error, setError] = useState(null);
  const routeChecked = useRef(false);

  // Provjera inicijalnog routa
  useEffect(() => {
    const checkInitialRoute = async () => {
      if (routeChecked.current) return;
      
      try {        
        const terminalActive = await AsyncStorage.getItem('terminalActive');
        
        if (terminalActive === 'true') {
          setInitialRouteName('Terminal');
          setIsCheckingTerminal(false);
          routeChecked.current = true;
          return;
        }

        // Provjeri je li korisnik upravo odjavljen
        const isLoggedOut = await AsyncStorage.getItem('isLoggedOut');
        
        if (isAuthenticated) {
          setInitialRouteName('Main');
          // Reset logged out flag ako se korisnik ponovno prijavi
          if (isLoggedOut === 'true') {
            await AsyncStorage.setItem('isLoggedOut', 'false');
          }
        } else {
          // Ako je korisnik upravo odjavljen, idi direktno na Login
          if (isLoggedOut === 'true') {
            setInitialRouteName('Login');
          } else {
            setInitialRouteName('Landing');
          }
        }
      } catch (error) {
        setError('Greška pri učitavanju aplikacije');
        setInitialRouteName('Landing');
      } finally {
        setIsCheckingTerminal(false);
        routeChecked.current = true;
      }
    };

    if (!isLoading) {
      checkInitialRoute();
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    const unsubscribe = eventEmitter.on('SESSION_EXPIRED', async () => {
      // Očisti stanje sesije
      await AsyncStorage.setItem('isLoggedOut', 'true');
      setInitialRouteName('Login');
    });
    return () => unsubscribe();
  }, []);

  if (error) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{error}</Text>
        <Button 
          title="Pokušaj ponovno" 
          onPress={() => {
            setError(null);
            routeChecked.current = false;
            setIsCheckingTerminal(true);
          }} 
        />
      </View>
    );
  }

  if (isLoading || isCheckingTerminal) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text style={styles.loadingText}>
          {isLoading ? 'Učitavanje...' : 'Provjera stanja...'}
        </Text>
      </View>
    );
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName={initialRouteName}>
        {isAuthenticated ? (
          // Ako je korisnik prijavljen, prikaži samo Main
          <Stack.Screen
            name="Main"
            component={WebMain}
            options={{ headerShown: false }}
          />
        ) : (
          // Ako korisnik nije prijavljen, prikaži landing, login i register
          <>
            <Stack.Screen name="Landing" component={WebLanding} options={{ headerShown: false }} />
            <Stack.Screen
              name="Login"
              component={WebLoginScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Register"
              component={WebRegisterScreen}
              options={{ headerShown: false }}
            />
          </>
        )}

        {/* Terminal rute */}
        <Stack.Screen
          name="Terminal"
          options={{
            headerShown: false,
            gestureEnabled: false
          }}
          component={TerminalScreen}
        />
        <Stack.Screen
          name="TerminalActivation"
          options={{ headerShown: false }}
          component={WebTerminalActivationScreen}
        />
        <Stack.Screen
          name="BreakList"
          options={{ headerShown: false }}
          component={BreakListScreen}
        />
        <Stack.Screen
          name="ActiveBreak"
          options={{ headerShown: false }}
          component={ActiveBreakScreen}
        />

        {/* Pomoćne rute */}
        <Stack.Screen name="Help" component={WebHelp} options={{ headerShown: false }} />
        <Stack.Screen name="AboutUs" component={AboutUs} options={{ headerShown: false }} />
        <Stack.Screen name="Guide" component={Guide} options={{ headerShown: false }} />
        <Stack.Screen 
          name="guide/registration" 
          component={Registration} 
          options={{ 
            headerShown: false,
            path: 'guide/registration'
          }} 
        />
        <Stack.Screen 
          name="guide/location-setup" 
          component={LocationSetup} 
          options={{ 
            headerShown: false,
            path: 'guide/location-setup'
          }} 
        />
        <Stack.Screen 
          name="guide/terminal-setup" 
          component={TerminalSetup} 
          options={{ 
            headerShown: false,
            path: 'guide/terminal-setup'
          }} 
        />
        <Stack.Screen 
          name="guide/employee-setup" 
          component={EmployeeSetup} 
          options={{ 
            headerShown: false,
            path: 'guide/employee-setup'
          }} 
        />
        <Stack.Screen 
          name="guide/break-setup" 
          component={BreakSetup} 
          options={{ 
            headerShown: false,
            path: 'guide/break-setup'
          }} 
        />
        <Stack.Screen 
          name="guide/system-testing" 
          component={SystemTesting} 
          options={{ 
            headerShown: false,
            path: 'guide/system-testing'
          }} 
        />
        <Stack.Screen 
          name="guide/access-management" 
          component={AccessManagement} 
          options={{ 
            headerShown: false,
            path: 'guide/access-management'
          }} 
        />
        <Stack.Screen 
          name="guide/getting-started-tips" 
          component={GettingStartedTips} 
          options={{ 
            headerShown: false,
            path: 'guide/getting-started-tips'
          }} 
        />
        <Stack.Screen 
          name="FAQ" 
          component={FAQ} 
          options={{ 
            headerShown: false,
            path: 'faq'
          }} 
        />
        <Stack.Screen 
          name="Contact" 
          component={Contact} 
          options={{ 
            headerShown: false,
            path: 'contact'
          }} 
        />
        <Stack.Screen 
          name="LegalDocuments" 
          component={LegalDocuments} 
          options={{ 
            headerShown: false,
            path: 'legal'
          }} 
        />
        <Stack.Screen 
          name="legal/privacy" 
          component={Privacy} 
          options={{ 
            headerShown: false,
            path: 'legal/privacy'
          }} 
        />
        <Stack.Screen 
          name="legal/terms" 
          component={Terms} 
          options={{ 
            headerShown: false,
            path: 'legal/terms'
          }} 
        />
        <Stack.Screen 
          name="legal/compliance" 
          component={Compliance} 
          options={{ 
            headerShown: false,
            path: 'legal/compliance'
          }} 
        />
        <Stack.Screen 
          name="legal/security" 
          component={Security} 
          options={{ 
            headerShown: false,
            path: 'legal/security'
          }} 
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 20,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  loadingText: {
    marginTop: 10,
    color: '#666',
  },
});

const WebApp = () => {
  return (
    <SafeAreaProvider>
      <PaperProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ToastProvider>
              <TerminalProvider>
                <WebAppContent />
              </TerminalProvider>
            </ToastProvider>
          </AuthProvider>
        </QueryClientProvider>
      </PaperProvider>
    </SafeAreaProvider>
  );
};

export default WebApp;
