import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, useTheme } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useDashboardStats } from '../../../../hooks/useDashboardStats';
import { formatCurrency, formatMinutes } from '../../../../utils/formatters';
import { LoadingScreen } from '../../../../components/LoadingScreen';
import { ErrorScreen } from '../../../../components/ErrorScreen';

export const WebTotalStatsCard = () => {
  const theme = useTheme();
  const { data: stats, isLoading, error } = useDashboardStats();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!stats) {
    return null;
  }

  // Dohvati trenutni mjesec
  const mjeseci = [
    'Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj',
    'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'
  ];
  const trenutniMjesec = mjeseci[new Date().getMonth()];

  // Zajedničke boje
  const neutralColor = '#37474F';
  const iconBgColor = 'rgba(55, 71, 79, 0.08)';
  const iconBorderColor = 'rgba(55, 71, 79, 0.12)';

  const StatCard = ({ 
    icon, 
    label, 
    value, 
    color = neutralColor,
    prefix = '',
    suffix = ''
  }: { 
    icon: string; 
    label: string; 
    value: string | number;
    color?: string;
    prefix?: string;
    suffix?: string;
  }) => (
    <Card style={styles.statCard}>
      <Card.Content style={styles.statCardContent}>
        <View style={[styles.iconContainer, { backgroundColor: iconBgColor, borderColor: iconBorderColor }]}>
          <MaterialCommunityIcons name={icon} size={24} color={color} />
        </View>
        <Text style={styles.statLabel}>{label}</Text>
        <Text style={[styles.statValue, { color }]}>
          {prefix}{value}{suffix}
        </Text>
      </Card.Content>
    </Card>
  );

  return (
    <View style={styles.container}>
      <View style={styles.monthTitleContainer}>
        <MaterialCommunityIcons 
          name="calendar-month" 
          size={24} 
          color={neutralColor} 
          style={styles.monthIcon}
        />
        <Text style={styles.monthTitle}>
          Statistika za: {trenutniMjesec}
        </Text>
      </View>

      <View style={styles.statsContainer}>
        <StatCard 
          icon="chart-line" 
          label="Performanse" 
          value={stats.performance}
          suffix="%"
          color={stats.performance >= 95 ? '#2E7D32' : stats.performance >= 85 ? '#F57C00' : '#D32F2F'}
        />
        <StatCard 
          icon="timer-sand" 
          label="Prekoračenja" 
          value={formatMinutes(stats.overBreakMinutes)}
        />
        <StatCard 
          icon="cash-plus" 
          label="Bruto" 
          value={formatCurrency(stats.totalEarnings)}
          color="#2E7D32"
        />
        <StatCard 
          icon="cash-minus" 
          label="Odbici" 
          value={formatCurrency(stats.totalDeductions)}
          color="#D32F2F"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  monthTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  monthIcon: {
    marginRight: 12,
  },
  monthTitle: {
    fontSize: 18,
    fontWeight: '500',
    color: '#37474F',
  },
  statsContainer: {
    display: 'flex' as any,
    flexDirection: 'row',
    flexWrap: 'wrap' as any,
    gap: 16,
  },
  statCard: {
    flex: 1,
    minWidth: 200,
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  statCardContent: {
    alignItems: 'center',
    padding: 24,
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    borderWidth: 1,
  },
  statLabel: {
    fontSize: 14,
    color: '#37474F',
    opacity: 0.7,
    marginBottom: 8,
  },
  statValue: {
    fontSize: 24,
    fontWeight: '600',
  },
});
