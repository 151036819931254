import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const LocationSetup = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Kreiranje lokacije</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Postavljanje lokacije</Text>
            <Text style={styles.description}>
              Lokacije su ključne za praćenje prisutnosti zaposlenika. Svaka lokacija predstavlja fizičko mjesto gdje vaši zaposlenici rade:
            </Text>
            <View style={styles.steps}>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>1.</Text>
                <Text style={styles.stepText}>
                  U glavnom izborniku odaberite "Postavke" → "Lokacije"
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>2.</Text>
                <Text style={styles.stepText}>
                  Kliknite na gumb "Nova lokacija"
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>3.</Text>
                <Text style={styles.stepText}>
                  Unesite naziv lokacije (npr. "Glavni ured", "Skladište Zagreb")
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>4.</Text>
                <Text style={styles.stepText}>
                  Unesite adresu lokacije ili označite točku na karti
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Geofencing postavke</Text>
            <Text style={styles.description}>
              Geofencing omogućuje automatsku provjeru je li zaposlenik stvarno na radnom mjestu:
            </Text>
            <View style={styles.steps}>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>1.</Text>
                <Text style={styles.stepText}>
                  Postavite radijus geofencinga (preporučeno: 50-100 metara)
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>2.</Text>
                <Text style={styles.stepText}>
                  Odaberite želite li strogo ili fleksibilno praćenje lokacije
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>3.</Text>
                <Text style={styles.stepText}>
                  Definirajte radno vrijeme za ovu lokaciju
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.codeBlock}>
            <Text style={styles.codeTitle}>Važne napomene:</Text>
            <Text style={styles.codeText}>
              • Možete kreirati neograničen broj lokacija{'\n'}
              • Svaka lokacija može imati različite postavke geofencinga{'\n'}
              • Preporučeni radijus ovisi o veličini vaše lokacije{'\n'}
              • Zaposlenici mogu biti dodijeljeni na više lokacija{'\n'}
              • Lokaciju možete naknadno uređivati ili deaktivirati
            </Text>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 24,
  },
  section: {
    marginBottom: 32,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 16,
  },
  steps: {
    marginTop: 16,
  },
  step: {
    flexDirection: 'row',
    marginBottom: 12,
    alignItems: 'flex-start',
  },
  stepNumber: {
    width: 24,
    fontSize: 16,
    fontWeight: '600',
    color: '#4C6FFF',
  },
  stepText: {
    flex: 1,
    fontSize: 16,
    color: '#475569',
    lineHeight: 24,
  },
  codeBlock: {
    backgroundColor: '#F8FAFC',
    padding: 16,
    borderRadius: 8,
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  codeTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  codeText: {
    fontFamily: 'monospace',
    fontSize: 14,
    lineHeight: 20,
    color: '#475569',
  },
});
