import React, { useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useChangelog } from '../../context/ChangelogContext';

const APP_VERSION = '1.0.5';

const CHANGELOG = [
  {
    version: '1.0.5',
    date: '11.03.2025.',
    changes: [
      'Dodan indikator "NOVO" za nepročitane promjene u glavnom izborniku',
      'Implementirano automatsko označavanje promjena kao pročitanih',
    ],
  },
  {
    version: '1.0.4',
    date: '09.03.2025.',
    changes: [
      'Redizajnirana početna stranica s modernim izgledom i prikazom aktivnosti',
      'Kreiran kompletan help centar s detaljnim uputama i dokumentacijom',
      'Dodana sekcija za često postavljena pitanja (FAQ)',
      'Dodani pravni dokumenti i uvjeti korištenja',
      'Poboljšana responzivnost na svim stranicama',
    ],
  },
  {
    version: '1.0.3',
    date: '08.03.2025.',
    changes: [
      'Dodana detaljna statistika za sve djelatnike u poslovnici, analiza poslovnice + pdf izvoz',
      'Dodan skeleton loader na dashboard za bolje korisničko iskustvo',
      'Poboljšan izgled aplikacije',
    ],
  },
  {
    version: '1.0.2',
    date: '08.03.2025.',
    changes: [
      'Implementiran detaljni tjedni pregled statistike za svakog djelatnika s preciznim prikazom sati.',
      'Dodana mogućnost izvoza detaljnog PDF izvještaja za svakog djelatnika s kompletnom statistikom rada.',
    ],
  },
  {
    version: '1.0.1',
    date: '04.03.2025.',
    changes: [
      'Poboljšan izgled admin dashboarda',
      'Popravljena responzivnost na svim ekranima',
      'Dodato praćenje statusa djelatnika na listi djelatnika',
      'Poboljšan prikaz statistika po poslovnicama',
      'Optimizirana brzina učitavanja aplikacije',
    ],
  },
  {
    version: '1.0.0',
    date: '23.02.2025.',
    changes: [
      'Dodana mogućnost upravljanja poslovnicama',
      'Dodana mogućnost upravljanja djelatnicima',
      'Dodana mogućnost upravljanja pauzama',
      'Dodano praćenje radnog vremena',
      'Dodana statistika po poslovnicama',
      'Dodani logovi aktivnosti',
    ],
  },
];

export const AboutScreen = ({ navigation }: any) => {
  const { markChangelogAsRead, hasUnreadChangelog, setIsViewingChangelog } = useChangelog();

  useEffect(() => {
    setIsViewingChangelog(true);
    
    return () => {
      markChangelogAsRead();
      setIsViewingChangelog(false);
    };
  }, []);

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Text style={styles.headerTitle}>O aplikaciji</Text>
        <View style={{ width: 32 }} />
      </View>

      <ScrollView style={styles.container}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Verzija</Text>
          <View style={styles.versionContainer}>
            <Text style={styles.version}>{APP_VERSION}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Novosti</Text>
          {CHANGELOG.map((release, index) => (
            <View key={release.version} style={styles.releaseContainer}>
              <View style={styles.releaseHeader}>
                <Text style={styles.releaseVersion}>Verzija {release.version}</Text>
                <Text style={styles.releaseDate}>{release.date}</Text>
                {hasUnreadChangelog && index === 0 && (
                  <View style={styles.newBadge}>
                    <Text style={styles.newBadgeText}>NOVO</Text>
                  </View>
                )}
              </View>
              {release.changes.map((change, changeIndex) => (
                <View key={changeIndex} style={styles.changeItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.changeText}>{change}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    backgroundColor: '#fff',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  headerTitle: {
    fontSize: 20,
  },
  container: {
    flex: 1,
  },
  section: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
  },
  sectionTitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 8,
  },
  versionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  version: {
    fontSize: 24,
    fontWeight: '600',
    color: '#333',
    marginRight: 8,
  },
  newBadge: {
    backgroundColor: '#4299E1',
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 12,
  },
  newBadgeText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: '600',
  },
  releaseContainer: {
    marginBottom: 20,
  },
  releaseHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    gap: 8,
  },
  releaseVersion: {
    fontSize: 16,
    fontWeight: '600',
    color: '#2D3748',
  },
  releaseDate: {
    fontSize: 14,
    color: '#718096',
  },
  changeItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 4,
    paddingRight: 16,
  },
  bulletPoint: {
    fontSize: 16,
    color: '#666',
    marginRight: 8,
    marginTop: -2,
  },
  changeText: {
    flex: 1,
    fontSize: 15,
    color: '#333',
    lineHeight: 20,
  },
});
