import { LocationDetails } from '../types/location';

export const hasAnyActiveDevices = (location: LocationDetails | null): boolean => {
  return location?.activatedDevices?.some(device => device.active) || false;
};

export const shouldShowActivationBar = (location: LocationDetails | null): boolean => {
  return !hasAnyActiveDevices(location);
};

export const shouldShowNewDeviceInfo = (
  location: LocationDetails | null,
  showActivationInfo: boolean,
  isCodeExpired: boolean
): boolean => {
  return (
    showActivationInfo && 
    hasAnyActiveDevices(location) && 
    !!location?.activationCode && 
    !location?.activationCodeUsed &&
    !isCodeExpired
  );
};

export const shouldShowAddDeviceButton = (location: LocationDetails | null): boolean => {
  return hasAnyActiveDevices(location);
};

export const shouldShowGenerateCodeButton = (
  location: LocationDetails | null,
  isCodeExpired: boolean
): boolean => {
  return !location?.activationCode || isCodeExpired;
};
