import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useAuthContext } from '../../../context/AuthContext';

export const HelpHeader = () => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const { isAuthenticated } = useAuthContext();
  const isMobile = width < 768;

  return (
    <View style={styles.header}>
      <View style={styles.headerContent}>
        <View style={styles.rightSection}>
          {isAuthenticated ? (
            <TouchableOpacity 
              style={styles.backButton}
              onPress={() => navigation.navigate('Main' as never)}
            >
              <Text style={styles.backButtonText}>Povratak na aplikaciju</Text>
            </TouchableOpacity>
          ) : (
            <>
              <TouchableOpacity 
                style={styles.loginButton}
                onPress={() => navigation.navigate('Login' as never)}
              >
                <Text style={styles.loginButtonText}>Prijava</Text>
              </TouchableOpacity>
              <TouchableOpacity 
                style={styles.registerButton}
                onPress={() => navigation.navigate('Register' as never)}
              >
                <Text style={styles.registerButtonText}>Registracija</Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    height: 64,
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    backgroundColor: '#ffffff',
  },
  headerContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 24,
    maxWidth: 2000,
    width: '100%',
    marginHorizontal: 'auto',
  },
  rightSection: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    position: 'absolute',
    right: 0,
    height: '100%',
    paddingRight: 24,
    backgroundColor: '#ffffff',
  },
  loginButton: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 6,
  },
  loginButtonText: {
    fontSize: 14,
    color: '#4C6FFF',
    fontWeight: '500',
  },
  registerButton: {
    backgroundColor: '#4C6FFF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 6,
  },
  registerButtonText: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: '500',
  },
  backButton: {
    backgroundColor: '#4C6FFF',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 6,
  },
  backButtonText: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: '500',
  },
});
