import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const SystemTesting = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Testiranje sustava</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Priprema za testiranje</Text>
            <Text style={styles.description}>
              Prije nego počnete koristiti sustav u produkciji, važno je provjeriti sve njegove funkcionalnosti:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Provjerite jesu li svi djelatnici uneseni u sustav</Text>
              <Text style={styles.bulletItem}>• Osigurajte da su sve lokacije ispravno postavljene</Text>
              <Text style={styles.bulletItem}>• Potvrdite da su terminali aktivirani i spremni za rad</Text>
              <Text style={styles.bulletItem}>• Pregledajte postavke radnog vremena i pauza</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Koraci za testiranje</Text>
            <Text style={styles.description}>
              1. Testiranje prijave i odjave:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Prijavite se kao administrator</Text>
              <Text style={styles.bulletItem}>• Testirajte prijavu djelatnika PIN-om na terminalu</Text>
              <Text style={styles.bulletItem}>• Provjerite odjavu djelatnika</Text>
            </View>
            
            <Text style={styles.description}>
              2. Testiranje pauza:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Započnite različite vrste pauza</Text>
              <Text style={styles.bulletItem}>• Provjerite ograničenja trajanja pauza</Text>
              <Text style={styles.bulletItem}>• Testirajte upozorenja za prekoračenje</Text>
            </View>

            <Text style={styles.description}>
              3. Provjera izvještaja:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Generirajte dnevne izvještaje</Text>
              <Text style={styles.bulletItem}>• Provjerite točnost radnih sati</Text>
              <Text style={styles.bulletItem}>• Pregledajte statistiku pauza</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Testni scenariji</Text>
            <Text style={styles.description}>
              Preporučujemo testiranje sljedećih scenarija:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Kašnjenje na posao</Text>
              <Text style={styles.bulletItem}>• Raniji odlazak s posla</Text>
              <Text style={styles.bulletItem}>• Prekovremeni rad</Text>
              <Text style={styles.bulletItem}>• Više pauza u jednom danu</Text>
              <Text style={styles.bulletItem}>• Rad na različitim lokacijama</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Važne napomene</Text>
            <View style={styles.noteBox}>
              <Text style={styles.noteText}>
                • Testirajte sustav nekoliko dana prije pune implementacije
              </Text>
              <Text style={styles.noteText}>
                • Obavezno educirajte djelatnike o korištenju sustava
              </Text>
              <Text style={styles.noteText}>
                • Provjerite jesu li svi PIN-ovi ispravno dostavljeni
              </Text>
              <Text style={styles.noteText}>
                • Osigurajte da su svi terminali postavljeni na pristupačnim mjestima
              </Text>
            </View>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: '#444',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  bulletList: {
    marginLeft: 20,
    marginTop: 8,
    marginBottom: 16,
  },
  bulletItem: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  noteBox: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderLeftColor: '#007bff',
  },
  noteText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
  },
});
