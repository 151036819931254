import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Text, useTheme, Appbar, List, Divider, ActivityIndicator, Button } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { employeesAPI } from '../../services/api';

type Location = {
  _id: string;
  name: string;
};

export const LogsScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await employeesAPI.getLocations();
      setLocations(data);
    } catch (err) {
      console.error('Greška pri dohvaćanju poslovnica:', err);
      setError('Došlo je do greške pri dohvaćanju poslovnica');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={styles.safeArea} edges={['left', 'right']}>
      <Appbar.Header style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Appbar.Content title="Logovi" titleStyle={styles.headerTitle} />
      </Appbar.Header>

      <View style={styles.container}>
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        ) : error ? (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{error}</Text>
            <TouchableOpacity 
              onPress={fetchLocations} 
              style={[styles.retryButton, { backgroundColor: theme.colors.primary }]}
            >
              <Text style={styles.retryText}>Pokušaj ponovno</Text>
            </TouchableOpacity>
          </View>
        ) : locations.length === 0 ? (
          <View style={styles.emptyStateContainer}>
            <View style={styles.emptyStateContent}>
              <Text style={styles.emptyStateText}>
                Nemate još dodanih poslovnica
              </Text>
              <Text style={styles.emptyStateSubtext}>
                Dodajte svoju prvu poslovnicu kako biste mogli pratiti logove
              </Text>
              <Button 
                mode="contained" 
                onPress={() => navigation.navigate('CreateLocation')}
                style={styles.createButton}
                contentStyle={styles.createButtonContent}
              >
                Dodaj poslovnicu
              </Button>
            </View>
          </View>
        ) : (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Odaberite poslovnicu</Text>
            <View style={styles.locationsContainer}>
              {locations.map((location, index) => (
                <React.Fragment key={location._id}>
                  <List.Item
                    title={location.name}
                    left={props => (
                      <MaterialCommunityIcons
                        name="office-building"
                        size={24}
                        color={theme.colors.primary}
                        style={styles.locationIcon}
                      />
                    )}
                    right={props => (
                      <MaterialCommunityIcons
                        name="chevron-right"
                        size={24}
                        color="#666"
                        style={styles.chevronIcon}
                      />
                    )}
                    onPress={() => navigation.navigate('LocationLogs', { location })}
                    style={styles.locationItem}
                  />
                  {index < locations.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    elevation: 0,
    backgroundColor: '#fff',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'normal',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  section: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  sectionTitle: {
    fontSize: 13,
    color: '#666',
    marginBottom: 8,
    letterSpacing: 0.1,
  },
  locationsContainer: {
    backgroundColor: '#fff',
    borderRadius: 12,
    overflow: 'hidden',
    elevation: 1,
  },
  locationItem: {
    paddingVertical: 12,
  },
  locationIcon: {
    marginLeft: 8,
    marginRight: 8,
  },
  chevronIcon: {
    marginRight: 8,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  errorText: {
    fontSize: 16,
    color: '#666',
    textAlign: 'center',
    marginBottom: 16,
  },
  retryButton: {
    padding: 12,
    borderRadius: 8,
  },
  retryText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '500',
  },
  emptyStateContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStateContent: {
    width: '100%',
    paddingHorizontal: 24,
    alignItems: 'center',
    marginTop: -115,
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#37474F',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: '#78909C',
    textAlign: 'center',
    marginBottom: 32,
    fontWeight: 'normal',
  },
  createButton: {
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  createButtonContent: {
    minWidth: 180,
  },
});
