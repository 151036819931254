import React, { createContext, useContext, ReactNode } from 'react';
import { useAuth } from '../hooks/useAuth';
import { NavigationProp } from '@react-navigation/native';
import { ownerAPI } from '../services/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface AuthContextType {
  token: string | null;
  owner: any;
  company: any;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  login: (data: { email: string; password: string }) => Promise<boolean>;
  register: (data: { name: string; email: string; password: string }) => Promise<boolean>;
  logout: (navigation?: NavigationProp<any>) => Promise<void>;
  updateProfile: (data: { name: string; email: string }) => Promise<any>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext mora biti korišten unutar AuthProvider-a');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();

  // Omotaj originalnu login funkciju za postavljanje isLoggedOut zastavice
  const login = async (data: { email: string; password: string }) => {
    const result = await auth.login(data);
    if (result) {
      // Resetiraj zastavicu odjave nakon uspješne prijave
      await AsyncStorage.setItem('isLoggedOut', 'false');
    }
    return result;
  };

  // Omotaj originalnu register funkciju za postavljanje isLoggedOut zastavice
  const register = async (data: { name: string; email: string; password: string }) => {
    const result = await auth.register(data);
    if (result) {
      // Resetiraj zastavicu odjave nakon uspješne registracije
      await AsyncStorage.setItem('isLoggedOut', 'false');
    }
    return result;
  };

  // Omotaj originalnu logout funkciju za postavljanje isLoggedOut zastavice
  const logout = async (navigation?: NavigationProp<any>) => {
    // Postavi zastavicu da je korisnik upravo odjavljen
    await AsyncStorage.setItem('isLoggedOut', 'true');
    // Izvrši originalnu logout logiku
    await auth.logout(navigation);
  };

  const updateProfile = async (profileData: any) => {
    try {
      const updatedOwner = await ownerAPI.updateProfile(profileData);
      await auth.setOwner(updatedOwner.owner);
      return updatedOwner;
    } catch (err: any) {
      throw err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        login,
        register,
        logout,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};