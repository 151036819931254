import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  ScrollView,
  SafeAreaView,
  Platform,
} from 'react-native';
import { useAuthContext } from '../../context/AuthContext';
import { Ionicons } from '@expo/vector-icons';

export const AccountSettingsScreen = ({ navigation }: any) => {
  const { owner, updateProfile, isLoading, error } = useAuthContext();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: owner?.name || '',
    email: owner?.email || '',
  });

  const validateForm = () => {
    const { name, email } = formData;
    
    if (!name.trim() || !email.trim()) {
      Alert.alert('Greška', 'Molimo popunite sva polja');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Alert.alert('Greška', 'Unesite ispravnu email adresu');
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        const success = await updateProfile(formData);
        if (success) {
          Alert.alert('Uspjeh', 'Podaci su uspješno ažurirani');
          setIsEditing(false);
        }
      } catch (err) {
        Alert.alert('Greška', 'Došlo je do greške pri spremanju podataka');
      }
    } else {
      Alert.alert('Greška', 'Forma nije validna');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData({
      name: owner?.name || '',
      email: owner?.email || '',
    });
  };

  const renderInput = (label: string, key: keyof typeof formData, keyboardType: any = 'default') => (
    <View style={styles.inputContainer}>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.inputWrapper}>
        <TextInput
          style={[styles.input, !isEditing && styles.disabledInput]}
          value={formData[key]}
          onChangeText={(text) => setFormData(prev => ({ ...prev, [key]: text }))}
          editable={isEditing}
          placeholder={`Unesite ${label.toLowerCase()}`}
          placeholderTextColor="#999"
          keyboardType={keyboardType}
          autoCapitalize={key === 'email' ? 'none' : 'words'}
        />
      </View>
    </View>
  );

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Text style={styles.title}>Postavke računa</Text>
        {!isEditing ? (
          <TouchableOpacity onPress={() => setIsEditing(true)} style={styles.editButton}>
            <Ionicons name="create-outline" size={24} color="#007AFF" />
          </TouchableOpacity>
        ) : (
          <View style={styles.actionButtons}>
            <TouchableOpacity onPress={handleCancel} style={styles.cancelButton}>
              <Ionicons name="close" size={24} color="#FF3B30" />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleSave} style={styles.saveButton}>
              <Ionicons name="checkmark" size={24} color="#34C759" />
            </TouchableOpacity>
          </View>
        )}
      </View>

      <ScrollView style={styles.container}>
        {renderInput('Ime i prezime', 'name')}
        {renderInput('Email', 'email', 'email-address')}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    paddingTop: Platform.OS === 'android' ? 35 : 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    backgroundColor: '#fff',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  title: {
    fontSize: 20,
  },
  editButton: {
    padding: 5,
  },
  actionButtons: {
    flexDirection: 'row',
    gap: 10,
  },
  cancelButton: {
    padding: 5,
  },
  saveButton: {
    padding: 5,
  },
  inputContainer: {
    marginBottom: 20,
  },
  label: {
    fontSize: 16,
    marginBottom: 8,
    color: '#333',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#E5E5E5',
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  input: {
    flex: 1,
    padding: 12,
    fontSize: 16,
    color: '#333',
  },
  disabledInput: {
    backgroundColor: '#F5F5F5',
    color: '#666',
  },
});
