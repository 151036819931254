import React, { useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Alert,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Appbar, TextInput, Text } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { employeesAPI } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';

interface LocationData {
  name: string;
  address: string;
  country: string;
  city: string;
  companyName: string;
  companyOIB: string;
  weeklyHours: number;
  ownerId: string;
}

export const EditLocationScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { owner } = useAuth();
  const locationId = route.params?.locationId;

  const [locationData, setLocationData] = useState<LocationData>({
    name: '',
    address: '',
    country: 'Hrvatska',
    city: '',
    companyName: '',
    companyOIB: '',
    weeklyHours: 40,
    ownerId: owner?.id || '',
  });

  useEffect(() => {
    const loadLocationData = async () => {
      try {
        const data = await employeesAPI.getLocationDetails(locationId);
        setLocationData({
          name: data.name,
          address: data.address,
          country: data.country,
          city: data.city,
          companyName: data.companyName,
          companyOIB: data.companyOIB,
          weeklyHours: data.weeklyHours,
          ownerId: data.ownerId,
        });
      } catch (error) {
        console.error('Greška pri učitavanju podataka:', error);
        Alert.alert('Greška', 'Nije moguće učitati podatke poslovnice');
        navigation.goBack();
      }
    };

    loadLocationData();
  }, [locationId]);

  const handleSave = async () => {
    if (!owner) {
      Alert.alert('Greška', 'Niste prijavljeni');
      return;
    }

    // Validacija
    if (!locationData.name.trim()) {
      Alert.alert('Greška', 'Molimo unesite ime poslovnice');
      return;
    }
    if (!locationData.address.trim()) {
      Alert.alert('Greška', 'Molimo unesite adresu poslovnice');
      return;
    }
    if (!locationData.city.trim()) {
      Alert.alert('Greška', 'Molimo unesite grad');
      return;
    }
    if (!locationData.companyName.trim()) {
      Alert.alert('Greška', 'Molimo unesite ime tvrtke');
      return;
    }
    if (!locationData.companyOIB.trim() || locationData.companyOIB.length !== 11) {
      Alert.alert('Greška', 'Molimo unesite ispravan OIB (11 znamenki)');
      return;
    }

    try {
      const updatedLocation = await employeesAPI.updateLocation(locationId, {
        ...locationData,
        ownerId: owner.id
      });

      Alert.alert('Uspjeh', 'Poslovnica je uspješno ažurirana');
      navigation.navigate('LocationDetails', { 
        locationId,
        location: updatedLocation,
        refresh: true
      });
    } catch (error) {
      console.error('Greška pri ažuriranju:', error);
      Alert.alert('Greška', 'Greška pri ažuriranju poslovnice');
    }
  };

  return (
    <SafeAreaView style={styles.safeArea} edges={['top', 'left', 'right']}>
      <Appbar.Header style={styles.header} statusBarHeight={0}>
        <TouchableOpacity 
          style={styles.backButton}
          onPress={() => navigation.goBack()}
        >
          <Ionicons name="arrow-back" size={24} color="#000" />
          <Text style={styles.backText}>Natrag</Text>
        </TouchableOpacity>
        <Appbar.Content title="Uredi poslovnicu" titleStyle={styles.headerTitle} />
        <TouchableOpacity 
          style={styles.saveButton}
          onPress={handleSave}
        >
          <Text style={styles.saveButtonText}>Spremi</Text>
        </TouchableOpacity>
      </Appbar.Header>

      <KeyboardAvoidingView 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 20}
      >
        <ScrollView 
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.content}>
            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Ime poslovnice"
                value={locationData.name}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, name: text }))}
                placeholder="npr. Šibenik - Podi"
                style={styles.input}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Adresa"
                value={locationData.address}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, address: text }))}
                placeholder="npr. Ul. Velimira Škorpika 24"
                style={styles.input}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Država"
                value={locationData.country}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, country: text }))}
                placeholder="npr. Hrvatska"
                style={styles.input}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Grad"
                value={locationData.city}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, city: text }))}
                placeholder="npr. Šibenik"
                style={styles.input}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Ime tvrtke"
                value={locationData.companyName}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, companyName: text }))}
                placeholder="npr. Moja Tvrtka d.o.o."
                style={styles.input}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="OIB tvrtke"
                value={locationData.companyOIB}
                onChangeText={(text) => setLocationData(prev => ({ ...prev, companyOIB: text }))}
                placeholder="11 znamenki"
                keyboardType="numeric"
                maxLength={11}
                style={styles.input}
                dense
              />
            </View>

            <View style={styles.inputGroup}>
              <TextInput
                mode="outlined"
                label="Tjedno radno vrijeme (sati)"
                value={locationData.weeklyHours.toString()}
                onChangeText={(text) => {
                  const hours = parseInt(text) || 0;
                  setLocationData(prev => ({ ...prev, weeklyHours: hours }));
                }}
                keyboardType="numeric"
                style={styles.input}
                dense
              />
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#fff',
    elevation: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerTitle: {
    fontSize: 20,
    color: '#000',
    fontWeight: '600',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
  },
  backText: {
    marginLeft: 4,
    fontSize: 16,
    color: '#000',
  },
  saveButton: {
    marginRight: 16,
  },
  saveButtonText: {
    color: '#007AFF',
    fontSize: 16,
    fontWeight: '600',
  },
  scrollView: {
    flex: 1,
  },
  content: {
    padding: 16,
  },
  inputGroup: {
    marginBottom: 16,
  },
  input: {
    backgroundColor: '#fff',
  },
});
