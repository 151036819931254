import React from 'react';
import { View, StyleSheet, useWindowDimensions, Platform } from 'react-native';
import { Dialog, Button, Text } from 'react-native-paper';
import { Break } from '../BreakListScreen';

interface ConfirmDialogProps {
  visible: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  selectedBreak: Break | null;
  title?: string;
  message?: string;
}

interface ErrorDialogProps {
  visible: boolean;
  onDismiss: () => void;
  error: string | null;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  visible,
  onDismiss,
  onConfirm,
  selectedBreak,
  title = 'Potvrda pauze',
  message,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = Platform.OS === 'web' ? width < 768 : true;

  return (
    <Dialog 
      visible={visible} 
      onDismiss={onDismiss}
      style={[
        styles.dialog,
        Platform.OS === 'web' ? {
          marginHorizontal: isMobile ? 16 : 24,
          maxWidth: isMobile ? '100%' : 400,
          width: isMobile ? '92%' : 'auto',
          alignSelf: 'center'
        } : null
      ]}
    >
      <Dialog.Title style={styles.dialogTitle}>{title}</Dialog.Title>
      <Dialog.Content style={styles.dialogContent}>
        <Text style={styles.dialogText}>
          {message || (selectedBreak ? `Želite li započeti pauzu "${selectedBreak.name}"?` : '')}
        </Text>
      </Dialog.Content>
      <Dialog.Actions>
        <View style={styles.buttonContainer}>
          <Button 
            onPress={onConfirm}
            mode="contained"
            style={styles.button}
          >
            Započni
          </Button>
          <Button 
            onPress={onDismiss}
            mode="outlined"
            style={styles.button}
          >
            Odustani
          </Button>
        </View>
      </Dialog.Actions>
    </Dialog>
  );
};

export const ErrorDialog: React.FC<ErrorDialogProps> = ({
  visible,
  onDismiss,
  error,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = Platform.OS === 'web' ? width < 768 : true;

  return (
    <Dialog 
      visible={visible} 
      onDismiss={onDismiss}
      style={[
        styles.dialog,
        Platform.OS === 'web' ? {
          marginHorizontal: isMobile ? 16 : 24,
          maxWidth: isMobile ? '100%' : 400,
          width: isMobile ? '92%' : 'auto',
          alignSelf: 'center'
        } : null
      ]}
    >
      <Dialog.Title style={styles.dialogTitle}>Upozorenje</Dialog.Title>
      <Dialog.Content style={styles.dialogContent}>
        <Text style={styles.dialogText}>
          {error}
        </Text>
      </Dialog.Content>
      <Dialog.Actions>
        <Button 
          onPress={onDismiss}
          mode="contained"
          style={[styles.button, { flex: 1 }]}
        >
          U redu
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  dialog: {
    borderRadius: 16,
    backgroundColor: '#fff',
  },
  dialogTitle: {
    textAlign: 'center',
    marginTop: 16,
    fontWeight: '600',
  },
  dialogContent: {
    paddingHorizontal: 16,
  },
  dialogText: {
    textAlign: 'center',
    marginVertical: 8,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
    width: '100%',
  },
  button: {
    flex: 1,
    margin: 4,
  }
});
