import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Platform,
  ActivityIndicator,
  Dimensions,
  useWindowDimensions,
} from 'react-native';
import { useAuthContext } from '../../context/AuthContext';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';

type RootStackParamList = {
  Register: undefined;
  Login: undefined;
  Onboarding: undefined;
  TerminalActivation: undefined;
  Terminal: undefined;
  Main: undefined;
  Landing: undefined;
};

type LoginScreenNavigationProp = NavigationProp<RootStackParamList>;

export const WebLoginScreen = () => {
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 1100;
  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { login, isAuthenticated } = useAuthContext();
  const navigation = useNavigation<LoginScreenNavigationProp>();

  const handleLogin = async () => {
    if (isSmallScreen) {
      setHasError(true);
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);
      const response = await login({ email, password });
      
      if (response.success) {
        if (Platform.OS === 'web') {
          window.location.href = '/dashboard';
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Main' }],
          });
        }
      } else {
        setHasError(true);
        setPassword('');
      }
    } catch (error) {
      setHasError(true);
      setPassword('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerminalMode = async () => {
    // Provjeri sve potrebne podatke za terminal
    const [terminalPin, locationId, deviceId] = await Promise.all([
      AsyncStorage.getItem('@terminal_pin'),
      AsyncStorage.getItem('locationId'),
      AsyncStorage.getItem('@device_id')
    ]);

    // Ako imamo sve podatke, terminal je aktivan
    if (terminalPin && locationId && deviceId) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Terminal' }],
      });
    } else {
      navigation.navigate('TerminalActivation' as never);
    }
  };

  return (
    <View style={styles.container}>
      {!isMobile && (
        <View style={[styles.leftPanel, isTablet && styles.leftPanelTablet]}>
          <View style={styles.backgroundGradient} />
          <View style={styles.logoContainer}>
            <View style={styles.logoTextContainer}>
              <Text style={styles.logoPart}>clock</Text>
              <Text style={styles.logoDot}>.</Text>
              <Text style={styles.logoHr}>hr</Text>
            </View>
            <Text style={styles.subtitle}>Evidencija radnog vremena</Text>
          </View>
          <View style={styles.featureContainer}>
            {!isTablet && (
              <>
                <View style={styles.featureItem}>
                  <Ionicons name="time-outline" size={48} color="#2563eb" />
                  <Text style={styles.featureText}>Pratite radno vrijeme zaposlenika</Text>
                </View>
                <View style={styles.featureItem}>
                  <Ionicons name="stats-chart-outline" size={48} color="#2563eb" />
                  <Text style={styles.featureText}>Pristupite izvještajima bilo kada</Text>
                </View>
                <View style={styles.featureItem}>
                  <Ionicons name="phone-portrait-outline" size={48} color="#2563eb" />
                  <Text style={styles.featureText}>Koristite kao terminal za evidenciju</Text>
                </View>
              </>
            )}
          </View>
        </View>
      )}
      
      <View style={[
        styles.rightPanel, 
        isMobile && styles.rightPanelMobile,
        isTablet && styles.rightPanelTablet
      ]}>
        {isMobile && (
          <View style={styles.mobileLogoContainer}>
            <View style={styles.logoTextContainer}>
              <Text style={styles.logoPart}>clock</Text>
              <Text style={styles.logoDot}>.</Text>
              <Text style={styles.logoHr}>hr</Text>
            </View>
            <Text style={styles.subtitle}>Evidencija radnog vremena</Text>
          </View>
        )}
        <View style={[
          styles.loginBox, 
          isMobile && styles.loginBoxMobile,
          isTablet && styles.loginBoxTablet
        ]}>
          <Text style={styles.loginTitle}>Prijava</Text>
          <Text style={styles.loginSubtitle}>Dobrodošli natrag! Molimo prijavite se u svoj račun.</Text>
          
          <View style={styles.inputContainer}>
            <TextInput
              style={[
                styles.input,
                { borderColor: hasError ? '#ff4444' : '#e6e6e6' }
              ]}
              placeholder="Email"
              value={email}
              onChangeText={(text) => {
                setEmail(text);
                setHasError(false);
              }}
              autoCapitalize="none"
              keyboardType="email-address"
              placeholderTextColor="#666666"
            />
            <Ionicons 
              name="mail-outline" 
              size={20} 
              color={hasError ? '#ff4444' : '#666666'} 
              style={styles.inputIcon} 
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInput
              style={[
                styles.input,
                { borderColor: hasError ? '#ff4444' : '#e6e6e6' }
              ]}
              placeholder="Lozinka"
              value={password}
              onChangeText={(text) => {
                setPassword(text);
                setHasError(false);
              }}
              secureTextEntry={!showPassword}
              placeholderTextColor="#666666"
            />
            <Ionicons 
              name="lock-closed-outline" 
              size={20} 
              color={hasError ? '#ff4444' : '#666666'} 
              style={styles.inputIcon} 
            />
            <TouchableOpacity 
              onPress={() => setShowPassword(!showPassword)}
              style={styles.eyeIcon}
            >
              <Ionicons 
                name={showPassword ? "eye-off-outline" : "eye-outline"} 
                size={20} 
                color="#666666"
              />
            </TouchableOpacity>
          </View>

          {hasError && (
            <View style={styles.errorContainer}>
              <Ionicons name="alert-circle-outline" size={18} color="#ff4444" />
              <Text style={styles.errorText}>
                {isSmallScreen 
                  ? (
                    <>
                      Molimo koristite desktop računalo ili{' '}
                      <Text 
                        style={styles.downloadLink}
                        onPress={() => {
                          fetch('/clock.hr.appv1.0.0.apk')
                            .then(response => response.blob())
                            .then(blob => {
                              const url = window.URL.createObjectURL(blob);
                              const link = document.createElement('a');
                              link.href = url;
                              link.download = 'clock.hr.appv1.0.0.apk';
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              window.URL.revokeObjectURL(url);
                              setTimeout(() => {
                                alert('Nakon preuzimanja aplikacije, molimo omogućite instalaciju iz nepoznatih izvora u postavkama vašeg Android uređaja kako biste mogli instalirati aplikaciju.');
                              }, 100);
                            })
                            .catch(() => {
                              alert('Došlo je do greške prilikom preuzimanja aplikacije. Molimo pokušajte ponovno.');
                            });
                        }}
                      >
                        preuzmite našu Android aplikaciju
                      </Text>
                      {' '}
                    </>
                  )
                  : "Neispravni podaci za prijavu. Molimo pokušajte ponovno."}
              </Text>
            </View>
          )}

          <TouchableOpacity
            style={styles.button}
            onPress={handleLogin}
            disabled={isLoading}
          >
            {isLoading ? (
              <ActivityIndicator color="#fff" />
            ) : (
              <Text style={styles.buttonText}>Prijava</Text>
            )}
          </TouchableOpacity>

          <View style={[
            styles.footerContainer,
            isMobile && styles.footerContainerMobile,
            isTablet && styles.footerContainerTablet
          ]}>
            <TouchableOpacity
              style={styles.backButton}
              onPress={() => navigation.navigate('Landing' as never)}
            >
              <Ionicons name="arrow-back-outline" size={20} color="#666666" />
              <Text style={styles.backButtonText}>Natrag na početnu</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.registerButton}
              onPress={() => navigation.navigate('Register' as never)}
            >
              <Text style={styles.registerText}>
                Nemate račun? <Text style={styles.registerTextBold}>Registrirajte se</Text>
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.terminalButton}
              onPress={handleTerminalMode}
            >
              <Text style={styles.terminalText}>
                Aktivacija terminala
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#fff',
  },
  leftPanel: {
    flex: 1,
    padding: 48,
    position: 'relative',
    backgroundColor: '#f8fafc',
    borderRightWidth: 1,
    borderRightColor: '#e2e8f0',
  },
  leftPanelTablet: {
    padding: 24,
  },
  rightPanel: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 48,
  },
  rightPanelMobile: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  rightPanelTablet: {
    padding: 30,
  },
  mobileLogoContainer: {
    alignItems: 'center',
    marginBottom: 32,
  },
  logoContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 48,
  },
  logoTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  logoPart: {
    fontSize: 28,
    fontWeight: '600',
    color: '#1E293B',
    letterSpacing: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoDot: {
    fontSize: 28,
    fontWeight: '800',
    color: '#4299E1',
    marginHorizontal: 1,
    transition: 'color 0.3s ease',
  } as any,
  logoHr: {
    fontSize: 28,
    fontWeight: '800',
    color: '#1E293B',
    letterSpacing: 1,
    transition: 'color 0.3s ease',
  } as any,
  subtitle: {
    fontSize: 16,
    color: '#64748B',
    marginTop: 16,
    textAlign: 'center',
  },
  loginBox: {
    width: '80%',
    maxWidth: 450,
    backgroundColor: '#ffffff',
    borderRadius: 12,
    padding: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  loginBoxMobile: {
    width: '100%',
    padding: 20,
    shadowOpacity: 0,
    elevation: 0,
  },
  loginBoxTablet: {
    padding: 30,
    width: '90%',
  },
  backgroundGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#f8fafc',
  },
  featureContainer: {
    marginTop: 48,
  },
  featureItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
    backgroundColor: '#fff',
    padding: 24,
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
    elevation: 2,
    borderWidth: 1,
    borderColor: '#e2e8f0',
  },
  featureText: {
    fontSize: 16,
    color: '#334155',
    marginLeft: 20,
    flex: 1,
    lineHeight: 24,
    letterSpacing: 0.1,
  },
  loginTitle: {
    fontSize: 32,
    fontWeight: '700',
    marginBottom: 10,
    color: '#333',
  },
  loginSubtitle: {
    fontSize: 16,
    color: '#666',
    marginBottom: 30,
  },
  inputContainer: {
    marginBottom: 20,
    position: 'relative',
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 45,
    fontSize: 16,
    backgroundColor: '#ffffff',
    color: '#333',
  },
  inputIcon: {
    position: 'absolute',
    left: 15,
    top: 15,
  },
  eyeIcon: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
  button: {
    height: 50,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    backgroundColor: '#0056b3',
  },
  buttonText: {
    fontSize: 18,
    fontWeight: '600',
    color: '#ffffff',
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 68, 68, 0.1)',
    padding: 10,
    borderRadius: 8,
    marginBottom: 15,
  },
  errorText: {
    color: '#ff4444',
    marginLeft: 8,
    fontSize: 14,
  },
  downloadLink: {
    color: '#2196F3',
    textDecorationLine: 'underline',
  },
  footerContainer: {
    marginTop: 25,
    alignItems: 'center',
  },
  footerContainerMobile: {
    marginTop: 15,
  },
  footerContainerTablet: {
    marginTop: 20,
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
  },
  backButtonText: {
    color: '#666666',
    marginLeft: 5,
    fontSize: 14,
  },
  registerButton: {
    marginBottom: 10,
  },
  registerText: {
    fontSize: 15,
    color: '#666666',
  },
  registerTextBold: {
    fontWeight: '700',
    color: '#0056b3',
  },
  terminalButton: {
    marginTop: 5,
  },
  terminalText: {
    fontSize: 14,
    color: '#888888',
    textDecorationLine: 'underline',
  },
});

export default WebLoginScreen;
