import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
// @ts-ignore
import autoTable from 'jspdf-autotable';
import { companyAPI } from '../../../../services/api';

interface Props {
  locationId: string;
  startDate: Date;
  endDate: Date;
  locationStats?: any;
}

interface EmployeeStats {
  employeeId: string;
  employeeName: string;
  totalWorkMinutes: number;
  effectiveWorkMinutes: number;
  totalBreakMinutes: number;
  paidTime: number;
  totalPaidBreakMinutes: number;
  totalUnpaidBreakMinutes: number;
  overPaidBreakMinutes: number;
  overUnpaidBreakMinutes: number;
  totalOverBreakMinutes: number;
  deductions: number;
  earnings: number;
  days: Array<{
    date: string;
    totalWorkMinutes: number;
    effectiveWorkMinutes: number;
    totalBreakMinutes: number;
    paidTime: number;
    totalPaidBreakMinutes: number;
    totalUnpaidBreakMinutes: number;
    overPaidBreakMinutes: number;
    overUnpaidBreakMinutes: number;
    deductions: number;
    earnings: number;
  }>;
}

// Funkcija za uklanjanje dijakritičkih znakova
const removeDiacritics = (str: string): string => {
  return str
    .replace(/č/g, 'c')
    .replace(/ć/g, 'c')
    .replace(/đ/g, 'd')
    .replace(/š/g, 's')
    .replace(/ž/g, 'z')
    .replace(/Č/g, 'C')
    .replace(/Ć/g, 'C')
    .replace(/Đ/g, 'D')
    .replace(/Š/g, 'S')
    .replace(/Ž/g, 'Z');
};

// Helper funkcije za formatiranje podataka
const formatDuration = (minutes: number) => {
  if (minutes === 0) return '0min';
  
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes % 1) * 60);
  
  let result = '';
  if (hours > 0) result += `${hours}h `;
  if (mins > 0 || hours > 0) result += `${mins}min `;
  if (secs > 0 || (minutes > 0 && minutes < 1)) result += `${secs}s`;
  
  return result.trim();
};

const formatMoney = (amount: number) => {
  return `${amount.toFixed(2)} €`;
};

export const WebLocationStatsPDF: React.FC<Props> = ({ 
  locationId, 
  startDate, 
  endDate, 
  locationStats
}) => {
  const generatePDF = async () => {
    try {
      // Dohvati podatke o kompaniji
      let companyData = { name: 'Tvrtka' };
      try {
        const companyResponse = await companyAPI.getCompanyData();
        if (companyResponse) {
          companyData = companyResponse;
        }
      } catch (error) {
        console.error('Greska pri dohvacanju podataka o kompaniji:', error);
      }
      
      if (!locationStats || !locationStats.employees || !Array.isArray(locationStats.employees)) {
        alert('Nema podataka za odabrani period.');
        return;
      }
      
      // Kreiraj PDF dokument
      const doc = new jsPDF({
        orientation: 'landscape', // Landscape za više prostora
        unit: 'mm',
        format: 'a4'
      });
      
      // Dodaj zaglavlje
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0); // Crna boja za "clock"
      doc.setFont(undefined, 'bold');
      doc.text('clock', 14, 15);
      
      // Dodaj plavu točku
      doc.setTextColor(0, 123, 255); // Plava boja za točku
      doc.text('.', 14 + doc.getTextWidth('clock'), 15);
      
      // Dodaj "hr" u crnoj boji
      doc.setTextColor(0, 0, 0); // Crna boja za "hr"
      doc.text('hr', 14 + doc.getTextWidth('clock.'), 15);
      doc.setFont(undefined, 'normal');
      
      // Uklonjen datum iz gornjeg desnog kuta
      
      // Dodaj naslov
      doc.setFontSize(14);
      doc.text('Statistika poslovnice - pregled po zaposlenicima', doc.internal.pageSize.width / 2, 30, { align: 'center' });
      
      // Dodaj podatke o tvrtki i lokaciji
      const leftMargin = 14;
      let yPosition = 45;
      const lineHeight = 6;
      
      // Tvrtka
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Tvrtka:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(companyData?.name ? removeDiacritics(companyData.name) : 'Tvrtka', leftMargin + 30, yPosition);
      yPosition += lineHeight;
      
      doc.setFontSize(9);
      doc.text(`OIB: ${companyData?.oib || '12345678901'}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 2;
      
      // Poslovnica
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Poslovnica:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(locationStats?.locationName ? removeDiacritics(locationStats.locationName) : 'Poslovnica', leftMargin + 30, yPosition);
      yPosition += lineHeight;
      
      doc.setFontSize(9);
      doc.text(`Adresa: ${companyData?.address ? removeDiacritics(companyData.address) : 'Ulica 123, Zagreb'}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 2;
      
      // Period
      doc.setFontSize(10);
      doc.setFont(undefined, 'bold');
      doc.text('Period:', leftMargin, yPosition);
      doc.setFont(undefined, 'normal');
      doc.text(`${format(startDate, 'dd.MM.yyyy.')} - ${format(endDate, 'dd.MM.yyyy.')}`, leftMargin + 30, yPosition);
      yPosition += lineHeight + 5;
      
      // Funkcija za formatiranje vremena - prikazuje sate, minute i sekunde
      const formatDuration = (minutes: number | undefined | null): string => {
        if (!minutes || minutes === 0) return '0min';
        
        // Pretvaranje minuta u sekunde
        const hours = Math.floor(minutes / 60);
        const mins = Math.floor(minutes % 60);
        
        if (hours === 0) {
          return `${mins}min`;
        }
        return `${hours}h ${mins}min`;
      };
      
      // Funkcija za formatiranje novca
      const formatMoney = (amount: number | undefined | null): string => {
        if (!amount) return '0.00 €';
        return `${amount.toFixed(2)} €`;
      };
      
      // Izračunaj ukupno prekoračenje za svakog zaposlenika
      const employeesWithTotalOverage = locationStats.employees.map((employee: any) => ({
        ...employee,
        totalOverBreakMinutes: (employee.overPaidBreakMinutes || 0) + (employee.overUnpaidBreakMinutes || 0),
        deductions: employee.deductions || 0
      }));
      
      // Izračunaj ukupne statistike za period
      const periodTotals = employeesWithTotalOverage.reduce((acc: any, employee: any) => ({
        totalWorkMinutes: acc.totalWorkMinutes + (employee.totalWorkMinutes || 0),
        effectiveWorkMinutes: acc.effectiveWorkMinutes + (employee.effectiveWorkMinutes || 0),
        totalBreakMinutes: acc.totalBreakMinutes + (employee.totalBreakMinutes || 0),
        paidTime: acc.paidTime + (employee.paidTime || 0),
        totalPaidBreakMinutes: acc.totalPaidBreakMinutes + (employee.totalPaidBreakMinutes || 0),
        totalUnpaidBreakMinutes: acc.totalUnpaidBreakMinutes + (employee.totalUnpaidBreakMinutes || 0),
        overPaidBreakMinutes: acc.overPaidBreakMinutes + (employee.overPaidBreakMinutes || 0),
        overUnpaidBreakMinutes: acc.overUnpaidBreakMinutes + (employee.overUnpaidBreakMinutes || 0),
        totalOverBreakMinutes: acc.totalOverBreakMinutes + employee.totalOverBreakMinutes,
        deductions: acc.deductions + (employee.deductions || 0),
        earnings: acc.earnings + (employee.earnings || 0)
      }), {
        totalWorkMinutes: 0,
        effectiveWorkMinutes: 0,
        totalBreakMinutes: 0,
        paidTime: 0,
        totalPaidBreakMinutes: 0,
        totalUnpaidBreakMinutes: 0,
        overPaidBreakMinutes: 0,
        overUnpaidBreakMinutes: 0,
        totalOverBreakMinutes: 0,
        deductions: 0,
        earnings: 0
      });
      
      // Kreiraj tablicu sa statistikama zaposlenika
      autoTable(doc, {
        startY: yPosition,
        head: [
          [
            'Zaposlenik', 
            'Ukupno rad', 
            'Efektivno', 
            'Placeno', 
            'Pauze ukupno', 
            'Placene pauze', 
            'Neplacene pauze', 
            'Preko. placenih', 
            'Preko. neplacenih', 
            'Preko. ukupno',
            'Odbitak',
            'Zarada'
          ]
        ],
        body: [
          ...employeesWithTotalOverage.map((employee: any) => [
            removeDiacritics(employee.employeeName || 'Nepoznat'),
            formatDuration(employee.totalWorkMinutes),
            formatDuration(employee.effectiveWorkMinutes),
            formatDuration(employee.paidTime),
            formatDuration(employee.totalBreakMinutes),
            formatDuration(employee.totalPaidBreakMinutes),
            formatDuration(employee.totalUnpaidBreakMinutes),
            formatDuration(employee.overPaidBreakMinutes),
            formatDuration(employee.overUnpaidBreakMinutes),
            formatDuration(employee.totalOverBreakMinutes),
            formatMoney(employee.deductions),
            formatMoney(employee.earnings)
          ]),
          // Dodaj redak s ukupnim vrijednostima
          [
            'UKUPNO',
            formatDuration(periodTotals.totalWorkMinutes),
            formatDuration(periodTotals.effectiveWorkMinutes),
            formatDuration(periodTotals.paidTime),
            formatDuration(periodTotals.totalBreakMinutes),
            formatDuration(periodTotals.totalPaidBreakMinutes),
            formatDuration(periodTotals.totalUnpaidBreakMinutes),
            formatDuration(periodTotals.overPaidBreakMinutes),
            formatDuration(periodTotals.overUnpaidBreakMinutes),
            formatDuration(periodTotals.totalOverBreakMinutes),
            formatMoney(periodTotals.deductions),
            formatMoney(periodTotals.earnings)
          ]
        ],
        theme: 'grid',
        styles: {
          fontSize: 8,
          cellPadding: 1,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          overflow: 'linebreak',
          cellWidth: 'wrap',
          minCellHeight: 6
        },
        headStyles: {
          fillColor: [240, 240, 240],
          textColor: [0, 0, 0],
          fontSize: 8,
          fontStyle: 'normal',
          halign: 'center',
          cellPadding: 1,
          lineWidth: 0.1,
          lineColor: [0, 0, 0]
        },
        bodyStyles: {
          fontSize: 8,
          cellPadding: 1,
          lineWidth: 0.1,
          lineColor: [0, 0, 0]
        },
        alternateRowStyles: {
          fillColor: [248, 250, 252]
        },
        columnStyles: {
          0: { cellWidth: 'auto' }, // Zaposlenik
          1: { cellWidth: 'auto', halign: 'right' }, // Ukupno rad
          2: { cellWidth: 'auto', halign: 'right' }, // Efektivno
          3: { cellWidth: 'auto', halign: 'right' }, // Plaćeno
          4: { cellWidth: 'auto', halign: 'right' }, // Pauze ukupno
          5: { cellWidth: 'auto', halign: 'right' }, // Plaćene pauze
          6: { cellWidth: 'auto', halign: 'right' }, // Neplaćene pauze
          7: { cellWidth: 'auto', halign: 'right' }, // Preko. plaćenih
          8: { cellWidth: 'auto', halign: 'right' }, // Preko. neplaćenih
          9: { cellWidth: 'auto', halign: 'right' }, // Preko. ukupno
          10: { cellWidth: 'auto', halign: 'right' }, // Odbitak
          11: { cellWidth: 'auto', halign: 'right' }, // Zarada
        },
        didParseCell: function(data) {
          // Posebno oblikovanje za redak s ukupnim vrijednostima
          if (data.row.index === data.table.body.length - 1) {
            data.cell.styles.fontStyle = 'bold';
            data.cell.styles.fillColor = [240, 240, 240]; // Siva pozadina za ukupno redak
          }
        },
        margin: { left: 5, right: 5 },
        tableWidth: doc.internal.pageSize.width - 10, // Puna širina stranice minus margine
        showHead: 'everyPage',
        showFoot: 'everyPage',
        tableLineWidth: 0.1,
        tableLineColor: [0, 0, 0]
      });
      
      // Dodaj podnožje
      const pageCount = doc.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text(
          `Stranica ${i} od ${pageCount}`,
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 10,
          { align: 'center', angle: 0 }
        );
        
        // Dodaj datum i vrijeme generiranja
        const generatedDateTime = format(new Date(), 'dd.MM.yyyy. HH:mm:ss');
        doc.text(
          `Generirano: ${generatedDateTime}`,
          doc.internal.pageSize.width - 14,
          doc.internal.pageSize.height - 10,
          { align: 'right' }
        );
      }
      
      // Spremi PDF
      const fileName = `Statistika_poslovnice_${removeDiacritics(locationStats.locationName || 'poslovnica')}_${format(startDate, 'dd_MM_yyyy')}_${format(endDate, 'dd_MM_yyyy')}.pdf`;
      doc.save(fileName);
      
    } catch (error) {
      console.error('Greska pri generiranju PDF-a:', error);
      alert('Doslo je do greske pri generiranju PDF-a. Molimo pokusajte ponovno.');
    }
  };

  return (
    <TouchableOpacity onPress={generatePDF} style={styles.pdfButton}>
      <MaterialIcons name="picture-as-pdf" size={20} color="#FFFFFF" />
      <Text style={styles.pdfButtonText}>Preuzmi PDF</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  pdfButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#DC3545',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 4,
  },
  pdfButtonText: {
    color: '#FFFFFF',
    marginLeft: 4,
  },
});
