import React, { useEffect, useState, useRef, useCallback } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { View, ActivityIndicator, Text, Button, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { PaperProvider, DefaultTheme, IconButton } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// Importiramo EventEmitter
import eventEmitter from './src/utils/eventEmitter';

// Contexts
import { AuthProvider, useAuthContext } from './src/context/AuthContext';
import { TerminalProvider } from './src/modules/terminal/context/TerminalContext';
import { ToastProvider } from './src/context/ToastContext';

// Auth Screens
import { LoginScreen } from './src/screens/auth/LoginScreen';
import { RegisterScreen } from './src/screens/auth/RegisterScreen';
import { OnboardingScreen } from './src/screens/onboarding/OnboardingScreen';

// Main Tab Screens
import { DashboardScreen } from './src/screens/dashboard/DashboardScreen';
import { LocationsScreen } from './src/screens/locations/LocationsScreen';
import { EmployeesScreen } from './src/screens/employees/EmployeesScreen';
import { BreaksScreen } from './src/screens/breaks/BreaksScreen';
import { SettingsScreen } from './src/screens/settings/SettingsScreen';

// Settings Screens
import { AccountSettingsScreen } from './src/screens/settings/AccountSettingsScreen';
import { CompanySettingsScreen } from './src/screens/settings/CompanySettingsScreen';
import { LogsScreen } from './src/screens/settings/LogsScreen';
import { LocationLogsScreen } from './src/screens/settings/LocationLogsScreen';
import { AboutScreen } from './src/screens/settings/AboutScreen';
import { FAQScreen } from './src/screens/settings/FAQScreen';
import { LegalScreen } from './src/screens/settings/LegalScreen';

// Location Screens
import { LocationDetailsScreen } from './src/screens/locations/components/LocationDetailsScreen';
import { CreateLocationScreen } from './src/screens/locations/CreateLocationScreen';
import { EditLocationScreen } from './src/screens/locations/EditLocationScreen';

// Employee Screens
import { CreateEmployeeScreen } from './src/screens/employees/components/CreateEmployeeScreen';
import { EmployeeDetailsScreen } from './src/screens/employees/components/EmployeeDetailsScreen';
import { EditEmployeeScreen } from './src/screens/employees/components/EditEmployeeScreen';

// Break Screens
import { CreateBreakScreen } from './src/screens/breaks/CreateBreakScreen';
import { BreakDetailsScreen } from './src/screens/breaks/components/BreakDetailsScreen';

// Terminal Screens
import { TerminalActivationScreen } from './src/modules/terminal/screens/TerminalActivationScreen';
import { TerminalScreen } from './src/modules/terminal/screens/TerminalScreen';
import { BreakListScreen } from './src/modules/terminal/screens/BreakListScreen';
import { ActiveBreakScreen } from './src/modules/terminal/screens/ActiveBreakScreen';

// Utils
import { getGreeting } from './src/utils/greetings';
// Importirajmo hook za navigaciju da ga možemo koristiti u Tab navigatoru
import { useNavigation } from '@react-navigation/native';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

// Theme configuration
const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#2196F3', // Material Design Blue
    primaryContainer: '#E3F2FD', // Light Blue background
    secondary: '#1976D2', // Darker Blue
    secondaryContainer: '#BBDEFB', // Light Blue background
    surface: '#FFFFFF',
    background: '#F5F5F5',
    error: '#FF5252',
    onSurface: '#212121',
    onBackground: '#212121',
    onPrimary: '#FFFFFF',
    onSecondary: '#FFFFFF',
    outline: '#BDBDBD'
  },
};

const queryClient = new QueryClient();

// Header title component for Dashboard that safely uses context
const DashboardHeaderTitle = () => {
  const { owner } = useAuthContext();
  const greeting = getGreeting();
  return (
    <View>
      <Text style={{ fontSize: 20, fontWeight: '600', color: '#37474F' }}>
        {greeting}, {owner?.name || 'Korisnik'}
      </Text>
    </View>
  );
};

const TabNavigator = () => {
  const navigation = useNavigation();
  
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;

          switch (route.name) {
            case 'Dashboard':
              iconName = focused ? 'home' : 'home-outline';
              break;
            case 'Locations':
              iconName = focused ? 'business' : 'business-outline';
              break;
            case 'Employees':
              iconName = focused ? 'people' : 'people-outline';
              break;
            case 'Breaks':
              iconName = focused ? 'time' : 'time-outline';
              break;
            case 'Settings':
              iconName = focused ? 'settings' : 'settings-outline';
              break;
            default:
              iconName = 'alert';
          }

          return <Ionicons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: '#007AFF',
        tabBarInactiveTintColor: 'gray',
        tabBarStyle: {
          height: Platform.OS === 'android' ? 65 : 85,
          paddingBottom: Platform.OS === 'android' ? 15 : 35,
          paddingTop: Platform.OS === 'android' ? 8 : 0,
          borderTopWidth: 0.2,
          borderTopColor: '#E0E0E0',
        },
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          borderBottomWidth: 0.2,
          height: 110,
        },
        headerTitleContainerStyle: {
          paddingTop: 8,
        },
      })}
    >
      <Tab.Screen 
        name="Dashboard" 
        component={DashboardScreen} 
        options={{ 
          title: 'Dashboard',
          headerTitleAlign: 'left',
          headerTitleStyle: {
            fontSize: 20,
            fontWeight: '600',
          },
          headerTitle: () => <DashboardHeaderTitle />
        }}
      />
      <Tab.Screen 
        name="Locations" 
        component={LocationsScreen} 
        options={{ 
          title: 'Poslovnice',
          headerTitleAlign: 'left',
          headerTitleStyle: {
            fontSize: 22,
            fontWeight: '600',
            color: '#37474F'
          },
          headerRight: () => (
            <IconButton
              icon="plus"
              size={22}
              mode="contained-tonal"
              containerColor={theme.colors.primaryContainer}
              iconColor={theme.colors.primary}
              style={{ 
                marginRight: 16,
                marginVertical: 8,
                borderRadius: 18,
                width: 36,
                height: 36
              }}
              onPress={() => navigation.navigate('CreateLocation')}
            />
          ),
        }}
      />
      <Tab.Screen 
        name="Employees" 
        component={EmployeesScreen} 
        options={{ 
          title: 'Djelatnici',
          headerTitleAlign: 'left',
          headerTitleStyle: {
            fontSize: 22,
            fontWeight: '600',
            color: '#37474F'
          },
          headerRight: () => (
            <IconButton
              icon="plus"
              size={22}
              mode="contained-tonal"
              containerColor={theme.colors.primaryContainer}
              iconColor={theme.colors.primary}
              style={{ 
                marginRight: 16,
                marginVertical: 8,
                borderRadius: 18,
                width: 36,
                height: 36
              }}
              onPress={() => navigation.navigate('CreateEmployee')}
            />
          ),
        }}
      />
      <Tab.Screen 
        name="Breaks" 
        component={BreaksScreen} 
        options={{ 
          title: 'Pauze',
          headerTitleAlign: 'left',
          headerTitleStyle: {
            fontSize: 22,
            fontWeight: '600',
          },
          headerRight: () => (
            <IconButton
              icon="plus"
              size={22}
              mode="contained-tonal"
              containerColor={theme.colors.primaryContainer}
              iconColor={theme.colors.primary}
              style={{ 
                marginRight: 16,
                marginVertical: 8,
                borderRadius: 18,
                width: 36,
                height: 36
              }}
              onPress={() => navigation.navigate('CreateBreak')}
            />
          ),
        }}
      />
      <Tab.Screen 
        name="Settings" 
        component={SettingsScreen} 
        options={{ title: 'Postavke' }}
      />
    </Tab.Navigator>
  );
};

const AppContent = () => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const [initialRouteName, setInitialRouteName] = useState('Login');
  const [isCheckingTerminal, setIsCheckingTerminal] = useState(true);
  const [error, setError] = useState(null);
  const routeChecked = useRef(false);

  // Provjera inicijalnog routa - s popravkom ovisnosti
  useEffect(() => {
    const checkInitialRoute = async () => {
      if (routeChecked.current) return;
      
      try {        
        const terminalActive = await AsyncStorage.getItem('terminalActive');
        
        if (terminalActive === 'true') {
          setInitialRouteName('Terminal');
          setIsCheckingTerminal(false);
          routeChecked.current = true;
          return;
        }

        // Provjeri je li korisnik upravo odjavljen
        const isLoggedOut = await AsyncStorage.getItem('isLoggedOut');
        
        if (isAuthenticated) {
          setInitialRouteName('Main');
          // Reset logged out flag ako se korisnik ponovno prijavi
          if (isLoggedOut === 'true') {
            await AsyncStorage.setItem('isLoggedOut', 'false');
          }
        } else {
          // Ako je korisnik upravo odjavljen, idi direktno na Login
          if (isLoggedOut === 'true') {
            setInitialRouteName('Login');
          } else {
            // Inače provjeri je li već vidio onboarding
            const hasSeenOnboarding = await AsyncStorage.getItem('hasSeenOnboarding');
            setInitialRouteName(hasSeenOnboarding ? 'Login' : 'Onboarding');
          }
        }
      } catch (error) {
        setError('Greška pri učitavanju aplikacije');
        setInitialRouteName('Login');
      } finally {
        setIsCheckingTerminal(false);
        routeChecked.current = true;
      }
    };

    if (!isLoading) {
      checkInitialRoute();
    }
  }, [isAuthenticated, isLoading]); // Dodane ovisnosti koje su nedostajale

  useEffect(() => {
    const unsubscribe = eventEmitter.on('SESSION_EXPIRED', async () => {
      // Očisti stanje sesije
      await AsyncStorage.setItem('isLoggedOut', 'true');
      setInitialRouteName('Login');
    });
    return () => unsubscribe();
  }, []);

  if (error) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', padding: 20 }}>
        <Text style={{ color: 'red', marginBottom: 10 }}>{error}</Text>
        <Button 
          title="Pokušaj ponovno" 
          onPress={() => {
            setError(null);
            routeChecked.current = false;
            setIsCheckingTerminal(true);
          }} 
        />
      </View>
    );
  }

  if (isLoading || isCheckingTerminal) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
        <ActivityIndicator size="large" color="#0000ff" />
        <Text style={{ marginTop: 10, color: '#666' }}>
          {isLoading ? 'Učitavanje...' : 'Provjera stanja...'}
        </Text>
      </View>
    );
  }

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName={initialRouteName}>
        {isAuthenticated ? (
          <Stack.Screen
            name="Main"
            component={TabNavigator}
            options={{ headerShown: false }}
          />
        ) : (
          <>
            <Stack.Screen
              name="Onboarding"
              component={OnboardingScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{ headerShown: false }}
            />
            <Stack.Screen 
              name="Register" 
              component={RegisterScreen}
              options={{ headerShown: false }}
            />
          </>
        )}
        <Stack.Screen
          name="Terminal"
          options={{
            headerShown: false,
            gestureEnabled: false
          }}
        >
          {() => (
            <TerminalProvider>
              <TerminalScreen />
            </TerminalProvider>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="TerminalActivation"
          options={{ headerShown: false }}
        >
          {() => (
            <TerminalProvider>
              <TerminalActivationScreen />
            </TerminalProvider>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="AccountSettings"
          component={AccountSettingsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CompanySettings"
          component={CompanySettingsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Logs"
          component={LogsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="LocationLogs"
          component={LocationLogsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="About"
          component={AboutScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="FAQ"
          component={FAQScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Legal"
          component={LegalScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen 
          name="LocationDetails" 
          component={LocationDetailsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreateBreak"
          component={CreateBreakScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="BreakDetails"
          component={BreakDetailsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreateLocation"
          component={CreateLocationScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="EditLocation"
          component={EditLocationScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen 
          name="CreateEmployee" 
          component={CreateEmployeeScreen} 
          options={{ headerShown: false }}
        />
        <Stack.Screen 
          name="EmployeeDetails" 
          component={EmployeeDetailsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen 
          name="EditEmployee" 
          component={EditEmployeeScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="BreakList"
          component={BreakListScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ActiveBreak"
          component={ActiveBreakScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SafeAreaProvider>
        <PaperProvider theme={theme}>
          <ToastProvider>
            <AuthProvider>
              <AppContent />
            </AuthProvider>
          </ToastProvider>
        </PaperProvider>
      </SafeAreaProvider>
    </QueryClientProvider>
  );
}