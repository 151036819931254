import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const Compliance = () => {
  return (
    <HelpLayout>
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Zakonska usklađenost</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Alat za pomoć pri usklađenosti</Text>
            <Text style={styles.description}>
              Clock.hr je alat koji omogućuje poslodavcima vođenje evidencije radnog vremena u skladu s 
              hrvatskim zakonodavstvom.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Funkcionalnosti sustava</Text>
              <Text style={styles.paragraph}>
                Clock.hr je softversko rješenje koje poslodavcima omogućuje bilježenje i vođenje evidencije 
                radnog vremena. Naš sustav nudi funkcionalnosti koje poslodavcima olakšavaju ispunjavanje 
                njihovih zakonskih obveza, no odgovornost za pravnu usklađenost snosi sam poslodavac.
              </Text>
              
              <Text style={styles.heading}>2. Raspodjela odgovornosti</Text>
              <Text style={styles.paragraph}>
                Važno je razumjeti sljedeće:
              </Text>
              <Text style={styles.bulletPoint}>• Clock.hr je tehnološko rješenje, a ne pravni savjetnik</Text>
              <Text style={styles.bulletPoint}>• Poslodavac je isključivo odgovoran za pravilnu primjenu zakona i propisa</Text>
              <Text style={styles.bulletPoint}>• Naša aplikacija pomaže u prikupljanju potrebnih podataka, ali ne jamči pravnu usklađenost</Text>
              <Text style={styles.bulletPoint}>• Za specifične pravne savjete preporučujemo konzultaciju s odvjetnikom specijaliziranim za radno pravo</Text>

              <Text style={styles.heading}>3. Dostupne funkcionalnosti</Text>
              <Text style={styles.paragraph}>Sustav omogućuje bilježenje:</Text>
              <Text style={styles.bulletPoint}>• Početka i završetka rada</Text>
              <Text style={styles.bulletPoint}>• Pauza tijekom rada</Text>
              <Text style={styles.bulletPoint}>• Različitih kategorija radnog vremena</Text>
              <Text style={styles.bulletPoint}>• Izostanaka s posla</Text>

              <Text style={styles.heading}>4. Mogućnosti izvještavanja</Text>
              <Text style={styles.paragraph}>
                Naš sustav nudi različite opcije izvještavanja koje poslodavci mogu koristiti prema svojim potrebama:
              </Text>
              <Text style={styles.bulletPoint}>• Izvještaji o radnom vremenu zaposlenika</Text>
              <Text style={styles.bulletPoint}>• Pregled korištenja pauza</Text>
              <Text style={styles.bulletPoint}>• Statistike prisutnosti</Text>
              <Text style={styles.bulletPoint}>• Izvoz podataka u različitim formatima</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Dodatne informacije</Text>
            <Text style={styles.description}>
              Važne napomene o ulozi Clock.hr sustava u odnosu na zakonske obveze poslodavca.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Ograničenje odgovornosti</Text>
              <Text style={styles.paragraph}>
                Clock.hr ne preuzima odgovornost za:
              </Text>
              <Text style={styles.bulletPoint}>• Usklađenost poslodavca s važećim zakonima i propisima</Text>
              <Text style={styles.bulletPoint}>• Točnost podataka koje unose poslodavci ili zaposlenici</Text>
              <Text style={styles.bulletPoint}>• Pravilno tumačenje zakona i propisa od strane korisnika</Text>
              <Text style={styles.bulletPoint}>• Posljedice inspekcijskih nadzora kod poslodavca</Text>

              <Text style={styles.heading}>2. Pravni odnos</Text>
              <Text style={styles.paragraph}>
                Clock.hr je pružatelj softverske usluge, a ne:
              </Text>
              <Text style={styles.bulletPoint}>• Pravni savjetnik</Text>
              <Text style={styles.bulletPoint}>• Računovodstveni servis</Text>
              <Text style={styles.bulletPoint}>• Zastupnik poslodavca pred državnim tijelima</Text>
              <Text style={styles.bulletPoint}>• Subjekt odgovoran za poštivanje radnog zakonodavstva</Text>

              <Text style={styles.heading}>3. Preporuke korisnicima</Text>
              <Text style={styles.paragraph}>
                Za osiguravanje pune zakonske usklađenosti preporučujemo:
              </Text>
              <Text style={styles.bulletPoint}>• Redovito praćenje izmjena relevantnog zakonodavstva</Text>
              <Text style={styles.bulletPoint}>• Konzultacije s pravnim stručnjacima za specifična pitanja</Text>
              <Text style={styles.bulletPoint}>• Redovito ažuriranje internih pravilnika</Text>
              <Text style={styles.bulletPoint}>• Pravilno dokumentiranje eventualnih izuzetaka i posebnih situacija</Text>

              <Text style={styles.heading}>4. Kontinuirano unapređenje</Text>
              <Text style={styles.paragraph}>
                Clock.hr sustav kontinuirano unapređujemo kako bi sadržavao funkcionalnosti koje odgovaraju 
                trenutnim poslovnim potrebama, međutim:
              </Text>
              <Text style={styles.bulletPoint}>• Korisnici trebaju sami procijeniti jesu li dostupne funkcionalnosti dovoljne za njihove specifične potrebe</Text>
              <Text style={styles.bulletPoint}>• Nismo odgovorni za specifične zahtjeve pojedinih industrija ili poslodavaca</Text>
              <Text style={styles.bulletPoint}>• Korisnici trebaju samostalno provjeriti odgovara li sustav njihovim internim pravilnicima</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 48,
  },
  section: {
    marginBottom: 48,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 24,
    fontStyle: 'italic',
  },
  contentContainer: {
    backgroundColor: '#F8FAFC',
    padding: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginTop: 24,
    marginBottom: 12,
  },
  paragraph: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 12,
  },
  bulletPoint: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 8,
    paddingLeft: 20,
  },
});