import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const BreakSetup = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Kreiranje pauza</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Vrste pauza</Text>
            <Text style={styles.description}>
              U Clock.hr sustavu možete definirati različite vrste pauza:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Pauza za marendu/ručak</Text>
              <Text style={styles.bulletItem}>• Kratka pauza (npr. za kavu)</Text>
              <Text style={styles.bulletItem}>• Službeni izlazak</Text>
              <Text style={styles.bulletItem}>• Privatni izlazak</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Postavljanje pauza</Text>
            <Text style={styles.description}>
              Za kreiranje nove pauze slijedite ove korake:
            </Text>
            <Text style={styles.description}>
              1. U glavnom izborniku odaberite "Postavke"
            </Text>
            <Text style={styles.description}>
              2. Kliknite na "Pauze"
            </Text>
            <Text style={styles.description}>
              3. Odaberite "Dodaj novu pauzu"
            </Text>
            <Text style={styles.description}>
              4. Definirajte postavke pauze:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Naziv pauze</Text>
              <Text style={styles.bulletItem}>• Maksimalno trajanje</Text>
              <Text style={styles.bulletItem}>• Dozvoljeni broj pauza po danu</Text>
              <Text style={styles.bulletItem}>• Plaćena ili neplaćena pauza</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Dodjela pauza djelatnicima</Text>
            <Text style={styles.description}>
              Nakon kreiranja pauze, možete je dodijeliti:
            </Text>
            <View style={styles.bulletList}>
              <Text style={styles.bulletItem}>• Svim djelatnicima odjednom</Text>
              <Text style={styles.bulletItem}>• Pojedinačnim djelatnicima</Text>
              <Text style={styles.bulletItem}>• Određenim grupama djelatnika</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Važne napomene</Text>
            <View style={styles.noteBox}>
              <Text style={styles.noteText}>
                • Sustav automatski prati trajanje svake pauze
              </Text>
              <Text style={styles.noteText}>
                • Djelatnici se moraju odjaviti kada idu na pauzu i prijaviti kad se vrate
              </Text>
              <Text style={styles.noteText}>
                • Prekoračenje dozvoljenog vremena pauze se posebno bilježi
              </Text>
              <Text style={styles.noteText}>
                • Možete postaviti automatsko upozorenje za predugo trajanje pauze
              </Text>
            </View>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  content: {
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#333',
  },
  section: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
    color: '#444',
  },
  description: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  bulletList: {
    marginLeft: 20,
    marginTop: 8,
  },
  bulletItem: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8,
    color: '#555',
  },
  noteBox: {
    backgroundColor: '#f8f9fa',
    padding: 15,
    borderRadius: 8,
    borderLeftWidth: 4,
    borderLeftColor: '#007bff',
  },
  noteText: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
  },
});
