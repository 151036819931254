import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet, ScrollView, TextInput } from 'react-native';
import { Text, Button, useTheme, ActivityIndicator, Chip, DataTable } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { breaksAPI } from '../../../services/api';
import { WebBreakDetailsScreen } from './components/WebBreakDetailsScreen';
import { WebEmptyState } from '../dashboard/WebEmptyState';

interface Break {
  id: string;
  name: string;
  duration: number;
  isPaid: boolean;
  isUnlimited: boolean;
  allowReuseAfterExpiry: boolean;
  restrictTimeEnabled: boolean;
  minWorkMinutes?: number;
  isTimeRestrictionEnabled: boolean;
  restrictedHours?: number[];
  createdAt: string;
  updatedAt: string;
}

export const WebBreaksScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [breaks, setBreaks] = useState<Break[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchBreaks = async () => {
    try {
      const response = await breaksAPI.getBreaks();
      setBreaks(response);
    } catch (error) {
      console.error('Greška pri dohvaćanju pauza:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBreaks();
  }, []);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await fetchBreaks();
    setRefreshing(false);
  }, []);

  const filteredBreaks = breaks.filter(breakItem => {
    return breakItem.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleBreakPress = (breakId: string) => {
    // @ts-ignore
    navigation.navigate('BreakDetails', { breakId });
  };

  const formatDuration = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes} min`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return `${hours}h`;
    }
    return `${hours}h ${remainingMinutes}min`;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '-';
    return date.toLocaleDateString('hr-HR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        ) : breaks.length === 0 ? (
          <WebEmptyState 
            title="Nemate još dodanih pauza"
            description="Dodajte svoju prvu pauzu kako biste mogli pratiti vrijeme odmora djelatnika"
            actionText="Dodaj pauzu"
            iconName="coffee-off-outline"
            navigationRoute="CreateBreak"
          />
        ) : (
          <>
            <View style={styles.searchContainer}>
              <View style={styles.searchBarContainer}>
                <View style={styles.searchBar}>
                  <MaterialCommunityIcons name="magnify" size={20} color="#6B7280" style={styles.searchIcon} />
                  <TextInput
                    placeholder="Pretraži pauze"
                    onChangeText={(query) => setSearchQuery(query)}
                    value={searchQuery}
                    style={[styles.searchInput, { outlineStyle: 'none' }]}
                    placeholderTextColor="#6B7280"
                    autoComplete="off"
                    onFocus={() => {}}
                  />
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <Button 
                  mode="contained"
                  onPress={() => navigation.navigate('CreateBreak')}
                  style={[styles.addButton]}
                  contentStyle={styles.buttonContent}
                  labelStyle={{ color: '#FFFFFF' }}
                  buttonColor="#1F2937"
                  icon={({ size, color }) => (
                    <MaterialCommunityIcons name="coffee-plus" size={20} color={color} />
                  )}
                >
                  Dodaj pauzu
                </Button>
              </View>
            </View>

            <View style={styles.tableContainer}>
              <DataTable style={styles.table}>
                <DataTable.Header style={styles.tableHeader}>
                  <DataTable.Title style={[styles.column, styles.nameColumn]}>Naziv pauze</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.statusColumn]}>Status</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.durationColumn]}>Trajanje</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.dateColumn]}>Kreirano</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.dateColumn]}>Zadnje korištena</DataTable.Title>
                  <DataTable.Title style={[styles.column, styles.actionsColumn]}>Akcije</DataTable.Title>
                </DataTable.Header>

                {filteredBreaks.length === 0 ? (
                  <DataTable.Row key="no-results">
                    <DataTable.Cell style={styles.noResultsCell}>
                      <Text style={styles.noResultsText}>Nema pronađenih pauza</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                ) : (
                  filteredBreaks.map((breakItem, index) => (
                    <DataTable.Row 
                      key={breakItem.id} 
                      style={[styles.tableRow, index === filteredBreaks.length - 1 ? styles.lastRow : {}]}
                      onPress={() => handleBreakPress(breakItem.id)}
                    >
                      <DataTable.Cell style={[styles.column, styles.nameColumn]}>
                        <Text style={styles.breakName}>{breakItem.name}</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={[styles.column, styles.statusColumn]}>
                        <View style={styles.chipContainer}>
                          <Chip 
                            style={[styles.chip, breakItem.isPaid ? styles.paidChip : styles.unpaidChip]} 
                            textStyle={breakItem.isPaid ? styles.paidChipText : styles.unpaidChipText}
                          >
                            {breakItem.isPaid ? 'Plaćena' : 'Neplaćena'}
                          </Chip>
                        </View>
                      </DataTable.Cell>
                      <DataTable.Cell style={[styles.column, styles.durationColumn]}>
                        <Text style={styles.duration}>
                          {breakItem.isUnlimited ? 'Neograničeno' : formatDuration(breakItem.duration)}
                        </Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={[styles.column, styles.dateColumn]}>
                        <Text style={styles.dateText}>{formatDate(breakItem.createdAt)}</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={[styles.column, styles.dateColumn]}>
                        <Text style={styles.dateText}>{formatDate(breakItem.updatedAt)}</Text>
                      </DataTable.Cell>
                      <DataTable.Cell style={[styles.column, styles.actionsColumn]}>
                        <MaterialCommunityIcons
                          name="chevron-right"
                          size={24}
                          color="#000"
                        />
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))
                )}
              </DataTable>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  content: {
    flex: 1,
    padding: 24,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
    gap: 24,
  },
  searchBarContainer: {
    flex: 1,
  },
  searchBar: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    paddingHorizontal: 12,
    height: 40,
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  searchIcon: {
    marginRight: 8,
  },
  searchInput: {
    flex: 1,
    fontSize: 14,
    color: '#1F2937',
    height: '100%',
    padding: 0,
    outline: 'none',
    border: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
  },
  buttonContainer: {
    width: 200,
  },
  addButton: {
    height: 40,
    borderRadius: 8,
  },
  buttonContent: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#E5E7EB',
  },
  table: {
    backgroundColor: 'white',
    width: '100%',
  },
  tableHeader: {
    backgroundColor: '#F8FAFC',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
    minHeight: 60,
  },
  lastRow: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  column: {
    justifyContent: 'center',
    padding: 0,
  },
  nameColumn: {
    flex: 3,
  },
  statusColumn: {
    flex: 2,
    justifyContent: 'center',
  },
  durationColumn: {
    flex: 2,
  },
  dateColumn: {
    flex: 2,
  },
  actionsColumn: {
    flex: 1,
  },
  breakName: {
    color: '#1F2937',
    fontSize: 14,
  },
  chip: {
    height: 24,
    borderRadius: 12,
    justifyContent: 'center',
  },
  chipContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  paidChip: {
    backgroundColor: '#E6FFFA',
  },
  unpaidChip: {
    backgroundColor: '#FFF5F5',
  },
  paidChipText: {
    color: '#2C7A7B',
    fontSize: 12,
  },
  unpaidChipText: {
    color: '#C53030',
    fontSize: 12,
  },
  duration: {
    color: '#1F2937',
    fontSize: 14,
  },
  dateText: {
    color: '#1F2937',
    fontSize: 14,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsCell: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 24,
  },
  noResultsText: {
    color: '#718096',
    fontSize: 16,
  },
});
