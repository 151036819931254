import React from 'react';
import { View, Text, StyleSheet, useWindowDimensions } from 'react-native';
import { HelpLayout } from '../components/HelpLayout';
import { Ionicons } from '@expo/vector-icons';

interface GuideSection {
  id: string;
  title: string;
  description: string;
  content: string[];
  code?: string;
}

const GuideItem = ({ title, description, content, code }: Omit<GuideSection, 'id'>) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <View style={styles.guideItem}>
      <Text style={styles.guideTitle}>{title}</Text>
      <Text style={styles.guideDescription}>{description}</Text>
      
      <View style={styles.guideContent}>
        {content.map((text, index) => (
          <View key={index} style={styles.contentItem}>
            <Text style={styles.contentText}>{text}</Text>
          </View>
        ))}
      </View>

      {code && (
        <View style={styles.codeBlock}>
          <Text style={styles.codeText}>{code}</Text>
        </View>
      )}
    </View>
  );
};

export const Guide = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const guideSections: GuideSection[] = [
    {
      id: 'prijava',
      title: 'Prijava u sustav',
      description: 'Za pristup Clock.hr sustavu potrebno je imati valjane pristupne podatke. Evo kako se možete prijaviti:',
      content: [
        'Otvorite web preglednik i posjetite www.clock.hr/login',
        'Unesite svoju email adresu i lozinku u za to predviđena polja',
        'Kliknite na gumb "Prijava" za ulazak u sustav'
      ],
      code: 'https://www.clock.hr/login'
    },
    {
      id: 'evidencija',
      title: 'Evidencija radnog vremena',
      description: 'Nakon uspješne prijave, možete započeti s evidencijom radnog vremena. Sustav nudi nekoliko osnovnih funkcija:',
      content: [
        'Na početnom zaslonu odaberite "Prijava dolaska"',
        'Sustav će automatski zabilježiti vašu lokaciju',
        'Za odlazak s posla, odaberite "Odjava odlaska"'
      ],
      code: 'Radno vrijeme: 08:00 - 16:00\nPauza: 30 minuta\nLokacija: Automatska detekcija'
    },
    {
      id: 'pauze',
      title: 'Upravljanje pauzama',
      description: 'Clock.hr omogućuje precizno praćenje pauza tijekom radnog dana:',
      content: [
        'Kliknite na gumb "Nova pauza" kada započinjete pauzu',
        'Odaberite vrstu pauze (ručak, kratka pauza, itd.)',
        'Po povratku kliknite "Završi pauzu"'
      ],
      code: 'Vrste pauza:\n- Pauza za ručak (30 min)\n- Kratka pauza (15 min)\n- Službeni izlazak'
    },
    {
      id: 'izvjestaji',
      title: 'Pregled izvještaja',
      description: 'Pristupite detaljnim izvještajima o radnom vremenu:',
      content: [
        'U glavnom izborniku odaberite "Izvještaji"',
        'Filtrirajte po željenom vremenskom razdoblju',
        'Izvezite podatke u željenom formatu'
      ],
      code: 'Formati izvoza:\n- PDF (mjesečni izvještaj)\n- Excel (detaljni pregled)\n- CSV (sirovi podaci)'
    }
  ];

  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.mainTitle}>Početak rada</Text>
          <Text style={styles.mainDescription}>
            Dobrodošli u Clock.hr sustav. Ovdje ćete pronaći detaljne upute za postavljanje i korištenje sustava.
          </Text>
          
          {guideSections.map((section) => (
            <GuideItem key={section.id} {...section} />
          ))}
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
  },
  mainTitle: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  mainDescription: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 48,
  },
  guideItem: {
    marginBottom: 48,
    paddingBottom: 32,
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  guideTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  guideDescription: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 24,
  },
  guideContent: {
    marginBottom: 24,
  },
  contentItem: {
    marginBottom: 12,
  },
  contentText: {
    fontSize: 16,
    lineHeight: 24,
    color: '#475569',
  },
  codeBlock: {
    backgroundColor: '#1E293B',
    padding: 16,
    borderRadius: 8,
    marginTop: 16,
  },
  codeText: {
    fontFamily: 'monospace',
    fontSize: 14,
    lineHeight: 20,
    color: '#E2E8F0',
    whiteSpace: 'pre-wrap',
  }
});
