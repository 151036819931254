import React from 'react';
import { View, Text, StyleSheet, ScrollView, Pressable } from 'react-native';
import { HelpLayout } from '../components/HelpLayout';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';

interface Section {
  title: string;
  description: string;
  route: string;
  icon: string;
}

const sections: Section[] = [
  {
    title: 'Zaštita podataka',
    description: 'Politika privatnosti i upravljanje kolačićima',
    route: 'legal/privacy',
    icon: 'shield-checkmark-outline'
  },
  {
    title: 'Uvjeti korištenja',
    description: 'Opći uvjeti poslovanja i prava korištenja',
    route: 'legal/terms',
    icon: 'document-text-outline'
  },
  {
    title: 'Zakonska usklađenost',
    description: 'Usklađenost sa Zakonom o radu i drugim propisima',
    route: 'legal/compliance',
    icon: 'checkmark-circle-outline'
  },
  {
    title: 'Sigurnost',
    description: 'Sigurnosne mjere i protokoli za zaštitu podataka',
    route: 'legal/security',
    icon: 'lock-closed-outline'
  }
];

export const LegalDocuments = () => {
  const navigation = useNavigation();

  return (
    <HelpLayout>
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Pravni dokumenti</Text>
          <Text style={styles.subtitle}>
            Ovdje možete pronaći sve pravne dokumente vezane uz Clock.hr sustav. Dokumenti su redovito 
            ažurirani u skladu s važećim zakonima i propisima Republike Hrvatske.
          </Text>

          <View style={styles.grid}>
            {sections.map((section, index) => (
              <Pressable
                key={index}
                style={styles.card}
                onPress={() => navigation.navigate(section.route)}
              >
                <View style={styles.cardHeader}>
                  <Ionicons name={section.icon} size={24} color="#2563EB" />
                  <Text style={styles.cardTitle}>{section.title}</Text>
                </View>
                <Text style={styles.cardDescription}>{section.description}</Text>
                <View style={styles.cardFooter}>
                  <Text style={styles.readMore}>Pročitaj više</Text>
                  <Ionicons name="arrow-forward" size={16} color="#2563EB" />
                </View>
              </Pressable>
            ))}
          </View>
        </View>
      </ScrollView>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 48,
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -12,
  },
  card: {
    width: 'calc(50% - 24px)',
    marginHorizontal: 12,
    marginBottom: 24,
    padding: 24,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
    cursor: 'pointer',
  },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1E293B',
    marginLeft: 12,
  },
  cardDescription: {
    fontSize: 15,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 16,
  },
  cardFooter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  readMore: {
    fontSize: 14,
    fontWeight: '500',
    color: '#2563EB',
    marginRight: 8,
  }
});
