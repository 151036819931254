import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const Registration = () => {
  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Registracija i prijava</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Registracija</Text>
            <Text style={styles.description}>
              Za početak korištenja Clock.hr sustava, potrebno je registrirati vašu tvrtku:
            </Text>
            <View style={styles.steps}>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>1.</Text>
                <Text style={styles.stepText}>
                  Posjetite app.clock.hr i kliknite na "Registracija"
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>2.</Text>
                <Text style={styles.stepText}>
                  Unesite podatke vaše tvrtke (naziv, OIB, adresa)
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>3.</Text>
                <Text style={styles.stepText}>
                  Postavite administratorski račun (email i lozinka)
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Prijava</Text>
            <Text style={styles.description}>
              Nakon uspješne registracije, možete se prijaviti u sustav:
            </Text>
            <View style={styles.steps}>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>1.</Text>
                <Text style={styles.stepText}>
                  Otvorite app.clock.hr
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>2.</Text>
                <Text style={styles.stepText}>
                  Unesite vašu email adresu i lozinku
                </Text>
              </View>
              <View style={styles.step}>
                <Text style={styles.stepNumber}>3.</Text>
                <Text style={styles.stepText}>
                  Kliknite na "Prijava"
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.codeBlock}>
            <Text style={styles.codeTitle}>Važne napomene:</Text>
            <Text style={styles.codeText}>
              • Lozinka mora sadržavati minimalno 8 znakova{'\n'}
              • Preporučamo korištenje jake lozinke (kombinacija slova, brojeva i znakova){'\n'}
              • Email adresa mora biti valjana i aktivna{'\n'}
              • Nakon registracije primit ćete potvrdni email
            </Text>
          </View>
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 24,
  },
  section: {
    marginBottom: 32,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 16,
  },
  steps: {
    marginTop: 16,
  },
  step: {
    flexDirection: 'row',
    marginBottom: 12,
    alignItems: 'flex-start',
  },
  stepNumber: {
    width: 24,
    fontSize: 16,
    fontWeight: '600',
    color: '#4C6FFF',
  },
  stepText: {
    flex: 1,
    fontSize: 16,
    color: '#475569',
    lineHeight: 24,
  },
  codeBlock: {
    backgroundColor: '#F8FAFC',
    padding: 16,
    borderRadius: 8,
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  codeTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 12,
  },
  codeText: {
    fontFamily: 'monospace',
    fontSize: 14,
    lineHeight: 20,
    color: '#475569',
  },
});
