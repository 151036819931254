import React, { useState, useRef, useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useTheme } from 'react-native-paper';

interface CustomTimePickerProps {
  value: Date;
  onChange: (date: Date) => void;
  minTime?: Date;
  maxTime?: Date;
}

export const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  value,
  onChange,
  minTime,
  maxTime
}) => {
  const theme = useTheme();
  const [selectedHour, setSelectedHour] = useState(value.getHours());
  const [selectedMinute, setSelectedMinute] = useState(value.getMinutes());

  const generateTimeOptions = (start: number, end: number) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i.toString().padStart(2, '0'));
    }
    return options;
  };

  const getValidHours = () => {
    let start = 0;
    let end = 23;

    if (minTime) {
      start = minTime.getHours();
    }
    if (maxTime) {
      end = maxTime.getHours();
    }

    return generateTimeOptions(start, end);
  };

  const getValidMinutes = (hour: number) => {
    let start = 0;
    let end = 59;

    if (minTime && hour === minTime.getHours()) {
      start = minTime.getMinutes();
    }
    if (maxTime && hour === maxTime.getHours()) {
      end = maxTime.getMinutes();
    }

    return generateTimeOptions(start, end);
  };

  const handleTimeSelect = (type: 'hour' | 'minute', selectedValue: number) => {
    const newDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());
    if (type === 'hour') {
      setSelectedHour(selectedValue);
      newDate.setHours(selectedValue, selectedMinute);
    } else {
      setSelectedMinute(selectedValue);
      newDate.setHours(selectedHour, selectedValue);
    }
    onChange(newDate);
  };

  const TimeColumn = ({ 
    values, 
    selected, 
    onSelect, 
    label 
  }: { 
    values: string[], 
    selected: number, 
    onSelect: (value: number) => void,
    label: string 
  }) => {
    const scrollViewRef = useRef<ScrollView>(null);
    
    useEffect(() => {
      // Čekamo da se komponenta renderira
      setTimeout(() => {
        // Računamo poziciju za scroll
        const selectedIndex = values.findIndex(v => parseInt(v) === selected);
        if (selectedIndex !== -1 && scrollViewRef.current) {
          scrollViewRef.current.scrollTo({
            y: selectedIndex * 44,
            animated: true
          });
        }
      }, 100);
    }, []);

    return (
      <View style={styles.columnContainer}>
        <Text style={[styles.label, { color: theme.colors.primary }]}>{label}</Text>
        <View style={[styles.pickerContainer, { borderColor: theme.colors.primary }]}>
          <ScrollView 
            ref={scrollViewRef}
            style={styles.picker}
            showsVerticalScrollIndicator={false}
            snapToInterval={44}
            decelerationRate="fast"
          >
            <View style={styles.pickerTopSpace} />
            {values.map((value) => {
              const numValue = parseInt(value);
              const isSelected = numValue === selected;
              return (
                <TouchableOpacity
                  key={value}
                  style={[
                    styles.pickerItem,
                    isSelected && { backgroundColor: theme.colors.primaryContainer }
                  ]}
                  onPress={() => onSelect(numValue)}
                >
                  <Text style={[
                    styles.pickerItemText,
                    isSelected && { color: theme.colors.primary, fontWeight: 'bold' }
                  ]}>
                    {value}
                  </Text>
                </TouchableOpacity>
              );
            })}
            <View style={styles.pickerBottomSpace} />
          </ScrollView>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.timeDisplay}>
        <Text style={[styles.timeDisplayText, { color: theme.colors.primary }]}>
          {selectedHour.toString().padStart(2, '0')}:{selectedMinute.toString().padStart(2, '0')}
        </Text>
      </View>
      
      <View style={styles.columnsContainer}>
        <TimeColumn
          values={getValidHours()}
          selected={selectedHour}
          onSelect={(value) => handleTimeSelect('hour', value)}
          label="Sati"
        />
        <TimeColumn
          values={getValidMinutes(selectedHour)}
          selected={selectedMinute}
          onSelect={(value) => handleTimeSelect('minute', value)}
          label="Minute"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center'
  },
  timeDisplay: {
    marginBottom: 20,
    padding: 8,
    borderRadius: 8,
    backgroundColor: '#f5f5f5'
  },
  timeDisplayText: {
    fontSize: 32,
    fontWeight: 'bold'
  },
  columnsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%'
  },
  columnContainer: {
    alignItems: 'center'
  },
  label: {
    fontSize: 14,
    fontWeight: '500',
    marginBottom: 8
  },
  pickerContainer: {
    height: 176,
    width: 70,
    borderRadius: 8,
    borderWidth: 1,
    overflow: 'hidden'
  },
  picker: {
    flex: 1
  },
  pickerTopSpace: {
    height: 66
  },
  pickerBottomSpace: {
    height: 66
  },
  pickerItem: {
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginHorizontal: 4
  },
  pickerItemText: {
    fontSize: 20
  }
});
