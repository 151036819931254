import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Card, DataTable, useTheme, Button } from 'react-native-paper';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import { hr } from 'date-fns/locale';
import { useQuery } from '@tanstack/react-query';
import { DateFilters } from '../../employees/components/admin-controls';
import { WebLocationStatsPDF } from './WebLocationStatsPDF';
import axios from 'axios';
import { API_URL } from '../../../../config';
import { useAuth } from '../../../../hooks/useAuth';

interface Props {
  locationId: string;
}

interface EmployeeStats {
  employeeId: string;
  employeeName: string;
  totalWorkMinutes: number;
  effectiveWorkMinutes: number;
  totalBreakMinutes: number;
  paidTime: number;
  totalPaidBreakMinutes: number;
  totalUnpaidBreakMinutes: number;
  overPaidBreakMinutes: number;
  overUnpaidBreakMinutes: number;
  earnings: number;
  deductions: number;
  days: Array<{
    date: string;
    totalWorkMinutes: number;
    effectiveWorkMinutes: number;
    totalBreakMinutes: number;
    paidTime: number;
    totalPaidBreakMinutes: number;
    totalUnpaidBreakMinutes: number;
    overPaidBreakMinutes: number;
    overUnpaidBreakMinutes: number;
    earnings: number;
    deductions: number;
  }>;
}

interface LocationStatsResponse {
  locationId: string;
  locationName: string;
  startDate: string;
  endDate: string;
  employees: EmployeeStats[];
}

const formatDuration = (minutes: number) => {
  if (minutes === 0) return '0min';
  
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes % 1) * 60);
  
  let result = '';
  if (hours > 0) result += `${hours}h `;
  if (mins > 0 || hours > 0) result += `${mins}min `;
  if (secs > 0 || (minutes > 0 && minutes < 1)) result += `${secs}s`;
  
  return result.trim();
};

const formatMoney = (amount: number) => {
  return `${amount.toFixed(2)} €`;
};

// API za dohvaćanje statistika lokacije
const locationStatsAPI = {
  getLocationEmployeesStats: async (locationId: string, startDate: string, endDate: string, token: string) => {
    try {
      const response = await axios.get(`${API_URL}/location-stats/${locationId}/employees/stats`, {
        params: { startDate, endDate },
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Greška pri dohvaćanju statistike lokacije:', error);
      throw error;
    }
  }
};

export const WebLocationStats: React.FC<Props> = ({ locationId }) => {
  const theme = useTheme();
  const { token } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState({
    start: startOfWeek(selectedDate, { locale: hr }),
    end: endOfWeek(selectedDate, { locale: hr })
  });

  const quickFilters = [
    { label: 'Ovaj tjedan', date: startOfWeek(new Date(), { locale: hr }) },
    { label: 'Prošli tjedan', date: startOfWeek(new Date(), { locale: hr }) },
    { label: 'Ovaj mjesec', date: startOfMonth(new Date()) },
    { label: 'Prošli mjesec', date: startOfMonth(new Date()) },
  ];

  const handleQuickFilter = (filter: string, date: Date) => {
    setSelectedFilter(filter);
    setSelectedDate(date);

    let start, end;
    switch (filter) {
      case 'Ovaj tjedan':
        start = startOfWeek(new Date(), { locale: hr });
        end = endOfWeek(new Date(), { locale: hr });
        break;
      case 'Prošli tjedan':
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7);
        start = startOfWeek(lastWeek, { locale: hr });
        end = endOfWeek(lastWeek, { locale: hr });
        break;
      case 'Ovaj mjesec':
        start = startOfMonth(new Date());
        end = endOfMonth(new Date());
        break;
      case 'Prošli mjesec':
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        start = startOfMonth(lastMonth);
        end = endOfMonth(lastMonth);
        break;
      default:
        start = startOfWeek(date, { locale: hr });
        end = endOfWeek(date, { locale: hr });
    }
    
    setDateRange({ start, end });
  };

  const { data: locationStats, isLoading } = useQuery<LocationStatsResponse>({
    queryKey: ['locationStats', locationId, format(dateRange.start, 'yyyy-MM-dd'), format(dateRange.end, 'yyyy-MM-dd')],
    queryFn: () => locationStatsAPI.getLocationEmployeesStats(
      locationId,
      format(dateRange.start, 'yyyy-MM-dd'),
      format(dateRange.end, 'yyyy-MM-dd'),
      token || ''
    ),
    enabled: !!token && !!locationId
  });

  // Filtriraj zaposlenike koji imaju barem neke podatke (nisu sve nule)
  const filteredEmployees = locationStats?.employees.filter(employee => 
    employee.totalWorkMinutes > 0 || 
    employee.effectiveWorkMinutes > 0 || 
    employee.totalBreakMinutes > 0 || 
    employee.paidTime > 0 || 
    employee.earnings > 0
  ) || [];

  // Izračunaj ukupno prekoračenje za svakog zaposlenika
  const employeesWithTotalOverage = filteredEmployees.map(employee => ({
    ...employee,
    totalOverBreakMinutes: (employee.overPaidBreakMinutes || 0) + (employee.overUnpaidBreakMinutes || 0),
    deductions: employee.deductions || 0
  }));

  // Izračunaj ukupne statistike za period
  const periodTotals = employeesWithTotalOverage.reduce((acc, employee) => ({
    totalWorkMinutes: acc.totalWorkMinutes + (employee.totalWorkMinutes || 0),
    effectiveWorkMinutes: acc.effectiveWorkMinutes + (employee.effectiveWorkMinutes || 0),
    totalBreakMinutes: acc.totalBreakMinutes + (employee.totalBreakMinutes || 0),
    paidTime: acc.paidTime + (employee.paidTime || 0),
    totalPaidBreakMinutes: acc.totalPaidBreakMinutes + (employee.totalPaidBreakMinutes || 0),
    totalUnpaidBreakMinutes: acc.totalUnpaidBreakMinutes + (employee.totalUnpaidBreakMinutes || 0),
    overPaidBreakMinutes: acc.overPaidBreakMinutes + (employee.overPaidBreakMinutes || 0),
    overUnpaidBreakMinutes: acc.overUnpaidBreakMinutes + (employee.overUnpaidBreakMinutes || 0),
    totalOverBreakMinutes: acc.totalOverBreakMinutes + employee.totalOverBreakMinutes,
    earnings: acc.earnings + (employee.earnings || 0),
    deductions: acc.deductions + (employee.deductions || 0)
  }), {
    totalWorkMinutes: 0,
    effectiveWorkMinutes: 0,
    totalBreakMinutes: 0,
    paidTime: 0,
    totalPaidBreakMinutes: 0,
    totalUnpaidBreakMinutes: 0,
    overPaidBreakMinutes: 0,
    overUnpaidBreakMinutes: 0,
    totalOverBreakMinutes: 0,
    earnings: 0,
    deductions: 0
  });

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Učitavanje...</Text>
      </View>
    );
  }

  if (!locationStats || !locationStats.employees || locationStats.employees.length === 0) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Nema podataka za odabrani period</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Card style={styles.tableCard}>
        <Card.Title title={`Statistika za lokaciju: ${locationStats.locationName}`} />
        <Card.Content>
          <View style={styles.filterContainer}>
            <View style={styles.filterRow}>
              <DateFilters
                displayDate={selectedDate}
                onDateChange={setSelectedDate}
                tempDate={selectedDate}
                onTempDateChange={setSelectedDate}
                showDatePicker={false}
                onToggleDatePicker={() => {}}
                quickFilters={quickFilters}
                selectedFilter={selectedFilter}
                onQuickFilterSelect={handleQuickFilter}
                hideCalendar={true}
              />
              {locationStats && !isLoading && (
                <WebLocationStatsPDF
                  locationId={locationId}
                  startDate={dateRange.start}
                  endDate={dateRange.end}
                  locationStats={{
                    ...locationStats,
                    employees: employeesWithTotalOverage
                  }}
                />
              )}
            </View>
          </View>
          
          <DataTable>
            <DataTable.Header style={styles.tableHeader}>
              <DataTable.Title style={[styles.nameColumn, styles.headerCell]}>
                <Text style={styles.headerText}>Zaposlenik</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Ukupno{'\n'}rad</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Efektivno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Plaćeno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Pauze{'\n'}ukupno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Plaćene{'\n'}pauze</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Neplaćene{'\n'}pauze</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Preko.{'\n'}plaćenih</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Preko.{'\n'}neplaćenih</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Preko.{'\n'}ukupno</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Odbitak</Text>
              </DataTable.Title>
              <DataTable.Title numeric style={[styles.dataCell, styles.headerCell]}>
                <Text style={styles.headerText}>Zarada</Text>
              </DataTable.Title>
            </DataTable.Header>

            {employeesWithTotalOverage.map((employee, index) => (
              <DataTable.Row key={employee.employeeId || index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                <DataTable.Cell style={styles.nameColumn}>
                  <Text>{employee.employeeName}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatDuration(employee.totalWorkMinutes || 0)}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatDuration(employee.effectiveWorkMinutes || 0)}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatDuration(employee.paidTime || 0)}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatDuration(employee.totalBreakMinutes || 0)}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatDuration(employee.totalPaidBreakMinutes || 0)}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatDuration(employee.totalUnpaidBreakMinutes || 0)}</Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text style={employee.overPaidBreakMinutes > 0 ? styles.warning : undefined}>
                    {formatDuration(employee.overPaidBreakMinutes || 0)}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text style={employee.overUnpaidBreakMinutes > 0 ? styles.warning : undefined}>
                    {formatDuration(employee.overUnpaidBreakMinutes || 0)}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text style={employee.totalOverBreakMinutes > 0 ? styles.warning : undefined}>
                    {formatDuration(employee.totalOverBreakMinutes || 0)}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text style={employee.deductions > 0 ? styles.warning : undefined}>
                    {formatMoney(employee.deductions || 0)}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell numeric style={styles.dataCell}>
                  <Text>{formatMoney(employee.earnings || 0)}</Text>
                </DataTable.Cell>
              </DataTable.Row>
            ))}

            {/* Ukupno redak */}
            <DataTable.Row style={styles.totalRow}>
              <DataTable.Cell style={styles.nameColumn}>
                <Text style={styles.totalText}>UKUPNO</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.totalWorkMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.effectiveWorkMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.paidTime || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.totalBreakMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.totalPaidBreakMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.totalUnpaidBreakMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.overPaidBreakMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.overUnpaidBreakMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatDuration(periodTotals?.totalOverBreakMinutes || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatMoney(periodTotals?.deductions || 0)}</Text>
              </DataTable.Cell>
              <DataTable.Cell numeric style={styles.dataCell}>
                <Text style={styles.totalText}>{formatMoney(periodTotals?.earnings || 0)}</Text>
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>
        </Card.Content>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  tableCard: {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#E2E8F0',
    borderRadius: 0,
    overflow: 'hidden',
    elevation: 0,
  },
  filterContainer: {
    marginBottom: 16,
  },
  filterRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
  },
  tableHeader: {
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  headerCell: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: 12,
  },
  headerText: {
    fontSize: 12,
    textAlign: 'center',
    color: '#212529', // Tamno siva boja za tekst zaglavlja
  },
  nameColumn: {
    flex: 2,
    justifyContent: 'flex-start',
  },
  dataCell: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 2,
  },
  evenRow: {
    backgroundColor: '#FFFFFF',
  },
  oddRow: {
    backgroundColor: '#FFFFFF', // Svijetlo siva za neparne retke
  },
  totalRow: {
    backgroundColor: '#FFFFFF', // Srednje siva za ukupni redak
  },
  totalText: {
    color: '#212529', // Tamno siva boja za tekst ukupno
  },
  warning: {
    color: '#DC3545', // Crvena boja za upozorenja
    fontWeight: '600',
  },
});
