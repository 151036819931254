import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Platform, Modal, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { Text, useTheme, IconButton, Button, FAB, ActivityIndicator } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { logsAPI, LogEntry } from '../../services/api/logsAPI';
import { isToday, format, subDays } from 'date-fns';
import { hr } from 'date-fns/locale';
import DateTimePicker from '@react-native-community/datetimepicker';

const getActionText = (action: LogEntry['action'], breakName?: string) => {
  switch (action) {
    case 'login':
      return 'Prijava u smjenu';
    case 'logout':
      return 'Odjava iz smjene';
    case 'break_start':
      return `Odlazak na pauzu${breakName ? ` (${breakName})` : ''}`;
    case 'break_end':
      return `Povratak s pauze${breakName ? ` (${breakName})` : ''}`;
    case 'admin_checkin':
      return 'Admin: Ručna prijava u smjenu';
    case 'admin_checkout':
      return 'Admin: Ručna odjava iz smjene';
    case 'admin_break_start':
      return `Admin: Ručni početak pauze${breakName ? ` (${breakName})` : ''}`;
    case 'admin_break_end':
      return `Admin: Ručni završetak pauze${breakName ? ` (${breakName})` : ''}`;
    case 'admin_edit_session':
      return 'Admin: Uređivanje vremena sesije';
    case 'admin_edit_break':
      return `Admin: Uređivanje vremena pauze${breakName ? ` (${breakName})` : ''}`;
  }
};

const getActionIcon = (action: LogEntry['action']) => {
  switch (action) {
    case 'login':
      return 'log-in';
    case 'logout':
      return 'log-out';
    case 'break_start':
      return 'cafe';
    case 'break_end':
      return 'return-up-back';
    case 'admin_checkin':
      return 'add-circle';
    case 'admin_checkout':
      return 'close-circle';
    case 'admin_break_start':
    case 'admin_break_end':
      return 'timer';
    case 'admin_edit_session':
    case 'admin_edit_break':
      return 'create';
  }
};

const getActionColor = (action: LogEntry['action']) => {
  switch (action) {
    case 'login':
      return '#4CAF50'; // zelena
    case 'logout':
      return '#F44336'; // crvena
    case 'break_start':
    case 'break_end':
      return '#FF9800'; // narančasta
    case 'admin_checkin':
    case 'admin_checkout':
    case 'admin_break_start':
    case 'admin_break_end':
    case 'admin_edit_session':
    case 'admin_edit_break':
      return '#673AB7'; // ljubičasta za admin akcije
  }
};

const formatDate = (date: Date) => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
};

const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('hr-HR', { hour: '2-digit', minute: '2-digit' });
};

export const LocationLogsScreen = ({ route, navigation }) => {
  const { colors } = useTheme();
  const { location } = route.params;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tempDate, setTempDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleSaveDate = (date: Date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
    fetchLogs(date);
  };

  const handleDatePress = () => {
    setTempDate(new Date(selectedDate));
    setShowDatePicker(true);
  };

  const handleDateChange = (event: any, selectedDate?: Date) => {
    if (Platform.OS === 'android') {
      setShowDatePicker(false);
    }
    
    if (selectedDate) {
      setTempDate(selectedDate);
      
      if (Platform.OS === 'android') {
        handleSaveDate(selectedDate);
      }
    }
  };

  const fetchLogs = async (date: Date) => {
    try {
      setLoading(true);
      setError(null);
      const data = await logsAPI.getLocationLogs(location._id, date);
      setLogs(data);
    } catch (err) {
      console.error('Greška pri dohvaćanju logova:', err);
      setError('Došlo je do greške pri dohvaćanju logova');
    } finally {
      setLoading(false);
    }
  };

  // Dohvati današnje logove pri učitavanju
  useEffect(() => {
    const today = new Date();
    setSelectedDate(today);
    fetchLogs(today);
  }, []);

  // Osvježavaj logove svakih 30 sekundi
  useEffect(() => {
    const interval = setInterval(() => {
      if (isToday(selectedDate)) {
        fetchLogs(selectedDate);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [selectedDate]);

  const isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  return (
    <SafeAreaView style={styles.safeArea} edges={['top']}>
      <SafeAreaView style={styles.safeArea} edges={['left', 'right']}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={24} color="#000" />
            <Text style={styles.backText}>Natrag</Text>
          </TouchableOpacity>
          <Text style={styles.headerTitle}>{location.name}</Text>
          <TouchableOpacity 
            onPress={() => fetchLogs(selectedDate)} 
            style={styles.refreshButton}
            disabled={loading}
          >
            {loading ? (
              <ActivityIndicator size="small" color="#007AFF" />
            ) : (
              <Ionicons name="refresh" size={24} color="#007AFF" />
            )}
          </TouchableOpacity>
        </View>

        <View style={styles.container}>
          {loading ? (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color={colors.primary} />
            </View>
          ) : error ? (
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}>{error}</Text>
              <Button 
                mode="contained" 
                onPress={() => fetchLogs(selectedDate)}
                style={styles.retryButton}
              >
                Pokušaj ponovno
              </Button>
            </View>
          ) : logs.length === 0 ? (
            <View style={styles.emptyStateContainer}>
              <View style={styles.emptyStateContent}>
                <Text style={styles.emptyStateText}>
                  Nema dostupnih logova
                </Text>
                <Text style={styles.emptyStateSubtext}>
                  Za odabrani datum nema zabilježenih aktivnosti djelatnika
                </Text>
              </View>
            </View>
          ) : (
            <ScrollView style={styles.scrollView}>
              {logs.map((log) => (
                <View style={styles.logItem} key={log.id}>
                  <View style={styles.logIconContainer}>
                    <View style={[styles.logIcon, { backgroundColor: getActionColor(log.action) }]}>
                      <Ionicons name={getActionIcon(log.action)} size={20} color="#fff" />
                    </View>
                  </View>
                  <View style={styles.logContent}>
                    <View style={styles.logAction}>
                      <Ionicons 
                        name={getActionIcon(log.action)} 
                        size={20} 
                        color={getActionColor(log.action)} 
                        style={styles.actionIcon}
                      />
                      <Text style={[styles.actionText, { color: getActionColor(log.action) }]}>
                        {getActionText(log.action, log.breakName)}
                      </Text>
                    </View>
                    <Text style={styles.logEmployeeName}>{log.employeeName}</Text>
                    {log.actionBy && (
                      <Text style={styles.logActionBy}>Započeo: {log.actionBy}</Text>
                    )}
                    {log.endedBy && (
                      <Text style={styles.logEndedBy}>Završio: {log.endedBy}</Text>
                    )}
                    {log.startedBy && log.actionBy && log.startedBy !== log.actionBy && (
                      <Text style={styles.logStartedBy}>Započeo: {log.startedBy}</Text>
                    )}
                    {log.originalTime && (
                      <Text style={styles.logOriginalTime}>Izvorno vrijeme: {formatTime(log.originalTime)}</Text>
                    )}
                    <Text style={styles.logTimestamp}>{formatTime(log.timestamp)}</Text>
                  </View>
                </View>
              ))}
            </ScrollView>
          )}
        </View>
        <View style={styles.dateContainer}>
          <TouchableOpacity onPress={handleDatePress} style={styles.dateButton}>
            <MaterialIcons name="date-range" size={24} color="#333" />
            <Text style={styles.dateText}>{format(selectedDate, 'dd. MMMM yyyy.', { locale: hr })}</Text>
          </TouchableOpacity>
        </View>
        {showDatePicker && (
          Platform.OS === 'ios' ? (
            <Modal
              visible={showDatePicker}
              transparent={true}
              animationType="slide"
            >
              <TouchableWithoutFeedback onPress={handleCancel}>
                <View style={styles.modalBackground}>
                  <View style={styles.modalContainer}>
                    <Text style={styles.modalTitle}>Odaberi datum</Text>
                    <DateTimePicker
                      value={tempDate}
                      mode="date"
                      display="spinner"
                      onChange={handleDateChange}
                      style={{ width: 320, height: 180 }}
                      locale="hr"
                    />
                    <View style={styles.buttonContainer}>
                      <Button 
                        mode="outlined" 
                        onPress={handleCancel}
                        style={{ marginRight: 10 }}
                      >
                        Odustani
                      </Button>
                      <Button 
                        mode="contained" 
                        onPress={() => handleSaveDate(tempDate)}
                      >
                        Potvrdi
                      </Button>
                    </View>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </Modal>
          ) : (
            <DateTimePicker
              value={tempDate}
              mode="date"
              display="default"
              onChange={handleDateChange}
            />
          )
        )}
      </SafeAreaView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E5E5',
    backgroundColor: '#fff',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backText: {
    fontSize: 16,
    marginLeft: 4,
    color: '#000',
  },
  headerTitle: {
    fontSize: 20,
  },
  refreshButton: {
    padding: 5,
  },
  dateContainer: {
    position: 'absolute',
    bottom: 80,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  dateButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRadius: 25,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  dateText: {
    marginLeft: 8,
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
  },
  scrollView: {
    flex: 1,
  },
  logItem: {
    backgroundColor: '#fff',
    padding: 16,
    marginHorizontal: 16,
    marginBottom: 8,
    borderRadius: 8,
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  logIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  logIcon: {
    width: 24,
    height: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logContent: {
    flex: 1,
  },
  logAction: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  actionIcon: {
    marginRight: 8,
  },
  actionText: {
    fontSize: 16,
  },
  logText: {
    fontSize: 16,
    color: '#333',
  },
  logEmployeeName: {
    fontSize: 14,
    color: '#666',
  },
  logActionBy: {
    fontSize: 13,
    color: '#673AB7',
    fontStyle: 'italic',
  },
  logStartedBy: {
    fontSize: 13,
    color: '#673AB7',
    fontStyle: 'italic',
  },
  logEndedBy: {
    fontSize: 13,
    color: '#673AB7',
    fontStyle: 'italic',
  },
  logOriginalTime: {
    fontSize: 12,
    color: '#999',
    fontStyle: 'italic',
  },
  logTimestamp: {
    fontSize: 13,
    color: '#666',
    marginTop: 2,
  },
  emptyStateContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyStateContent: {
    width: '100%',
    paddingHorizontal: 24,
    alignItems: 'center',
    marginTop: -30,
  },
  emptyStateText: {
    fontSize: 20,
    fontWeight: '600',
    color: '#37474F',
    textAlign: 'center',
    marginBottom: 8,
  },
  emptyStateSubtext: {
    fontSize: 16,
    color: '#78909C',
    textAlign: 'center',
    marginBottom: 32,
    paddingHorizontal: 20,
    fontWeight: 'normal',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  errorText: {
    fontSize: 16,
    color: '#ff3b30',
    textAlign: 'center',
    marginBottom: 20,
  },
  retryButton: {
    marginTop: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    elevation: 10,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
});
