import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { HelpLayout } from '../../components/HelpLayout';

export const Security = () => {
  return (
    <HelpLayout>
      <ScrollView style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Sigurnost</Text>
          
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Sigurnosna politika</Text>
            <Text style={styles.description}>
              Opis sigurnosnih mjera i pristupa koji se koristi za zaštitu podataka unutar Clock.hr platforme.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Naš pristup sigurnosti</Text>
              <Text style={styles.paragraph}>
                Clock.hr ozbiljno pristupa sigurnosti podataka u skladu s mogućnostima i obimom naše usluge. 
                Primjenjujemo razumne sigurnosne mjere za zaštitu podataka koji se pohranjuju kroz našu platformu, 
                no važno je razumjeti raspodjelu odgovornosti za sigurnost podataka.
              </Text>

              <Text style={styles.heading}>2. Podjela odgovornosti</Text>
              <Text style={styles.paragraph}>
                Sigurnost podataka je zajednička odgovornost, pri čemu:
              </Text>
              <Text style={styles.bulletPoint}>• Clock.hr je odgovoran za implementaciju razumnih sigurnosnih kontrola na platformi</Text>
              <Text style={styles.bulletPoint}>• Korisnici (poslodavci) su odgovorni za sigurnost vlastitih uređaja i pristupnih podataka</Text>
              <Text style={styles.bulletPoint}>• Korisnici su odgovorni za definiranje i upravljanje pristupnim pravima svojih zaposlenika</Text>
              <Text style={styles.bulletPoint}>• Korisnici su odgovorni za pravilno korištenje sigurnosnih funkcija koje nudi platforma</Text>

              <Text style={styles.heading}>3. Razumne sigurnosne prakse</Text>
              <Text style={styles.paragraph}>Nastojimo primjenjivati sljedeće sigurnosne prakse:</Text>
              <Text style={styles.bulletPoint}>• Sigurna autentifikacija korisnika</Text>
              <Text style={styles.bulletPoint}>• Redovito ažuriranje softvera</Text>
              <Text style={styles.bulletPoint}>• Korištenje sigurnih hosting usluga za pohranu podataka</Text>
              <Text style={styles.bulletPoint}>• Primjena industrijskog standarda za sigurnosnu pohranu lozinki</Text>
              <Text style={styles.bulletPoint}>• Periodičko sigurnosno kopiranje podataka</Text>

              <Text style={styles.heading}>4. Pristup podacima</Text>
              <Text style={styles.paragraph}>
                Pristup podacima u Clock.hr sustavu ograničen je na:
              </Text>
              <Text style={styles.bulletPoint}>• Ovlaštene osobe poslodavca s administratorskim pravima</Text>
              <Text style={styles.bulletPoint}>• Zaposlenike u okviru njihovih definiranih prava</Text>
              <Text style={styles.bulletPoint}>• Ograničeni broj tehničkog osoblja Clock.hr-a u svrhu održavanja sustava i podrške</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Ograničenja i preporuke</Text>
            <Text style={styles.description}>
              Važne informacije o ograničenjima sigurnosnih mjera i preporuke za povećanje sigurnosti.
            </Text>
            
            <View style={styles.contentContainer}>
              <Text style={styles.heading}>1. Ograničenja sigurnosnih mjera</Text>
              <Text style={styles.paragraph}>
                Važno je razumjeti da:
              </Text>
              <Text style={styles.bulletPoint}>• Nijedan softverski sustav ne može jamčiti apsolutnu sigurnost podataka</Text>
              <Text style={styles.bulletPoint}>• Clock.hr nije namjenski dizajniran za obradu visoko povjerljivih ili klasificiranih podataka</Text>
              <Text style={styles.bulletPoint}>• Naše sigurnosne mjere su proporcionalne prirodi podataka koji se uobičajeno obrađuju kroz sustav</Text>
              <Text style={styles.bulletPoint}>• Ne provodimo kontinuirani 24/7 nadzor sustava</Text>

              <Text style={styles.heading}>2. Preporuke korisnicima</Text>
              <Text style={styles.paragraph}>Za maksimalnu zaštitu podataka preporučujemo korisnicima da:</Text>
              <Text style={styles.bulletPoint}>• Koriste jake i jedinstvene lozinke za pristup platformi</Text>
              <Text style={styles.bulletPoint}>• Redovito mijenjaju pristupne podatke</Text>
              <Text style={styles.bulletPoint}>• Ne dijele pristupne podatke među zaposlenicima</Text>
              <Text style={styles.bulletPoint}>• Promptno deaktiviraju račune bivših zaposlenika</Text>
              <Text style={styles.bulletPoint}>• Implementiraju vlastite sigurnosne politike za korištenje platforme</Text>
              <Text style={styles.bulletPoint}>• Redovito provjeravaju i revidiraju pristupne ovlasti</Text>

              <Text style={styles.heading}>3. Postupanje u slučaju sigurnosnog incidenta</Text>
              <Text style={styles.paragraph}>
                U slučaju sumnje na sigurnosni incident:
              </Text>
              <Text style={styles.bulletPoint}>• Korisnici trebaju odmah promijeniti svoje pristupne podatke</Text>
              <Text style={styles.bulletPoint}>• Obavijestiti Clock.hr putem kontakt podataka navedenih na platformi</Text>
              <Text style={styles.bulletPoint}>• Dokumentirati sve sumnjive aktivnosti</Text>
              <Text style={styles.bulletPoint}>• Slijediti upute našeg tima za podršku</Text>

              <Text style={styles.heading}>4. Ograničenje odgovornosti</Text>
              <Text style={styles.paragraph}>
                Clock.hr ne može biti odgovoran za:
              </Text>
              <Text style={styles.bulletPoint}>• Sigurnosne incidente uzrokovane nemarnim postupanjem korisnika</Text>
              <Text style={styles.bulletPoint}>• Neovlašteni pristup zbog dijeljenja ili slabih pristupnih podataka</Text>
              <Text style={styles.bulletPoint}>• Gubitak podataka uzrokovan faktorima izvan naše razumne kontrole</Text>
              <Text style={styles.bulletPoint}>• Posljedice sigurnosnih propusta na strani korisnika</Text>
              <Text style={styles.bulletPoint}>• Štetu nastalu korištenjem sustava na način koji nije u skladu s našim preporukama</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 48,
  },
  section: {
    marginBottom: 48,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 24,
    fontStyle: 'italic',
  },
  contentContainer: {
    backgroundColor: '#F8FAFC',
    padding: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E2E8F0',
  },
  heading: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1E293B',
    marginTop: 24,
    marginBottom: 12,
  },
  paragraph: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 12,
  },
  bulletPoint: {
    fontSize: 15,
    color: '#475569',
    lineHeight: 24,
    marginBottom: 8,
    paddingLeft: 20,
  },
});