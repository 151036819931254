import { useState, useCallback } from 'react';
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Device from 'expo-device';
// Uklonjen import Notifications
// import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTerminalContext } from '../context/TerminalContext';
import { terminalEndpoints } from '../api/terminalApi';
import { useToast } from '../../../context/ToastContext';

type RootStackParamList = {
  Login: undefined;
  Register: undefined;
  Dashboard: undefined;
  Locations: undefined;
  Employees: undefined;
  Breaks: undefined;
  Settings: undefined;
  AccountSettings: undefined;
  CompanySettings: undefined;
  Logs: undefined;
  LocationLogs: undefined;
  LocationDetails: { locationId: string };
  CreateBreak: undefined;
  BreakDetails: { breakId: string };
  TerminalActivation: undefined;
  Terminal: {
    locationId: string;
    locationName: string;
    deactivationPin: string;
  };
  CreateLocation: undefined;
  CreateEmployee: undefined;
  EmployeeDetails: { employeeId: string };
  BreakList: undefined;
  ActiveBreak: { breakId: string };
};

type NavigationProp = NativeStackNavigationProp<RootStackParamList>;

export function useTerminalActivation() {
  const { dispatch } = useTerminalContext();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation<NavigationProp>();
  const { showToast } = useToast();

  const generateDeviceId = useCallback(async () => {
    try {
      const existingDeviceId = await AsyncStorage.getItem('@device_id');
      if (existingDeviceId) {
        return existingDeviceId;
      }

      const newDeviceId = [
        Device.modelName || 'unknown',
        Device.deviceName || 'device',
        Device.deviceYearClass || new Date().getFullYear(),
        Date.now()
      ].join('-');

      await AsyncStorage.setItem('@device_id', newDeviceId);
      return newDeviceId;
    } catch (error: any) {
      console.error('Greška pri generiranju device ID-a:', error);
      const fallbackId = `device-${Date.now()}`;
      return fallbackId;
    }
  }, []);

  const checkTerminalStatus = useCallback(async () => {
    try {
      const locationId = await AsyncStorage.getItem('locationId');
      const terminalActive = await AsyncStorage.getItem('terminalActive');
      const deviceId = await AsyncStorage.getItem('@device_id');
      
      if (locationId && terminalActive === 'true' && deviceId) {
        const status = await terminalEndpoints.getTerminalStatus(locationId, deviceId);
        
        if (status.data.active) {
          dispatch({ 
            type: 'SET_ACTIVE', 
            payload: { 
              terminalId: deviceId,
              locationId: locationId 
            } 
          });
          return true;
        }
      }

      // Ako nema aktivnog terminala, očisti podatke
      await AsyncStorage.multiRemove([
        'locationId',
        'locationName',
        'terminalActive'
      ]);
      return false;
    } catch (error: any) {
      console.error('Greška pri provjeri statusa:', error);
      // U slučaju greške, također očisti podatke
      await AsyncStorage.multiRemove([
        'locationId',
        'locationName',
        'terminalActive'
      ]);
      return false;
    }
  }, [dispatch]);

  const activateTerminal = useCallback(async (activationCode: string, onError?: () => void) => {
    try {
      setLoading(true);
      const deviceId = await generateDeviceId();
      
      const response = await terminalEndpoints.activate(activationCode);
      const { id: locationId, name: locationName } = response.data.location;

      // Spremi podatke
      await AsyncStorage.multiSet([
        ['locationId', locationId],
        ['locationName', locationName],
        ['terminalActive', 'true'],
        ['@terminal_pin', activationCode],
        ['@device_id', deviceId]
      ]);

      // Postavi context
      dispatch({ 
        type: 'SET_ACTIVE', 
        payload: { terminalId: deviceId, locationId } 
      });

      // Notifikacije su uklonjene
      // await Notifications.scheduleNotificationAsync({
      //   content: {
      //     title: 'Terminal aktiviran',
      //     body: `Terminal je uspješno aktiviran za lokaciju ${locationName}`,
      //     sound: true,
      //   },
      //   trigger: null,
      // });

      showToast('Terminal aktiviran', `Terminal je uspješno aktiviran za lokaciju ${locationName}`);

      // Navigiraj na Terminal
      navigation.replace('Terminal', { 
        locationId,
        locationName,
        deactivationPin: response.data.deactivationPin
      });
      
      return true;
    } catch (error: any) {
      console.error('Error activating terminal:', error);
      
      // Prikaži toast obavijest o grešci
      const errorMessage = error.response?.data?.message || error.response?.data?.error || error.message || 'Nepoznata greška';
      showToast('Greška aktivacije', errorMessage, 'error');
      
      // Prikaži i Alert za dodatnu vidljivost
      Alert.alert(
        'Greška kod aktivacije terminala',
        error.response?.data?.message || error.response?.data?.error || error.message || 'Nepoznata greška',
        [{ text: 'OK', onPress: onError }]
      );
      
      return false;
    } finally {
      setLoading(false);
    }
  }, [generateDeviceId, dispatch, navigation, showToast]);

  const deactivateTerminal = useCallback(async (deactivationPin: string) => {
    try {
      setLoading(true);
      const deviceId = await AsyncStorage.getItem('@device_id');
      if (!deviceId) throw new Error('Device ID nije pronađen');

      await terminalEndpoints.deactivate(deactivationPin);
      
      // Očisti lokalne podatke
      await AsyncStorage.multiRemove([
        '@terminal_pin',
        'terminalActive',
        'locationId',
        'locationName'
      ]);

      dispatch({ type: 'SET_INACTIVE' });
      showToast('Terminal deaktiviran', 'Terminal je uspješno deaktiviran');
      return true;
    } catch (error: any) {
      Alert.alert('Greška', 'Neispravan deaktivacijski kod');
      return false;
    } finally {
      setLoading(false);
    }
  }, [dispatch, showToast]);

  return {
    loading,
    checkTerminalStatus,
    activateTerminal,
    deactivateTerminal
  };
}
