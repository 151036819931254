import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { HelpLayout } from '../components/HelpLayout';
import { Ionicons } from '@expo/vector-icons';

interface FAQItem {
  question: string;
  answer: string;
  category: string;
}

const FAQList: FAQItem[] = [
  {
    category: 'Općenito',
    question: 'Što je Clock.hr?',
    answer: 'Clock.hr je profesionalni sustav za evidenciju radnog vremena koji omogućuje jednostavno praćenje dolazaka, odlazaka i pauza zaposlenika. Sustav je potpuno potrebama modernih tvrtki.'
  },
  {
    category: 'Općenito',
    question: 'Koje su prednosti korištenja Clock.hr sustava?',
    answer: 'Glavne prednosti su: automatska evidencija radnog vremena, jednostavno praćenje pauza, generiranje detaljnih izvještaja, mogućnost evidencije djelatnika na više uređaja, te pristup putem web sučelja i Android mobilne aplikacije.'
  },
  {
    category: 'Pristup',
    question: 'Kako mogu pristupiti sustavu?',
    answer: 'Sustavu možete pristupiti putem web preglednika na adresi www.clock.hr ili putem Android mobilne aplikacije. Preporučujemo korištenje desktop uređaja.'
  },
  {
    category: 'Pristup',
    question: 'Što ako djelatnik zaboravi svoj PIN?',
    answer: 'U slučaju zaboravljenog PIN-a, obratit će se svom administratoru koji može generirati novi PIN. Iz sigurnosnih razloga, sustav ne dopušta samostalno resetiranje PIN-a.'
  },
  {
    category: 'Korištenje',
    question: 'Kako djelatnik može prijaviti dolazak na posao?',
    answer: 'Dolazak može prijaviti unosom PIN-a na terminalu u poslovnici.'
  },
  {
    category: 'Korištenje',
    question: 'Kako započeti pauzu?',
    answer: 'Za početak pauze, unesite svoj PIN, odaberite pauzu s liste pauza na terminalu. Po povratku s pauze, obavezno ponovno unesite PIN i odaberite završetak pauze.'
  },
  {
    category: 'Izvještaji',
    question: 'Koji izvještaji su dostupni?',
    answer: 'Dostupni su razni izvještaji: dnevni pregled rada, mjesečni izvještaji, pregled pauza, godišnji odmori, bolovanja te posebni izvještaji prema potrebama tvrtke. Svi izvještaji mogu se izvesti u PDF formatu.'
  },
  {
    category: 'Izvještaji',
    question: 'Kako ispraviti pogrešan unos?',
    answer: 'Ako primijetite pogrešku u evidenciji, obratite se svom nadređenom ili administratoru sustava. Oni imaju ovlasti za ispravljanje pogrešnih unosa uz obavezno bilježenje razloga izmjene.'
  },
  {
    category: 'Tehnička podrška',
    question: 'Kome se obratiti u slučaju problema?',
    answer: 'Za tehničku podršku možete kontaktirati našu službu za korisnike na info@rakicgroup.com. Radno vrijeme podrške je radnim danom od 08:00 do 20:00 sati.'
  },
  {
    category: 'Tehnička podrška',
    question: 'Što ako terminal ne radi?',
    answer: 'U slučaju problema s terminalom, prvo provjerite internetsku vezu. Ako problem i dalje postoji, obavijestite administratora o problemu.'
  }
];

const FAQSection = ({ category, items, expandedItem, setExpandedItem }: {
  category: string;
  items: FAQItem[];
  expandedItem: string | null;
  setExpandedItem: (item: string | null) => void;
}) => {
  return (
    <View style={styles.section}>
      <Text style={styles.categoryTitle}>{category}</Text>
      {items.map((item, index) => (
        <Pressable
          key={index}
          style={[
            styles.questionContainer,
            expandedItem === `${category}-${index}` && styles.questionContainerActive
          ]}
          onPress={() => setExpandedItem(expandedItem === `${category}-${index}` ? null : `${category}-${index}`)}
        >
          <View style={styles.questionHeader}>
            <Text style={styles.question}>{item.question}</Text>
            <Ionicons
              name={expandedItem === `${category}-${index}` ? 'chevron-up' : 'chevron-down'}
              size={20}
              color="#64748B"
            />
          </View>
          {expandedItem === `${category}-${index}` && (
            <Text style={styles.answer}>{item.answer}</Text>
          )}
        </Pressable>
      ))}
    </View>
  );
};

export const FAQ = () => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const categorizedItems = FAQList.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {} as Record<string, FAQItem[]>);

  return (
    <HelpLayout>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>Česta pitanja</Text>
          <Text style={styles.description}>
            Pronađite odgovore na najčešća pitanja o Clock.hr sustavu. Ako ne pronađete odgovor koji tražite, 
            slobodno nas kontaktirajte putem obrasca za podršku.
          </Text>

          {Object.entries(categorizedItems).map(([category, items]) => (
            <FAQSection
              key={category}
              category={category}
              items={items}
              expandedItem={expandedItem}
              setExpandedItem={setExpandedItem}
            />
          ))}
        </View>
      </View>
    </HelpLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  content: {
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  description: {
    fontSize: 16,
    color: '#64748B',
    lineHeight: 24,
    marginBottom: 48,
  },
  section: {
    marginBottom: 40,
  },
  categoryTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1E293B',
    marginBottom: 16,
  },
  questionContainer: {
    backgroundColor: '#F8FAFC',
    borderRadius: 8,
    marginBottom: 8,
    padding: 16,
  },
  questionContainerActive: {
    backgroundColor: '#EFF6FF',
    borderColor: '#BFDBFE',
    borderWidth: 1,
  },
  questionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  question: {
    fontSize: 16,
    fontWeight: '500',
    color: '#1E293B',
    flex: 1,
    marginRight: 16,
  },
  answer: {
    fontSize: 15,
    color: '#64748B',
    lineHeight: 24,
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: '#E2E8F0',
  },
});
